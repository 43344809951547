<template>
  <div class="flexbox underlined">
    <button v-if="category.items.length > 0" type="button" class="site-button" @click="expand">{{category.isExpanded ? '-' : '+'}}</button>
    <button v-else type="button" disabled class="site-button disabled">·</button>
    <div v-if="!isSpecial">
        &nbsp;
    </div>
    <div v-else class="flexbox">
        <p>{{category.name}}</p>
        <img
          v-if="category.items.length > 0"
          src="../../assets/images/expand-all.svg"
          @click="toggleTree(true)"
        />
        <img
          v-if="category.items.length > 0"
          src="../../assets/images/collapse-all.svg"
          @click="toggleTree(false)"
        />
        <category-tree-menu 
            :limited="isSpecial"
            :isOpened="category.menuIsOpened"
            :category="category"
            :treeServices="treeServices" 
            :insertMode="category.insertMode"
            @open-menu="openMenu"
        />
    </div>
    <div class="fullwidth" v-if="!isSpecial" @click="onSelect"  :class="{'greyed-out':category.isGreyedOut}">
        <div class="flexbox first-line">
            <p class="small-muted">id:</p>
            <input v-if="!editable.isReadOnly" type="text" class="form-item number-item" v-model="editable.id"/>
            <p v-else>{{category.id}}</p>
            <p class="small-muted">имя:</p>
            <input type="text" class="form-item" v-model="editable.name"/>
            <img
              v-if="category.items.length > 0"
              src="../../assets/images/expand-all.svg"
              @click="toggleTree(true)"
            />
            <img
              v-if="category.items.length > 0"
              src="../../assets/images/collapse-all.svg"
              @click="toggleTree(false)"
            />
            <category-tree-menu 
                :limited="isSpecial"
                :isOpened="category.menuIsOpened"
                :category="category"
                :treeServices="treeServices" 
                :insertMode="category.insertMode"
                @open-menu="openMenu"
            />
        </div>
        <div class="flexbox">
            <p class="small-muted">цвет:</p>
            <input type="color" class="form-tem" v-model="editable.color" />
            <p class="small-muted ml-1">полное имя:</p>
            <input type="text" class="small form-item" v-model="editable.fullname"/>
        </div>
    </div>
    <div v-if="category.isGreyedOut">
        <button type="button" class="site-button" @click="cancelCut">Отмена</button>
    </div>
  </div>
  <div v-if="category.isExpanded"  :class="{'greyed-out':category.isGreyedOut}">
    <div style="margin-left: 2.5em;" v-for="child in category.items" :key="child.id">
    <category-tree-item :category="child" :treeServices="treeServices"/>
  </div>
  </div>
</template>

<script>
import CategoryTreeMenu from "./CategoryTreeMenu.vue";
export default {
    components:{
        CategoryTreeMenu
    },
    data(){
        return{
            isExpanded: true,
        };
    },
    computed: {
        editable (){
            return this.category;
        },
        isSpecial(){
            return !this.category || this.category.id === 0;
        }
    },
    props: ["category", "treeServices"],
    methods: {
        expand(){
            const cat = this.category;
            cat.isExpanded = !cat.isExpanded;
        },
        onSelect(){
            this.treeServices.select(this.category.id);
        },
        toggleTree(expand){
            this.treeServices.toggleNodes(expand, this.category);
        },
        openMenu(e){
            this.treeServices.openMenu(this.category,e);
        },
        cancelCut(){
            this.treeServices.cancelCut();
        }
    }
}
</script>

<style scoped>
p+p{
    margin-left: 0.3rem;
}
.color-rect {
    min-width: 1rem;
    align-self: stretch;
    margin-right: 0.25rem;
}
.underlined {
    border-bottom: 2px dashed grey;
    padding: 0.4rem 0.6rem;
    max-width: 650px;
}
.first-line{
    align-items: center;
}
.small.form-item{
    font-size: 80%;
}
.form-item{
    flex-grow: 1;
    margin: 0 0.3rem;
}
.number-item{
    max-width: 3rem;
}
.fullwidth{
    width: 100%;
}
.greyed-out{
    pointer-events: none;
    opacity: 0.5;
}
</style>