export default class StorageManager {
    getNeuralHelperId = () => {
        return this.getState("helper-id");
    }
    setNeuralHelperId = id => {
        this.setState("helper-id", id);
    }
    getNeuralNetAddress = () => {
        return this.getState("nnurl");
    }
    setNeuralNetAddress = address => {
        this.setState("nnurl", address);
    }
    getFavoriteCategories = () => {
        return this.getState("favourite-categories") ?? [];
    }
    setFavoriteCategories = list => {
        this.setState("favourite-categories", list);
    }

    getState = stateName => {
        let json = localStorage.getItem(stateName);
        if (!json) {
            return null;
        }
        const state = JSON.parse(json);
        return state;
    }
    setState = (stateName, objValue) =>{
        let json = JSON.stringify(objValue);
        localStorage.setItem(stateName, json);
    }
}