import axios from 'axios'
window.vuebusy = false
export default class DataService {
    constructor() {
        this.notesLink
        this.baseAddress = ''
        this.apiAddress = window.location.pathname
        if (process.env.NODE_ENV == 'development') {
            //this.baseAddress = 'https://localhost:5001'
            this.baseAddress = 'https://localhost:44369'
        }
    }

    getAllData(imageId, layerId, callback) {
        console.log("getAllData___________");
        const data = {
            imageId: imageId,
            layerId: layerId
        }
        const searchParams = new URLSearchParams(data)
        axios.get(this.baseAddress + this.apiAddress + '?' + searchParams)
            // axios.get(this.baseAddress + "/img" + searchParams)

            .then(response => {
                callback(response.data)
            })
            .catch(err => {
                console.log('ERROR', err)
            })
    }
    async getVideoFrame(imageFrameCount, delta, relative, dataSetId, callback, errorCallBack) {
        if (window.vuebusy) {
            return
        }
        window.vuebusy = true
        const data = {
            // imageId: 78318,
            imageFrameCount: imageFrameCount,
            delta: delta,
            relative: relative,
            // dataSetId : 26,
            dataSetId: dataSetId
        }
        const searchParams = new URLSearchParams(data)
        // axios.get("https://localhost:44369/mission/96/GetImage?"+searchParams)
        await axios.get(this.baseAddress + this.apiAddress + 'GetVideoFrame?' + searchParams)
            .then(response => {
                callback(response.data);
                window.vuebusy = false;
            })
            .catch(err => {
                window.vuebusy = false
                if (errorCallBack) {
                    errorCallBack(err)
                }
                // console.log('ERROR', err)
            })
    }
    async getImage(imageId, delta, relative, dataSetId, callback, errorCallBack) {
        if (window.vuebusy) {
            return
        }
        window.vuebusy = true
        const data = {
            // imageId: 78318,
            imageId: imageId,
            delta: delta,
            relative: relative,
             //dataSetId : 26,
            dataSetId: dataSetId,
        }
        const searchParams = new URLSearchParams(data)
        // axios.get("https://localhost:44369/mission/96/GetImage?"+searchParams)
        await axios.get(this.baseAddress + this.apiAddress + 'GetImage?' + searchParams)
            .then(response => {
                callback(response.data);
                window.vuebusy = false;
            })
            .catch(err => {
                window.vuebusy = false
                if (errorCallBack) {
                    errorCallBack(err)
                }
                // console.log('ERROR', err)
            })
    }

    getPageInfo(imageId, layerId, callback) {
        const data = {
            // imageId: 78318,
            imageId: imageId,
            delta: 0,
            relative: false,
            layerId: layerId
        }
        const searchParams = new URLSearchParams(data)
        // axios.get("https://localhost:44369/mission/96/PageInfo?"+searchParams)
        axios.get(this.baseAddress + this.apiAddress + 'PageInfo?' + searchParams)
            .then(response => {
                //console.log('GETPageInfo', response.data)
                callback(response.data)
            })
            .catch(err => {
                console.log('ERROR', err)
            })
    }

    async getMarkup(imageId, layerId, callback, errorCallback) {
        const data = {
            imageId: imageId,
            layerId: layerId
        }
        const searchParams = new URLSearchParams(data)
        await axios.get(this.baseAddress + this.apiAddress + 'GetMarkup?' + searchParams)
            .then(response => {
                console.log("___DataServise--getMarkup___",response.data);
                callback(response.data, imageId)
                // window.vuebusy = false;
            })
            .catch(err => {
                errorCallback(imageId)
                console.log('ERROR', err)
                // swindow.vuebusy = false;
            });
    }

    getExtraMarkup(imageId, layerId, callback, errorCallback) {
        const data = {
            imageId: imageId,
            layerId: layerId
        }
        const searchParams = new URLSearchParams(data)
        axios.get(this.baseAddress + this.apiAddress + 'GetExtraMarkup?' + searchParams)
            .then(response => {
                callback(response.data, imageId)
                // window.vuebusy = false;
            })
            .catch(err => {
                errorCallback(imageId)
                console.log('ERROR', err)
                // swindow.vuebusy = false;
            })
    }

    getNotes(imageId, noteId, callback, errorCallback) {
        const data = {
            imageId: imageId,
            noteId: noteId
        }
        axios.post(this.baseAddress + this.apiAddress + 'GetNotes', data)
            .then(response => {
                callback(response.data, imageId)
            })
            .catch(err => {
                errorCallback(noteId);
                console.log('ERROR', err)
            });
    }

    saveNotes(imageId, drawData, callback) {
        drawData.imageId = imageId;
        const dataToSave = drawData;
        if (process.env.NODE_ENV == 'development') {
            console.log("save notes", dataToSave);
        }
        axios.post(this.baseAddress + this.apiAddress + 'SaveNotes', dataToSave)
            .then(response => {
                callback(response.data, true, null)
            })
            .catch(err => {
                console.log('ERROR', err)
                callback(null, false, err)
            })
    }
    async saveData(data, callback) {
        console.log("Saving layers busy", window.vuebusy);

        if (window.vuebusy) {
            return
        }
        const layers = data.layers;
        if (layers.length <= 0) {
            callback('', true, null)
            return
        }
        const layer = data.layers[0];
        const markup = this.createMarkupObject(data, layer.categories);
        markup.objects = this.getMarkupObjects(layer, data.mapManager);
        const dataToSave = markup
        console.log("SaveMarkup", dataToSave);
        await axios.post(this.baseAddress + this.apiAddress + 'SaveMarkup', dataToSave)
            .then(response => {
                callback(response.data, true, null)
            })
            .catch(err => {
                console.log('ERROR', err)
                callback(null, false, err, err.response.status)
            });
    }
    createMarkupObject(data, categories) {
        const markup = {
            imageId: data.imageId,
            layerId: data.layerId,
            imageName: data.imageName,
            koefficient: data.coordinateMultiplier,
            objects: [],
            categories: categories,
            zeroChanges: data.zeroChanges
        }
        return markup;
    }
    getMarkupObjects(layer, mapManager) {
        const objects = [];
        for (var obj of layer.data) {
            const geo = obj.getSavePoints();
            const flat = geo.map(g => mapManager.toFlat(g));
            const savingObject = {
                id: obj.id,
                type: obj.type,
                category: parseInt(obj.category),
                points: flat,
                comment: obj.comment,
                delete: false,
                trackerId: obj.trackerId,
                locked: obj.locked,
            };
            if (obj.tagList) {
                savingObject.tagList = [...obj.tagList];
            }
            else {
                savingObject.tagList = [];
            }
            if (obj.orientation) {
                savingObject.orientation = obj.orientation.angle;
            }
            objects.push(savingObject)
        }
        return objects;
    }
    saveMarkup(imageId, layerId, imageName, callback) {
        if (window.vuebusy) {
            return
        }
        var layers = window.services.markupLayers;
        if (layers.length <= 0) {
            callback('', true, null)
            return
        }
        var layer = layers[0]
        const edited = window.services.markupManager.edited;
        var zeroChanges = (!edited || edited.length == 0) && (!layer.disposeList || layer.disposeList.length == 0)
        var markup = {
            imageId: imageId,
            layerId: layerId,
            imageName: imageName,
            koefficient: window.coordinateMultiplier,
            objects: [],
            categories: layer.categories,
            zeroChanges: zeroChanges
        }
        if (!zeroChanges) {
            for (var obj of layer.data) {
                const geo = obj.getSavePoints();
                const savingObject = {
                    id: obj.id,
                    type: obj.type,
                    category: parseInt(obj.category),
                    points: geo,
                    comment: obj.comment,
                    delete: false
                };
                if (obj.tagList) {
                    savingObject.tagList = [...obj.tagList];
                }
                else {
                    savingObject.tagList = [];
                }
                if (obj.orientation) {
                    savingObject.orientation = obj.orientation.angle;
                }
                markup.objects.push(savingObject)
            }
        }
        const dataToSave = markup
        console.log(dataToSave);
        axios.post(this.baseAddress + this.apiAddress + 'SaveImage', dataToSave)
            .then(response => {
                callback(response.data, true, null)
            })
            .catch(err => {
                console.log('ERROR', err)
                callback(null, false, err, err.response.status)
            })
    }
    getNeuralMarkup(payload, callback) {
        console.log("URL", payload.url);
        const dataToSave = {
            imageId: payload.imageId,
            bounds: payload.bounds,
            url: payload.url,
            markups: payload.markups
        };
        axios.post(this.baseAddress + this.apiAddress + 'NeuralMarkup', dataToSave)
            .then(response => {
                callback(response.data, true, null)
            })
            .catch(err => {
                console.log('ERROR', err)
                callback(null, false, err)
            });
    }
}
