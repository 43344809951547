<template>
    <div class="project-users">
        <div v-for="user in currentUsers" :key="user.name" class="user-card">
            <div class="card-title">
                <div class="flexbox">
                    <img :src="'https://robohash.org/'+user.name+'.png?set=set4'" width="48" height="48" alt="">
                    <router-link :to="'/userPage/' + user.id">{{user.name}}</router-link>
                    <!-- <p>{{ user.name }}</p> -->
                </div>
            </div>
            <ul>
                <li v-for="role in usersRoles[user.id]" :key="role.role">
                    <label :for="'check'+role.role+user.id">
                        <input type="checkbox" :id="'check'+role.role+user.id" v-model="role.active" />
                        {{ role.label }}
                    </label>
                </li>
            </ul>
        </div>
    </div>
    <div class="flexbox">
        <span>Добавить в проект пользователя</span>
        <select class="input-text" v-model="newProjUser">
            <option v-for="user in possibleUsers" :key="user.id" :value="user">
                {{ user.name }}
            </option>
        </select>
        <button class="site-button" @click="addUserInProject">Добавить</button>
    </div>
    <button class="site-button main-button" @click="saveUsers">Сохранить изменения в пользователях</button>
    <statusElement :statusCode="saveUsersStatusCode"/>
</template>

<script>
    import statusElement from "../statusElement.vue"
    export default {
        components: {
            statusElement,
        },
        data() {
            return {
                users: [
                    { name: "User1 user1", roles: ["admin", "guest", "tagger"] },
                    { name: "User2 user2", roles: ["admin", "guest", "tagger"] },
                    { name: "User3 user3", roles: ["admin", "guest", "tagger"] },
                    { name: "User4 user4", roles: ["admin", "guest", "tagger"] },
                ],
                newProjUser: {},
                //saveUsersStatusCode:"",
            };
        },
        props: ['currentUsers', 'usersRoles', 'possibleUsers','saveUsersStatusCode'],
        emits: ['add-user', 'save-users'],
        methods: {
            addUserInProject() {
                this.$emit('add-user', this.newProjUser);
            },
            saveUsers() {
                this.$emit('save-users');
            }
        }
    };
</script>

<style>
    .input-text {
        flex-grow: 1;
        max-width: 20rem;
        margin-left: 0.6rem;
    }

    .project-users {
        display: flex;
        flex-wrap: wrap;
    }

    .user-card {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        min-width: 250px;
        margin: 1rem;
        transition: 0.3s;
    }

        .user-card:hover {
            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
        }

    .card-title {
        text-align: center;
        background-color: rgba(0, 0, 0, 0.2);
        padding: 0.3rem;
        border-radius: 5px 5px 0 0;
        text-shadow: 1px 1px 5px black;
        font-weight: bold;
        color: white;
    }

    .user-card ul {
        list-style: none;
        padding: 0.2rem;
    }

    .user-card a {
        text-decoration: none;
        color: white;
    }

        .user-card a:hover {
            text-decoration: underline;
        }
</style>