
import { createRouter, createWebHistory } from 'vue-router'

import Home from "../new_components/Home.vue"
import NewProj from "../new_components/NewProj.vue"
import Tasks from "../new_components/Tasks.vue"
import Users from "../new_components/Users.vue"
import UserPage from "../new_components/UserPage.vue"
import NewUser from "../new_components/NewUser.vue"
//import Group from "../new_components/Group.vue"
//import Login from "../new_components/Login.vue"
import ProjSettings from "../new_components/ProjSettings.vue"
//import ProjEdit from "../new_components/ProjEdit.vue"
import PhotoAlbum from "../new_components/PhotoAlbum.vue"
import EditTaskGroup from "../new_components/EditTaskGroup.vue"
import EditCategories from "../new_components/EditCategories.vue"
import TasksGroup from "../new_components/TasksGroup.vue"
import MarkupPage from "../components/markup-page/MarkupPage.vue";
import NotFoundPage from "../components/NotFoundPage.vue";

const routes = [
  
    {
        path: '/newProj',
        name: 'NewProj',
        component: NewProj
    },
    {
        path: '/mission/:id',
        name: 'Mission',
        component: MarkupPage
    },
    {
        path: '/view/',
        name: 'ViewImage',
        component: MarkupPage
    },
    {
        path: '/tasks',
        name: 'Tasks',
        component: Tasks
    },
    {
        path: '/users',
        name: 'Users',
        component: Users
    },
    {
        path: '/newUser',
        name: 'NewUser',
        component: NewUser
    },
    {
        path: '/editUser/:id',
        name: 'EditUser',
        component: NewUser,
        props: true
    },
    {
        path: '/userPage/:id',
        name: 'UserPageWithId',
        component: UserPage,
        props: true
    },
    {
        path: '/userPage',
        name: 'UserPage',
        component: UserPage
    },
    //{
    //    path: '/group/:id',
    //    name: 'Group',
    //    component: Group,
    //    props: true
    //},
    {
        //path: '/tasksGroup/:id',
        path: '/group/:id',
        name: 'TasksGroup',
        component: TasksGroup,
        props: true
    },
    //{
    //    path: '/login',
    //    name: 'Login',
    //    component: Login,
    //},
    {
        path: '/projSettings/:id',
        name: 'ProjSettings',
        component: ProjSettings,
        props: true
    },
    {
        path: '/photoAlbum/:id',
        name: 'PhotoAlbum',
        component: PhotoAlbum,
        props: true
    },
    //{
    //    path: '/projEdit/:id',
    //    name: 'ProjEdit',
    //    component: ProjEdit,
    //    props: true
    //},
    {
        path: '/newTaskGroup/:datasetId',
        name: 'NewTaskGroup',
        component: EditTaskGroup,
        props: true
    },
    {
        path: '/editTaskGroup/:datasetId/:id',
        name: 'EditTaskGroup',
        component: EditTaskGroup,
        props: true
    },
    {
        path: '/editCategories/:id',
        name: 'EditCategories',
        component: EditCategories,
        props: true
    },
    {
        path: '/',
        name: 'Root',
        component: Home
    },
    {
        path: '/Home',
        name: 'Home',
        component: Home
    },
    {
        path: '/:notFound(.*)',
        name: 'NotFound',
        component: NotFoundPage
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router