<template>
  <div
    class="context-menu"
    :class="{ shown: contextMenuIsOpen }"
    v-bind:style="{
      top: `${contextMenuPosition.y}px`,
      left: `${contextMenuPosition.x}px`,
    }"
  >
    <span v-bind:style="{fontSize : '80%', fontWeight: 'bold'}">Координаты мыши:</span>
    <div>{{ contextMenuCoordinates.x }}, {{ contextMenuCoordinates.y }}</div>
    <div><button class="strech" @click="copyCoordinates">Копировать координаты</button></div>
    <div><button class="strech" @click="copyLink">Копировать ссылку</button></div>
    <hr />
    <span v-bind:style="{fontSize : '80%', fontWeight: 'bold'}">Координаты для перехода:</span>
    <div><input type="text" class="strech" v-bind:style="{marginBottom: '0.1em'}"  v-model="contextMenuInput" /></div>
    <div><button class="strech" @click="flyToCoordinates">Перейти к координатам</button></div>
  </div>
  <div
    class="map-marker"
    :class="{ shown: markerIsShown }"
    v-bind:style="{
      top: `${markerPosition.y}px`,
      left: `${markerPosition.x}px`,
    }"
  ></div>
</template>

<script>
import ClipBoardManager from "../services/ClipBoardManager";
import BrowserManager from "../services/browserManager";

export default {
  data() {
    return {
      contextMenuIsOpen: false,
      contextMenuPosition: { x: 0, y: 0 },
      contextMenuCoordinates: { x: 0, y: 0 },
      contextMenuInput: "",
      markerIsShown: false,
      markerPosition: { x: -100, y: -100 },
      zoomIn : false,
      clipBoardManager : null
    };
  },
  methods: {
    onToggleContextMenu(open, e) {
      if(!open){
        this.contextMenuIsOpen = open;
        this.markerIsShown = false;
        return;
      }
      this.contextMenuPosition = {
        x: e.originalEvent.clientX,
        y: e.originalEvent.clientY,
      };
      var lat = e.latlng.lat;
      var lng = e.latlng.lng;
      const m = this.$services.mapManager;
      const xy = m.toStrFlat({lat:lat, lng:lng});
      const zoom = m.getZoom();
      this.contextMenuCoordinates = { x: xy.x, y: xy.y, zoom: zoom };
      this.contextMenuIsOpen = open;
      this.markerIsShown = false;
    },
    copyCoordinates() {
      this.contextMenuIsOpen = false;
      this.clipBoardManager.copyTextToClipboard(this.contextMenuCoordinates.x + ", " + this.contextMenuCoordinates.y + ", "+this.contextMenuCoordinates.zoom);
      this.$messageManger.openPopup(this.contextMenuCoordinates.x + ", " + this.contextMenuCoordinates.y + ", "+this.contextMenuCoordinates.zoom);
    },
    copyLink() {
      this.contextMenuIsOpen = false;
      const mapInfo = this.$services.mapManager.getMapState();
      const data = {
        objx:this.contextMenuCoordinates.x,
        objy:this.contextMenuCoordinates.y,
        zoom: mapInfo.zoom
      }
      const linkText = this.browserManager.getQueryLink(data);
      console.log(linkText);
      this.clipBoardManager.copyTextToClipboard(linkText);
      this.$messageManger.openPopup(linkText);
    },
    flyToCoordinates() {
      this.contextMenuIsOpen = false;
      this.$services.mapManager.fire("flyto", {type:"flatstr", text: this.contextMenuInput, zoomIn: this.zoomIn});
      this.showMarkerPosition();
      this.zoomIn = false;
    },
    flyToGeoCoordinates(lat,lng,zoom){
      this.$services.mapManager.fire("flyto", {type:"geo", lat: lat, lng:lng, zoom: zoom});
      this.showMarkerPosition();
    },
    flyToObject(params){
      this.zoomIn = true;
      const x = parseFloat(params.get("objx"));
      const y = parseFloat(params.get("objy"));
      const zoom = parseFloat(params.get("zoom"));
      if(!isNaN(x) && !isNaN(y)){
        this.contextMenuInput = x+", "+y;
        if(!isNaN(zoom)){
          this.contextMenuInput += ", "+zoom;
        }
        window.vueContext.actions.onMarkupInit = this.flyToCoordinates;
        return true;
      }      
      return false;
    },
    showMarkerPosition(){
      const mapDiv = window.document.querySelector("#mapid");
      const size = mapDiv.getBoundingClientRect();
      this.markerPosition = {
        x: size.x + size.width / 2 - 0,
        y: size.y + size.height / 2 - 5,
      };
      const map = this.$services.mapManager.map;
      if (map) {
        map.once("zoomend", () => {
          this.markerIsShown = true;
        });
      } 
    },
    flyToGeoPoint(params){
      const lat = parseFloat(params.get("maplat"));
      const lng = parseFloat(params.get("maplng"));
      let zoom = parseFloat(params.get("mapzoom"));
      if(!isNaN(lat) && !isNaN(lng)){
        if(isNaN(zoom)){
          zoom = null;
        }
        window.vueContext.actions.onMarkupInit = () => this.flyToGeoCoordinates(lat, lng, zoom);
        return true;
      }
    }
  },
  mounted() {
      this.$services.mapManager.on("contextmenu", (args) => this.onToggleContextMenu(args.open, args.e));
      this.$services.eventAggregator.on("showmarker", () => this.showMarkerPosition());
      this.clipBoardManager = new ClipBoardManager(this.$messageManger);
      this.browserManager = new BrowserManager();
      let uri = window.location.search.substring(1);  
      let params = new URLSearchParams(uri);
      if(this.flyToObject(params)){
        return;
      }
      this.flyToGeoPoint(params);
  },
};
</script>

<style>
.context-menu{
  position: absolute;
  z-index: 5000;
  background: white;
  border-radius: 0.2em;
  padding: 0.2em;
  display: none;
  box-shadow: 0.2em 0.5em 1em rgba(0, 0, 0, 0.3);
}
.context-menu.shown{
  display: block;
}
.map-marker{
  position: absolute;
  z-index: 5000;
  background: rgba(255, 255, 0, 0.7);
  border-radius: 5px;
  width: 10px;
  height: 10px;
  visibility: hidden;
  transform: scale(10);
  transition: transform 1.5s ease-in;
}
.map-marker.shown{
  visibility: initial;
  transform: scale(0);
}
</style>