import axios from 'axios'
import ApiResponse from './ApiResponse';
export default class BackEndService {

    async get(link) {
        var req = await axios.get(link);
        var reply = new ApiResponse(req);
        return reply;
    }
    async post(link, data) {
        var req = await axios.post(link, data);
        var reply = new ApiResponse(req);
        return reply;
    }

    async createGroupVue(dataObj) {
        return this.post("/Tasks/CreateGroupVue", dataObj);
    }

    async updateTaskGroupLayers(dataObj) {
        return this.post("/Tasks/UpdateTaskGroupLayers", dataObj);
    }

    async getProjForGroupCreate(datasetId, id) {
        const link = id
            ? "/Tasks/GetProjForGroupCreate/" + id + "?dataset=" + datasetId
            : "/Tasks/GetProjForGroupCreate?dataset=" + Number(datasetId)
        return this.get(link)
    }

    async editCategoriesVue(id) {
        var link = "/Tasks/EditCategoriesVue/" + id
        return this.get(link)
    }

    async DivisionImagesByTasks(taskGroupDivisionViewModel) {
        return this.post('/Tasks/DivisionImagesByTasks', taskGroupDivisionViewModel);
    }

    async imageList(dataObj) {
        return this.post('/Tasks/ImageList', dataObj);
    }

    async getTaskGroup(dataObj) {
        return this.post('/Tasks/GetTaskGroup', dataObj);
    }

    async getUsersJson() {
        return this.post('/Users/GetUsersJson');
    }

    async taskUser(newUserChanges) {
        return this.post("/Tasks/TaskUser", newUserChanges);
    }

    async getHomeJson() {
        return this.post('/Projects/GetHomeJson');
    }

    async vueLogin(loginModel) {
        return this.post('/Users/VUELogin', loginModel);
    }
    async logout() {
        return this.get('/Users/Logout');
    }

    async saveNewProj(proj) {
        return this.post('/Projects/SaveNewProj', proj);
    }

    async saveUser(newUser) {
        return this.post('/Users/SaveUser', newUser);
    }

    async getUserInfo(id) {
        return this.post('/Users/GetUserInfo/' + id);
    }

    async restoreGroup(MarkupItemInfo) {
        return this.post("/Tasks/RestoreGroup", MarkupItemInfo);
    }

    async cancelGroup(MarkupItemInfo) {
        return this.post("/Tasks/CancelGroup", MarkupItemInfo);
    }

    async archiveDataSet(DataSetActionsViewModel) {
        return this.post("/Home/ArchiveDataSet", DataSetActionsViewModel);
    }

    async updateReleaseTime(MarkupItemInfo) {
        return this.post("/Tasks/UpdateReleaseTime", MarkupItemInfo);
    }

    async publishDraft(MarkupItemInfo) {
        return this.post("/Tasks/PublishDraft", MarkupItemInfo);
    }

    async revertToDraft(MarkupItemInfo) {
        return this.post("/Tasks/RevertToDraft", MarkupItemInfo);
    }

    async imageListVue(id) {
        return this.post("/Home/ImageListVue/" + id);
    }
    //список всех картинок датасета(фотоальбома)
    async dataSetImagesVue(id) {
        return this.post("/Home/GetDataSetImagesVue/" + id);
    }
    async getMissionImages(dataObj) {
        return this.post("/Tasks/GetMissionImages", dataObj);
    }
    async getTaskGroupId() {
        return this.post("GetTaskGroupId");
    }

    async editDescription(dataObj) {
        return this.post("/Projects/EditDescription", dataObj);
    }

    async projectDelete(id) {
        return this.get("/Projects/Delete/" + id);
    }

    async toggleArchivationVue(dataObj) {
        return this.post("/Projects/ToggleArchivationVue", dataObj);
    }

    async generateApiKeys(dataObj) {
        return this.post("/Projects/GenerateApiKeys", dataObj);
    }

    async projectsRoles(dataObj) {
        return this.post("/Projects/Roles", dataObj);
    }

    async saveTags(dataObj) {
        return this.post("/Projects/Tags", dataObj)
    }

    async getProjSettings(id) {
        return this.post('/Projects/ProjectVue/' + id);
    }

    async saveCategories(dataObj) {
        return this.post("/Projects/Categories", dataObj);
    }
    async saveGroupCategories(dataObj) {
        return this.post("/Tasks/EditCategories", dataObj);
    }

    async getTasks() {
        return this.post('/Tasks/GetTasks');
    }

    async getTasksItems() {
        return this.post('/Tasks/GetTasksItems');
    }

    async getUserDataJson(id) {
        return this.post('/Users/GetUserDataJson/' + id);
    }
    async createNewTask(dataObj) {
        return this.post('/Tasks/CreateNewTask', dataObj)
    }
    async deleteTaskFromGroup(task) {
        return this.post('/Tasks/DeleteTaskFromGroup', task);
    }
    async saveTaskToGroup(tasksObj) {
        return this.post('/Tasks/SaveTasksToGroup', tasksObj);
    }
    async createFinalLayer(FinalLayerInfo) {
        return this.post('/Tasks/CreateFinalLayer', FinalLayerInfo);
    }

    async getCurrentUser() {
        return this.post('/Users/GetCurrentUser/');
    }

    async VideoTracking(data) {
        return this.post('/videoTracking/Tracking', data);
    }

    async lockMarkupItem(data){
        return this.post('/videoTracking/LockMarkupItem', data);
    }
    async deleteAcross(data) {
        return this.post('/videoTracking/DeleteAcross', data);
    }
    async getTrackerId(data) {
        return this.post('/videoTracking/GetTrackerId', data);
    }


}