import RTreeManager from "./RtreeManager";
import ClusterObject from "../objects/ClusterObject";
export default class ClusterManager {
  clusters = new Object();
  currentZoom = -100;
  constructor(mapManager) {
    this.mapManager = mapManager;
  }
  calculate(zoom, layer, force) {
    const list = layer.data;
    if (!(zoom in this.clusters) || zoom !== this.currentZoom || force) {
      this.currentZoom = zoom;
      this.clusters[zoom] = { list: [], tree: new RTreeManager() };
    } 
    else {
      return;
    }
    const listCopy = [...list];
    const zoomCluster = this.clusters[zoom];
    if (window.appstate.maxZoom - zoom < 1 || !window.appstate.hasClusterization) {
      zoomCluster.list = listCopy;
      zoomCluster.tree.addItems(zoomCluster.list);
      return;
    }
    for (const obj of listCopy) {
      if (!obj.getShapeCenter) {
        console.warn("CANNNOT CLUSTER SHAPE!", obj);
        continue;
      }
      const otherCategory = layer.categories[99999];
      const category = layer.categories[obj.category];
      const addWithCategory = (!category && otherCategory && otherCategory.isOn) || (category && category.isOn);
      if (!addWithCategory) {
        continue;
      }
      const latlng = obj.getShapeCenter();
      const k = Math.pow(2, window.appstate.maxZoom);
      const m = Math.pow(2, zoom - (window.appstate.maxZoom - 8));
      let cYId = Math.floor((-latlng.lat * k) / (40000 / m));
      let cXId = Math.floor((latlng.lng * k) / (40000 / m));
      let key = cXId + "_" + cYId;
      if (!zoomCluster[key]) {
        zoomCluster[key] = [];
        zoomCluster[key].type = "cluster";
        zoomCluster[key].labels = [];
        zoomCluster.list.push(zoomCluster[key]);
      }
      zoomCluster[key].push(obj);
      this.updateBounds(zoomCluster[key], obj);
      this.updateLabel(zoomCluster[key], obj);
    }
    for (const objCluster of zoomCluster.list) {
      if(objCluster.length === 1){
        objCluster.obj = objCluster[0];
      }
      else {
        objCluster.obj = new ClusterObject(this.mapManager);
        objCluster.obj.createNew(objCluster.getBounds(), objCluster.labels);
        const firstObj = objCluster[0];
        if(firstObj){
          objCluster.obj.changeOpacity(firstObj.fillOpacity, firstObj.labelOpacity);
        }
      }
      
    }
    zoomCluster.tree.addItems(zoomCluster.list);
    // console.log("clusters!", this.clusters);
  }
  createClusterShape(groupBounds, id) {
    const x = groupBounds.getNorthWest().lng;
    const y = groupBounds.getNorthWest().lat;
    const x2 = groupBounds.getSouthEast().lng;
    const y2 = groupBounds.getSouthEast().lat;
    const points = [
      [y, x],
      [y, x2],
      [y2, x2],
      [y2, x],
    ];
    const options = {
      color: "black",
      fillColor: "yellow",
      weight: 3,
      shape: "cluster",
      id: id,
      category: 99999,
      comment: "comment",
      orientation: { angle: null },
      tagList: [],
      dashArray: "10 5",
    };
    const shape = window.L.polygon(points, options);
    return shape;
  }
  updateLabel(cluster, obj) {
    let category = "неизвестно";
    let catColor = "red";
    if (obj.itsLayer) {
      var catList = obj.itsLayer.categories;
      var catId = obj.category;
      let cat = catList[catId];
      if (cat) {
        category = cat.name;
        catColor = cat.color;
      }
    }
    const label = cluster.labels.find((l) => l.name == category);
    if (!label) {
      cluster.labels.push({ name: category, count: 1, categoryId: catId, color : catColor });
    } else {
      label.count++;
    }
  }
  updateBounds(cluster, obj){
    //const group = L.featureGroup();
    if(!cluster.bounds){
      const bb = obj.getBounds();
      cluster.bounds = window.L.latLngBounds(bb.getSouthWest(), bb.getNorthEast());
      cluster.getBounds = () => {
        return cluster.bounds;
      };
    }
    else{
      cluster.bounds.extend(obj.getBounds());
    }
  }
  updateCategoryCount(){
    for (let [key, value] of Object.entries(this.clusters)) {
      console.log(key, value);
  }
  }
}
