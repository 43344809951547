<template>
    <div style="text-align:center" v-if="isLoading">
        <h2>Загрузка</h2>
        <br />
        <IsLoadingItem />
    </div>
    <div v-if="!isLoading">
        <div>
            <h2>Опорный слой</h2>
            <span>
                Выберите опорный слой для помощи разметчику
            </span>
            <select v-model="selectedRefLayer">
                <option value="0">Без слоя</option>
                <option v-for="baseLayer in taskGroup.baseLayers" :key="baseLayer.id" :value="baseLayer.id">{{baseLayer.name}}</option>
            </select>
        </div>
        <div v-if="!hasUsers">
            <h2>Копирование разметки</h2>
            <span>
                Выберите слой, если хотите скопировать разметку в группу миссий
            </span>
            <select v-model="selectedFromLayer">
                <option value="0">Без слоя</option>
                <option v-for="baseLayer in taskGroup.baseLayers" :key="baseLayer.id" :value="baseLayer.id">{{baseLayer.name}}</option>
            </select>
        </div>
        <div class="mt-2">
            <button class="site-button main-button ml-0" style="background-color: #1b6ec2" @click="UpdateLayers">Сохранить</button>
        </div>
    </div>
</template>

<script>

    import BackEndService from "../../services/BackEndService.js"

    import IsLoadingItem from "../IsLoadingItem.vue"

    export default {
        components: {
            IsLoadingItem,
        },
        props: ['id', 'datasetId'],
        data() {
            return {
                taskGroup: {},
                selectedRefLayer: 0,
                selectedFromLayer: 0,
                isLoading: false,
                hasUsers: false,
            };
        },
        methods: {
            async UpdateLayers(){
                var dataObj = {
                    id: this.id,
                    refLayerId: this.selectedRefLayer,
                    markupSourceId: this.selectedFromLayer,
                };
                var b = new BackEndService;
                var req = await b.updateTaskGroupLayers(dataObj);

                console.log("CreateGroup", req);
            },
            // async CreateGroup() {

            //     var dataObj = {
            //         id: 0,//если создавать новый
            //         name: this.newTaskGroupName,
            //         description: this.newTaskGroupDescription,
            //         //layer: this.taskGroup.baseLayers.find(L => L.id == this.selectedLayerId).name,
            //         layerId: this.selectedLayerId,
            //         dataSetId: Number(this.datasetId),

            //         projectId: this.selectedProjectId,
            //         refLayerId: this.selectedRefLayer,
            //         classSource: this.selectedClassSource,
            //     };
            //     if (this.id) {
            //         dataObj.id = this.id;
            //     }
            //     console.log(dataObj);

            //     //var req=await axios.post("/Tasks/CreateGroupVue", dataObj);


            //     var b = new BackEndService;
            //     var req = await b.createGroupVue(dataObj);

            //     console.log("CreateGroup", req);
            //     this.$router.push('/PhotoAlbum/' + this.datasetId)
            // },

            async getInfo() {
                this.isLoading = true;

                var b = new BackEndService;
                var req = await b.getProjForGroupCreate(this.datasetId, this.id);

                this.taskGroup = req.data;
                console.log("req", req);
                //if (this.id) {
                //    this.newTaskGroupName = this.taskGroup.name;
                //    this.newTaskGroupDescription = this.taskGroup.description;
                //    this.selectedLayerId = this.taskGroup.layerId;
                //    //this.selectedProjectId = ;
                //}
                this.selectedRefLayer = this.taskGroup.refLayerId;
                this.isLoading = false;
                if(!this.taskGroup.tasks)
                {
                    return;
                }
                const ids = this.taskGroup.tasks.map(t=>t.user.id);
                const sum = ids.reduce((partialSum, a) => partialSum + a, 0);
                if(sum > 0)
                {
                    this.hasUsers = true;
                }
            },
        },
        mounted() {
            console.log("info", this.datasetId, this.id,);
            this.getInfo();
        },
    }
</script>

<style>
</style>