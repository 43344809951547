<template>
  <footer class="footer-bar">
    <div>
      <p>© {{year}} - Программа разметки {{version}} - МФТИ</p>
    </div>
    <div class="center-element">
      <input type="text" ref="imageNumberInput" v-model="imageNumber" style="text-align: right; width: 5em;" @keypress="goTo" />
      <span style="margin: 0 0.2em;">из</span>
      <span>{{ imageCount }}</span>
    </div>
    <div class="footer-telemetry">
      <p>
        <span>Шаг: {{jumpStep}}</span>
        <span style="margin-left: 1em;">
          <strong>Координата:</strong>
          <span id="map-coordinate" style="width: 10rem">{{coordinate}}</span>
        </span>
        Уровень карты: <span>{{mapZoom}}</span>
      </p>
    </div>
  </footer>
</template>

<script>
import {toFixed} from "../../services/mathHelpers/geometryHelper";
export default {
  props: {
    imageCount: {
      type: Number,
      required: true,
    },
    number: {
      type: Number,
      required: true,
    },
  },
  watch: {
      // whenever question changes, this function will run
      number(newValue) {
        if (this.imageNumber !== newValue) {
          this.imageNumber = newValue;
        }
      }
    },
  data() {
    return {
      imageNumber: 1,
      mapZoom: "---",
      coordinate: "-----,-----",
      jumpStep: 1,
    };
  },
  computed : {
    version(){
      return this.$services.version;
    },
    year(){
      var date = new Date(Date.now());
      return date.getFullYear();
    }
  },
  emits: ["go-to"],
  methods: {
    goTo(event) {
      if (event.key === "Enter") {
        this.$emit("go-to", this.imageNumber);
        const input = this.$refs.imageNumberInput;
        if(input){
          input.blur();
        }
      }
    },
    onZoom(z){
      const maxZoom = window.appstate.maxZoom;
      var dZoom = (z - maxZoom);
      var scale = Math.pow(2, dZoom) * 100;
      this.mapZoom = toFixed(z, 2) + " (" + toFixed(scale, 2) + "%)";
    },
    onMouseMoved(latlng){
      const mapManager = this.$services.mapManager;
      const point = mapManager.toFlat(latlng);
      const x = point.x;
      const y = point.y;
      if(this.$services.state.mapSystem === 'gps'){
          this.coordinate = toFixed(latlng.lat, 5) + "; " + toFixed(latlng.lng, 5);
      }
      else{
          this.coordinate = toFixed(x, 2) + "; " + toFixed(y, 2);
      }
    },
    onMapReady(map){
      const zoom = map.getZoom();
      this.onZoom(zoom);
    },
    onSetStep(number){
      this.jumpStep = number;
    }
  },
  mounted(){
    this.$services.mapManager.on("zoom", this.onZoom);
    this.$services.mapManager.on("mousemoved", this.onMouseMoved);
    this.$services.mapManager.on("mapready", this.onMapReady);
    this.$services.eventAggregator.on("keystep", k => this.onSetStep(k));
  }
};
</script>

<style>
.footer-bar {
  height: 2rem;
  display: flex;
  align-items: center;
}
.footer-bar div {
  align-content: center;
}
.footer-bar p {
  margin: 0;
}
.center-element {
  margin-left: auto;
  margin-right: auto;
}
#map-coordinate {
  display: inline-block;
}
</style>