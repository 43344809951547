<template>
  <nav class="menu" id="main-menu" :class="{ 'is-open': isOpen }">
    <div>
      <div class="tooltip">
        <input
        type="text"
        class="search-line"
        id="serch-line"
        ref="searchInput"
        v-if="isOpen"
        v-model="searchStr"
        @input.prevent="search"
        @focus="openMenu"
        autocomplete="off"
        v-on:keyup="(e) => onKeyDown(e)"
        v-on:keyup.down="arrowDown"
        v-on:keyup.up="arrowUp"
      />
        <span class="tooltiptext">{{searchStr}}</span>
      </div>
      
    </div>

    <div class="menu-dropdown" v-if="showMenu">
      <ul class="nav-menu">
        <li
          v-for="tool in showList"
          :key="tool.key"
          :id="tool.refname"
          :ref="tool.refname"
        >
          <p
            :class="{ 'word-select': tool.isSelected, 'word-item': true }"
            @click="(e) => itemClick(tool, e)"
          >
            <span
              v-for="part in tool.parts"
              :key="part.key"
              :class="{
                'word-highlight': part.isHighlighted,
                'word-item': true,
              }"
              >{{ part.text }}</span
            >
          </p>
          <p
            v-if="tool.fullNameParts"
            :class="{
              'word-select': tool.isSelected,
              'word-item': true,
            }"
            style="padding-top: 0em"
            @click="(e) => itemClick(tool, e)"
          >
            <small
              v-for="part in tool.fullNameParts"
              :key="part.key"
              :class="{
                'word-highlight': part.isHighlighted,
              }"
              >{{ part.text }}</small
            >
          </p>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import highlight from "../../helpers/Highlighter";
export default {
  data() {
    return {
      showMenu: false,
      selected: -1,
      searchStr: "",
      isOpen: true,
      toolList: [],
      showList: [],
    };
  },
  props: {
    categories: {
      type: Array,
      required: true,
    }
  },
  emits: ["category-selected"],
  methods: {
    setSearchStr(name) {
      this.searchStr = name;
    },
    openMenu() {
      this.showMenu = true;
      const field = this.$refs.searchInput;
      if(field){
          const len = field.value.length;
          field.setSelectionRange(0, len);
      }
    },
    closeMenu() {
      this.showMenu = false;
      this.$refs.searchInput?.blur();
    },
    onKeyDown(e) {
      if (e.key === "Escape") {
        this.searchStr = "";
      } else if (e.key === "Enter") {
        if (!this.showList[this.selected]) {
          return;
        }
        this.onItemSelected(this.showList[this.selected]);
        this.closeMenu();
      }
    },
    arrowUp() {
      this.selected = this.selected - 1;
      if (this.selected < 0) {
        this.selected = 0;
      }
      this.showList[this.selected].isSelected = true;
      this.showList[this.selected + 1].isSelected = false;
      var element = this.$refs[this.showList[this.selected].refname];
      element.scrollIntoView(false);
    },
    arrowDown() {
      if (this.selected >= this.showList.length - 1) {
        this.showList[this.selected].isSelected = true;
        return;
      } else {
        this.selected = this.selected + 1;

        if (this.selected - 1 >= 0) {
          this.showList[this.selected - 1].isSelected = false;
        }
        this.showList[this.selected].isSelected = true;
        var element = this.$refs[this.showList[this.selected].refname];
        element.scrollIntoView(false);
      }
    },
    itemClick(tool, e) {
      e.preventDefault();
      this.onItemSelected(tool);
      if (tool.action) {
        tool.action();
      }
    },
    onItemSelected(category){
        this.searchStr = category.text;
        this.$emit("category-selected", category);
    },
    setItem(category){
        this.searchStr = category.name;
        const index = this.showList.findIndex(l=>l.id === category.id);
        if(index >= 0){
          this.selected = index;
          this.showList[this.selected].isSelected = true;
        }
    },
    windowClick(e) {
      const source = e.srcElement;
      if (source.classList.contains("search-line")) {
        return;
      }
      this.closeMenu();
    },
    search() {
      var filtered = this.toolList.filter(
        (v) =>
          this.searchStr.length == 0 ||
          v.Name.toLowerCase().includes(this.searchStr.toLowerCase()) ||
          (v.FullName != null &&
            v.FullName.toLowerCase().includes(this.searchStr.toLowerCase()))
      );
      this.showList = [];
      let i = 0;
      for (const name of filtered) {
        const item = {
          text: name.Name,
          parts: highlight(name.Name, this.searchStr),
          isSelected: false,
          refname: "word" + i,
          id: name.ID
        };
        if (name.FullName != null) {
          item.fullNameParts = highlight(name.FullName, this.searchStr);
        }
        this.showList.push(item);
        i++;
      }
      this.selected = -1;
    },
    initShowList(categoryList){
        const list = [];
        for (const category of categoryList) {
            list.push({
                ID: category.id,
                Name: category.name,
                FullName: category.fullname,
            });
        }
        this.toolList = list;
        this.showList = [];
        let i = 0;
        for (const obj of this.toolList) {
          const item = {
            text: obj.Name,
            fullname: obj.FullName,
            parts: [
              {
                text: obj.Name,
                isHighlighted: false,
              },
            ],
            fullNameParts: [{ text: obj.FullName, isHighlighted: false }],
            isSelected: false,
            refname: "word" + i,
            id: obj.ID
          };
          this.showList.push(item);
          i++;
        }
    }
  },
  mounted() {
    
    window.document.addEventListener("click", this.windowClick);
  },
};
</script>

<style scoped>
.button-tree {
  width: 100%;
}
.search-line {
  width: calc(100% - 8px);
}
.menu-toggle {
  width: 100%;
}
.menu-toggle.main-button {
  margin: 0 0 0 0;
}
.menu {
  margin: 0.5rem 0;
  width: 100%;
  position: relative;
}
.menu-dropdown {
  width: 160%;
  display: none;
  position: absolute;
  overflow: scroll;
  /* bottom: 100%; */
  top: 100%;
  left: 0;
  margin: 0;
  height: 30vh;
  overflow: auto;
  transform: translateX(-40%);
  z-index: 9001;
}
.menu.is-open .menu-dropdown {
  display: block;
}
.nav-menu {
  margin: 0;
  padding-left: 0;
  border: 1px solid #ccc;
  list-style: none;
  background-color: white;
  color: #fff;
}

.nav-menu > li {
  cursor: pointer;
}
.nav-menu > li + li {
  border-top: 1px solid #ccc;
}

.nav-menu > li > p {
  display: block;
  padding: 0.5em 1em 0em 1em;
  color: #000;
  font-weight: normal;
  text-decoration: none;
  margin: 0;
}

.nav-menu > li > a:hover {
  background-color: lightgray;
}
.word-highlight {
  background-color: yellow;
}
.word-select {
  background-color: lightskyblue;
}
</style>