import ShapeParam from "./ShapeParam";
import {distance} from "../../services/mathHelpers/geometryHelper";
export default class RulerShape extends ShapeParam {
    type = "ruler";
    constructor(mapManager, options) {
        super(mapManager, options);
        this.points = [];
        this.size = 5;
        this.shape = window.L.polyline(this.points, { shape: "ruler", dashArray: "10 5", color: "orange"});
        console.log("sahpe", this.shape);
        const heightTimes = 1;
        this.lengthLabel = new window.L.ClickableTooltip({
            direction: 'center',
            permanent: true,
            noWrap: false,
            offset: [0, -10*heightTimes - 12],
            className: "ruler-distance",
            labelText: "1meter"
        });

    }
    changeColor() {
    }
    draw(points) {
        let geo = this.toGeo(points);
        this.shape.setLatLngs(geo);
        this.drawCorners();
        this.drawLength();
    }
    drawCorners() {
        this.undrawCorners();
        let points = this.getGeoPoints();
        let R = this.getCircleR();
        for (let i = 0; i < points.length; i++) {
            this.addCorner(points[i], R, "orange", i);
        }
        if(this.selectedCornerIndex >= 0){
            this.selectCorner(this.selectedCornerIndex);
        }
    }
    drawLength = () => {
        if(!this.lengthLabel){
            return;
        }
        var geo = this.shape.getLatLngs();
        if (geo.length === 1) {
            geo = geo[0];
        }

        const p1 = this.mapManager.toFlat(geo[0]);
        const p2 = this.mapManager.toFlat(geo[1]);
        const dist = distance(p1,p2);
        const textDistance = dist.toFixed(1);

        this.lengthLabel.setContent(`${textDistance} пикс.`);
        const halfLat = 0.5*(geo[0].lat + geo[1].lat);
        const halfLng = 0.5*(geo[0].lng + geo[1].lng);
        this.lengthLabel.setLatLng(new window.L.LatLng(halfLat, halfLng));
    }
    add() {
        this.shape.addTo(this.map);
        this.lengthLabel.addTo(this.map);
    }
    remove() {
        this.shape.removeFrom(this.map);
        this.lengthLabel.removeFrom(this.map);
    }

    move(latlng) {
        let lat = latlng.lat;
        let lon = latlng.lng;
        if (this.selectedCorner) {
            var geo = this.getGeoPoints();
            geo[this.selectedCorner.options.index] = { lat: lat, lng: lon };
            this.shape.setLatLngs(geo);
            this.selectedCorner.setLatLng({ lat: lat, lng: lon });
            this.drawLength();
        }
    }
}