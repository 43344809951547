<template>
  <h3>Список изображений</h3>
  <div class="imageList">
    <div
      v-for="image in imageList.items"
      :key="image.id"
      class="imageListItem"
      :class="{'bring-to-front':imageStates[image.id]}"
      @mouseover="(e) => onImageHover(e, image.id)"
      @mouseleave="onImageLeave(image.id)"
    >
      <router-link :to="'/view/?image=' + image.id">
        {{ image.name }}
      </router-link>
      <image-preview
        :isShown="imageStates[image.id]"
        :id="image.id"
        :position="imagePos[image.id]"
      />
      <!-- <div class="imageThumb">
        <div v-if="imageStates[image.id]">
          <img :src="imageSrcs[image.id]" alt="" width="500" height="200">
        </div>
        <div v-else>
          <is-loading-item />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import ImagePreview from "../../components/shared/ImagePreview.vue";
export default {
  components: {
    ImagePreview,
  },
  props: ["imageList"],
  data() {
    return {
      baseAddress: "https://localhost:5001",
      apiAddress: "",
      imageStates: {},
      imagePos: {},
    };
  },
  methods: {
    onImageHover(e, id) {
      this.imageStates[id] = true;
      this.imagePos[id] = { x: e.clientX, y: e.clientY };
    },
    onImageLeave(id) {
      this.imageStates[id] = false;
    },
  },
};
</script>

<style>
.imageList {
  display: flex;
  flex-wrap: wrap;
  background: #f6f6f6;
  min-height: 600;
}

.imageListItem {
  margin: auto;
  margin-bottom: 0.7em;
  position: relative;
}
.bring-to-front{
  z-index: 1;
}
</style>