import {distance} from "./mathHelpers/geometryHelper";
export default class InputHelper{
    constructor(){
        this.mouseDownPosition = {x:0,y:0};
    }
    mouseMoved = (e) => {
        let mouseUpPosition = {x:e.clientX,y:e.clientY};
        let dist = distance(mouseUpPosition, this.mouseDownPosition);
        if(dist > 0){
            return true;
        }
        return false;
    }
}