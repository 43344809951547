import ShapesMath from "./shapesMath";
export default class PolygonHelper {
    epsilon = 10000;
    isSimple(P) {
        for (let j = 0; j < P.length; j++) {
            for (let i = j + 1; i < P.length; i++) {
                if (Math.abs(j - i) > 1 && Math.abs(j - i) < P.length - 1) {

                    let J = (j + 1) % P.length;
                    let I = (i + 1) % P.length;
                    let line1 = ShapesMath.GetAb(P[j], P[J]);
                    let line2 = ShapesMath.GetAb(P[i], P[I]);
                    if (!line1 || !line2) {
                        continue;
                    }
                    let cP = ShapesMath.GetIntersectionPoint(line1.a, line1.b, line2.a, line2.b);
                    if (!cP) {
                        continue;
                    }
                    let isIn1 = this.pointInSection(cP, P[j], P[J])
                    let isIn2 = this.pointInSection(cP, P[i], P[I])
                    if (isIn1 && isIn2) {
                        return false;
                    }
                }
            }
        }
        return true;
    }
    pointInSection(p, p1, p2) {
        p.x = Math.round((p.x + Number.EPSILON) * this.epsilon) / this.epsilon;
        p.y = Math.round((p.y + Number.EPSILON) * this.epsilon) / this.epsilon;

        const xMin = Math.min(p1.x, p2.x);
        const xMax = Math.max(p1.x, p2.x);
        const yMin = Math.min(p1.y, p2.y);
        const yMax = Math.max(p1.y, p2.y);
        const res = yMin <= p.y && p.y <= yMax && xMin <= p.x && p.x <= xMax;
        
        return res;
    }
}