<template>
    <div style="text-align:center" v-if="isLoading">
        <h2>Загрузка</h2>
        <br />
        <IsLoadingItem />
    </div>
    <div v-if="!isLoading" class="page-container">
        <div class="tree-div">
            <category-tree ref="categoryTree" @on-load="treeLoaded" />
        </div>
        <!--<span>
            мусор
        </span>-->
        <div class="" style="border-top: 1px solid lightgrey; margin: 1.2em;">
            <div class="flexbox">
                <span class="flex-grower" style="max-width:250px">Синхронизировать с группой классов из проекта: </span>
                <select class="flex-grower" v-model="selectedGroup">
                    <option v-for="group in projectGroups" v-bind:value="group" :key="group.id">{{group.name}}</option>
                </select>
                <button class="site-button main-button" type="button" @click="syncronize">Ок!</button>
            </div>

            <div class="flexbox">
                <label for="sync-supercategory-checkbox" style="margin: 0.4rem;">
                    <input id="sync-supercategory-checkbox" type="checkbox" v-model="shouldSyncSuperCategory">
                    Синхронизировать суперкатегории
                </label>
                <label for="sync-fullname-checkbox" style="margin: 0.4rem;">
                    <input id="sync-fullname-checkbox" type="checkbox" v-model="shouldSyncFullname">
                    Синхронизировать полное имя
                </label>
            </div>
            <div style="border-bottom: 1px solid lightgrey;">
                <p v-if="shouldSyncFullname || shouldSyncSuperCategory" style="color:orangered; font-size:90%; font-weight: bold;">
                    Внимание! При синхронизации полного имени и суперкатегорий могут возникнуть несоответствия!
                </p>
            </div>
            <div class="flexbox" style="border-bottom: 1px solid lightgrey">
                <span>Цвет для всех класссов группы</span>
                <input class="ml-1" type="color" v-model="unicolor" />
                <button class="site-button" type="button" @click="applyGroupColor">задать всем классам</button>
            </div>
            <div>
                <button type="button" class="site-button main-button" :disabled="saveSendInfo.loading" @click="save">Сохранить изменения в классах</button>
                <statusElement :statusCode="savingStatusCode" />
                <!--<span class="ml-2 badge" v-bind:class="saveSendInfo.classList">{{saveSendInfo.status}}</span>-->
                <!--<p v-if="hasError">{{errorMessage}}</p>-->
            </div>
        </div>

    </div>

    <!--<span>Источник классов группы миссий</span>-->
</template>

<script>
    import BackEndService from "../../services/BackEndService.js"
    import IsLoadingItem from "../IsLoadingItem.vue"
    import SendInfo from "../../helpers/SendInfo.js"
    import CategoryTree from "../categories/CategoryTree.vue"
    import statusElement from "../statusElement.vue"


    export default {
        props: ['id'],
        components: {
            IsLoadingItem,
            CategoryTree,
            statusElement
        },
        data() {
            return {
                tree: null,
                data: {},
                markupGroup: { markupCategories: [] },
                projectGroups: [],
                isLoading: false,
                selectedGroup: null,
                shouldSyncSuperCategory: false,
                shouldSyncFullname: false,
                unicolor: "#ff0000",
                saveSendInfo: new SendInfo(),
                errorMessage: "",
                hasError: false,
                savingStatusCode:"",
            };
        },
        methods: {
            async getCategories() {
                this.isLoading = true;
                var b = new BackEndService;
                var req = await b.editCategoriesVue(this.id)
                this.data = req.data;
                this.createMarkupGroup();
                this.createTree(this.data.group.name, this.data.markupCategories);
                this.isLoading = false;
            },
            updatecategories() {
            },
            createMarkupGroup() {
                this.markupGroup = {
                    id: this.data.group.id,
                    name: this.data.group.name,
                    projectId: 0,
                    unicolor: "#ff0000",
                    markupCategories: this.data.markupCategories,
                }
                this.projectGroups = this.data.projectGroups;
                if (this.projectGroups && this.projectGroups.length > 0) {
                    this.selectedGroup = this.projectGroups[0];
                }
                console.log("MARKUP GROUP", this.markupGroup);
            },
            syncronize() {
                this.$refs.categoryTree.syncronize(this.selectedGroup);
            },
            applyGroupColor() {
                this.$refs.categoryTree.applyGroupColor(this.unicolor);
            },
            async save() {
                this.savingStatusCode = "0";
                const categories = this.$refs.categoryTree.enlistCategories();
                const dataObj = {
                    group: { id: this.markupGroup.id },
                    markupCategories: categories
                };
                var b = new BackEndService;
                var rep = await b.saveGroupCategories(dataObj)
                this.savingStatusCode = rep.statusCode;
            },
            createTree(name, categories) {
                if (this.$refs.categoryTree) {
                    this.$refs.categoryTree.init(name, categories);
                }
            },
            treeLoaded() {
                if (this.$refs.categoryTree && this.data.group) {
                    this.$refs.categoryTree.init(this.data.group.name, this.data.markupCategories);
                }
            }
        },
        mounted() {
            console.log(this.id);
            this.getCategories();
        },
    }
</script>

<style scoped>
    .btn {
        padding: 0.25rem 0.5rem;
        font-size: .875rem;
        line-height: 1.5;
        background-color: #6c757d;
        border-color: #6c757d;
        border-radius: 0.2rem;
        color: white;
        text-decoration: none;
    }

        .btn:hover {
            text-decoration: underline;
        }

    .page-container{
        display:flex;
        flex-direction:column;
        justify-content:flex-end;
    }
    .tree-div{
        min-height: 200px;
    }
</style>