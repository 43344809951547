<template>
  <div class="tree-back">
    <div class="tree-box">
      <div style="width: 100%; display: flex">
        <p class="title">Дерево классов</p>
        <button @click="close" class="close-button">⨉</button>
      </div>
      <div style="width: 100%; display: flex">
          <input
        type="text"
        class="search-line"
        @input.prevent="search"
        @keydown.esc="onEscape"
        v-model="searchStr"
        ref="text-tree-field"
      />
      <button @click="clear" type="button" class="tool-button main-button">очистить</button>
      </div>
      <div style="overflow: auto; height: 100%">
        <category-tree-item
          :treeroot="treemodel"
          @toggle-expand="toggleExpand"
          @toggle-view="toggleView"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CategoryTreeItem from "./CategoryTreeItem.vue";
import TreeFS from "../../helpers/TreeForSearch";

export default {
  data() {
    return {
      searchStr: "",
      treemodel: { Name: "Пусто", branch: [] },
    };
  },
  provide() {
    const vm = this;
    return {
      onNodeSelected: (node) => {
        vm.putInLine(node);
      },
    };
  },
  emits: ["put-in-line", "close-tree", "on-mount"],
  methods: {
    search() {
      if (
        this.searchStr == null ||
        this.searchStr == "" ||
        this.searchStr == " " ||
        this.searchStr == "  "
      ) {
        this.treemodel.searchClear(this.treemodel);
        return;
      }

      this.treemodel.search(this.treemodel, this.searchStr);
    },
    onEscape(){
        this.clear();
    },
    setValue(name){
        this.searchStr = name;
        this.search();
        const field = this.$refs["text-tree-field"];
        if(field){
            field.value = name;
            field.focus();
          const len = name.length;
          field.setSelectionRange(0, len);
        }
    },
    toggleView(parent) {
      for (var rt of parent.branch) {
        rt.isVisible = true;
      }
    },
    toggleExpand(shouldExpand, _, recursive) {
      this.treemodel.isExpanded = shouldExpand;
      if (recursive) {
        this.toggleExpandRecursive(shouldExpand, this.treemodel);
      }
    },
    toggleExpandRecursive(shouldExpand, parent) {
      for (var rt of parent.branch) {
        rt.isExpanded = shouldExpand;
        this.toggleExpandRecursive(shouldExpand, rt);
      }
    },
    putInLine(root) {
      this.searchStr = root.name;
      this.$emit("put-in-line", root);
    },
    close() {
      this.$emit("close-tree");
    },
    clear(){
        this.searchStr = "";
        this.search();
        const field = this.$refs["text-tree-field"];
        if(field){
            field.focus();
        }
    },
    init(categories){
        let newTree = {Name:"Объекты", Branch:[]};
        let idList = {};
        for (const category of categories) {
            const item = {ID:category.id, Name: category.name, FullName: category.fullname, Branch:[], isVisible: true};
            idList[item.ID] = item;
        }
        for (const category of categories) {
            const child = idList[category.id];
            const parent = idList[category.supercategoryId];
            if(parent && category.supercategoryId !== 0){
                parent.Branch.push(child);
            }
            else{
                newTree.Branch.push(child);
            }
        }
        var t = new TreeFS(newTree, false);
        t.create(newTree, false);
        this.treemodel = t;
    }
  },
  mounted() {
    this.$emit("on-mount");
  },
  components: {
    CategoryTreeItem,
  },
};
</script>

<style scoped>
.search-line {
    flex-grow: 1;
}
.tree-back {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9000;
  height: auto;
  background-color: rgba(255, 255, 255, 0.7);
}
.tree-box {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 3em;
  padding: 0.2em;
  background-color: white;
  border: solid 2px black;
  bottom: 0;
  display: flex;
  flex-flow: column;
}
.title {
  text-align: center; 
  flex: 1 1.5em;
  font-size: 200%;
  margin: 0;
}
.close-button {
  margin-left: auto;
  border: solid 0 black;
  font-size: 200%;
  font-weight: bold;
  background-color: white;
  flex: 0 1 1.5em;
}
</style>