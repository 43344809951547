<template>
  <div class="left-bottom hot-keys" style="z-index: 1000">
                <pre class="hot-keys-info">Клавиши</pre>
                <pre class="hot-keys-legend">
Клавиши
Q : Скрыть/показать разметку
W : Скрыть/показать надписи
E : Скрыть/показать комментарии
R : Скрыть/показать группировку разметки
T : Скрыть/показать сетку
I : Открыть нейросетевой помощник

A : К предыдущему изображению*
S : К следующему изображению*
F : На весь экран
G : К предыдущему объекту*
Shift + G : К предыдущему объекту того же класса*
H : К следующему объекту*
Shift + H : К следующему объекту того же класса*


<span style="font-size: 80%">Инструменты:</span>
C : Редактирование
V : Рамка
B : Многоугольник
N : Центр
M : Ориентированная рамка
&lt; : +/- Точка
&gt; : Тень
L : Линейка

Ctrl + Y : Повторить
Ctrl + Z : Отменить
Delete : Удалить выделенные объекты
Escape : Очистить выделение

<span style="font-size: 80%">*) если перед нажатием на клавишу набрать цифры,
то шаг будет соответствовать введенному числу.
Шаг отображается внизу экрана справа</span></pre>
            </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.left-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-right: auto;
}
.hot-keys{
  margin: 0.5em;
  color: white;
  min-width: 2rem;
}
.hot-keys-legend{
  margin: 0;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0.2rem;
  display: none;
}
.hot-keys-info{
  margin: 0;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0.2rem;
  display: block;
  color: white;

}
.hot-keys:hover .hot-keys-legend{
  animation: createBox .25s;
  display: block;
  color: white;
  transform-origin: left bottom;
}
.hot-keys:hover .hot-keys-info{
  display: none;
}
@keyframes createBox {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}

</style>