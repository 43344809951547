<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width = "24px"
    height = "24px"
    viewBox="0 0 60 60"
    style="enable-background: new 0 0 60 60"
    xml:space="preserve"
  >
    <g>
      <path
        d="M21,8c0.552,0,1-0.447,1-1V4c0-0.553-0.448-1-1-1s-1,0.447-1,1v3C20,7.553,20.448,8,21,8z"
      />
      <path
        d="M21,52c-0.552,0-1,0.447-1,1v3c0,0.553,0.448,1,1,1s1-0.447,1-1v-3C22,52.447,21.552,52,21,52z"
      />
      <path
        d="M4.029,45.557l-2.121,2.121c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293
		s0.512-0.098,0.707-0.293l2.121-2.121c0.391-0.391,0.391-1.023,0-1.414S4.42,45.166,4.029,45.557z"
      />
      <path
        d="M4.029,14.443c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414l-2.121-2.121
		c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414L4.029,14.443z"
      />
      <path
        d="M26.694,8.75c0.086,0.023,0.174,0.034,0.259,0.034c0.441,0,0.846-0.294,0.965-0.741l0.777-2.898
		c0.143-0.533-0.173-1.082-0.707-1.225c-0.531-0.144-1.082,0.174-1.225,0.707l-0.777,2.898C25.844,8.059,26.16,8.607,26.694,8.75z"
      />
      <path
        d="M15.306,51.25c-0.532-0.144-1.082,0.173-1.225,0.707l-0.777,2.898c-0.143,0.533,0.173,1.082,0.707,1.225
		c0.086,0.023,0.174,0.034,0.259,0.034c0.441,0,0.846-0.294,0.965-0.741l0.777-2.898C16.156,51.941,15.84,51.393,15.306,51.25z"
      />
      <path
        d="M27.918,51.957c-0.143-0.534-0.69-0.852-1.225-0.707c-0.534,0.143-0.85,0.691-0.707,1.225l0.777,2.898
		c0.12,0.447,0.524,0.741,0.965,0.741c0.085,0,0.173-0.011,0.259-0.034c0.534-0.143,0.85-0.691,0.707-1.225L27.918,51.957z"
      />
      <path
        d="M14.082,8.043c0.12,0.447,0.524,0.741,0.965,0.741c0.085,0,0.173-0.011,0.259-0.034c0.534-0.143,0.85-0.691,0.707-1.225
		l-0.777-2.898c-0.143-0.534-0.692-0.852-1.225-0.707c-0.534,0.143-0.85,0.691-0.707,1.225L14.082,8.043z"
      />
      <path
        d="M8.634,10.581c0.186,0.321,0.521,0.5,0.867,0.5c0.169,0,0.342-0.043,0.499-0.134c0.479-0.276,0.642-0.888,0.366-1.366
		l-1.5-2.598C8.59,6.504,7.978,6.341,7.5,6.617C7.021,6.894,6.858,7.505,7.134,7.983L8.634,10.581z"
      />
      <path
        d="M10,49.053c-0.479-0.276-1.09-0.113-1.366,0.366l-1.5,2.598c-0.276,0.479-0.112,1.09,0.366,1.366
		c0.157,0.091,0.33,0.134,0.499,0.134c0.346,0,0.682-0.179,0.867-0.5l1.5-2.598C10.642,49.94,10.479,49.329,10,49.053z"
      />
      <circle cx="34" cy="33" r="1" />
      <circle cx="47" cy="18" r="1" />
      <circle cx="48" cy="35" r="1" />
      <path
        d="M29.5,18c-1.93,0-3.5,1.57-3.5,3.5s1.57,3.5,3.5,3.5s3.5-1.57,3.5-3.5S31.43,18,29.5,18z"
      />
      <path
        d="M59.901,28.037c-0.034-0.367-0.095-0.725-0.148-1.086c-0.039-0.258-0.07-0.517-0.118-0.774
		c-0.089-0.483-0.203-0.955-0.325-1.425c-0.032-0.121-0.06-0.242-0.093-0.362c-0.146-0.524-0.313-1.037-0.497-1.543
		c-0.016-0.045-0.027-0.091-0.044-0.136l-0.279-0.754l-0.003,0C55.229,14.359,47.731,9,39,9c-1.761,0-3.462,0.242-5.097,0.652
		l0.963-1.668c0.276-0.479,0.112-1.09-0.366-1.366c-0.479-0.276-1.09-0.113-1.366,0.366l-1.5,2.598
		c-0.147,0.256-0.161,0.546-0.077,0.807c-0.529,0.202-1.047,0.424-1.554,0.666C27.16,9.696,24.135,9,21,9C9.42,9,0,18.421,0,30
		s9.42,21,21,21c3.135,0,6.16-0.696,9.003-2.054c0.508,0.242,1.025,0.465,1.554,0.666c-0.085,0.26-0.071,0.551,0.077,0.807
		l1.5,2.598c0.186,0.321,0.521,0.5,0.867,0.5c0.169,0,0.342-0.043,0.499-0.134c0.479-0.276,0.642-0.888,0.366-1.366l-0.963-1.668
		c1.497,0.375,3.053,0.596,4.657,0.629l0.006,0.018l0.281-0.004C38.899,50.993,38.949,51,39,51c0.089,0,0.175-0.012,0.264-0.013
		l0.021,0c0.484-0.007,0.964-0.035,1.442-0.074c0.258-0.021,0.515-0.045,0.77-0.075c0.475-0.056,0.946-0.128,1.414-0.216
		c0.254-0.048,0.505-0.104,0.755-0.162c0.359-0.082,0.716-0.175,1.071-0.276c0.362-0.103,0.717-0.216,1.071-0.338
		c0.255-0.088,0.508-0.181,0.761-0.279c0.437-0.17,0.865-0.354,1.287-0.552c0.176-0.082,0.354-0.159,0.529-0.247l0.437-0.219
		c5.223-2.777,9.123-7.696,10.559-13.576l0.041-0.001l0.15-0.74c0.001-0.003,0.001-0.006,0.002-0.009l0.006-0.028
		c0.048-0.237,0.068-0.479,0.108-0.717c0.076-0.451,0.159-0.899,0.205-1.358C59.964,31.414,60,30.706,60,30
		C60,29.344,59.963,28.69,59.901,28.037z M48.542,46.404C47.706,44.399,45.742,43,43.5,43c-3.033,0-5.5,2.468-5.5,5.5
		c0,0.15,0.027,0.301,0.041,0.451c-2.751-0.138-5.348-0.855-7.671-2.046c-0.305-0.156-0.602-0.322-0.896-0.492
		c-0.183-0.107-0.364-0.218-0.543-0.331c-0.245-0.153-0.49-0.305-0.727-0.467c-0.408-0.283-0.806-0.577-1.19-0.89
		c-0.101-0.081-0.195-0.169-0.294-0.252c-0.355-0.302-0.702-0.613-1.034-0.94c-0.064-0.063-0.129-0.125-0.192-0.189
		c-1.683-1.703-3.051-3.719-3.999-5.955c0,0,0-0.001,0-0.001c-0.224-0.53-0.42-1.073-0.596-1.626
		c-0.021-0.067-0.044-0.133-0.064-0.2c-0.172-0.561-0.322-1.133-0.442-1.715c-0.007-0.036-0.012-0.074-0.019-0.11
		c-0.103-0.516-0.18-1.042-0.241-1.572c-0.016-0.143-0.032-0.286-0.045-0.429C20.036,31.164,20,30.586,20,30
		s0.036-1.164,0.088-1.737c0.013-0.143,0.029-0.286,0.045-0.429c0.061-0.53,0.137-1.056,0.241-1.572
		c0.007-0.036,0.012-0.074,0.019-0.11c0.12-0.582,0.27-1.153,0.442-1.715c0.02-0.067,0.043-0.133,0.064-0.2
		c0.176-0.552,0.372-1.096,0.596-1.625c0,0,0-0.001,0-0.001c0.948-2.237,2.316-4.252,3.999-5.955
		c0.063-0.064,0.128-0.126,0.192-0.189c0.332-0.327,0.679-0.638,1.034-0.94c0.099-0.083,0.193-0.171,0.294-0.252
		c0.384-0.313,0.782-0.608,1.19-0.89c0.236-0.163,0.482-0.314,0.727-0.467c0.179-0.113,0.36-0.224,0.543-0.331
		c0.294-0.17,0.591-0.336,0.896-0.492C32.963,11.765,35.891,11,39,11c7.76,0,14.439,4.682,17.386,11.365
		C53.818,23.254,52,25.702,52,28.5c0,3.187,2.308,5.84,5.339,6.39C56.031,39.787,52.822,43.904,48.542,46.404z"
      />
    </g>
  </svg>
</template>