<template>
    <div class="login-page">
        <h1 class="center-text">Вход</h1>
        <div class="login-from">
            <div>
                <span class="small-muted text">Логин</span>
                <input type="text" aria-describedby="Логин" placeholder="Логин" v-model="username" />
            </div>
            <div>
                <span class="small-muted text">Пароль</span>
                <input type="password" aria-describedby="Пароль" placeholder="Пароль" v-model="password" @keyup.enter="login"/>
            </div>
            <button type="button" class="site-button main-button ml-0 mt-2"  @click="login">Войти</button>
        </div>
        <div v-if="loginError" style="text-align: center; font-size: x-large; color: rgb(220, 53, 69);">
            неправильный логин или пароль
        </div>
    </div>

</template>
<script>
    //import axios from 'axios'
    import BackEndService from "../services/BackEndService.js"

    export default {
        data() {
            return {
                username: "",
                password: "",
                loginError: false,
            };
        },
        emits: ['get-current-user'],
        methods: {
            async login() {
                let loginModel = {};
                loginModel = { Username: this.username, Password: this.password };
                var b = new BackEndService;
                var er;
                var req = await b.vueLogin(loginModel).catch(function (error) {
                    if (error.response) {
                        //console.log("error.response", error.response);
                        er = error.response
                    }
                });
                this.loginError = er ? true : false;

                if (req) {
                    this.$emit('get-current-user');
                    let link = "/";
                    this.$router.push(link);
                }
            },
        },
        mounted() { },
    }
</script>
<style scoped>
    input {
        line-height: 2;
    }

    .btn:hover {
        text-decoration: underline;
    }

    .login-from {
        max-width: 30rem;
        margin-right: auto;
        margin-left: auto;
    }

        .login-from input {
            width: 100%;
        }
</style>
