<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="24px"
    height="24px"
    viewBox="0 0 325 325"
    style="enable-background: new 0 0 325 325"
    xml:space="preserve"
  >
    <g>
      <path
        d="M235.723,89.431c3.566,3.567,8.3,5.532,13.33,5.532c5.088,0,9.859-2.003,13.438-5.641
		c7.307-7.431,7.253-19.475-0.12-26.848c-3.567-3.568-8.301-5.533-13.329-5.533c-5.086,0-9.857,2.004-13.435,5.641
		C228.296,70.015,228.349,82.059,235.723,89.431z M244.161,70.997c1.304-1.325,3.037-2.056,4.88-2.056
		c1.823,0,3.543,0.717,4.844,2.018c2.733,2.734,2.756,7.197,0.05,9.949c-1.304,1.325-3.037,2.055-4.882,2.055
		c-1.823,0-3.544-0.716-4.845-2.018C241.475,78.213,241.453,73.75,244.161,70.997z"
      />
      <path
        d="M323.241,83.284L241.717,1.758C240.591,0.632,239.065,0,237.474,0c-1.591,0-3.117,0.632-4.242,1.758L1.759,233.23
		c-2.344,2.343-2.344,6.142,0,8.484l81.524,81.527c1.126,1.126,2.651,1.758,4.243,1.758c1.591,0,3.117-0.632,4.242-1.758
		l24.422-24.422l0.001-0.001c0,0,0,0,0.001-0.001l29.988-29.988l0.001-0.001l0.001-0.001l29.982-29.981
		c0.004-0.004,0.009-0.007,0.013-0.011c0.004-0.004,0.007-0.009,0.011-0.013l29.967-29.968c0.005-0.004,0.01-0.007,0.014-0.011
		c0.004-0.004,0.007-0.009,0.011-0.014l29.978-29.977c0,0,0.002-0.002,0.003-0.003c0.001-0.001,0.002-0.002,0.003-0.003
		l29.984-29.985c0.001-0.001,0.003-0.002,0.004-0.003c0,0,0.002-0.003,0.003-0.004l57.088-57.088
		C325.585,89.426,325.585,85.627,323.241,83.284z M261.907,136.133l-13.373-13.372c-2.342-2.342-6.142-2.343-8.485,0.001
		c-2.343,2.343-2.343,6.142,0.001,8.485l13.372,13.371l-21.506,21.506l-13.373-13.372c-2.342-2.342-6.142-2.343-8.485,0.001
		c-2.343,2.343-2.343,6.142,0.001,8.485l13.372,13.371l-21.507,21.507l-13.374-13.373c-2.342-2.342-6.142-2.343-8.485,0.001
		c-2.343,2.343-2.343,6.142,0.001,8.485l13.373,13.372l-21.506,21.507l-13.375-13.373c-2.344-2.342-6.142-2.343-8.485,0.001
		c-2.343,2.343-2.343,6.143,0.001,8.485l13.374,13.372l-21.507,21.507l-13.373-13.373c-2.344-2.343-6.144-2.344-8.485-0.001
		c-2.344,2.344-2.344,6.143-0.001,8.485l13.373,13.374l-21.506,21.507L98.573,272.72c-2.344-2.342-6.142-2.343-8.485,0.001
		c-2.343,2.343-2.343,6.143,0.001,8.485l13.374,13.372l-15.937,15.936l-73.04-73.042L237.474,14.485l73.04,73.041L261.907,136.133z"
      />
    </g>
  </svg>
</template>