<template>
    <div style="text-align:center" v-if="isLoading">
        <h2>Загрузка</h2>
        <br />
        <IsLoadingItem />
    </div>

    <div class="mx-2 project-page" v-if="!isLoading">
        <h2 class="page-header" style="text-align:center" v-if="!isEditDescription">
            <span class="muted">Проект</span>
            <span> '{{project?.markupProject?.title}}'</span>
            <button class="svg-btn" v-if="!isEditDescription" @click="isEditDescription=!isEditDescription">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z" />
                </svg>
            </button>
        </h2>
        <h4 class="center-text" v-if="!isEditDescription">{{projDescription}}</h4>
        <div class="center-text mt-4">
            <span v-if="isEditDescription">Имя проекта: <input type="text" style="font-size: x-large" v-model="project.markupProject.title" /></span>
        </div>
        <div v-if="isEditDescription">
            Описание:
            <textarea class="description-edit" placeholder="введите описание" v-model="project.markupProject.description"></textarea>
        </div>
        <div class="flexbox">
            <button class="site-button main-button" v-if="isEditDescription" @click="isEditDescription=!isEditDescription; editDescription();">Сохранить изменения</button>
            <button class="site-button" v-if="isEditDescription" @click="isEditDescription=!isEditDescription;">отмена</button>
        </div>

        <div class="section-tabs">
            <div class="section-tab" :class="{active:toggleCategories}" @click="open('classes')"><p>Классы</p></div>
            <div class="section-tab" :class="{active:togglePhotoAlbum}" @click="open('alboms')"><p>Фотоальбомы</p></div>
            <div class="section-tab" :class="{active:toggleUsers}" @click="open('users')"><p>Пользователи</p></div>
            <div class="section-tab" :class="{active:toggleTags}" @click="open('tags')"><p>Теги</p></div>
            <div class="section-tab" :class="{active:toggleActions}" @click="open('actions')"><p>Действия</p></div>
        </div>
        <div class="tab-content">
            <project-categories v-show="toggleCategories" ref="projectCategoriesContainer" :project="project" :idManagerMarkupGroup="idManagerMarkupGroup" />
            <project-tags v-if="toggleTags" ref="projectTagsContainer" @save-tags="saveTags" @on-ready="initTags" />
            <project-datasets v-if="togglePhotoAlbum" :datasets="project?.datasets" />
            <div v-if="toggleUsers">
                <project-users :currentUsers="usedUser"
                               :usersRoles="usersRoles"
                               :possibleUsers="project.users"
                               :saveUsersStatusCode="saveUsersStatusCode"
                               @add-user="addUserInProject"
                               @save-users="saveUsers" />
            </div>
            <ProjectActions v-if="toggleActions" :id="id" :status="project.status" />
        </div>
        <!--<h3>Действия</h3>
        <div>
            <button class="site-button" @click="generateApiKeys">Создать ключи API</button>
            <statusElement :statusCode="generateApiKeysStatusCode" />
            <button class="site-button" @click="toggleArchivation">Архивировать</button>
            <statusElement :statusCode="archivationStatusCode" />
            <button style="background-color:#dc3545" class="site-button" @click="askDeleteProject">Удалить</button>
        </div>
        <confirmation-modal :isShown="showModal" @close-win="onModalClose" :messasge="modalMessage" />
        <modal :isShown="modalIsShown" :messasge="modalMessage" @close-win="onModalClose"/>-->

    </div>

</template>
<script>
    //import axios from 'axios'
    import BackEndService from "../services/BackEndService.js"

    import IsLoadingItem from "./IsLoadingItem.vue"
    import IdManager from "../helpers/IdManager.js"
    //import SendInfo from "../helpers/SendInfo.js"
    import ProjectUsers from "./project-page/PorjectUsers.vue";
    import ProjectTags from "./project-page/ProjectTags.vue";
    import ProjectDatasets from "./project-page/ProjectDatasets.vue";
    import ProjectCategories from "./project-page/ProjectCategories.vue";
    import ProjectActions from "./project-page/ProjectActions.vue";

    //import statusElement from "./statusElement.vue"

    export default {
        components: {
            IsLoadingItem,
            ProjectUsers,
            ProjectTags,
            ProjectDatasets,
            ProjectCategories,
            ProjectActions,
            //statusElement,
        },

        props: ['id'],

        data() {
            return {
                saveUsersStatusCode:"",
                //archivationStatusCode: "",
                //generateApiKeysStatusCode:"",

                project: {},
                isLoading: false,

                //showCategories: true,
                toggleCategories: true,
                toggleTags: false,
                togglePhotoAlbum: false,
                toggleUsers: false,
                toggleActions:false,

                isEditDescription: false,

                listOrTree: [],
                unicolors: [],
                jsonGroup: "",
                idManagerMarkupGroup: {},
                newProjUser: {},

                usedUser: [],
                usersRoles: [],
                pullProjectRoles: [{
                    role: "Admin",
                    label: "Администратор",
                    active: false,
                },
                {
                    role: "Supervisor",
                    label: "Руководитель",
                    active: false,
                },
                {
                    role: "SeniorTagger",
                    label: "Старший Разметчик",
                    active: false,
                },
                {
                    role: "Tagger",
                    label: "Разметчик",
                    active: false,
                },
                {
                    role: "DataManager",
                    label: "Управляющий данными",
                    active: false,
                },
                {
                    role: "Developer",
                    label: "Разработчик ",
                    active: false,
                },
                {
                    role: "Viewer",
                    label: "Наблюдатель",
                    active: false,
                }],

                //apiKeysGenerationInfo: new SendInfo(),
                showModal: false,
                modalIsShown: false,
                onModalClose: ()=>{},
                modalMessage: "",
            };
        },
        computed: {
            projDescription() {
                const description = this.project.markupProject?.description;
                if (!description || description.length === 0) {
                    return 'Нет описания';
                }
                return description;
            }
        },
        methods: {
            async editDescription() {
                var dataObj = {
                    name: this.project.markupProject.name,
                    title: this.project.markupProject.title,
                    id: this.project.markupProject.id,
                    description: this.project.markupProject.description,
                    projectRoles: this.project.projectRoles,
                };
                var b = new BackEndService();
                await b.editDescription(dataObj)
            },
            //askDeleteProject(){
            //    this.modalMessage = `Вы дейстивтельно хотите удалить проект "${this.project?.markupProject?.title}"?`;
            //    this.onModalClose = answer => this.projectDelete(answer);
            //    this.showModal = true;
            //},
            //async projectDelete(answer) {
            //    this.showModal = false;
            //    if(answer !== 'yes'){
            //        return;
            //    }
            //    var b = new BackEndService;
            //    try {
            //        await b.projectDelete(this.id);
            //        let link = "/";
            //        this.$router.push(link);
            //    } catch (error) {
            //        this.modalIsShown = true;
            //        this.modalMessage = "Невозможно удалить проект";
            //        this.onModalClose = () => this.modalIsShown = false;
            //    }
            //},
            //async toggleArchivation() {
            //    this.archivationStatusCode = "0";
            //    var BoolStatus = !(this.project.status === "Archived");
            //    var dataObj = {
            //        id: Number(this.id),
            //        archive: BoolStatus,
            //    }
            //    var b = new BackEndService();
            //    var rep= await b.toggleArchivationVue(dataObj);
            //    if (rep) {
            //        this.archivationStatusCode = rep.statusCode;
            //    }
            //    else {
            //        this.archivationStatusCode = "404";
            //    }
            //    console.log("rep", rep);
            //},

            //async generateApiKeys() {
            //    this.generateApiKeysStatusCode = "0";
            //    const dataObj = { projectId: this.id };
            //    this.apiKeysGenerationInfo.wait();
            //    var b = new BackEndService();
            //    var rep = await b.generateApiKeys(dataObj);
            //    if (rep) {
            //        this.generateApiKeysStatusCode = rep.statusCode;
            //    }
            //    else {
            //        this.generateApiKeysStatusCode = "404";
            //    }
            //},
            createUsedUser() {
                for (var pRole of this.project.projectRoles) {
                    if (this.usedUser.length == 0 || (this.usedUser.length > 0 && !this.usedUser.find(U => U.id == pRole.user.id))) {
                        this.usedUser.push(pRole.user);
                    }
                }
            },
            async saveUsers() {
                this.saveUsersStatusCode = "0";
                var newRoles = [];
                for (var pRole of this.project.projectRoles) {
                    var obj = {
                        user: pRole.user,
                        roles: this.usersRoles[pRole.user.id],
                    }
                    newRoles.push(obj);
                }
                const dataObj = { projectId: this.id, roles: newRoles };
                var b = new BackEndService();
                var rep = await b.projectsRoles(dataObj);
                this.saveUsersStatusCode = rep.statusCode;
            },

            createUsersRoles() {
                var localUserId = [];
                for (var curentRoles of this.project.projectRoles) {
                    var pullRoles = [];
                    //localUserId.push(curentRoles.user.id);
                    if (localUserId.find(U => U == curentRoles.user.id)) {
                        for (let role of this.usersRoles[curentRoles.user.id]) {
                            if (role.role == curentRoles.role) {
                                role.active = true;
                            }
                        }
                    }
                    else {
                        for (let role of this.pullProjectRoles) {
                            let obj = {
                                role: role.role,
                                label: role.label,
                                active: role.role == curentRoles.role,
                            }
                            //if (role.role == curentRoles.role) {
                            //    obj.active = true;
                            //}
                            pullRoles.push(obj);
                        }
                        this.usersRoles[curentRoles.user.id] = pullRoles;
                    }
                    localUserId.push(curentRoles.user.id);
                }
            },

            addUserInProject(newProjUser) {
                let ProjUser = {
                    isReadOnly: false,
                    label: "",
                    role: "",
                    user: newProjUser,
                };
                this.project.users = this.project.users.filter(U => U != newProjUser);
                this.project.projectRoles.push(ProjUser);
                this.createUsedUser();
                this.createUsersRoles();
            },
            async saveTags(list) {
                this.project.tagList = [];
                for (var pTag of list) {
                    this.project.tagList.push(pTag)
                }
                const dataObj = { projectId: Number(this.id), tags: this.project.tagList };
                var b = new BackEndService();
                await b.saveTags(dataObj);
                this.reorderTags();
            },
            reorderTags() {
                this.initTags();
            },
            initTags() {
                if (!this.$refs.projectTagsContainer) {
                    return;
                }
                this.$refs.projectTagsContainer.updateTags(this.project.tagList);
            },

            async getProjSettings() {
                this.isLoading = true;
                let id = Number(this.id);

                var b = new BackEndService();
                try {
                    var req = await b.getProjSettings(id);
                    this.project = req.data;
                    const projectCategoriesContainer = this.$refs.projectCategoriesContainer;
                    if (projectCategoriesContainer) {
                        this.$refs.projectCategoriesContainer.fillListOrTree();
                    }
                    this.isLoading = false;
    
                    this.createIdManagerMarkupGroup();
                    this.reorderTags();
                    this.createUsedUser();
                    this.createUsersRoles();
                } 
                catch (error) {
                    this.$router.push("/notfound");
                }
            },
            createIdManagerMarkupGroup() {
                this.idManagerMarkupGroup = new IdManager(0);
                for (var markupGroups of this.project.markupGroups) {
                    this.idManagerMarkupGroup.addId(markupGroups.id)
                }
            },

            async save() {
                const dataObj = { projectId: Number(this.id), groups: this.project.markupGroups, roles: null };
                var b = new BackEndService();
                await b.saveCategories(dataObj);
            },
            open(tab) {
                this.toggleCategories = tab === 'classes';
                this.togglePhotoAlbum = tab === 'alboms';
                this.toggleUsers = tab === 'users';
                this.toggleTags = tab === 'tags';
                this.toggleActions = tab ==='actions'
            }
        },
        mounted() {
            this.getProjSettings();

        },
    }
</script>
<style scoped>
        .project-page {
            margin-left: 1rem;
            margin-right: 1rem;
        }

        .section-tabs {
            display: flex;
            justify-content: space-around;
        }

            .section-tabs .section-tab.active {
                background-color: white;
            }

            .section-tabs .section-tab {
                background-color: lightgrey;
            }

        .section-tab {
            color: gray;
            flex: 1 0 0;
            padding: 0.2em 1.8em;
            text-align: center;
            border: 1px solid gray;
            border-width: 1px 1px 0 1px;
            border-radius: 0.6rem 0.6rem 0 0;
            cursor: pointer;
        }

            .section-tab + .section-tab {
                margin-left: 0.4rem;
            }

            .section-tab.active {
                color: black;
                border-color: black;
                margin-bottom: -0.5px;
            }

        .tab-content {
            border: 1px solid black;
            border-width: 1px 0 0 0;
        }

        textarea {
            font-size: large;
            width: calc(100% - 3rem);
            margin: 0 0.4rem;
            min-height: 3em;
            height: max-content;
            resize: none;
        }

        .input-tag {
            width: 100%;
        }

        .tag-container {
            display: flex;
            flex-direction: column;
        }

        .btn-bottom-bord {
            align-items: center !important;
            display: flex !important;
        }

        .btn-bottom-bord-left {
            align-items: center !important;
            display: flex !important;
            line-height: 1.5;
        }

        .btn-bottom-bord-right {
            margin-left: 0;
            text-align: right !important;
        }

        .btn {
            cursor: pointer;
        }

        th {
            border: 1px solid #dee2e6;
        }

        hr {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        /*.d-flex-container {
        display: flex !important;
    }*/
        .svg-btn {
            max-width: min-content;
            margin-top: 0.5em;
            margin-left: auto;
            margin-right: 0;
        }

        .class-tree-buttons button {
            margin: 0;
        }

        .users-table {
            background-color: white;
            width: 80%;
        }

            .users-table tr {
                border: 2px solid darkgrey;
            }
</style>
