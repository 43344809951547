<template>
    <h3>Действия</h3>
    <div>
        <button class="site-button" @click="generateApiKeys">Создать ключи API</button>
        <statusElement :statusCode="generateApiKeysStatusCode" />
        <button v-if="status != 'Archived'" class="site-button" @click="toggleArchivation">Архивировать</button>
        <button v-if="status == 'Archived'" class="site-button" @click="toggleArchivation">Разархивировать</button>
        <statusElement :statusCode="archivationStatusCode" />
        <button style="background-color:#dc3545" class="site-button" @click="askDeleteProject">Удалить</button>
    </div>
    <confirmation-modal :isShown="showModal" @close-win="onModalClose" :messasge="modalMessage" />
    <modal :isShown="modalIsShown" :messasge="modalMessage" @close-win="onModalClose" />
</template>

<script>
    import statusElement from "../statusElement.vue"

    import BackEndService from "../../services/BackEndService.js"

    import SendInfo from "../../helpers/SendInfo.js"

    export default {
        components: {
            statusElement,
        },
        props: ['id', 'status'],
        data() {
            return {
                archivationStatusCode: "",
                generateApiKeysStatusCode: "",
                apiKeysGenerationInfo: new SendInfo(),

                modalMessage: "",
                onModalClose: () => { },
                showModal: false,
                modalIsShown: false,

            };
        },
        methods: {
            async generateApiKeys() {
                this.generateApiKeysStatusCode = "0";
                const dataObj = { projectId: this.id };
                this.apiKeysGenerationInfo.wait();
                var b = new BackEndService();
                var rep = await b.generateApiKeys(dataObj);
                if (rep) {
                    this.generateApiKeysStatusCode = rep.statusCode;
                }
                else {
                    this.generateApiKeysStatusCode = "404";
                }
            },
            async toggleArchivation() {
                this.archivationStatusCode = "0";
                var BoolStatus = !(this.status === "Archived");
                var dataObj = {
                    id: Number(this.id),
                    archive: BoolStatus,
                }
                var b = new BackEndService();
                var rep = await b.toggleArchivationVue(dataObj);
                if (rep) {
                    this.archivationStatusCode = rep.statusCode;
                }
                else {
                    this.archivationStatusCode = "404";
                }
                console.log("rep", rep);
            },
            askDeleteProject() {
                this.modalMessage = `Вы дейстивтельно хотите удалить проект "${this.project?.markupProject?.title}"?`;
                this.onModalClose = answer => this.projectDelete(answer);
                this.showModal = true;
            },
            async projectDelete(answer) {
                this.showModal = false;
                if (answer !== 'yes') {
                    return;
                }
                var b = new BackEndService;
                try {
                    await b.projectDelete(this.id);
                    let link = "/";
                    this.$router.push(link);
                } catch (error) {
                    this.modalIsShown = true;
                    this.modalMessage = "Невозможно удалить проект";
                    this.onModalClose = () => this.modalIsShown = false;
                }
            },
        },
        mounted() {
        },
    }
</script>

<style>
</style>