<template>
    <div style="text-align:center" v-if="isLoading">
        <h2>Загрузка</h2>
        <br />
        <IsLoadingItem />
    </div>

    <div v-else class="center-container">
        <h1 v-if="id==null" style="text-align:center">Новая группа миссий</h1>
        <div v-if="id==null" style="text-align:center; font-weight:bold">
            <span class="muted">Для фотоальбома </span> 
            {{taskGroup.dataSet}}
            <span class="muted"> проекта </span> 
              
             {{taskGroup.project}}
        </div>
        <span>Название</span>
        <input type="text" class="form-control" aria-describedby="Имя" placeholder="Имя" v-model="newTaskGroupName"/>
        <span>Описание</span>
        <textarea type="text" class="form-control" aria-describedby="Описание" placeholder="Описание" rows="5" v-model="newTaskGroupDescription"></textarea>
        <div>
            <h3>Опорный слой</h3>
            <span>
                Выберите опорный слой для помощи разметчику
            </span>
            <select v-model="selectedRefLayer">
                <option value="0">Без слоя</option>
                <option v-for="baseLayer in taskGroup.baseLayers" :key="baseLayer.id" :value="baseLayer.id">{{baseLayer.name}}</option>
            </select>
        </div>
        <div>
            <h3>Копирование разметки</h3>
            <span>
                Выберите слой, если хотите скопировать разметку в группу миссий
            </span>
            <select v-model="selectedLayerId">
                <option value="0">Без слоя</option>
                <option v-for="baseLayer in taskGroup.baseLayers" :key="baseLayer.id" :value="baseLayer.id">{{baseLayer.name}}</option>
            </select>
        </div>
        <div>
            <h3>Источник классов</h3>
            <div class="group-source-choice">
                <label for="projLayer">
                    <input type="radio" name="projLayer" id="projLayer" value="projLayer" v-model="radioButton">
                    взять из проекта
                </label>
                <label for="markupGroupSource" :class="{muted:selectedLayerId == 0}">
                    <input :disabled="selectedLayerId == 0"  type="radio" name="markupGroupSource" id="markupGroupSource" value="markupGroupSource" v-model="radioButton">
                    взять из слоя
                </label>
                
            </div>
            <div v-if="radioButton == 'projLayer'">
                <span>
                    Выберите группу проекта, если хотите скопировать классы ои неё
                </span>
                <select v-model="selectedProjectId">
                    <option value="0">Без группы</option>
                    <option v-for="projectGroup in taskGroup.projectGroups" :key="projectGroup.id" :value="projectGroup.id">{{projectGroup.name}}</option>
                </select>
            </div>
        </div>
        <!-- <h2>Источник классов группы миссий</h2>
        <span>
            Выберите базовый слой для миссии или если его нет укажите группу классов проекта
        </span>
        <span>
            <input type="radio" name="classSource" id="classSource1" value="baseLayer" v-model="selectedClassSource" checked/>
            <label for="classSource1">Классы из слоя</label><br />
            <select style="width:100%" v-model="selectedLayerId">
                <option v-for="baseLayer in taskGroup.baseLayers" :key="baseLayer.id" :value="baseLayer.id">{{baseLayer.name}}</option>
            </select>
        </span>
        <span>
            <input type="radio" name="classSource" id="classSource2" value="projLayer" v-model="selectedClassSource"/>
            <label for="classSource2">Список классов из проекта</label><br />
            <select style="width:100%" v-model="selectedProjectId">
                <option v-for="projectGroup in taskGroup.projectGroups" :key="projectGroup.id" :value="projectGroup.id">{{projectGroup.name}}</option>
            </select>
        </span>

        <h2>Опорный слой</h2>
        <span>
            Выберите опорный слой для помощи разметчику
        </span>
        <select v-model="selectedRefLayer">
            <option value="0">Без слоя</option>
            <option v-for="baseLayer in taskGroup.baseLayers" :key="baseLayer.id" :value="baseLayer.id">{{baseLayer.name}}</option>
        </select> -->
        <div>
            <h3>
                <button v-if="id==null" class="site-button main-button ml-0 mt-2" style="background-color: #1b6ec2" @click="CreateGroup">Создать</button>
            </h3>
        </div>
    </div>

</template>
<script>
    import IsLoadingItem from "./IsLoadingItem.vue"
    import BackEndService from "../services/BackEndService.js"

    export default {
        props: ['datasetId', 'id'],
        components: {
            IsLoadingItem,
        },
        data() {
            return {
                taskGroup: {},
                isLoading: false,

                newTaskGroupName:"",
                newTaskGroupDescription: "",

                selectedLayerId: 0,
                selectedProjectId: 0,
                selectedRefLayer: 0,
                selectedFromLayer: 0,
                selectedFromProject: 0,
                selectedClassSource:"baseLayer",
                radioButton: "projLayer"
            };
        },
        watch:{
            selectedLayerId(newValue){
                if(newValue == 0){
                    this.radioButton="projLayer";
                }
            }
        },
        methods: {
            async CreateGroup() {

                var dataObj = {
                    id:0,//если создавать новый
                    name: this.newTaskGroupName,
                    description: this.newTaskGroupDescription,
                    //layer: this.taskGroup.baseLayers.find(L => L.id == this.selectedLayerId).name,
                    layerId: this.selectedLayerId,
                    dataSetId: Number(this.datasetId),

                    projectId: this.selectedProjectId,
                    refLayerId : this.selectedRefLayer,
                    classSource: this.radioButton,
                };
                if (this.id) {
                    dataObj.id = this.id;
                }
                console.log(dataObj);

                var b = new BackEndService;
                var req = await b.createGroupVue(dataObj);

                console.log("CreateGroup", req);
                this.$router.push('/PhotoAlbum/' + this.datasetId)
            },
            async getInfo() {
                this.isLoading = true;
               
                var b = new BackEndService;
                var req = await b.getProjForGroupCreate(this.datasetId, this.id);

                this.taskGroup = req.data;
                console.log("req", req);
                if (this.id) {
                    this.newTaskGroupName = this.taskGroup.name;
                    this.newTaskGroupDescription = this.taskGroup.description;
                    this.selectedLayerId = this.taskGroup.layerId;
                    //this.selectedProjectId = ;
                }
                this.isLoading = false;
            }
        },
        mounted() {
            console.log("info", this.datasetId, this.id,);
            this.getInfo();
        },
    }
</script>
<style scoped>
.group-source-choice{
    display: flex;
    justify-content: space-evenly;
}
input[type='checkbox'],
input[type='radio'] {
    display: inline-block;
    width: auto;
}
input[type='checkbox'] + label,
input[type='radio'] + label {
    font-weight: normal;
}
input,
select,
textarea {
    display: block;
    width: 100%;
    font: inherit;
    margin-top: 0.5rem;
    margin-left: 0;
}
</style>
