<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 485 485"
    width="24"
    height="24"
    style="enable-background: new 0 0 485 485"
    xml:space="preserve"
  >
    <g>
      <polygon points="30,30 106,30 106,0 0,0 0,106 30,106 	" />
      <polygon points="379,0 379,30 455,30 455,106 485,106 485,0 	" />
      <rect x="197.16" width="91" height="30" />
      <rect x="197.16" y="455" width="91" height="30" />
      <polygon points="455,455 379,455 379,485 485,485 485,379 455,379 	" />
      <rect x="455" y="197" width="30" height="91" />
      <rect y="197" width="30" height="91" />
      <path
        d="M274.484,270.622l-60.103-60.104l38.661-38.661l60.104,60.103L274.484,270.622z"
      />
      <path d="M25,460l68.463-19.986l-48.477-48.477L25,460z" />
      <path
        d="M60.383,364.508l132.781-132.781l60.104,60.104L120.487,424.612L60.383,364.508z"
      />
    </g>
  </svg>
</template>