<template>
  <h3>Группы миссий</h3>
  <div>
    <div class="taskGroup-container">
      <div
        class="taskGroup"
        v-for="taskGroup of imageList.taskGroups"
        :key="taskGroup.id"
      >
        <dataset-mission-group-card
          :taskGroup="taskGroup"
          @cancel-group="cancelGroup"
        />
      </div>
    </div>
    <router-link
      :to="'/newTaskGroup/' + imageList.dataSetId"
      class="site-button"
      >Новая группа миссий</router-link
    ><br />

    <span style="font-weight: bold">{{
      hasCancelledMissions ? "Отменённые миссии" : "Отменённых миссий нет"
    }}</span>
    <button
      v-if="hasCancelledMissions > 0"
      class="site-button"
      @click="toggleCancelledTaskGroups = !toggleCancelledTaskGroups"
    >
      {{ toggleCancelledTaskGroups ? "-" : "+" }}
    </button>
    <div class="taskGroup-container" v-if="toggleCancelledTaskGroups">
      <div
        class="taskGroup"
        v-for="cancelledTaskGroup of imageList.cancelledTaskGroups"
        :key="cancelledTaskGroup.id"
      >
        <dataset-mission-group-card
          :taskGroup="cancelledTaskGroup"
          :cancelled="true"
          @cancel-group="cancelGroup"
          @restore-group="restore"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DatasetMissionGroupCard from "./DatasetMissionGroupCard.vue";
import BackEndService from "../../services/BackEndService.js";

export default {

    components: {
        DatasetMissionGroupCard,
    },
    props: ["imageList", "hasCancelledMissions"],
    data() {
        return {
            toggleCancelledTaskGroups: false,
        };
    },
    emits: ["get-image-list"],
    methods : {
      async restore(id) {
            var MarkupItemInfo = {
                id: id,
            }
            var b = new BackEndService;
            var req = await b.restoreGroup(MarkupItemInfo);
            this.getImageList();
            console.log("req", req);
        },
        async cancelGroup(id) {
            var MarkupItemInfo = {
                id: id,
            }
            var b = new BackEndService;
            var req = await b.restoreGroup(MarkupItemInfo);
            //var req = await axios.post("/Tasks/CancelGroup", MarkupItemInfo);
            this.getImageList();
            console.log("req", req);
        },
        getImageList(){
          this.$emit('get-image-list');
        }
    }

};
</script>

<style scoped>
.taskGroup-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}

.taskGroup {
  flex: 1 1 auto;
  border: 1px solid gray;
  padding: 0.7em;
  margin: 0.5em;
  max-width: calc(50% - 4em);
}
</style>