export default class LabelMarker {
    mapObj = null;
    labelLng = 100;
    labelLat = -100;
    map = null;
    labels = null;

    constructor(map) {
        this.map = map;
    }

    remove = () => {
        if (this.mapObj) {
            this.mapObj.off('click', this.onEditTooltip);
            this.mapObj.removeFrom(this.map);
            this.mapObj = null;
        }
    }

    setLatLng() {

    }
    setMultiLabel(labels){  
        this.labels = labels;
    }
    drawMultilabel(options){
        let content = "";
        let total = 0;
        let heightTimes = 0;
        for (const label of this.labels) {
            content += `<strong style="border-left: 4px solid ${label.color}; padding-left:0.2em;">${label.name} : ${label.count}</strong><br>`;
            heightTimes++;
            total += label.count;
        }
        let text = "итого : "+total;
        content += text; 
        heightTimes++;
        if(heightTimes > 4){
            heightTimes = 4;
        }
        this.mapObj = new window.L.ClickableTooltip({
            direction: 'center',
            permanent: true,
            noWrap: false,
            offset: [0, -10 * heightTimes - 4],
            className: "label-cluster",
            labelText: "итого "+total,
            opacity: options.opacity
        });
        this.mapObj.setContent(content);
        this.mapObj.setLatLng(new window.L.LatLng(this.labelLat, this.labelLng));
        this.mapObj.addTo(this.map);
        return this.mapObj;
    }
    setLabel(category, text, labelPnt, options) {
        this.labelLng = labelPnt.lng;
        this.labelLat = labelPnt.lat;
        this.remove();

        if(this.labels){
            this.drawMultilabel(options);
            return;
        }
        text = this.isEmptyOrSpaces(text) ? "---" : text;
        const lineParams = this.getLinesHeight(text.replace(/<br\/>/g, "\n"));
        let toolClass = lineParams.toolClass;
        let heightTimes = 0;
        let content = "";
        if (!this.isEmptyOrSpaces(category)) {
            content = "<strong style=\"border-bottom: 4px solid " + options.color + "\">" + category + "</strong>";
            heightTimes++;
        }
        if(options.hasTags){
            content += `
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 426.686 426.686" style="enable-background:new 0 0 426.686 426.686;" xml:space="preserve" width="16" height="16">
<g>
	<path stroke="black" fill="black" d="M214.057,151.371c-3.906-3.905-10.236-3.905-14.143,0L83.855,267.429c-3.905,3.905-3.905,10.237,0,14.143
		c1.953,1.952,4.512,2.929,7.071,2.929s5.118-0.977,7.071-2.929l116.059-116.059C217.962,161.608,217.962,155.276,214.057,151.371z"
		/>
	<path stroke="black" d="M220.836,252.966l-75.723,75.722c-3.905,3.905-3.905,10.237,0,14.143c1.953,1.952,4.512,2.929,7.071,2.929
		s5.118-0.977,7.071-2.929l75.723-75.722c3.905-3.905,3.905-10.237,0-14.143C231.072,249.06,224.742,249.06,220.836,252.966z"/>
	<path stroke="black" d="M423.757,2.929c-3.906-3.905-10.236-3.905-14.143,0l-70.853,70.854l-29.44-29.441c-3.905-3.904-10.235-3.905-14.143,0
		c-8.816,8.816-20.538,13.672-33.007,13.672c-12.468,0-24.189-4.855-33.006-13.671c-1.875-1.875-4.419-2.929-7.071-2.929
		s-5.195,1.054-7.071,2.929L2.929,256.436C1.054,258.311,0,260.855,0,263.507s1.054,5.196,2.929,7.071l153.179,153.179
		c1.953,1.953,4.512,2.929,7.071,2.929s5.118-0.976,7.071-2.929l212.095-212.094c1.875-1.875,2.929-4.419,2.929-7.071
		s-1.054-5.196-2.929-7.071c-8.816-8.816-13.672-20.539-13.672-33.006c0-12.468,4.855-24.19,13.672-33.007
		c1.875-1.875,2.929-4.419,2.929-7.071s-1.054-5.196-2.929-7.071l-29.44-29.44l70.853-70.853
		C427.662,13.166,427.662,6.834,423.757,2.929z M361.654,124.959c-8.428,11.391-12.981,25.123-12.981,39.555
		c0,14.432,4.554,28.163,12.981,39.554L163.179,402.544L24.143,263.507L222.618,65.031c11.391,8.428,25.122,12.982,39.554,12.982
		s28.164-4.554,39.555-12.982l22.893,22.893l-19.385,19.384c-12.774-6.731-28.988-4.73-39.725,6.005
		c-6.392,6.392-9.912,14.891-9.912,23.931c0,9.041,3.521,17.539,9.912,23.931c13.195,13.194,34.665,13.195,47.862,0
		c6.392-6.392,9.912-14.891,9.912-23.931c0-5.6-1.351-10.991-3.897-15.803l19.375-19.375L361.654,124.959z M287.796,138.889
		c3.906,3.906,10.236,3.906,14.143,0l1.343-1.343c-0.076,3.585-1.508,6.944-4.052,9.487c-5.396,5.399-14.181,5.397-19.577,0
		c-2.615-2.614-4.055-6.091-4.055-9.789c0-3.697,1.439-7.174,4.055-9.788c2.543-2.543,5.901-3.975,9.485-4.052l-1.342,1.342
		C283.891,128.652,283.891,134.984,287.796,138.889z"/>
	<path stroke="black" d="M230.543,182L114.484,298.059c-3.905,3.905-3.905,10.237,0,14.142c1.953,1.953,4.512,2.929,7.071,2.929
		s5.118-0.977,7.071-2.929l116.059-116.058c3.905-3.905,3.905-10.237,0-14.143C240.779,178.096,234.449,178.096,230.543,182z"/>
</g>
</svg>
            `;
        }
        if (window.appstate.showComments) {
            if (heightTimes > 0) {
                content += "<br>";
            }
            content += text.replace(/\n/g, "<br/>");
            heightTimes += lineParams.count;
        }
        this.mapObj = new window.L.ClickableTooltip({
            direction: 'center',
            permanent: true,
            noWrap: false,
            //opacity: this.shape.options.opacity*1.6,
            offset: [0, -10 * heightTimes - 12],
            className: toolClass,
            labelText: text,
            opacity: options.opacity
        });
        // this.mapObj.options.opacity = options.labelOpacity;
        this.mapObj.setContent(content);
        this.mapObj.setLatLng(new window.L.LatLng(this.labelLat, this.labelLng));
        this.mapObj.addTo(this.map);
        if (this.onEditTooltip) {
            this.mapObj.on('click', this.onEditTooltip);
        }
        return this.mapObj;

    }
    /*work with text */
    getTextWidth = function (text, font) {
        // re-use canvas object for better performance
        var canvas = this.getTextWidth.canvas || (this.getTextWidth.canvas = document.createElement("canvas"));
        var context = canvas.getContext("2d");
        context.font = font;
        var metrics = context.measureText(text);
        return metrics.width;
    }
    getLinesHeight(text) {
        let toolClass = "";
        let heightTimes = 0;
        const lines = text.split(/\r?\n/);
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            const textW = this.getTextWidth(line, '12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif');
            if (textW > 250) {
                toolClass = "label-with-width";
                heightTimes += Math.ceil(textW / (250.0 - 6));
            }
            else {
                heightTimes++;
            }
        }
        return { count: heightTimes, toolClass: toolClass };
    }
    isEmptyOrSpaces(str){
        return typeof str === 'undefined' || str === null || str.match(/^ *$/) !== null;
    }
}