export default class EventAggregator {
    listeners = {
        editorenabled: [],
        enableeditor: [],
        toolchanged: [],
        categoryselected: [],
        drawcategoryselected: [],
        key: [],
        keystep: [],
        editactions: [],
        markupwarning: [],
        toggledrawings: [],
        showmarker: [],
        changeimage: [],
        fullscreen: [],
        togglenet: [],
        externalobjects: [],
        toggleneuralassist: [],
        favouritecategories:[],
        togglefavouritecategory:[],
        favouritecategoryselected:[],
        mouseneuralclick:[],
        objectinfochanged: []
    };
    on(label, action) {
        if (!(label in this.listeners)) {
            return;
        }
        this.listeners[label].push(action);
    }
    off(label, action) {
        if (!(label in this.listeners)) {
            return;
        }
        const actions = this.listeners[label];
        const index = actions.indexOf(action);
        if (index > -1) {
            action.splice(index, 1);
        }
    }
    fire(label, e) {
        if (!(label in this.listeners)) {
            console.warn("EventAggregator fired unknown action",label)
            return;
        }
        for (const action of this.listeners[label]) {
            action(e);
        }
    }
}