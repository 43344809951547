import ShapeObjectBase from "./ShapeObjectBase";
import NoteShape from "../shapes/NoteShape";
import LabelMarker from "../tooling/LabelMarker";
export default class NoteObject extends ShapeObjectBase{
    shape;
    type="note";
    points=[];

    constructor(mapManager) {
        super(mapManager);
    }

    createShape(note){
        let newPoints=note.points;
        var options = new Object();
        this.shape=new NoteShape(this.mapManager, options);
        this.shape.value=note.text;
        this.shape.draw(newPoints);
        this.label = new LabelMarker(this.map);
    }

    createNewShape(options, point){
        const p1 = {x:point.x, y:point.y};
        const p2 = {x:point.x+this.delta, y:point.y};
        const p3 = {x:point.x+this.delta, y:point.y+this.delta};
        const p4 = {x:point.x, y:point.y+this.delta};
        let newPoints = [p1,p2,p3,p4];
        this.shape = new NoteShape(this.mapManager, options);
        this.shape.draw(newPoints);
        this.select();
        this.shape.selectCorner(2);
    }
    updateLabel() {}
    onZoom(zoom) {
        this.zoom = zoom;
        if(this.selected){
            this.shape.drawCorners();
        }
        this.shape.onZoom();

    }
    mouseUp(){
        this.shape.selectedCorner = null;
        this.shape.selectedCornerIndex = -1;
    }
}