import ShapeParam from "./ShapeParam";
export default class ClusterShape extends ShapeParam {
    type = "cluster";

    constructor(mapManager, options) {
        super(mapManager, options);
        this.points = [];
        this.color = "black";
        this.size = 5;
        const shoptions = {
            color: "black",
            fillColor: "yellow",
            weight: 3,
            shape: "cluster",
            id: Date.now(),
            category: 99999,
            comment: "comment",
            dashArray: "10 5",
          };
        this.shape = window.L.polygon(this.points, shoptions);
    }

    remove() {
        this.shape.removeFrom(this.map);
    }

    add() {
        this.shape.addTo(this.map);
    }

    changeColor() {
        //super.changeColor(color);
    }
    

    drawDirectionCorner(points) {
        super.drawDirectionCorner(points);
    }
    getCenter() {
        var geo = this.shape.getLatLngs();
        if (geo.length === 1) {
            geo = geo[0];
        }
        let avgLat = 0.0;
        let avgLng = 0.0;
        let counter = 0;
        for (let v of geo) {
            avgLat += v.lat;
            avgLng += v.lng;
            counter++;
        }
        if (counter === 0) {
            return null;
        }
        return { lat: avgLat / counter, lng: avgLng / counter }
    }
    move() {
        
    }

}