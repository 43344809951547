<template>
  <div class="flexbox mission-group-box">
    <router-link v-if="!cancelled" class="flex-grower" :to="'/group/' + taskGroup.id">
      {{ taskGroup.name }}
    </router-link>
      <!--<router-link v-if="!cancelled" class="flex-grower" :to="'/tasksGroup/' + taskGroup.id">
     новая страница {{ taskGroup.name }}
    </router-link>-->
    <div v-else>{{ taskGroup.name }}</div>
    <div class="to-right">
      <button v-if="!cancelled" class="site-button danger-button" @click="cancelGroup()">
        Отменить
      </button>
      <button v-else class="site-button main-button" @click="restoreGroup()">
        Восстановить
      </button>

    </div>
  </div>
  <div class="flexbox">
    <span class="small-bold flex-grower">{{ taskGroup.timeText }}</span>
    <span>
      <progress
        class="to-right"
        style="vertical-align: middle; flex: 0 1 0"
        :value="progress"
        max="100"
      ></progress>
      {{ progressText }}%
    </span>
  </div>
  <dataset-mission-item v-for="task in assignedTasks" :key="task.id" :task="task" :cancelled="cancelled"/>
  <div v-if="taskGroup.tasks.length === 0" class="mission-group-small-text">миссии не созданы</div>
  <div v-else-if="unassignedCount > 0" class="mission-group-small-text">не назначено {{ unassignedCount }} из {{ taskGroup.tasks.length }}</div>
  <div v-else class="mission-group-small-text">все миссии назначены</div>
</template>

<script>
import DatasetMissionItem from "./DatasetMissionItem.vue";
export default {
  components: {
    DatasetMissionItem,
  },
  props: ["taskGroup", "cancelled"],
  emits: ["cancel-group","restore-group"],
  computed: {
    assignedTasks(){
      console.log("TTT", this.taskGroup.tasks);
      return this.taskGroup.tasks.filter(t=>t.user?.id > 0);
    },
    unassignedCount() {
      var taskCount = this.taskGroup.tasks.length;
      var assignedCount = this.taskGroup.tasks.filter((T) => T.user.id > 0);
      const count = taskCount - assignedCount.length;
      return count;
    },
    progress() {
      var avr = 0;
      if (this.taskGroup.tasks.length > 0) {
        var sum = 0.0;
        for (var task of this.taskGroup.tasks) {
          sum = sum + Number(task.progress);
        }
        avr = sum / this.taskGroup.tasks.length;
      } else {
        avr = 0;
      }
      return avr;
    },
    progressText() {
      if(this.progress >= 100){
        return '100';
      }
      else if(this.progress <= 0){
        return '0';
      }
      return this.progress.toFixed(1);
    },
  },
  methods: {
    cancelGroup() {
      this.$emit("cancel-group", this.taskGroup.id);
    },
    restoreGroup(){
      this.$emit("restore-group", this.taskGroup.id);
    }
  },
};
</script>

<style scoped>
.flex-grower {
  flex-grow: 1;
}
.small-bold {
  font-size: 80%;
  font-weight: bold;
}
.mission-group-small-text{
  font-size: 80%;
}
.mission-group-box{
    flex: 0 0 50%;
}
</style>