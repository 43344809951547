<template>
  <div class="info-bottom-popup doselect" :class="{ 'info-popup-show': visible }">
      <input style="width:100%; text-align:center" type="text" readonly v-model="message" />
      <button type="button" class="info-close-button" @click="close">⨉</button>
  </div>
</template>

<script>
export default {
    data(){
        return{
            visible:false,
            message:""
        }
    },
    methods : {
        close(){
            this.visible = false;
        },
        show(text){
            this.visible = true;
            this.message = text;
        }
    },
    mounted() {
        this.$messageManger.openPopup = this.show;
    }
}
</script>

<style>
.info-bottom-popup{
    display: flex;
    width: calc(100% - 10rem);
    margin: 0 5rem;
    background-color: darkgray;
    border: 3px solid gray;
    border-radius: 0.8em;
    z-index: 999;
    position: absolute;
    bottom: 1rem;
    padding: 0.5em 1em;
    text-align: center;
    display: none;
}
.info-bottom-popup > input,
.info-bottom-popup > input:focus {
    border-width:0px;
    border:none;
    border-style: none;
    background: darkgray;
    outline: none;
}
.info-close-button {
    background-color:darkgray; 
    border-width:0;
    transition: transform .2s linear;
}
.info-close-button:hover {
    transform: scale(1.5);
}
.info-popup-show {
    display: flex;
    opacity: 0;
    animation: appear 0.2s ease-in forwards;
}
@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>