<template>
    <h1 class="center-text">Пользователи</h1>
    <div class="mt-4 site-container" v-if="!isLoading">
        <table>
            <thead>
                <tr class="header-row">
                    <th>#</th>
                    <th>Имя</th>
                    <th>Фамилия</th>
                    <th>Логин</th>
                    <th>Роль</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="user in users" :key="user.id">
                    <td>
                        <router-link :to="'/userPage/'+user.id">{{user.id}}</router-link>
                    </td>
                    <td> {{user.firstName}} </td>
                    <td> {{user.lastName}} </td>
                    <td>
                        <router-link tag="td" :to="'/userPage/'+user.id"> {{user.username}} </router-link>
                    </td>
                    <td> {{user.role}} </td>
                    <td>
                        <router-link tag="td" :to="'/editUser/'+user.id"> редактировать</router-link>
                    </td>
                </tr>
            </tbody>
        </table>
        <router-link class="site-button main-button mt-1" to="/newUser">Добавить пользователя</router-link>
    </div>
    <div v-else class="mt-4 center-text">
        <is-loading-item />
    </div>
</template>
<script>
    //import axios from 'axios';
    import BackEndService from "../services/BackEndService.js"
    import IsLoadingItem from './IsLoadingItem.vue';

    export default {
        components: {
            IsLoadingItem,
        },
        data() {
            return {
                users: [],
                isLoading: true
            };
        },
        methods: {
            async startUp() {
                if (window.appstate.serverUserRole == 'ServerAdmin') {
                    await this.getUsers();
                }
                else {
                    this.$router.push('/');
                }
            },
            async getUsers() {
                var b = new BackEndService;
                var req = await b.getUsersJson();
                this.users = req.data;
                this.isLoading = false;
            }
        },
        mounted() {
            this.startUp();
        },
    }</script>
<style scoped>


    .container {
        text-align: center;
    }

    .route {
        color: #0366d6;
        text-decoration: none;
    }

    table {
        border-spacing: 0px;
        margin: auto;
        border-collapse: collapse;
    }

    .table-header {
        max-width: 100%;
        background: rgba(0, 0, 0, 0.05);
    }

    tr {
        background: rgba(0, 0, 0, 0.05);
        border-bottom: 1px solid #ddd;
        vertical-align: middle;
    }

        tr:nth-child(even) {
            background: white;
            border-bottom: 1px solid #ddd;
        }

        tr.header-row {
            background: white;
        }

    .btn {
        padding: 0.25rem;
        /*font-size: .875rem;*/
        line-height: 1.5;
        background-color: #6c757d;
        border-color: #6c757d;
        border-radius: 0.2rem;
        color: white;
        text-decoration: none;
        /*     position:relative;
        margin-left:auto;
        margin-right: 0px;*/
        vertical-align: middle;
        font-size: 20px;
        margin: auto;
        margin-top: 1em;
    }

    th, td {
        padding: 1rem 1.4rem;
        text-align: left;
    }

        td a {
            margin: auto;
            text-decoration: none;
        }

            td a:hover {
                text-decoration: underline;
            }
</style>