<template>
    <div class="container">
        <div class="missions-container">
            <h4>Миссии</h4>
            <div v-for="task in taskGroup.tasks" :key="task.id">
                <div class="mission" :class="{highlightedMission:task.id==currentTaskId}">
                    <a @click="changeTask(task.id)"> миссия {{task.number}}</a>
                    <button class="site-button danger-button" @click="deleteTask(task)" v-if="task.isEditable">X</button>
                    <span v-if="!task.isEditable">
                        <span class="muted small">выполняет</span>
                        <span class="ml-1 small">{{task.user.name}}</span>
                    </span>
                    <IsLoadingItem style="" v-if="isImgLoading" />
                    <div class="mission-images-container" v-if="!isImgLoading">
                        <div v-for="img in imagesList" :key="img.id">
                            <div class="image" :class="{highlighted:img.isHighlighted}" v-if="img.taskId==task.id" @click="e=>togleImgHighlighted(img,e)">
                                {{img.id}}
                                {{img.name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button class="site-button main-button" @click="addTask">Добавить миссию</button>
        </div>
        <div class="buttons">
            <button class="big-button main-button site-button" @click="rightTurn()">→</button>
            <button class="big-button main-button site-button" @click="leftTurn()">←</button>
        </div>
        <div class="images-container">
            <h4>Нераспределенные картинки</h4>
            <IsLoadingItem style="" v-if="isImgLoading" />
            <div v-if="!isImgLoading">
                <div v-for="img in imagesList" :key="img.id">
                    <div class="image" :class="{highlighted:img.isHighlighted}" v-if="img.taskId==0" @click="e=>togleImgHighlighted(img,e)">
                        {{img.id}}
                        {{img.name}}
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="btn-container">
        <button class="site-button main-button" @click="saveChanges">Сохранить изменения</button>
        <statusElement :statusCode="saveChangesStatusCode" />
    </div>

</template>

<script>
    import ImageForTask from "../../helpers/ImageForTask.js";

    import BackEndService from "../../services/BackEndService.js"

    import IsLoadingItem from "../IsLoadingItem.vue"

    import statusElement from "../statusElement.vue"


    export default {
        components: {
            IsLoadingItem,
            statusElement,
        },
        props: ['taskGroup', 'editable'],
        emits: ['reload'],
        data() {
            return {
                imagesList: [],
                isImgLoading: false,
                currentTaskId: 0,
                saveChangesStatusCode: "",
                users: []
            };
        },
        methods: {
            async saveChanges() {
                this.saveChangesStatusCode = "0";

                var objArray = [];
                for (var task of this.taskGroup.tasks) {
                    var taskImg = this.imagesList.filter(i => i.taskId == task.id)
                    var imgIds = [];
                    for (var img of taskImg) {
                        imgIds.push(img.id)
                    }
                    var obj = {
                        id: task.id,
                        groupId: task.groupId,
                        imageIds: imgIds,
                    }
                    objArray.push(obj);
                }
                //console.log("saveChanges", objArray);

                var b = new BackEndService();
                var rep = await b.saveTaskToGroup(objArray);
                this.saveChangesStatusCode = rep.statusCode;
                console.log("saveChanges", rep);
            },
            async addTask() {
                var obj = { id: this.taskGroup.id };
                var b = new BackEndService();
                var rep = await b.createNewTask(obj);
                this.taskGroup.addTask(rep.data.result);
                console.log("addTask", rep);

            },
            async deleteTask(task) {
                console.log(task);
                var taskObj = { id: task.id, groupId: this.taskGroup.id };
                var b = new BackEndService();
                var rep = await b.deleteTaskFromGroup(taskObj);
                console.log("deleteTask", rep);
                var tmpImagesList = this.imagesList.filter(i => i.taskId == task.id);
                for (var img of tmpImagesList) {
                    img.setTaskId(0);
                }
                this.taskGroup.deleteTask(task);

            },
            rightTurn() {
                for (var img of this.imagesList) {
                    img.taskId = (img.isHighlighted) ? 0 : img.taskId;
                }
                // this.currentTaskId = 0;
                this.unlighlightImages();
                console.log("rightTurn");
            },
            leftTurn() {
                for (var img of this.imagesList) {
                    if (img.isHighlighted) {
                        img.setTaskId(this.currentTaskId);
                    }
                }
                console.log("leftTurn");
                this.unlighlightImages();
            },
            unlighlightImages(){
                for (var img of this.imagesList) {
                    if(img.isHighlighted){
                        img.togleHighlighted();
                    }
                        
                }
            },
            togleImgHighlighted(image, e) {
                console.log("e", e);//shiftKey
                if(image.taskId > 0){
                    const task = this.taskGroup.tasks.find(t=>t.id == image.taskId);
                    if(task && !task.isEditable){
                        return;
                    }
                }
                image.togleHighlighted();
                if (this.currentTaskId != image.taskId && image.taskId > 0) {
                    this.changeTask(image.taskId);
                    for (var img of this.imagesList) {
                        if (img.taskId != image.taskId) {
                            img.isHighlighted = false;
                        }
                    }
                }
            },
            changeTask(taskId) {
                const task = this.taskGroup.tasks.find(t=>t.id == taskId);
                if(task && !task.isEditable){
                    return;
                }
                console.log("changeTask", taskId)
                this.currentTaskId = (this.currentTaskId == taskId) ? 0 : taskId;
            },
            async startUp() {
                //console.time('startUp');
                this.isImgLoading = true;
                var tmpImagesList = await this.getImagesList();
                this.createTaskImagesLists(tmpImagesList);
                this.isImgLoading = false;
                if(this.taskGroup.tasks && this.taskGroup.tasks.length > 0){
                    const taskId = this.taskGroup.tasks[0].id;
                    this.changeTask(taskId);
                }
                await this.getUsers();
                //console.timeEnd('startUp');
            },
            async getUsers() {
                var b = new BackEndService;
                let req = await b.getUsersJson();

                this.users = req.data;
                console.log("USERS", this.users);
                /*await this.getCurrentUsers();*/
            },
            async getImagesList() {
                var b = new BackEndService();
                var rep = await b.dataSetImagesVue(this.taskGroup.dataSetId);
                var tmpImagesList = rep.data;
                //console.log("image from server", tmpImagesList);
                return tmpImagesList;
            },
            createTaskImagesLists(tmpImagesList) {
                for (var img of tmpImagesList) {
                    var image = new ImageForTask(img);
                    this.imagesList.push(image);
                }
                this.setImagesTaskId();
            },
            setImagesTaskId() {
                for (var task of this.taskGroup.tasks) {
                    if (task.imageCount > 0) {
                        var tmpImgList = this.imagesList.filter(i => task.imageIds.includes(i.id));
                        for (var img of tmpImgList) {
                            img.setTaskId(task.id);
                        }
                    }
                }
                //console.log("setImagesTaskId", this.imagesList);
            }
        },
        mounted() {
            this.startUp();
        },
    }
</script>

<style scoped>
    .btn-container {
        display: flex;
        flex-direction: row;
        margin:1em;
    }
    .container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        height: 50vh;
    }

    .missions-container {
        flex: 1 0 12.5%;
        border: groove;
        overflow: auto;
    }

    .images-container {
        flex: 1 0 12.5%;
        border: groove;
        overflow: auto;
        text-align: center
    }

    .buttons {
        display: flex;
        flex-direction: column;
        flex-shrink: 2;
        align-self: center;
    }

    .image {
        border: groove;
        border-width: 0.2em;
        cursor: pointer;
    }

    .highlighted {
        background-color: aquamarine;
    }

    .highlightedMission {
        background-color: beige;
    }

    .mission-images-container {
        border: groove;
        overflow: auto;
        height: 20vh;
    }

    .mission {
        /*border: 1;*/
    }
</style>