import ShapeObjectBase from "./ShapeObjectBase";
import BoxShape from "../shapes/BoxShape";
export default class BoxObject extends ShapeObjectBase {
    shape;
    type = "box";
    labelPnt;

    constructor(mapManager) {
        super(mapManager);
    }

    createShape(markup, options) {
        const x = markup.box[0];
        const y = markup.box[1];
        const dx = markup.box[2];
        const dy = markup.box[3];
        const x2 = x + dx;
        const y2 = y + dy;

        let points = [
            { x: x, y: y },
            { x: x2, y: y },
            { x: x2, y: y2 },
            { x: x, y: y2 },
        ];

        this.shape = new BoxShape(this.mapManager, options);
        this.shape.draw(points);
    }
    createNewShape(options, point){
        const p1 = {x:point.x, y:point.y};
        const p2 = {x:point.x+this.delta, y:point.y};
        const p3 = {x:point.x+this.delta, y:point.y+this.delta};
        const p4 = {x:point.x, y:point.y+this.delta};
        let newPoints = [p1,p2,p3,p4];
        this.shape = new BoxShape(this.mapManager, options);
        this.shape.draw(newPoints);
        this.select();
        this.shape.selectCorner(2);
        


    }
}