function createNewLayer(serverLayer){
    const newLayer = {
        name: serverLayer.name,
        isOn: serverLayer.isOn,
        id: serverLayer.id,
        data: [],
        categories: {
            99999: { color: '#FF0000', name: 'Неизвестно', isOn: true, count: 0 }
        },
        isReadOnly: serverLayer.isReadOnly,
        type: serverLayer.type,
        count: serverLayer.count
    }
    for (const category of serverLayer.items) {
        newLayer.categories[category.id] = createCategory(category);
    }
    return newLayer;
}
function duplicateLayer(layer){
    const newLayer = {
        name: layer.name,
        isOn: layer.isOn,
        id: layer.id+1000,
        data: [],
        categories: {
            99999: { color: '#FF0000', name: 'Неизвестно', isOn: true, count: 0 }
        },
        isReadOnly: layer.isReadOnly,
        categoryList: [...layer.categoryList]
    }
    for (const category of layer.categoryList) {
        newLayer.categories[category.id] = createCategory(category);
    }
    return newLayer;
}
function createCategory(category){
    return { 
        color: category.color, 
        name: category.name, 
        isOn: true, 
        count: category.count,
        fullname: category.fullname,
        id: category.id,
        supercategory: category.supercategory,
        supercategoryId: category.supercategoryId
    }
}
function getMarkupOptions(layer, markup){
    let color = '#ff0000';
    let categoryName = null;
    let objStrokeOpacity = !layer.isReadOnly ? 1.0 : 0.5;
    let objFillOpacity = !layer.isReadOnly ? 0.2 : 0.1;
    for (const key in layer.items) {
        if (Object.hasOwnProperty.call(layer.items, key)) {
            const category = layer.items[key]
            if (category.id === markup.class) {
                color = category.color;
                categoryName = category.name;
                break;
            }
        }
    }
    if(!categoryName && layer.categories){
        const category = layer.categories[markup.class];
        if(category){
            color = category.color;
            categoryName = category.name;
        }
    }
    const options = {
        id: markup.id,
        category : markup.class,
        color : color,
        categoryName : categoryName,
        //itsLayer : newLayer,
        tagList : markup.tagList,
        strokeOpacity : objStrokeOpacity,
        fillOpacity : objFillOpacity
    }
    return options;
}
export {createNewLayer, createCategory, getMarkupOptions, duplicateLayer}