<template>
  <div style="text-align: center" v-if="isLoading">
    <h2>Загрузка</h2>
    <br />
    <IsLoadingItem />
  </div>
  <div class="container mx-2" v-if="!isLoading">
    <h1 style="text-align: center">
      Фотоальбом {{ imageList.name }} проекта {{ imageList.projectTitle }}
    </h1>
    <div class="section-tabs">
      <div
        class="section-tab"
        :class="{ active: activeTab === 'missions' }"
        @click="open('missions')"
      >
        <p>Группы миссий</p>
      </div>
      <div
        class="section-tab"
        :class="{ active: activeTab === 'layers' }"
        @click="open('layers')"
      >
        <p>Слои</p>
      </div>
      <div
        class="section-tab"
        :class="{ active: activeTab === 'images' }"
        @click="open('images')"
      >
        <p>Изображения</p>
      </div>
      <div
        class="section-tab"
        :class="{ active: activeTab === 'actions' }"
        @click="open('actions')"
      >
        <p>Действия</p>
      </div>
    </div>
    <div class="tab-content">
      <dataset-task-groups
        v-if="activeTab === 'missions'"
        :imageList="imageList"
        :hasCancelledMissions="hasCancelledMissions"
        @get-image-list="getImageList"
      />
      <dataset-layers
        v-if="activeTab === 'layers'"
        :imageList="imageList"
        @get-image-list="getImageList"
      />
      <dataset-images v-if="activeTab === 'images'" :imageList="imageList" />
      <div v-if="activeTab === 'actions'">
        <h3>Действия</h3>
        <div class="flexbox">
            <button v-if="imageList.status == 'Active'"
                    class="site-button big-button first-left"
                    @click="archiveDataSet(imageList.dataSetId)">
                Архивировать
            </button>

            <button v-if="imageList.status == 'Archived'"
                    class="site-button big-button first-left"
                    @click="unarchiveDataSet(imageList.dataSetId)">
                Восстановить
            </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IsLoadingItem from "./IsLoadingItem.vue";
import BackEndService from "../services/BackEndService.js";
import DatasetTaskGroups from "./dataset-page/DatasetTaskGroups.vue";
import DatasetLayers from "./dataset-page/DatasetLayers.vue";
import DatasetImages from "./dataset-page/DatasetImages.vue";
export default {
  components: {
    IsLoadingItem,
    DatasetTaskGroups,
    DatasetLayers,
    DatasetImages,
  },
  props: ["id"],
  data() {
    return {
      isLoading: false,
      imageList: {},
      toggleCancelledTaskGroups: false,
      activeTab: "missions",
    };
  },
  computed: {
    hasCancelledMissions() {
      if (!this.imageList || !this.imageList.cancelledTaskGroups) {
        return false;
      }
      return this.imageList.cancelledTaskGroups.length > 0;
    },
  },
  methods: {
    open(tab) {
      this.activeTab = tab;
    },
    async archiveDataSet(id) {
      var DataSetActionsViewModel = {
        Id: id,
        Archive: true,
      };
      var b = new BackEndService();
      var req = await b.archiveDataSet(DataSetActionsViewModel);
      this.getImageList();
      console.log("req", req);
    },
    async unarchiveDataSet(id) {
      var DataSetActionsViewModel = {
        Id: id,
        Archive: false,
      };
      var b = new BackEndService();
      var req = await b.archiveDataSet(DataSetActionsViewModel);
      this.getImageList();
      console.log("req", req);
    },
    async getImageList() {
      this.isLoading = true;
      var b = new BackEndService();
      var req = await b.imageListVue(this.id);
      this.imageList = req.data;
      if(this.imageList.taskGroups){
        for (const taskGroup of this.imageList.taskGroups) {
          if(!taskGroup.name || taskGroup.name.length == 0){
            taskGroup.name = "Без названия";
          }
        }
      }
      this.isLoading = false;
      },
      //async setFrameNumber(dataSetId) {

      //},
  },
  mounted() {
    this.getImageList();
  },
};
</script>
<style scoped>
.section-tabs {
  display: flex;
  justify-content: space-around;
}

.section-tabs .section-tab.active {
  background-color: white;
}

.section-tabs .section-tab {
  background-color: lightgrey;
}

.section-tab {
  color: gray;
  flex: 1 0 0;
  padding: 0.2em 1.8em;
  text-align: center;
  border: 1px solid gray;
  border-width: 1px 1px 0 1px;
  border-radius: 0.6rem 0.6rem 0 0;
  cursor: pointer;
}

.section-tab + .section-tab {
  margin-left: 0.4rem;
}

.section-tab.active {
  color: black;
  border-color: black;
  margin-bottom: -1px;
}

.tab-content {
  border: 1px solid black;
  border-width: 1px 0 0 0;
}
table {
  border-collapse: collapse;
}
tr {
  background: rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid #ddd;
  vertical-align: middle;
}

tr:nth-child(even) {
  background: white;
  border-bottom: 1px solid #ddd;
}

.d-flex-container {
  display: flex !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}
.imageContainer {
  border: 1px solid #ccc !important;
  margin: 0.5em;
  padding: 1em;
}
</style>
