export default class CommandHistory {
    history = [];
    redos = [];
    constructor(emptyDoAction, emptyUndoAction) {
        this.emptyDoAction = emptyDoAction;
        this.emptyUndoAction = emptyUndoAction;
    }
    execute = (doAction, undoAction) => {
        this.history.push({ do: doAction, undo: undoAction });
        this.redos = [];
        doAction();
    }
    undo = () => {
        const action = this.history.pop();
        if (action) {
            action.undo();
            this.redos.push(action);
        }
        else if (this.emptyUndoAction) {
            this.emptyUndoAction();
        }
    }
    redo = () => {
        const action = this.redos.pop();
        if (action) {
            action.do();
            this.history.push(action);
        }
        else if (this.emptyDoAction) {
            this.emptyDoAction();
        }
    }
}