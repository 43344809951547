import ShapeParam from "./ShapeParam";
import PolygonHelper from "../../services/mathHelpers/PolygonHelper";
export default class ShadowShape extends ShapeParam {
    type = "shadow";

    constructor(mapManager, options) {
        super(mapManager, options);
        this.points = [];
        this.size = 5;
        this.shape = window.L.polyline(this.points, { shape: "shadow", id: 0, color: this.color, opacity: options.srokeOpacity, fillOpacity: options.fillOpacity });
        this.checker = new PolygonHelper();
        this.verticeLabels = [];
        this.verticeNames = ["Объект","Земля","Тень"]

    }
    
    remove() {
        this.shape.removeFrom(this.map);
        this.removeVerticeNumbers();
    }
    add() {
        this.shape.addTo(this.map);
        if(window.appstate.showObjectInfo) {
            this.drawVerticeNumbers();
        }
    }
    changeColor(color) {
        super.changeColor(color, () => { });
    }
    
    getArea() {
        var geo = this.shape.getLatLngs();
        if (geo.length === 1) {
            geo = geo[0];
        }
        if (geo.length <= 2) {
            return 0.0;
        }
        let area = 0.0;
        let i1 = 1;
        for (let i = 0; i < geo.length; i++) {
            i1 = (i + 1) % geo.length;
            area += geo[i].lat * geo[i1].lng - geo[i1].lat * geo[i].lng;
        }
        area *= 0.5;
        return area;
    }
    getSimpleCenter() {
        var geo = this.shape.getLatLngs();
        if (geo.length === 1) {
            geo = geo[0];
        }
        let avgLat = 0.0;
        let avgLng = 0.0;
        let counter = 0;
        for (let v of geo) {
            avgLat += v.lat;
            avgLng += v.lng;
            counter++;
        }
        if (counter === 0) {
            return null;
        }
        return { lat: avgLat / counter, lng: avgLng / counter }
    }
    getCenter() {
        var geo = this.shape.getLatLngs();
        if (geo.length === 1) {
            geo = geo[0];
        }

        let xsum = 0.0;
        let ysum = 0.0;

        let i1 = 1;
        for (let i = 0; i < geo.length; i++) {
            i1 = (i + 1) % geo.length;
            xsum += (geo[i].lat + geo[i1].lat) * (geo[i].lat * geo[i1].lng - geo[i1].lat * geo[i].lng);
            ysum += (geo[i].lng + geo[i1].lng) * (geo[i].lat * geo[i1].lng - geo[i1].lat * geo[i].lng);
        }
        const area = this.getArea();
        if (area == 0) {
            return this.getSimpleCenter();
        }
        xsum /= 6 * area;
        ysum /= 6 * area;

        return { lat: xsum, lng: ysum }
    }
    move = latlng => {
        let lat = latlng.lat;
        let lon = latlng.lng;
        if (this.selectedCorner) {
            if(this.selectedCorner.options.index == this.centerIndex){
                this.translate(this.getGeoPoints(this.shape), lat, lon);
                this.moveVerticeNumbers()
                return;
            }
            if(this.orientationIndex === this.selectedCorner.options.index){
                //this.moveDirectionCorner(false, e);
                this.moveVerticeNumbers()
                return;
            }
            var geo = this.getGeoPoints();
            let pointCount = geo.length;
            geo[this.selectedCorner.options.index] = { lat: lat, lng: lon };
            this.shape.setLatLngs(geo);
            this.selectedCorner.setLatLng({ lat: lat, lng: lon });
            if(pointCount > 2){
                this.moveCenterCorner(false);
                //this.moveDirectionCorner(false);
            }
            this.moveVerticeNumbers()
            //this.updateLabel();
        }
    }
    
    addPoint = point => {
        if(!this.hasNoIntersections()){
            //vueContext.actions.show("Нельзя создать многоугольник с самопересечениями!", "warning");
            console.warn("Нельзя создать многоугольник с самопересечениями!", "warning");
            return false;
        }
        //let r = this.getR();
        let lat = point.lat;
        let lon = point.lng;

        var geo = this.getGeoPoints();
        if (geo.length === 3) {
            this.shape.setLatLngs(geo);
            this.drawCorners();
            this.selectCorner(this.corners.length - 2);
            return true;
        }
        else {
            geo.push({ lat: lat, lng: lon });
        }
        this.drawCorners();
        this.selectCorner(this.corners.length - 2);
        this.shape.setLatLngs(geo);
        this.drawVerticeNumbers();
        return false;
    }
    finish(){
        let geo = this.getGeoPoints();
        geo.pop();
        this.shape.setLatLngs(geo);
        this.drawCorners();
        return true;
    }
    hasNoIntersections(){
        const geoPoints = this.getGeoPoints();
        const points = geoPoints.map(p=>this.mapManager.toFlat(p)); 
        if(this.checker.isSimple(points))
        {
            return true;
        }
        return false;
    }
    removeVerticeNumbers(){
        for (const el of this.verticeLabels) {
            el.removeFrom(this.map);
        }
        this.verticeLabels = [];
    }
    moveVerticeNumbers(){
        var points = this.getGeoPoints();
        for (let i = 0; i < points.length; i++) {
            const p = points[i];
            const v = this.verticeLabels[i];
            if(v && p){
                v.setLatLng(new window.L.LatLng(p.lat, p.lng));
            }
        }
    }
    drawVerticeNumbers(){
        for (const el of this.verticeLabels) {
            el.removeFrom(this.map);
        }
        this.verticeLabels = [];
        let toolClass = "shadow-label";
        let heightTimes = 1;
        var points = this.getGeoPoints();
        let num = 1;
        for (const p of points) {
            let content =num+"";
            if(this.verticeNames.length > num -1){
                content = this.verticeNames[num - 1];
            }
            var verticeNumber = new window.L.ClickableTooltip({
            direction: 'center',
            permanent: true,
            noWrap: false,
            opacity: this.shape.options.opacity*1.6,
            offset: [-14, 10*heightTimes + 14],
            className: toolClass,
            labelText: num
            });

            verticeNumber.setContent(content);
            verticeNumber.setLatLng(new window.L.LatLng(p.lat, p.lng));
            verticeNumber.addTo(this.map);
            this.verticeLabels.push(verticeNumber);
            num++;
        }
    }
}