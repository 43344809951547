import ShapeParam from "./ShapeParam";
export default class CenterShape extends ShapeParam {
    type = "center";

    constructor(mapManager, options) {
        super(mapManager, options);
        this.size = this.getR() / 2;
        this.point = {};
        this.centerShapeGroup = new window.L.featureGroup();
        this.shapeY = window.L.polyline([[0, 0]], { color: options.color, opacity: options.srokeOpacity, fillOpacity: options.fillOpacity }).addTo(this.centerShapeGroup);
        this.shapeX = window.L.polyline([[0, 0]], { color: options.color, opacity: options.srokeOpacity, fillOpacity: options.fillOpacity }).addTo(this.centerShapeGroup);
    }
    draw(points) {
        let geo = this.toGeo(points);
        const lat = geo[0].lat;
        const lng = geo[0].lng;
        const delta = this.getCircleR();
        let newPointLAT = [[lat - delta, lng], [lat + delta, lng]];
        let newPointLNG = [[lat, lng - delta], [lat, lng + delta]];
        this.shapeX.setLatLngs(newPointLAT);
        this.shapeY.setLatLngs(newPointLNG);
        this.point = { lat: lat, lng: lng };
        //this.add();
        // this.drawCorners(points);
    }
    remove() {
        this.centerShapeGroup.removeFrom(this.map);

    }
    add() {
        this.centerShapeGroup.addTo(this.map);
    }
    changeColor(color) {
        this.remove();
        this.shapeX.color = color;
        this.shapeY.color = color;
        this.add();
    }
    changeOpacity(fillOpacity, strokeOpacity){
        this.shapeX.options.opacity = fillOpacity;
        this.shapeX.options.strokeOpacity = strokeOpacity;

        this.shapeY.options.opacity = fillOpacity;
        this.shapeY.options.strokeOpacity = strokeOpacity;
    }
    getBounds() {
        return this.centerShapeGroup.getBounds();
    }
    getGeoPoints() {
        var geoX = this.shapeX.getLatLngs();
        var geoY = this.shapeY.getLatLngs();
        let points = [];
        for (const g of geoX) {
            points.push(g);
        }
        for (const g of geoY) {
            points.push(g);
        }
        return points;
    }
    getSavePoints(){
        let points = [];
        points.push(this.point);
        return points;
    }

    drawCorners() {
        this.undrawCorners();
        this.centerIndex = 0;
        let R = this.getCircleR();
        this.addCenterCorner(R, "white", this.centerIndex);
    }
    getCenter() {
        return this.point;
    }
    getArea() {
        const delta = this.getCircleR();
        return Math.pow(delta, 2);
    }
    move(latlng) {
        let lat = latlng.lat;
        let lng = latlng.lng;
        if (this.selectedCorner) {

            const delta = this.getCircleR();
            let newPointLAT = [[lat - delta, lng], [lat + delta, lng]];
            let newPointLNG = [[lat, lng - delta], [lat, lng + delta]];
            this.shapeX.setLatLngs(newPointLAT);
            this.shapeY.setLatLngs(newPointLNG);
            this.point = { lat: lat, lng: lng };
            this.moveCenterCorner(false);
        }
    }
    onZoom() {
        const lat = this.point.lat;
        const lng = this.point.lng;
        const delta = this.getCircleR();
        let newPointLAT = [[lat - delta, lng], [lat + delta, lng]];
        let newPointLNG = [[lat, lng - delta], [lat, lng + delta]];
        this.shapeX.setLatLngs(newPointLAT);
        this.shapeY.setLatLngs(newPointLNG);
    }
    isVisible(){
        return this.map.hasLayer(this.centerShapeGroup);
    }

}

