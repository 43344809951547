<template>
    <div class="element" v-bind:class="{ 'element-cutting': categoryItem.isCutting}">
        <div class="first-str">
            <button class="button-open"
                    v-if="categoryItem.isExpanded"
                    @click="toggleExpand">
                -
            </button>
            <button class="button-open"
                    v-if="!categoryItem.isExpanded"
                    @click="toggleExpand">
                +
            </button>

            <span class="text" v-if="categoryItem.id!=0">Id:</span>
            <input class="root-id" type="text" v-if="categoryItem.id!=0" v-model="id" @input="editElement" />
            <span class="text" v-if="categoryItem.id!=0">Класс:</span>
            <span class="text" v-if="categoryItem.id==0">Группа</span>
            <input class="root-name" v-if="categoryItem.id!=0" type="text" v-model="name" @input="editElement" />
            <button class="delete-button" v-if="!isCutOut && categoryItem.id!=0" @click="deleteElement">Удалить</button>
            <button class="add-element-button" v-if="categoryItem.isExpanded && !isCutOut" @click="addElementHere">Добавить&nbsp;элемент</button>
            <button class="add-element-button" v-if="isCutOut" @click="insertElementHere">Вставить элемент здесь</button>
        </div>
        <div class="second-str">
            <span class="text" v-if="categoryItem.id!=0">Цвет:</span>
            <input class="root-color" type="color" v-if="categoryItem.id!=0" v-model="color" @input="editElement" />
            <span class="text" v-if="categoryItem.id!=0">Полное имя:</span>
            <input class="root-full-name" type="text" v-if="categoryItem.id!=0" v-model="fullname" @input="editElement" />
            <button class="cut-button" v-if="!isCutOut && categoryItem.id!=0" @click="cutThisElement">Вырезать</button>
            <span class="uncut-button" v-if="categoryItem.isCutting && categoryItem.id!=0" @click="uncutThisElement">Отмена</span>
        </div>

        <div v-if="categoryItem.branch && categoryItem.branch.length === 0" class="text">
            <p>
                <span>Нет подклассов</span>
                <button class="add-element-button" v-if="categoryItem.isExpanded && !isCutOut" @click="addElementHere">Добавить элемент</button>
            </p>
        </div>
        <div v-for="catItem in categoryItem.branch" :key="catItem.id" class="sub-node">
            <TreeVueItem :categoryItem="catItem"
                         :isCutOut="isCutOut"
                         v-if="categoryItem.branch.length > 0 & categoryItem.isExpanded"
                         @delete-element="deleteElementHere"
                         @elementCutOut="elementCutOut"
                         @insert-element="insertElement" />
        </div>

        
    </div>

</template>
<script>

    export default {
        props: ["categoryItem", "isCutOut"],
        data() {
            return {
                id: this.categoryItem.id,
                name: this.categoryItem.name,
                fullname: this.categoryItem.fullname,
                color: this.categoryItem.color,
                //isCutOut: false
            }
        },
        computed: {

        },
        emits: ["delete-element", "element-cut-out", "insert-element"],
        methods: {
            toggleExpand() {
                this.categoryItem.toggleExpand();
            },
            addElementHere() {
                this.categoryItem.addElementHere();
            },
            deleteElement() {
                this.$emit("delete-element", this.categoryItem);
            },
            deleteElementHere(categoryItem) {
                this.categoryItem.deleteElement(categoryItem);
            },
            editElement() {
                let editData = {
                    id: this.id,
                    name: this.name,
                    fullname: this.fullname,
                    color: this.color,
                }
                if (!this.id || this.id == 0) {
                    editData.id = this.categoryItem.id;
                    this.id = this.categoryItem.id;
                }
                if (!this.name || this.name == " " || this.name == "  " || this.name == "") {
                    editData.name = this.categoryItem.name;
                    this.name = this.categoryItem.name;
                }
                this.categoryItem.editElement(editData);
            },
            elementCutOut(isCutOut, cuttingElement) {
                this.$emit("element-cut-out", isCutOut, cuttingElement)
            },
            cutThisElement() {
                this.$emit("element-cut-out", true, this.categoryItem)
                this.categoryItem.cutThisElement();
            },
            uncutThisElement() {
                this.categoryItem.uncutThisElement();
                this.$emit("element-cut-out", false, null)

            },
            insertElement(categoryItem) {
                this.$emit("insert-element", categoryItem)
            },
            insertElementHere() {
                this.$emit("insert-element", this.categoryItem)
                //this.$emit("elementCutOut", false, null)
            }
        },
        mounted() {
            //console.log("this.categoryItem", this.categoryItem);
        },
    };
</script>
<style scoped>
</style>