<template>
<nav class="menu" id="main-menu" :class="{'is-open':isOpen}">
        <button class="tool-button main-button menu-toggle" id="toggle-menu" @click="menuClick">
            инструменты
        </button>
        <div class="menu-dropdown">
            <ul class="nav-menu">
                <li v-for="tool in toolList.filter(this.filterList)" :key="tool.key">
                    <a href="javascript:void(0)" @click="e => itemClick(tool, e)">{{tool.text}}</a>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import ClipBoardManager from "../../services/ClipBoardManager";
import BrowserManager from "../../services/browserManager";
export default {
    data(){
        return{
            browserManager : {},
            isOpen : false,
            toolList : [
                {text: "нейросетевой помощник", key: 2, action : this.openNeuralAssist, role : "developer"},
                {text: "Заметки", key: 1, action : this.openNotesMenu, role : "" },
                {text: "ссылка на экран", key: 0, action : this.copyCenterLink, role : ""},
            ],
            clipBoardManager : null
        };
    },
    props: {
      roles: {
        type: Array,
        required: true,
      },
    },
    emits :["open-Notes-Menu", "open-neural-assist"],
    methods : {
        menuClick(e){
            e.preventDefault();
            this.isOpen = !this.isOpen;
        },
        itemClick(tool, e){
            e.preventDefault();
            this.isOpen = false;   
            if(tool.action){
                tool.action();
            }
        },
        openNotesMenu(){
            this.$services.eventAggregator.fire("toggledrawings", true);
        },
        filterList(item){
            if(!this.roles){
                return item.role === "";
            }
            return item.role === "" || this.roles.includes(item.role);
        },
        copyCenterLink(){
            const mapInfo = this.$services.mapManager.getMapState();
            const data = {
              maplat:mapInfo.centerLat,
              maplng:mapInfo.centerLng,
              mapzoom:mapInfo.zoom
            }
            const linkText = this.browserManager.getQueryLink(data);
            this.clipBoardManager.copyTextToClipboard(linkText);
            this.$messageManger.openPopup(linkText);
        },
        openNeuralAssist(){
            this.$services.eventAggregator.fire("toggleneuralassist", true);
            this.$services.eventAggregator.fire("toolchanged", "edit");
        }
    },
    mounted(){
        this.clipBoardManager = new ClipBoardManager(this.$messageManger);
        this.browserManager = new BrowserManager();
    }
}
</script>

<style>
.menu-toggle{
    width: 100%;
}
.menu-toggle.main-button {
    margin: 0 0 0 0;
}
.menu {
    width: 100%;
    position: relative;
}
.menu-dropdown {
    width: 100%;
    display: none;
    position: absolute;
    bottom: 100%;
    left: 0;
    margin: 0;
}
.menu.is-open .menu-dropdown {
    display: block;
}
.nav-menu{
    margin: 0;
    padding-left: 0;
    border: 1px solid #ccc;
    list-style: none;
    background-color: #6c757d;
    color: #fff;
}

.nav-menu > li + li {
    border-top: 1px solid #ccc;
}

.nav-menu > li > a {
    display: block;
    padding: 0.8em 1em;
    color: #fff;
    font-weight: normal;
    text-decoration: none;
}

.nav-menu > li > a:hover {
    background-color: black;
}
</style>