<template>
  <div class="no-overflow">
    <!-- <div style="height: 2rem">HEADER</div> -->
  <navigation-bar
    :imageName="imageName"
    :missionGroupName="missionGroupName"
    :isEditable="isEditable"
    :editorEnabled="editorEnabled" 
    :userName="userName"
    :navBarActive="navBarActive"
    :layers="layers"
    :drawingsOn="drawingsOn"
    :neuralOn="neuralOn"
    :userId="userId"
    @save-markup="save"
    @editor-is-enabled="turnEditorOn"
    @unable-editor="turnEditorOff"
  />  
  <div class="map-container">
    <map-context-menu></map-context-menu>
    <image-area :editable="editorEnabled"
                :datasetType="datasetType"/>
    <div class="left-panel">
      <div class="left-panel-even-spaced">
        <drawing-settings v-if="drawingsOn"/>
        <keep-alive>
          <object-info v-if="!drawingsOn" 
          :layers="layers" 
          :tags="tags" 
          :editable="editorEnabled" 
          :datasetType="datasetType"
          @lock-markup-item="lockMarkupItem"
          @delete-across="deleteAcross"/>
        </keep-alive>
      </div>
      <div class="left-panel-even-spaced scroll-list">
        <LayersArea
          :extraLayers="extraLayers"
          :hasExtraLayers="hasExtraLayers"
          @load-extra-layers="getExtraLayers"
          @hide-extra-layers="hideExtraLayers"
          :extraLayersLoaded="extraLayersLoaded"
          :extended="isSuperviser"
          :missionGroupId="missionGroupId"
        />
      </div>
      <div class="left-panel-min-spaced">
        <useful-tools :roles="roles" v-if="isEditable"/>
      </div>
    </div>
  </div>
  <message-presenter />
  <modal :isShown="modalIsShown" :messasge="modalMessage" @close-win="closeModal"/>
  <VideoToolBar v-if="datasetType=='Video'"
      @fbf-play="fbfPlayBack(-1)"  
      @fbf-pause="fbfPause"
      @fbf-play-with-breack="fbfPlayBack"
      @go-to-image="goToImage"
      @is-tracking="isTracking"
      :isPlaying="isFBFPlaying"
      :curImageNumber="number"
      :maxImageNumber="imageCount"
      :isTrackingOn="isTrackingOn"/>
      

  <Footer
    :imageCount="imageCount"
    :number="number"
    :jumpStep="jumpStep"
    @go-to="goToImage"
  />
  </div>
</template>

<script>
import Footer from "./Footer.vue";
import ImageArea from "./ImageArea.vue";
import BrowserManager from "../../services/browserManager";
import DataService from "../../services/DataService";
import MapContextMenu from "../MapContextMenu.vue";
import NavigationBar from "./NavigationBar.vue";
import UserInputManager from "../../services/UserInputManager";
import ObjectInfo from "./ObjectInfo.vue";
import LayersArea from "./LayersArea.vue";
import UsefulTools from "./UsefulTools.vue";
import MessagePresenter from "../MessagePresenter.vue";
import DrawingSettings from "./DrawingSettings.vue";
import BackEndService from "../../services/BackEndService.js"
import VideoToolBar from "./VideoToolBar.vue"
export default {
  components: {
    Footer,
    ImageArea,
    MapContextMenu,
    NavigationBar,
    ObjectInfo,
    LayersArea,
    UsefulTools,
    MessagePresenter,
    DrawingSettings,
    VideoToolBar 

  },
  data() {
    return {
      imageCount: 999,
      imageId: 0,
      userId: 0,
      layerId: 0,
      number: 1,
      jumpStep: 1,
      browserManager: null,
      dataService: null,
      datasetType: "",
      dataSetId: 0,
      drawingsOn: false,
      neuralOn: false,

      imageName: "Нет изображения",
      missionGroupName: "Нет названия миссии",
      missionGroupId:0,
      isEditable: false,
      editorEnabled: false,
      userName: "---",
      navBarActive: true,
      layers: [],
      tags: [],
      extraLayers: [],
      hasExtraLayers: false,
      extraLayersLoaded: true,
      isSuperviser: true,
      roles: [],
      debug : false,
      modalIsShown: false,
      modalMessage: "",

      isFBFPlaying:false,

      isTrackingOn:false,
    };
  },
  mounted() {
    if(process.env.NODE_ENV == 'development'){
      this.debug = true;
    }
    this.browserManager = new BrowserManager();
    let winParams = this.browserManager.getUrlParams();
    this.dataService = new DataService();
    console.log("Serv version", this.$services.version);
    this.dataService.getPageInfo(
      winParams.image,
      winParams.layer,
      this.setPageInfo
    );
    this.userInputManager = new UserInputManager(
      this.$services.eventAggregator
    );
    this.$services.eventAggregator.on("toggledrawings", s=>this.onToggleDrawings(s));
    this.$services.eventAggregator.on("toggleneuralassist", s=>this.onToggleNeuralAssist(s));
    this.$services.eventAggregator.on("changeimage", s=>this.changeImage(s));
    this.$services.eventAggregator.on("fullscreen", ()=>this.toggleFullScreen());
    this.$services.eventAggregator.on("enableeditor", ()=>this.turnEditorOn());

      this.$services.mapManager.on("savenotes", () => this.saveNotes());
      this.getTaskGroupId()
  },
  methods: {
      async deleteAcross(data) {
          //console.log("MarkupPage____deleteAcross",data)
          var b = new BackEndService();
          var uploadData = {
              ImgId: this.imageId,
              LayerId: this.layerId,
              MarkupId: data.markupId,
              After: data.after
          };
          var resp = await b.deleteAcross(uploadData);
          console.log("MarkupPage____deleteAcross RESP",resp);
          if (resp.statusCode == 200) {
              this.$messageManger.show("Выделение объекта удалено", 'success')
          } else {
              this.$messageManger.show("Ошибка при сквозном удалении", 'error')
          }
      },
      async lockMarkupItem(markupId,lockValue){
        var b = new BackEndService();
        var data={
          ImgId:this.imageId,
          LayerId:this.layerId,
          MarkupId: markupId,
          lockValue: lockValue,
        };
        console.log("selectedObject",data);
          var resp = await b.lockMarkupItem(data);
          //console.log("MarkupPage____deleteAcross RESP", resp);
          if (resp.statusCode == 200) {
              if (data.lockValue) {
                  this.$messageManger.show("Объект зафиксирован", 'success')
              } else {
                  this.$messageManger.show("Объект разблокирован", 'success')
              }
          } else {
              this.$messageManger.show("Ошибка при фиксации", 'error')
          }
        console.log("lockMarkupItem",resp);
      },
      isTracking() {
        if(this.debug){
          console.log("isTracking()", this.isTrackingOn );
        }
        this.isTrackingOn = !this.isTrackingOn;
      },
      async fbfPlayBack(imageNumber) {
          this.isFBFPlaying = true;

          var imgCounter = 1;

          if (this.$services.mapManager.hasChanges()) {
              await this.save();
          }

          var currLayer=this.$services.mapManager.getLayersToSave()[0];

          while ((this.isFBFPlaying && imgCounter <= imageNumber && imageNumber > 0)
              || (imageNumber < 0 && this.isFBFPlaying)) {
              imgCounter = imgCounter + 1;
             
              if(currLayer.data.length==0 && this.isTrackingOn){
              this.$messageManger.show("Нет разметки для трекинга", 'error');
              this.isTracking();
              break;
              }

              if (this.imageCount == this.number) {
                  this.isFBFPlaying = false;
                  break;
              }
              if (this.isTrackingOn ) {
                  var b = new BackEndService();
                  var data = { isPlaying: true, imageId: this.imageId }
                  var resp = await b.VideoTracking(data)
                  var trackingStatusCode = resp.statusCode;
                  if (trackingStatusCode == 200) {
                      this.$services.mapManager.fadeImage();
                      await this.getNewImage({ number: this.number + 1 });
                  }
                  else {
                      this.$messageManger.show("Ошибка трекинга", 'error');
                      this.isFBFPlaying = false;
                  }
              }
              if (!this.isTrackingOn) {
                  this.$services.mapManager.fadeImage();
                  await this.getNewImage({ number: this.number + 1 });
                  //await setTimeout(() => { console.log('Hello after 4 seconds'); }, 10000);
              }
          }
          this.isFBFPlaying = false;
      },
      fbfPause() {
          this.isFBFPlaying = false;
      },
    async getTaskGroupId() {
          var b = new BackEndService();
          var rep = await b.getTaskGroupId()
          console.log("getTaskGroupId", rep.data);
          this.missionGroupId = rep.data
          //return rep.data;
    },
    closeModal(){
      this.modalIsShown = false;
    },
    goToImage(num) {
      this.changeImage({number:num})
    },
    changeImage(data){
      const canChange = this.$services.mapManager.canSaveMarkup();
      if(!canChange){
        this.modalMessage = "Сначала нужно доработать со слоем нейросетевой разметки";
        this.modalIsShown = true;
        return;
      }
      this.$services.mapManager.fadeImage();
      this.save(true, data);
    },
      async getNewImage(data) {
          if (this.datasetType == 'Video') {
              if (data.number) {
                  await this.dataService.getVideoFrame(this.number, data.number - this.number, true, this.dataSetId, this.setImage, this.onImageError);
                  return;
              }
              if (data.step) {
                  await this.dataService.getVideoFrame(this.number, data.step, true, this.dataSetId, this.setImage, this.onImageError);
              }
          }
          else {
              if (data.number) {
                  await this.dataService.getImage(this.number, data.number - this.number, true, this.dataSetId, this.setImage, this.onImageError);
                  return;
              }
              if (data.step) {
                  await this.dataService.getImage(this.number, data.step, true, this.dataSetId, this.setImage, this.onImageError);
              }
          }
    },
    setPageInfo(data) {
      if(this.debug){
        console.log("PageInfo", data);
      }
      //data.datasetType = "";
      this.missionGroupName = data.missionGroupName;
      if(!this.missionGroupName){
        this.missionGroupName = "";
      }
           this.roles = data.roles;
      if(!this.roles){
        this.roles = [];
      }
      this.tags = data.tags;
      this.userId = data.userId;
      this.userName = data.username;
      this.imageCount = data.imageCount;
      this.isEditable = data.editable;
      window.appstate.isEditable = data.editorEnabled;
      this.editorEnabled = data.editorEnabled;
      this.$services.mapManager.init(data.datasetType);
      this.$services.state.datasetType=data.datasetType;
      this.datasetType = data.datasetType;
      let winParams = this.browserManager.getUrlParams();
      this.dataService.getImage(winParams.image, 0, false, 0, this.setImage, this.onImageError);
      this.$services.eventAggregator.fire("editorenabled", this.editorEnabled);
      //console.log("datasetType from MarkupPage", this.datasetType)
    },
    onImageError(data){
      if(data.response.status == 404){
        this.$services.mapManager.returnImage();
        this.$messageManger.show("Больше нет изображений", '');
        return;
      }
      this.$messageManger.show("Ошибка загрузки изображения!", 'error');

    },
    setImage(data) {
      console.log("IMAGEInfo", data);
      this.$services.state.maxZoom =
        this.datasetType == "MapSlices" ? 20 : data.maxZoom;
      this.$services.state.mapSystem =
        this.datasetType == "MapSlices" ? "gps" : "simple";
      this.$services.mapManager.initImage(data);
      this.imageId = data.id;
      this.layerId = data.layerId;
      this.imageName = data.imageName;
      this.dataSetId = data.dataSetId;
      this.number = data.number;
      this.dataService.getMarkup(
        this.imageId,
        this.layerId,
        this.setMarkup,
        this.clearMarkup
      );
      this.loadNotes();
      this.browserManager.setUrl(this.imageId, this.layerId, true);
    },
    setMarkup(data, imageId) {
      if(this.debug){
        console.log("MARKUP DATA --------");
        console.log(data);
        console.log("------- END MARKUP DATA --------");
      }
      this.layerId = data.layerId;
      if (imageId !== data.imageId) {
        console.log("Wrong markup data");
        return;
      }
      this.$services.mapManager.initMarkup(data);
      this.browserManager.setUrl(this.imageId, this.layerId, false);
    },

    clearMarkup() { },

    async save(shouldChangeImage,changeImageData){
      if(!this.isEditable){
        if(shouldChangeImage){
                this.getNewImage(changeImageData);
        }
        return;
      }
      const layers = this.$services.mapManager.getLayersToSave();
      const zeroChanges = !this.$services.mapManager.hasChanges();
      const dataToSave = {
        layers: layers,
        imageId: this.imageId,
        layerId: this.layerId,
        imageName: this.imageName,
        zeroChanges: zeroChanges,
        coordinateMultiplier: this.$services.mapManager.getMulitplier(),
        mapManager: this.$services.mapManager
      }
      if(this.debug){
      console.log("data to save", dataToSave);
      }
      await this.dataService.saveData(dataToSave,
        (data, success, errorMessage, code) => {
          if(this.debug){
            console.log("Response", data);
          }
          //clearTimeout(savingTimeout);
          if (success) {
            if(!zeroChanges){
              this.$messageManger.show("Разметка сохранена!", 'success');
            }
            if(zeroChanges && !shouldChangeImage){
              this.$messageManger.show("В разметку не внесены изменения", '');
            }
            if(shouldChangeImage){
                this.getNewImage(changeImageData);
            }
            this.$services.mapManager.onSaveSuccess();
            //window.services.markupManager.clearEditedArray();
          } else {
            if(code === 403){
              this.$messageManger.show("Ошибка! Нет доступа к этой миссии!", 'error');
            }
            else{
              this.$messageManger.show("Ошибка сохранения разметки!", 'error');
            }
            console.log(errorMessage);
          }
        }
      );
    },
    saveNotes(){
      this.dataService.saveNotes(
        this.imageId,
        this.$services.mapManager.getDrawings(),
        (data, success, errorMessage) => {
          if (success) {
            var nLink=window.location.origin+data.link;
            this.$messageManger.openPopup(nLink);
          } else {
            this.$messageManger.show("Ошибка сохранения заметки!", 'error');
            console.log(errorMessage);
          }
        }
        );
    },
    loadNotes(){
       const params = this.browserManager.getUrlParams();
       if(isNaN(params.note)){
        return;
      }
      this.dataService.getNotes(
        this.imageId, 
        params.note,
        (data, id) => {
          this.$services.mapManager.setNotes(data,id);
          this.$services.eventAggregator.fire("toggledrawings", true);
        }, 
        noteId => {
        console.log(`Note with id=${noteId} not found`);
      });
    },
    onToggleDrawings(show){
      this.drawingsOn = show;
      window.appstate.drawingsOn = show;
    },
    onToggleNeuralAssist(show){
      this.neuralOn = show;
    },
    turnEditorOn() {
      this.editorEnabled = true;
      this.isEditable = true;
      window.appstate.isEditable = true;
      this.$services.eventAggregator.fire("editorenabled", this.editorEnabled);
    },
    turnEditorOff() {
      this.save();
      this.editorEnabled = false;
      //this.isEditable = true;
      window.appstate.isEditable = false;
      //this.$services.eventAggregator.fire("editorenabled", this.editorEnabled);
    },
    getExtraLayers(){},
    hideExtraLayers(){},
    toggleFullScreen(){
      this.browserManager.toggleFullScreen();
    }
  },
};
</script>

<style scoped>
.no-overflow{
  overflow: hidden;
}
.map-container {
  display: flex;
  height: calc(100vh - 2rem - 2rem);
}
.left-panel {
  display: flex;
  /* justify-content: space-around; */
  flex-direction: column;
  margin-left: 0.5em;
  /* flex-wrap: wrap; */
  /* align-items: flex-start; */
  min-width: 15em;
  max-width: 20em;
}
.left-panel-even-spaced {
  flex: 1 0 0;
}
.left-panel-min-spaced {
  flex: 0 1 0;
}
.scroll-list{
  overflow: auto;
}
</style>