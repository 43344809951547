import { createNewLayer, createCategory, getMarkupOptions, duplicateLayer } from "../../models/layerFactory";
// import { createNewLayer, createCategory, getMarkupOptions, duplicateLayer } from "../../models/layerFactory";
export default class MapMarkupLayers {
    markupLayers = [];
    activeLayer = null;
    neuralLayer = null;

    constructor(objectCreator){
        this.createMarkupObject = objectCreator;
    }


    init(layers) {
        this.markupLayers = []
        for (const layer of layers) {
            const newLayer = createNewLayer(layer);
            newLayer.categoryList = [...layer.items];
            for (const category of layer.items) {
                newLayer.categories[category.id] = createCategory(category);
            }
            for (const markup of layer.markups) {
                const options = getMarkupOptions(layer, markup);
                options.itsLayer = newLayer;
                let objToDraw = this.createMarkupObject(markup);
                objToDraw.itsLayer = newLayer;
                objToDraw.createExisting(markup, options, options.objStrokeOpacity, options.objFillOpacity);
                newLayer.data.push(objToDraw);
            }
            newLayer.isOn = true;
            this.markupLayers.push(newLayer);
            if(!this.activeLayer && !newLayer.isReadOnly && newLayer.isOn){
                this.activeLayer = newLayer;   
            }
        }
        let i = 0;
        for (const l of this.markupLayers) {
            l.idName = "element" + l.id;
            l.isOn = true;
            l.isReadOnly = !this.hasExtraLayers && i > 0;
            i++;
        }
    }
    getActiveLayer(){
        if(this.neuralLayer){
            return this.neuralLayer;
        }
        for (const l of this.markupLayers) {
            if(!l.isReadOnly && l.isOn){
                return l;
            }
        }
        return null;
    }
    addTempLayer(markups){
        let activeLayer = this.getActiveLayer();
        if(!this.neuralLayer){
            this.changeObjectsOpacity(0.05, 0.25, activeLayer.data);
            this.neuralLayer = duplicateLayer(activeLayer);
            this.neuralLayer.name ="neuralLayer";
            this.markupLayers.push(this.neuralLayer);
        }
        const newLayer = this.neuralLayer;
        // console.log("Layer copy", newLayer, "Active", activeLayer);
        for (const markup of markups) {
            const options = getMarkupOptions(activeLayer, markup);
            // console.log("Object options", options);
            options.itsLayer = newLayer;
            let objToDraw = this.createMarkupObject(markup);
            objToDraw.itsLayer = newLayer;
            objToDraw.createExisting(markup, options, options.objStrokeOpacity, options.objFillOpacity);
            // console.log("Object ro draw", objToDraw);
            newLayer.data.push(objToDraw);
        }
        newLayer.isOn = true;
        newLayer.isTemporary = true;
    }
    discardNeuralLayer(){
        if(!this.neuralLayer){
            return;
        }
        for (var obj of this.neuralLayer.data) {
            obj.remove();
        }
        this.markupLayers = this.markupLayers.filter(l=>l !== this.neuralLayer);
        this.neuralLayer = null;

        let activeLayer = this.getActiveLayer();
        this.changeObjectsOpacity(0.2, 1.0, activeLayer.data);
    }
    applyNeuralMarkup(){
        if(!this.neuralLayer)
        {
            return;
        }
        const list = [...this.neuralLayer.data];
        this.discardNeuralLayer();
        const activeLayer = this.getActiveLayer();
        for (const obj of list) {
            activeLayer.data.push(obj);
        }
    }
    changeObjectsOpacity(fillOpacity, strokeOpacity, items){
        for (const obj of items) {
            obj.changeOpacity(fillOpacity, strokeOpacity);
        }
    }
    clearView = () => {
        for (var layer of this.markupLayers) {
            for (var obj of layer.data) {
                obj.remove();
            }
        }
        this.markupLayers = [];
    }
    getAll(){
        return this.markupLayers;
    }
    getSorted(){
        const orderedLayers = [...this.markupLayers];
        orderedLayers.sort((l1, l2) => Number(l2.isReadOnly) - Number(l1.isReadOnly));
        return orderedLayers;
    }
    getVisible(){
        if(this.neuralLayer){
            return this.markupLayers.filter(l=>l === this.neuralLayer);
        }
        return this.markupLayers.filter(l=>l.isOn);
    }
    isPassive(layer) {
        return !layer.isOn || layer.isReadOnly;
    }
    isEditableLayer(layer){
        return layer.isOn && !layer.isReadOnly;
    }
}