<template>
  <h2 style="text-align: center; font-weight: bold; font-size: 2.1vw">
    Текущие миссии
  </h2>
  <div style="text-align: center" v-if="isLoading">
    <IsLoadingItem />
  </div>
  <div class="mission-container" v-if="!isLoading">
    <div class="task" v-for="task in tasks" :key="task.id">
      <div class="flexbox">
        <p>
          <span class="small-muted">группа:</span>
          <router-link tag="span" :to="'/group/' + task.id">
            {{ task.name }}</router-link
          >
        </p>
        <span class="to-right bold">{{ task.createdText }}</span>
      </div>

      <span>{{ task.description ?? "нет описания" }}</span>
      <div class="flexbox">
        <progress
          style="vertical-align: middle"
          :value="tasksItems.find((t) => t.groupId == task.id)?.progress"
          max="100"
        ></progress>
        <span class="ml-1">
          {{ tasksItems.find((t) => t.groupId == task.id)?.progressText }}
        </span>
      </div>
      <div>
        <div
          v-for="mission in task.tasks"
          :key="mission.id"
          class="dataset-task-item mt-2"
        >
          <div class="flexbox">
            <a :href="'/Tasks/Continue/' + mission.id">
              <span class="small-muted">миссия</span>
              <span class="ml-1">{{ mission.id }}</span>
            </a>
            <progress
            style="vertical-align: middle"
            class="task-progress"
            :value="getProgress(mission)"
            max="100"></progress>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IsLoadingItem from "./IsLoadingItem.vue";
import BackEndService from "../services/BackEndService.js";

export default {
  components: {
    IsLoadingItem,
  },
  data() {
    return {
      tasks: [],
      tasksItems: [],
      isLoading: false,
    };
  },
  methods: {
    async getTasks() {
      var b = new BackEndService();
      var req = await b.getTasks();
      //let el = await axios.post('/Tasks/GetTasks');

      this.tasks = req.data;
      for (const taskGroup of this.tasks) {
        if(!taskGroup.description || taskGroup.description.length < 1){
            taskGroup.description = "нет описания";
        }
      }
      console.log("Tasks", this.tasks);
      await this.getTasksItems();
    },
    async getTasksItems() {
      var b = new BackEndService();
      var req = await b.getTasksItems();
      //let el = await axios.post('/Tasks/GetTasksItems');

      this.tasksItems = req.data;
      console.log("TasksItems", this.tasksItems);
      this.isLoading = false;
    },
    getProgress(task){
        if(task.doneImageIds.length == 0){
            return 0;
        }
        const number = task.doneImageIds.length / task.imageIds.length;
        return 100*number.toFixed(2);
    }
  },
  mounted() {
    this.isLoading = true;
    this.getTasks();
  },
};
</script>
<style scoped>
.task {
  width: calc(50% - 3em);
  border: 1px solid gray;
  margin: 0.4rem;
  padding: 0.7em;
}
.mission-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}
.dataset-task-item {
  border: 2px solid rgba(200, 200, 200, 0.4);
  border-width: 0;
  border-bottom-width: 2px;
  margin-left: 1em;
  
}
.task-progress{
    opacity: 0.3;
}
</style>