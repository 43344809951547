<template>
    <div>
        <div v-for="markupGroup in project.markupGroups" :key="markupGroup.id">
            <div class="flexbox">
                <h4>
                    <button class="site-button" @click="()=>toggleGroup(markupGroup.id)">
                        {{this.listStates[markupGroup.id] ? '-' : "+"}}
                    </button>
                </h4>
                <h3>
                    <span class="muted">Группа</span>
                </h3>
                <input class="input-text" type="text" v-model="markupGroup.name" />
                <span class="muted auto-left">вид:</span>
                <button class="site-button" :class="{'main-button':listOrTree[markupGroup.id] == 'list'}" @click="showClassList(markupGroup)">
                    список
                </button>
                <button class="site-button" :class="{'main-button':listOrTree[markupGroup.id] != 'list'}" @click="showTree(markupGroup)">
                    дерево
                </button>
            </div>
            <div v-if="listStates[markupGroup.id]">
                <table v-if="listOrTree[markupGroup.id] == 'list'"
                       class="table table-bordered table-sm table-primary">
                    <tr>
                        <th class="small-col">id</th>
                        <th>класс</th>
                        <th>цвет</th>
                        <th>наименование</th>
                        <th class="small-col">id категории</th>
                        <th>категория</th>
                    </tr>
                    <tr v-for="markupCategory in markupGroup.markupCategories"
                        :key="markupCategory.id">
                        <td class="small-col">
                            <input class="form-control"
                                   type="text"
                                   v-model="markupCategory.id" />
                        </td>
                        <td>
                            <input class="form-control"
                                   type="text"
                                   v-model="markupCategory.name" />
                        </td>
                        <td>
                            <input class="form-control"
                                   type="color"
                                   v-model="markupCategory.color" />
                        </td>
                        <td>
                            <input class="form-control"
                                   type="text"
                                   v-model="markupCategory.fullname" />
                        </td>
                        <td class="small-col">
                            <input class="form-control"
                                   type="text"
                                   v-model="markupCategory.supercategoryId" />
                        </td>
                        <td>
                            <input class="form-control"
                                   type="text"
                                   v-model="markupCategory.supercategory" />
                        </td>
                        <td>
                            <input class="form-control"
                                   type="button"
                                   @click="deleteMarkupCategory(markupGroup, markupCategory)"
                                   value="⨉" />
                        </td>
                    </tr>
                </table>

                <div class="tree" v-if="listOrTree[markupGroup.id] == 'tree'">
                    <TreeVue :markupGroup="markupGroup" v-if="false"
                             @update-categories="updatecategories"
                             :ref="'TreeVue' + markupGroup.id" />
                    <category-tree :ref="'categoryTree_'+markupGroup.id" @on-load="() => onCategoryTreeLoad(markupGroup)" />
                </div>
            </div>
            <div class="btn-bottom-bord">
                <div class="btn-bottom-bord-left">
                    <span>Цвет</span>
                    <input class="ml-1" type="color" v-model="unicolors[markupGroup.id]" />
                    <button class="site-button"
                            type="button"
                            @click="setColorsForAll(markupGroup)">
                        Задать всем классам
                    </button>
                </div>
                <div class="btn-bottom-bord-right mt-2" style="margin-left: auto">
                    <button class="site-button danger-button ml-0"
                            type="button"
                            @click="deleteMarkupGroup(markupGroup)">
                        Удалить группу
                    </button>
                    <button class="site-button"
                            type="button"
                            @click="duplicateMarkupGroup(markupGroup)">
                        Дублировать группу
                    </button>
                    <button class="site-button"
                            type="button"
                            @click="convertToJson(markupGroup)">
                        Преобразовать в JSON
                    </button>

                    <button class="site-button"
                            type="button"
                            v-if="listOrTree[markupGroup.id] == 'list'"
                            @click="addNewMarkupCategory(markupGroup)">
                        Добавить класс
                    </button>
                </div>
            </div>
            <hr />
        </div>
        <p>Группа из JSON</p>
        <div class="d-flex-container">
            <textarea class="json-area" v-model="jsonGroup" rows="10"></textarea>
            <div class="buttons-for-textarea">
                <button type="button"
                        class="site-button"
                        style="background-color: #1b6ec2"
                        @click="addNewMarkupGroupFromJson">
                    Конвертировать плоский список
                </button>
                <button type="button"
                        class="site-button"
                        style="background-color: #1b6ec2"
                        @click="downloadJson">
                    Скачать JSON
                </button>
            </div>
        </div>
        <hr />
        <div class="d-flex">
            <button type="button" class="site-button main-button" @click="()=>addNewGroup()">Новая группа</button>
            <button type="button" class="site-button" @click="save">Сохранить изменения в классах</button>
            <statusElement :statusCode="saveStatusCode" />
        </div>
        <confirmation-modal :isShown="showModal" @close-win="onModalClose" :messasge="modalMessage" />
    </div>
</template>

<script>
    import TreeVue from "../TreeVue.vue";
    import IdManager from "../../helpers/IdManager.js";
    import BackEndService from "../../services/BackEndService.js"
    import statusElement from "../statusElement.vue"
    import CategoryTree from "../categories/CategoryTree.vue";

    export default {
        components: {
            TreeVue,
            statusElement,
            CategoryTree
        },
        props: ["project", "idManagerMarkupGroup"],
        data() {
            return {
                listOrTree: {},
                unicolors: [],
                listStates:[],
                jsonGroup: "",
                saveStatusCode: "",
                showModal: false,
                modalMessage: ""
            };
        },
        mounted() {
            if (this.project && this.project.markupGroups) {
                this.fillListOrTree();
            }
        },
        methods: {
            async save() {
                this.saveStatusCode = "0";
                //
                for (var markupGroup of this.project.markupGroups) {
                    this.showClassList(markupGroup);
                }
                //
                const dataObj = { projectId: Number(this.project.markupProject.id), groups: this.project.markupGroups };
                console.log("save dataObj", dataObj);
                console.log("project", this.project);
                var b = new BackEndService();
                var req = await b.saveCategories(dataObj);
                if (req) {
                    this.saveStatusCode = req.statusCode;
                }
                else {
                    this.saveStatusCode ="404";
                }
                console.log("save Cat", req);
            },
            showClassList(markupGroup) {
                if (this.listOrTree[markupGroup.id] != "list") {
                    const tree = this.getTree(markupGroup.id);
                    if(!tree){
                        return;
                    }

                    this.listOrTree[markupGroup.id] = "list";
                    markupGroup.markupCategories = tree.enlistCategories();
                }
            },
            showTree(markupGroup) {
                this.listOrTree[markupGroup.id] = "tree";
            },
            deleteMarkupGroup(markupGroup) {
                this.modalMessage = `Вы действительно хотите удалить группу классов "${markupGroup.name}"?`;
                this.groupToDelete = markupGroup;
                this.showModal = true;
            },
            onModalClose(answer){
                this.showModal = false;
                const markupGroup = this.groupToDelete;
                if(answer !== 'yes' || !markupGroup){
                    return;
                }
                const markupGroups = this.project.markupGroups;
                const index = markupGroups.indexOf(markupGroup);
                if (index > -1) {
                    markupGroups.splice(index, 1);
                }
            },
            deleteMarkupCategory(markupGroup, markupCategory) {
                markupGroup.markupCategories = markupGroup.markupCategories.filter(
                    (C) => C != markupCategory
                );
            },
            updatecategories(newCategories, markupGroupId) {
                var neededMarkupGroup = this.project.markupGroups.find(
                    (g) => g.id == markupGroupId
                );
                neededMarkupGroup.markupCategories = newCategories;
            },
            setColorsForAll(markupGroup) {
                if (this.listOrTree[markupGroup.id] == "tree") {
                    const tree = this.getTree(markupGroup.id);
                    if(!tree){
                        return;
                    }
                    tree.applyGroupColor(this.unicolors[markupGroup.id]);
                }
                if (this.listOrTree[markupGroup.id] == "list") {
                    for (var category of markupGroup.markupCategories) {
                        category.color = this.unicolors[markupGroup.id];
                    }
                }
            },
            duplicateMarkupGroup(markupGroupForDuplicate) {
                let newMarkupGroup = {
                    id: this.idManagerMarkupGroup.getNewId(),
                    markupCategories: markupGroupForDuplicate.markupCategories,
                    name: markupGroupForDuplicate.name + "_clone",
                    projectId: 0,
                    unicolor: "#ff0000",
                };
                const markupGroups = this.project.markupGroups;
                markupGroups.push(newMarkupGroup);
                this.initNewGroupState(newMarkupGroup.id);
            },
            convertToJson(markupGroup) {
                if (this.listOrTree[markupGroup.id] == "tree") {
                    this.$refs["TreeVue" + markupGroup.id].createCategoryList();
                }
                var mCategories = [];
                for (var C of markupGroup.markupCategories) {
                    var cleanCat = {
                        color: C.color,
                        fullname: C.fullname,
                        id: C.id,
                        name: C.name,
                        supercategoryId: C.supercategoryId,
                        supercategory: C.supercategory,
                    };
                    mCategories.push(cleanCat);
                }
                const dataToSend = JSON.stringify(mCategories, null, "  ");
                this.jsonGroup = dataToSend;
            },
            addNewMarkupCategory(markupGroup) {
                const IdMn = new IdManager(markupGroup.markupCategories[0].id);
                let supercategory;
                for (var markupCategory of markupGroup.markupCategories) {
                    IdMn.addId(markupCategory.id);
                    if (markupCategory.supercategoryId == 0) {
                        supercategory = markupCategory.supercategory;
                    }
                }
                var newMarkupCategory = {
                    color: "#ff0000",
                    fullname: null,
                    id: IdMn.getNewId(),
                    name: "Новый класс",
                    objectCount: 0,
                    supercategory: supercategory,
                    supercategoryId: 0,
                };
                markupGroup.markupCategories.push(newMarkupCategory);
            },
            addNewMarkupGroupFromJson() {
                const json = this.jsonGroup;
                if(!json || json.length == 0){
                    return;
                }
                let newGroup = JSON.parse(json);
                this.addNewGroup("группа из Json", newGroup);
            },
            addNewGroup(name, markupCategories) {
                var newMarkupGroup = {
                    name: "",
                    id: this.idManagerMarkupGroup.getNewId(),
                    //projectId: Number(this.id),
                    projectId: Number(this.project.markupProject.id),
                    unicolor: "#ff0000",
                    markupCategories: [],
                };

                if (name) {
                    newMarkupGroup.name = name + " " + newMarkupGroup.id;
                } else {
                    newMarkupGroup.name = "Новая группа классов " + newMarkupGroup.id;
                }

                if (markupCategories) {
                    for (var markupCategory of markupCategories) {
                        if (
                            markupCategory.supercategory_id !== null &&
                            markupCategory.supercategory_id !== undefined
                        ) {
                            markupCategory.supercategoryId = Number(
                                markupCategory.supercategory_id
                            );
                            delete markupCategory.supercategory_id;
                        }
                        if (markupCategory.id == 0) {
                            newMarkupGroup.name = markupCategory.name;
                        } else {
                            newMarkupGroup.markupCategories.push(markupCategory);
                        }
                    }
                } else {
                    let newMarkupCategory = {
                        color: "#ff0000",
                        fullname: null,
                        id: 1,
                        name: "Новый класс",
                        objectCount: 0,
                        supercategory: "Новая группа классов",
                        supercategoryId: 0,
                    };
                    newMarkupGroup.markupCategories.push(newMarkupCategory);
                }
                const markupGroups = this.project.markupGroups;
                markupGroups.push(newMarkupGroup);
                this.initNewGroupState(newMarkupGroup.id);
            },
            initNewGroupState(id){
                this.listOrTree[id] = "list";
                this.unicolors[id] = "#ff0000";
                this.listStates[id] = true;
            },
            fillListOrTree() {
                for (var markupGroup of this.project.markupGroups) {
                    this.listOrTree[markupGroup.id] = "list";
                    this.unicolors[markupGroup.id] = "#ff0000";
                    this.listStates[markupGroup.id] = true;
                }
            },
            downloadJson() {
                var json = this.jsonGroup;
                json = [json];
                var blob1 = new Blob(json, { type: "text/plain;charset=utf-8" });
                var isIE = false || !!document.documentMode;
                if (isIE) {
                    window.navigator.msSaveBlob(blob1, "Customers.txt");
                } else {
                    var url = window.URL || window.webkitURL;
                    var link = url.createObjectURL(blob1);
                    var a = document.createElement("a");
                    a.download = "categories.json";
                    a.href = link;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            },
            getTree(id){
                const tree = this.$refs["categoryTree_"+id][0];
                return tree;
            },
            onCategoryTreeLoad(markupGroup){
                const tree = this.getTree(markupGroup.id);
                if(!tree){
                    return;
                }
                tree.init(markupGroup.name, markupGroup.markupCategories);
            },
            toggleGroup(id){
                this.listStates[id] = !this.listStates[id];
            }
        },
    };
</script>

<style scoped>
    table .small-col {
        width: 3rem !important;
    }

    table {
        background-color: rgba(0, 0, 0, 0.2);
        border: 1px solid #dee2e6;
        border-collapse: collapse;
        display: table;
        border-spacing: 2px;
        width: 100%;
    }

        table input:not([type=checkbox]):not([type=radio]):not([type=button]) {
            width: calc(100% - 0.8rem);
        }

    .json-area {
        flex-grow: 1 !important;
    }

    .input-text {
        width: 100%;
        font-size: 120%;
    }
</style>