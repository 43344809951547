import rbush from "rbush";
export default class RTreeManager{
    rtree;
    constructor(){
        this.rtree = new rbush();
    }
    addItems(objs){
        const items = objs.map(o=>{
            const b = o.getBounds();
            const item = {
                minX: b.getWest(),
                minY: b.getSouth(),
                maxX: b.getEast(),
                maxY: b.getNorth(),
                shape: o
            };
            return item;
        });
        this.rtree.load(items);
    }
    addItem(o){
        const b = o.getBounds();
            const item = {
                minX: b.getWest(),
                minY: b.getSouth(),
                maxX: b.getEast(),
                maxY: b.getNorth(),
                shape: o
            };
        this.rtree.instert(item);
    }
    getObjects(mapBounds){
        const bounds = {
            minX: mapBounds.getWest(),
            minY: mapBounds.getSouth(),
            maxX: mapBounds.getEast(),
            maxY: mapBounds.getNorth()
        }
        const items = this.rtree.search(bounds);
        const result = items.map(i=>i.shape);
        return result;
    }
}