<template>
  <td style="font-weight:bold">{{taggedLayer.name}}</td>
  <td>
    <span class="small-muted">тип:</span>
    <span>{{markupTypes[taggedLayer.type]}}</span>
  </td>
  <td v-if="taggedLayer.type=='Release'">
      <button class="site-button main-button" @click="revertToDraft(taggedLayer.id)">В черновик</button>
      <button class="site-button" @click="updateReleaseTime(taggedLayer.id)">
          <update-time-image />
      </button>
  </td>
  <td v-if="taggedLayer.type=='Draft'"><button class="site-button main-button" @click="publishDraft(taggedLayer.id)">Опубликовать</button></td>
  <td v-if="taggedLayer.type!='Draft' && taggedLayer.type!='Release'"></td>
  <td>
  <button class="site-button" @click="downloadMarkup('?proj=' + imageList.project + '&dataset=' + imageList.name + '&name=' + taggedLayer.name + '&layer=' + taggedLayer.layerId)">Скачать разметку</button>
  <button class="site-button" @click="downloadMarkupObjImg('?proj=' + imageList.project + '&dataset=' + imageList.name + '&name=' + taggedLayer.name + '&layer=' + taggedLayer.layerId)"> Скачать Obj.csv</button>
  </td>
</template>

<script>
import UpdateTimeImage from "./UpdateTimeImage.vue";
import BackEndService from "../../services/BackEndService.js";

export default {
    components :{
        UpdateTimeImage
    },
        props: ["taggedLayer", "imageList"],
    data() {
        return {
            markupTypes: {
                "Draft": "черновик",
                "NeuralNet": "нейросеть",
                "Private": "личный",
                "Release": "готовый",
            },
        };
    },
    emits: ["get-image-list"],
    methods : {
        async updateReleaseTime(id) {
            var MarkupItemInfo = {
                id: id,
            }
            var b = new BackEndService;
            var req = await b.updateReleaseTime(MarkupItemInfo);
            this.$emit('get-image-list');
            console.log("req", req);
        },
        async revertToDraft(id) {
            var MarkupItemInfo = {
                id: id,
            }
            var b = new BackEndService;
            var req = await b.revertToDraft(MarkupItemInfo);
            this.$emit('get-image-list');
            console.log("req", req);
        },
        async publishDraft(id) {
            var MarkupItemInfo = {
                id: id,
            }
            var b = new BackEndService;
            var req = await b.publishDraft(MarkupItemInfo);
            this.$emit('get-image-list');
            console.log("req", req);
        },
        downloadMarkup(downloadLinkParams) {
            var href = "/Markup/Download/" + downloadLinkParams;
            window.open(href, '_blank');
        },
        downloadMarkupObjImg(downloadLinkParams) {
            var href = "/Markup/DownloadObjImg/" + downloadLinkParams;
            window.open(href, '_blank');
        },
    }
}
</script>

<style scoped>
td {
    padding: 1rem 0;
}
span + span {
    margin-left: 0.4rem;
}
</style>