import ShapeObjectBase from "./ShapeObjectBase";
import PolyShapes from "../shapes/PolyShape";
export default class PolyObjects extends ShapeObjectBase {
    shape;
    // corner = [];
    type = "poly";
    constructor(mapManager) {
        super(mapManager);
    }
    createShape(markup, options) {

        let points = [];
        for (let i = 0; i < markup.points.length - 1; i = i + 2) {
            points[i / 2] = { x: markup.points[i], y: markup.points[i + 1] };
        }
        this.shape = new PolyShapes(this.mapManager, options);
        this.shape.draw(points);
    }
    createNewShape(options, point){
        const p1 = {x:point.x, y:point.y};
        const p2 = {x:point.x+2, y:point.y+2};
        let newPoints = [p1,p2];
        this.shape = new PolyShapes(this.mapManager, options);
        this.shape.draw(newPoints);
        this.select();
        this.shape.selectCorner(1);
    }
    addPoint(point, ignoreIntersection){
        return this.shape.addPoint(point, ignoreIntersection);
    }
    finish(){
        const result =  this.shape.finish();
        if(result){
            this.mouseUp();
        }
        return result;
    }
    processPoint(e){
        this.shape.processPoint(e);
    }
}