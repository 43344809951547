<template>
<div>
    <h2>
        {{hasUsers ? 'Разделение картинок на миссии' : 'Создать миссии, разделив картинки на группы'}}
    </h2>
    <div style="margin-top: 2em;" v-show="!hasUsers">
        <input type="radio" name="percentPiece" id="bypercent" value="bypercent" v-model="radioButton">
        <label for="bypercent">
            по процентному соотношению
        </label>
        <input type="radio" name="percentPiece" id="piece" value="bysize" v-model="radioButton">
        <label for="piece">
            по количесву изображений в миссии
        </label>
        <input type="radio" name="percentPiece" id="byHand" value="byHand" v-model="radioButton">
        <label for="byHand">
            вручную
        </label>
    </div>
    <div class="mt-2">
        <div v-if="radioButton=='bypercent'">
            по процентному соотношению
            <input type="number"
                   v-model="percent"
                   @input="checkPercent" />%
            <button @click="splitImagesByTasks" class="site-button main-button">Создать миссии</button>
        <statusElement :statusCode="splitingStatuseCode"/>
        </div>
        <div v-if="radioButton=='bysize'">
            по количеству изображений в миссии
            <input type="number"
                   v-model="piece"
                   @input="checkPiece" />шт.
            <button @click="splitImagesByTasks" class="site-button main-button">Создать миссии</button>
            <statusElement :statusCode="splitingStatuseCode"/>
        </div>
        <div v-if="radioButton=='byHand'">
            <DragImagesByMissions :taskGroup="taskGroup" @reload="this.$emit('reload')" :editable="!hasUsers"/>
        </div>
    </div>
</div>
</template>

<script>

    import BackEndService from "../../services/BackEndService.js"

    import DragImagesByMissions from "./DragImagesByMissions.vue"

    import statusElement from "../statusElement.vue"


    export default {
        components: {
            DragImagesByMissions,
            statusElement,
        },
        props: ['taskGroup'],
        emits: ['reload'],
        data() {
            return {
                percent: 20,
                piece: 10,
                radioButton: "bypercent",
                splitingStatuseCode: "",
                hasUsers: false,
            };
        },
        methods: {
            async splitImagesByTasks() {
                this.splitingStatuseCode = "0";
                let id = Number(this.taskGroup.id)
                var taskGroupDivisionViewModel = {
                    PercentStep: this.percent,
                    IntStep: this.piece,
                    Id: id,
                    PercentStepInput: this.radioButton,
                };
                var b = new BackEndService;
                var rep = await b.DivisionImagesByTasks(taskGroupDivisionViewModel)
                console.log("splitByTasks", rep);
                this.splitingStatuseCode = rep.statusCode;
                this.$emit('reload');
            },
           
        },
        mounted() {
            // console.log("******MOUNTED*******");
            // console.log("taskGroup", this.taskGroup);
            // console.log("datasetId", this.taskGroup.id);
            // console.log("tASKS", this.taskGroup.tasks);
            if(!this.taskGroup.tasks)
            {
                return;
            }
            const ids = this.taskGroup.tasks.map(t=>t.user.id);
            console.log("User ids", ids);
            const sum = ids.reduce((partialSum, a) => partialSum + a, 0);
            console.log("Sum", sum);
            if(sum > 0)
            {
                this.radioButton='byHand';
                this.hasUsers = true;
            }
        },
    }
</script>

<style>
    .borderStyle {
        border: 1px solid #ccc !important;
        padding: 1em;
        margin-bottom: 2em;
    }

    .task {
        border: 1px solid #ccc !important;
        margin: 1em;
        padding: 1em;
        display: flex;
        flex-direction: column;
    }

    .image-range-button {
        cursor: pointer;
        border-width: 0;
        background-color: transparent;
        color: #1b6ec2;
    }

        .image-range-button:hover {
            border-width: 0;
            background-color: transparent;
            color: #002350;
        }

    .imageContainer {
        border: 1px solid #ccc !important;
        margin: 0.5em;
        padding: 1em;
    }

    .imageList {
        column-width: 22%;
        column-count: 4;
        column-gap: 3%;
        background: #f6f6f6;
        /*  display: flex;
        flex-direction: row;
        flex-wrap: wrap;*/
    }


</style>