export default class UserInputManager {
	/*
	Hot keys
    
	Q : toggle markup
	W : toggle labels
	E : toggle comment in labels
	R : toggle clusterization
	T : toggle helper net
	I : open neural helper
	P : test ajax
	
	A : previuos image
	S : next image
	D : ___
	F : full screen
	G : previous object
	H : next object
	L : ruler
	< : point
	> : shadow

	C : Edit tool
	V : Box tool
	B : Poly tool
	N : Center tool
	M : Point tool
	
	Ctrl + Y: Redo
	Ctrl + Z : Undo
	
	
	*/

	constructor(eventAggregator) {
		this.stepMultiplier = 1.0;
		this.stepMultiplierStr = "";
		this.eventAggregator = eventAggregator;
		window.document.addEventListener("keydown", this.onKeyDown);
		window.document.addEventListener("keypress", this.onKeyPressed);
		this.toolKeys = {
			"KeyC":"edit",
			"KeyV":"box",
			"KeyB":"poly",
			"KeyN":"center",
			"KeyM":"obox",
			"KeyL":"ruler",
			"Comma":"point",
			"Period":"shadow"
		};
	}
	ignoreInput(e) {
		if (e.srcElement && e.srcElement.tagName && (e.srcElement.tagName === "TEXTAREA" || e.srcElement.tagName === "INPUT")) {
			return true;
		}
		return false;
	}
	toolKeysToFire(keyCode){
		return keyCode in this.toolKeys;
	}
	keysToFire(keyCode) {
		return keyCode === "KeyQ"
			|| keyCode === "KeyW"
			|| keyCode === "KeyE"
			|| keyCode === "KeyR"
			|| keyCode === "KeyG"
			|| keyCode === "KeyH"
			|| keyCode === "Escape"
			|| keyCode === "Enter"
			|| keyCode === "Delete"
			;
	}
	onKeyDown = (e) => {
		// if (lock === true) {
		// 	return;
		// }
		if (this.ignoreInput(e)) {
			return;
		}
		if (this.keysToFire(e.code)) {
			this.eventAggregator.fire("key", { key: e.code });
			if(e.code === "Escape"){
				this.eventAggregator.fire("toolchanged", "edit");
			}
		}
	}
	onKeyPressed = (e) => {
		// if (this.isLocked() === true) {
		// 	return;
		// }
		if (this.ignoreInput(e)) {
			return;
		}
		const isNumber = isFinite(e.key);
		if (isNumber) {
			this.setStep(e.key);
		}
		else {
			if (this.keysToFire(e.code)) {
				this.eventAggregator.fire("key", { key: e.code.substring(3), ctrl: e.ctrlKey, shift:e.shiftKey, alt: e.altKey });
			}
			else if (e.code === "KeyA") {
				this.eventAggregator.fire("changeimage", {step:-1})
			}
			else if (e.code === "KeyS") {
				this.eventAggregator.fire("changeimage", {step:1})
			}
			else if (e.code === "KeyF") {
				this.eventAggregator.fire("fullscreen", {});
			}
			else if (e.code === "KeyT") {
				this.eventAggregator.fire("togglenet", {});
			}
			else if (e.code === "KeyI") {
				this.eventAggregator.fire("toggleneuralassist", true);
				this.eventAggregator.fire("toolchanged", "edit");
			}
			else if(this.toolKeysToFire(e.code)){
				if(window.appstate.drawingsOn || window.appstate.neuralLayerOn){
					return;
				}
				if(!window.appstate.isEditable){
					return;
				}
				this.eventAggregator.fire("toolchanged", this.toolKeys[e.code]);
			} 
			else if (e.code === "KeyZ" && e.ctrlKey) {
				this.eventAggregator.fire("editactions", "undo");
			}
			else if (e.code === "KeyY" && e.ctrlKey) {
				this.eventAggregator.fire("editactions", "redo");
			}
			this.setStep(null);
		}
	}
	setStep(step){
		if(step){
			this.stepMultiplierStr += step;
			this.stepMultiplier = parseInt(this.stepMultiplierStr);
		}
		else{
			this.stepMultiplier = 1;
			this.stepMultiplierStr = "";
		}
		this.eventAggregator.fire("keystep", this.stepMultiplier);
	}
	
}