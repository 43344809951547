import ShapeParam from "./ShapeParam";
import PolygonHelper from "../../services/mathHelpers/PolygonHelper";
import ShapesMath from "../../services/mathHelpers/shapesMath";
import {geoDistance} from "../../services/mathHelpers/geometryHelper";
export default class PolyShapes extends ShapeParam {
    type = "poly";
    isFinished = false;

    constructor(mapManager, options) {
        super(mapManager, options);
        this.points = [];
        this.size = 5;
        this.shape = window.L.polygon(this.points, { shape: "poly", id: 0, color: this.color, opacity: options.srokeOpacity, fillOpacity: options.fillOpacity });
        this.checker = new PolygonHelper();
    }
    
    remove() {
        this.shape.removeFrom(this.map);
    }
    add() {
        this.shape.addTo(this.map);
    }
    changeColor(color) {
        super.changeColor(color, () => { });
    }
    
    getArea() {
        var geo = this.shape.getLatLngs();
        if (geo.length === 1) {
            geo = geo[0];
        }
        if (geo.length <= 2) {
            return 0.0;
        }
        let area = 0.0;
        let i1 = 1;
        for (let i = 0; i < geo.length; i++) {
            i1 = (i + 1) % geo.length;
            area += geo[i].lat * geo[i1].lng - geo[i1].lat * geo[i].lng;
        }
        area *= 0.5;
        return area;
    }
    getSimpleCenter() {
        var geo = this.shape.getLatLngs();
        if (geo.length === 1) {
            geo = geo[0];
        }
        let avgLat = 0.0;
        let avgLng = 0.0;
        let counter = 0;
        for (let v of geo) {
            avgLat += v.lat;
            avgLng += v.lng;
            counter++;
        }
        if (counter === 0) {
            return null;
        }
        return { lat: avgLat / counter, lng: avgLng / counter }
    }
    getCenter() {
        var geo = this.shape.getLatLngs();
        if (geo.length === 1) {
            geo = geo[0];
        }

        let xsum = 0.0;
        let ysum = 0.0;

        let i1 = 1;
        for (let i = 0; i < geo.length; i++) {
            i1 = (i + 1) % geo.length;
            xsum += (geo[i].lat + geo[i1].lat) * (geo[i].lat * geo[i1].lng - geo[i1].lat * geo[i].lng);
            ysum += (geo[i].lng + geo[i1].lng) * (geo[i].lat * geo[i1].lng - geo[i1].lat * geo[i].lng);
        }
        const area = this.getArea();
        if (area == 0) {
            return this.getSimpleCenter();
        }
        xsum /= 6 * area;
        ysum /= 6 * area;

        return { lat: xsum, lng: ysum }
    }
    move(latlng) {
        let lat = latlng.lat;
        let lon = latlng.lng;
        if (this.selectedCorner) {
            var geo = [...this.getGeoPoints()];
            if(geo.length > 2)
                {
                    if (this.selectedCorner.options.index == this.centerIndex) {
                        this.translate(this.getGeoPoints(this.shape), lat, lon);
                        return;
                    }
                    if (this.orientationIndex === this.selectedCorner.options.index) {
                        //this.moveDirectionCorner(false, e);
                        return;
                    }
                }
            let pointCount = geo.length;
            geo[this.selectedCorner.options.index] = { lat: lat, lng: lon };
            pointCount = geo.length;
            if(this.isFinished && !this.hasNoIntersections(geo)){
                window.appstate.messageManager.show("Нельзя создать многоугольник с самопересечениями!", "warning");
                return;
            }
            this.shape.setLatLngs(geo);
            this.selectedCorner.setLatLng({ lat: lat, lng: lon });
            if (pointCount > 2) {
                this.moveCenterCorner(false);
                // this.moveDirectionCorner(false);
            }
            // this.updateLabel();
        }
    }
    
    addPoint(point, ignoreIntersection){
        if(!this.hasNoIntersections(this.getGeoPoints()) && !ignoreIntersection){
            window.appstate.messageManager.show("Нельзя создать многоугольник с самопересечениями! Зажмите 'Ctrl' если всё равно хотите поставить", "warning");
            return false;
        }
        let r = this.getCircleR();
        let lat = point.lat;
        let lon = point.lng;
        
        let geo = this.getGeoPoints();
        const dist = geoDistance({ lat: lat, lng: lon }, geo[0]);

        if (dist < r) {
            console.log("Diatance", dist, "R:",r);
            geo.pop();
            this.shape.setLatLngs(geo);
            this.drawCorners();
            this.selectCorner(this.corners.length - 2);
            this.isFinished = true;
            return true;
        }
        else {
            geo.push({ lat: lat, lng: lon });
        }
        this.drawCorners();
        this.selectCorner(this.corners.length - 2);
        this.shape.setLatLngs(geo);
        return false;
    }
    finish(){
        if(!this.hasNoIntersections(this.getGeoPoints())){
            window.appstate.messageManager.show("Нельзя создать многоугольник с самопересечениями!", "warning");
            // console.warn("Нельзя создать многоугольник с самопересечениями!", "warning");
            return false;
        }
        let geo = this.getGeoPoints();
        if(geo.length <= 3){
            window.appstate.messageManager.show("Нельзя создать многоугольник менее чем с тремя точками!", "warning");
            return false;
        }
        geo.pop();
        this.shape.setLatLngs(geo);
        this.drawCorners();
        this.isFinished = true;
        return true;
    }
    hasNoIntersections(geoPoints){
        // const geoPoints = this.getGeoPoints();
        const points = geoPoints.map(p=>this.mapManager.toFlat(p)); 
        if(this.checker.isSimple(points))
        {
            return true;
        }
        return false;
    }
    processPoint = e => {
        console.log(e);
        let r = this.getCircleR();
        let lat = e.latlng.lat;
        let lng = e.latlng.lng;
        let foundIndex = -1;
        let intersection;
        let geo = this.getGeoPoints();
        var subGeo = geo;
        let foundPointindex = -1;
        for (let i = 0; i < subGeo.length; i++) {
            var g = subGeo[i];
            const dist = geoDistance({ lat: lat, lng: lng }, g);
            if (dist < r) {
                foundPointindex = i;
                break;
            }
        }
        if (foundPointindex >= 0) {
            subGeo.splice(foundPointindex, 1);
            this.shape.setLatLngs(geo);
            this.drawCorners();
            return;
        }

        for (let i = 0; i < subGeo.length; i++) {
            let lp1 = this.mapManager.toFlat(subGeo[i]);
            let lp2 = this.mapManager.toFlat(subGeo[(i + 1) % subGeo.length]);
            let p = this.mapManager.toFlat(e.latlng);
            let dist = ShapesMath.DistancePointLine(p, lp1, lp2);
            if (this.mapManager.toGeoDistance(dist.distance) < r) {
                foundIndex = (i + 1) % subGeo.length;
                intersection = dist.intersection;
            }
        }
        if (foundIndex >= 0) {
            subGeo.splice(foundIndex, 0, this.mapManager.toLatLng(intersection));
        }
        this.shape.setLatLngs(geo);
        this.drawCorners();

    }
}