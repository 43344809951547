<template>
    <div class="nav-bar">
        <router-link class="btn-l" tag="span" to="/">На главную</router-link>
        <router-link class="btn-l" tag="span" to="/tasks">Миссии</router-link>
        <router-link v-if="serverUserRole=='ServerAdmin'" class="btn-l" tag="span" to="/users">Пользователи</router-link>
        <router-link v-if="currentUser.username"
                     class="btn-r"
                     tag="span"
                     :to="'/userPage/' + currentUser.id">{{ currentUser.username }}</router-link>
        <span class="pipe" v-if="currentUser.username">&nbsp;</span>
        <span v-if="currentUser.username"
              @click="logOut"
              class="exit"
              style="padding-right: 0.7em">
            выход
        </span>
    </div>
</template>
<script>
    import BackEndService from "../services/BackEndService.js";
    export default {
        data() {
            return {};
        },
        emits: ["exit"],
        props: ["currentUser"],
        computed: {
            serverUserRole() {
                return window.appstate.serverUserRole;
            }
        },
        methods: {
            async logOut() {
                var b = new BackEndService();
                await b.logout();
                this.$emit("exit");
            },
        },
        mounted() { },
    };
</script>
<style scoped>
    .pipe {
        background-color: black;
        height: 100%;
        width: 2px;
        margin: 0 0.5rem;
    }

    .nav-bar {
        display: flex;
        flex-direction: row;
        font: inherit;
        line-height: 1.5;
        box-shadow: 0px 8px 22px -10px #ababab;
        flex-grow: 0;
        padding: 0.5rem 1rem;
    }

    .btn-l {
        color: black;
        margin-left: 1em;
        text-decoration: none;
    }

        .btn-l:hover {
            text-decoration: underline;
        }

    .exit {
        color: black;
        cursor: pointer;
        margin-right: 1rem;
        text-decoration: none;
    }

        .exit:hover {
            text-decoration: underline;
        }

    .btn-r {
        color: black;
        margin-left: auto;
        text-decoration: none;
    }

        .btn-r:hover {
            text-decoration: underline;
        }
</style>