<template>
  <div class="center-screen" style="text-align:center">
    <h1>Страница не найдена</h1>
    <h1 class="the-biggest">404</h1>
    <router-link to="/Home">На главную</router-link>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.the-biggest{
    font-size: 500%;
}
.center-screen {
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 2em;
}
</style>