<template>
    <h1 style="text-align: center">Страница пользователя</h1>
    <div style="text-align:center" v-if="isLoading">
        <h2>Загрузка</h2>
        <br />
        <IsLoadingItem />
    </div>
    <div v-if="userData" class="site-container">
        <h3>{{userData.user?.firstName}} {{userData.user?.lastName}} ({{userData.user?.username}})</h3>
        <h5>Доступ к API</h5>
        <div v-if="userData.apiKeys?.length > 0">
            <table>
                <thead>
                    <tr class="header-row">
                        <th>Проект</th>
                        <th>API название</th>
                        <th>Id</th>
                        <th>Ключ</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="apiKey in userData.apiKeys" :key="apiKey.projectId">
                        <td> {{apiKey.projectTitle}} </td>
                        <td> {{apiKey.projectName}} </td>
                        <td> {{apiKey.projectId}} </td>
                        <td> {{apiKey.key}} </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
            нет доступных Api ключей
        </div>

        <h5>Миссии пользователя</h5>
        <div>
            <div v-for="taskGroup in userData.missions" :key="taskGroup.id">
                <div class="flexbox mt-2">
                    <router-link class="ml-1" :to="'/group/'+taskGroup.id">{{taskGroup.name}}</router-link>
                    <div class="small-muted">фотоальбом</div>
                    <div>
                        <router-link class="ml-1" :to="'/photoAlbum/'+taskGroup.dataSetId">{{taskGroup.dataSet}}</router-link>
                    </div>
                    <div class="small-muted">проекта</div>
                    <div>
                        <router-link class="ml-1" :to="'/projSettings/'+taskGroup.projectId">{{taskGroup.project}}</router-link>
                    </div>
                </div>
                <div v-for="task in taskGroup.tasks" :key="task.id" class="dataset-task-item mt-2">
                    <div class="flexbox">
                        <a :href="'/Tasks/Continue/' + task.id">
                            <span class="small-muted">миссия</span>
                            <span class="ml-1">{{task.id}}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import IsLoadingItem from "./IsLoadingItem.vue";
import BackEndService from "../services/BackEndService.js";

export default {
    components : {
        IsLoadingItem
    },
    props: ['id'],
    data() {
        return {
            userData: null,
            isLoading: false,
        };
    },
    methods: {
        async getUserData() {
            this.isLoading = true;
            var b = new BackEndService();
            var req = await b.getUserDataJson(this.id);
            this.userData = req.data;
            this.isLoading = false;
        },
    },
    mounted() {
        this.getUserData();
    },
}
</script>
<style scoped>
table {
    border-spacing: 0px;
    border-collapse: collapse;
}
.table-header {
    max-width: 100%;
    background: rgba(0, 0, 0, 0.05);
}
tr {
    background: rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid #ddd;
    vertical-align: middle;
}
tr:nth-child(even) {
    background: white;
    border-bottom: 1px solid #ddd;
}
tr.header-row{
    background: white;
    border-top: 1px solid #ddd;
}
th, td {
    padding: 0.8rem 1.2rem;
    text-align: left;
}
.dataset-task-item {
    border: 2px solid rgba(200,200,200,0.6);
    border-width: 0;
    border-bottom-width: 2px;
    margin-left: 1em;
    background-color: rgba(200,200,200,0.2)
}
</style>