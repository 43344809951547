export default class ShapesMath {
    static delta = 5;
    static GetBounds(points) {
        let minX = Math.min(...points.map(o => o.x));
        let minY = Math.min(...points.map(o => o.y));
        let maxX = Math.max(...points.map(o => o.x));
        let maxY = Math.max(...points.map(o => o.y));
        return { x1: minX, y1: minY, x2: maxX, y2: maxY };
    }
    static GetBoundsIntersection(b1,b2){
        if(b1.x1 >= b2.x2 || b1.x2<=b2.x1 || b1.y1 >= b2.y2 || b1.y2 <= b2.y1){
            return {x1: 0, x2: 0, y1: 0, y2: 0};
        }
        let x1 = Math.max(b1.x1, b2.x1);
        let x2 = Math.min(b1.x2, b2.x2);
        let y1 = Math.max(b1.y1, b2.y1);
        let y2 = Math.min(b1.y2, b2.y2);
        return { x1: x1, y1: y1, x2: x2, y2: y2 };
    }
    static GetBoundsArea(bounds){
        return Math.abs((bounds.x2-bounds.x1)*(bounds.y2-bounds.y1));
    }
    static IsInPoly(polyPoints, point) {

        let bounds = this.GetBounds(polyPoints);
        if (point.x <= bounds.x2 + this.delta
            && point.x >= bounds.x1 - this.delta
            && point.y <= bounds.y2 + this.delta
            && point.y >= bounds.y1 - this.delta) {
            return true;
        }
        return false;
    }
    static IsInPoly2(poly, point) {

        let i, j;
        let c = false;
        for (i = 0, j = poly.length - 1; i < poly.length; j = i++) {
            if ((((poly[i].y <= point.y) && (point.y < poly[j].y))
                || ((poly[j].y <= point.y) && (point.y < poly[i].y)))
                && (point.x < (poly[j].x - poly[i].x) * (point.y - poly[i].y)
                    / (poly[j].y - poly[i].y) + poly[i].x)) {

                c = !c;
            }
        }
        return c;
    }
    static IsInBox(x, y, w, h, point) {
        if (point.x <= x + w + this.delta
            && point.x >= x - this.delta
            && point.y <= y + h + this.delta
            && point.y >= y - this.delta) {
            return true;
        }
        return false;
    }
    static BoxCenter(x, y, w, h) {
        return { x: x + w / 2, y: y + h / 2 };
    }
    static PolyCenter(points) {
        let bounds = this.GetBounds(points);
        return { x: (bounds.x1 + bounds.x2) / 2, y: (bounds.y1 + bounds.y2) / 2 };
    }
    static DistanceToPolyCenter(polyPoints, point) {
        let p1 = this.PolyCenter(polyPoints);
        let dist = this.Distance(p1, point);
        return dist;
    }
    static DistanceToBoxCenter(x, y, w, h, point) {
        let p1 = this.BoxCenter(x, y, w, h);
        let dist = this.Distance(p1, point);
        return dist;
    }
    static Distance(p1, p2) {
        return Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y));
    }
    static DistancePointLine(p, lp1, lp2) {
        let intersection;
        let line = this.GetAb(lp1, lp2);
        if (!isFinite(line.a) && !isFinite(line.b)) {
            intersection = { x: lp1.x, y: p.y };
        }
        else if (Math.abs(line.a) > 0.0001) {
            let perpend = this.GetPerpendLine(line.a, p);
            intersection = this.GetIntersectionPoint(line.a, line.b, perpend.a, perpend.b);

        }
        else {
            intersection = { x: p.x, y: lp1.y };

        }
        if (this.IsInside(intersection, lp1, lp2)) {
            let distance = this.Distance(intersection, p);
            return { distance: distance, intersection: intersection };
        }
        return { distance: Number.MAX_VALUE, intersection: intersection };
    }
    static GetAb(p1, p2) {
        if(p1.x == p2.x){
            return null;
        }
        let a = (p1.y - p2.y) / (1.0 * (p1.x - p2.x));
        let b = (p1.x * p2.y - p2.x * p1.y) / (1.0 * (p1.x - p2.x));
        return { a: a, b: b };
    }
    static GetPerpendLine(a1, p) {
        let a = -1 / a1;
        let b = p.y - a * p.x;
        return { a: a, b: b };
    }
    static GetIntersectionPoint(a1, b1, a2, b2) {
        if(a1 == a2){
            return null;
        }
        let x = (b2 - b1) / (a1 - a2);
        let y = (a1 * b2 - a2 * b1) / (a1 - a2);
        return { x: x, y: y };
    }
    static IsInside(p, p1, p2) {
        return Math.abs(p1.x - p.x) + Math.abs(p2.x - p.x) <= Math.abs(p1.x - p2.x) && Math.abs(p1.y - p.y) + Math.abs(p2.y - p.y) <= Math.abs(p1.y - p2.y);
    }

    static RotatePoint(point, pivotPoint, radAngle){
        var nx = Math.cos(radAngle) * (point.x - pivotPoint.x) - Math.sin(radAngle) * (point.y - pivotPoint.y) + pivotPoint.x;
        var ny = Math.sin(radAngle) * (point.x - pivotPoint.x) + Math.cos(radAngle) * (point.y - pivotPoint.y) + pivotPoint.y;
        return {x:nx, y:ny};
    }

    static RotatePointDegrees(point, pivotPoint, angleDegrees)
    {
        return this.RotatePoint(point, pivotPoint, Math.PI * angleDegrees / 180.0);
    }
    static GetRotatedPointOnSegment(p1, p2, len, degAngle)
    {
        const point = this.GetPointOnLine(p1,p2,len);
        return this.RotatePointDegrees(point, p1, degAngle);
    }
    static GetPointOnLine(p1, p2, len)
    {
        var c = Math.sqrt((p2.x - p1.x) * (p2.x - p1.x) + (p2.y - p1.y) * (p2.y - p1.y));
        var k = len / c;
        var x = p1.x + (p2.x - p1.x) * k;
        var y = p1.y + (p2.y - p1.y) * k;
        return { x: x, y: y };
    }
    static ToDegrees(rad)
    {
        return 180.0 * rad / Math.PI;
    }
    static toGeoArray(pp){
        const arr = [];
        for (const p of pp) {
            arr.push(this.toGeo(p));
        }
        return arr;
    }
    static getAngle(x, y){
        let phi = 0.0;
        if (x > 0)
        {
            phi = Math.atan(y / x);
        }
        else if (x < 0 && y >= 0)
        {
            phi = Math.atan(y / x) + Math.PI;
        }
        else if (x < 0 && y < 0)
        {
            phi = Math.atan(y / x) - Math.PI;
        }
        else if (x == 0 && y > 0)
        {
            phi = Math.PI / 2;
        }
        else if (x == 0 && y < 0)
        {
            phi = -Math.PI / 2;
        }
        phi += Math.PI;
        return phi;
    }
}