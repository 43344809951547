<template>
  <ul id="edit-buttons" class="tool-buttons" v-if="editorEnabled">
    <li v-for="button in buttons" :key="button.key">
      <div class="tooltip">
        <button
          class="tool-icon-button"
          v-bind:class="{ 'tool-active': button.active }"
          id="editToolButton"
          @click="selectTool(button)"
          :disabled="!button.enabled"
        >
          <div style="position: relative; width: 2.5rem;">
            <!-- <center-image/> -->
            <component
              :is="button.icon"
            />
            <sub class="hot-key-legend-item" style="position: absolute; bottom: 0; right:0;">{{button.key}}</sub>
          </div>
        </button>
        <span class="tooltiptext">{{button.name}}</span>
      </div>
    </li>
    <!-- <li v-for="button in buttons" :key="button.key">
      <button
        class="tool-button"
        v-bind:class="{ 'tool-active': button.active }"
        id="editToolButton"
        @click="selectTool(button)"
        :disabled="!button.enabled"
      >
        {{button.name}} <sub class="hot-key-legend-item">{{button.key}}</sub>
      </button>
    </li> -->
  </ul>
</template>
<script>
import CenterImage from "../images/CenterImage.vue";
import EditImage from "../images/EditImage.vue";
import PolyImage from "../images/PolyImage.vue";
import ShadowImage from "../images/ShadowImage.vue";
import PointImage from "../images/PointImage.vue";
import RulerImage from "../images/RulerImage.vue";
import OboxImage from "../images/OboxImage.vue";
import BoxImage from "../images/BoxImage.vue";
const toolButtons = {
  data() {
    return {
      buttons: [
        {
          name: "Редактирование",
          tool: "edit",
          key: "c",
          active: true,
          enabled : true,
          icon : "edit-image"
        },
        {
          name: "Прямоугольник",
          tool: "box",
          key: "v",
          active: false,
          enabled : true,
          icon : "box-image"
        },
        {
          name: "Многоугольник",
          tool: "poly",
          key: "b",
          active: false,
          enabled : true,
          icon : "poly-image"
        },
        {
          name: "Центр",
          tool: "center",
          key: "n",
          active: false,
          enabled : true,
          icon : "center-image"
        },
        {
          name: "Ориент. Прямоугольник",
          tool: "obox",
          key: "m",
          active: false,
          enabled : true,
          icon : "obox-image"
        },
        // {
        //   name: "Направление",
        //   tool: "orientation",
        //   key: "l",
        //   active: false,
        //   enabled : true,
        //   icon : "center-image"
        // },
        {
          name: "+/- Точка",
          tool: "point",
          key: "<",
          active: false,
          enabled : false,
          icon : "point-image"
        },
        {
          name: "Тень",
          tool: "shadow",
          key: ">",
          active: false,
          enabled : true,
          icon : "shadow-image"
        },
        {
          name: "Линейка",
          tool: "ruler",
          key: "L",
          active: false,
          enabled : true,
          icon : "ruler-image"
        },
      ]
    };
  },
  props: {
    editorEnabled : {
      type: Boolean,
      required: true
    }
  },
  emits: ["editor-is-enabled"],
  methods: {
    selectTool(button){
      for (const item of this.buttons) {
        item.active = item == button;
      }
      this.$services.eventAggregator.fire("toolchanged", button.tool);
      //window.selectTool(button.tool);
    },
    onToolSelected(tool){
      var activeButton = this.buttons.find(b=>b.active);
      if(activeButton && activeButton.tool !== tool){
        activeButton.active = false;
        const buttonToActivate = this.buttons.find(b=>b.tool === tool);
        if(buttonToActivate){
          this.selectTool(buttonToActivate);
        }
      }
    },
    onPointDisable(disable){
      const pointButton = this.buttons.find(b=>b.tool === "point");
      if(pointButton){
        pointButton.enabled = !disable;
      }
    },
    onCheckPointTool(data){
       if(!data.obj){
         this.onPointDisable(true);
         return;
       }
       this.onPointDisable(data.obj.type !== "poly");
    }
  },
  mounted() {
    this.$services.eventAggregator.on("toolchanged", this.onToolSelected);
    this.$services.mapManager.on("selected", this.onCheckPointTool);
  },
  components : {
    CenterImage,
    EditImage,
    PolyImage,
    ShadowImage,
    PointImage,
    RulerImage,
    OboxImage,
    BoxImage
  }    
};
export default toolButtons;
</script>
<style>
.tooltip {
  position: relative;
  
  
}

.tooltip .tooltiptext {
  visibility: hidden;
  /* width: 120px; */
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0.2rem 0.4rem;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1020;
  top: 110%;
  left: 50%;
  margin-left: -50%; /* Use half of the width (120/2 = 60), to center the tooltip */
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tool-icon-button {
  cursor: pointer;
  color: #fff;
  padding: 0.05em;
  border-radius: 0.2rem;
  display: inline-block;
  background-color: #6c757d;
  border: 1px solid transparent;
  margin-left: 0.5em;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}
.tool-icon-button:disabled {
  background-color: #ccc;
}
.tool-active {
  background-color: #28a745;
}
.tool-buttons {
  display: inline-flex;
  margin: 0;
  list-style: none;
}

</style>