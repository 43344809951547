<template>
  <div class="flexbox" @click="closeMenu" style="height:100%; align-items: stretch;">
    <div style="flex: 1 1 0; overflow-y: initial;">
        <category-tree-item v-if="tree" :category="tree" :treeServices="treeServices"/>
    </div>
    <div v-if="false"  class="editing" style="flex: 1 1 0">
        <category-editor ref="editor"/>
    </div>
  </div>
  <confirmation-modal :isShown="showModal" @close-win="onModalClose" :messasge="modalMessage" />
  <modal :isShown="showInfoModal" :messasge="modalMessage" @close-win="closeInfoModal"/>
</template>

<script>
import CategoryTreeItem from "./CategoryTreeItem.vue";
import CategoryEditor from "./CategoryEditor.vue";
export default {
    components: {
        CategoryTreeItem,
        CategoryEditor,
    },
    data(){
        return {
            tree: null,
            show: false,
            editing: null,
            categories: [],
            treeServices: {
                select: id => this.select(id),
                toggleNodes: (id, category) => this.toggleNodes(id, category),
                openMenu: (category,e) => this.openMenu(category,e),
                addItem: id => this.addItem(id),
                deleteItem: category => this.deleteItem(category),
                cutItem: category => this.cutItem(category),
                insertItem: category => this.insertItem(category),
                cancelCut: ()=>this.cancelCut()
            },
            menuCategory: null,
            showModal: false,
            showInfoModal: false,
            modalMessage: "",
            categoryToDelete: null,
            itemToBeCut: null,
        };
    },
    emits: ["on-load"],
    mounted(){
        this.$emit("on-load");
    },
    methods:{
        init(name, categories){
            this.categories = [];
            const dictionary = {};
            const root = {
                id: 0,
                name: name,
                items: [],
                isExpanded: true,
                menuIsOpened: false,
                insertMode: false,
            }
            for (const cat of categories) {
                if(cat.id === 0){
                    continue;
                }
                let item = dictionary[cat.id];
                if(!item){
                    item = {
                        id: cat.id,
                        name: cat.name,
                        color: cat.color,
                        fullname: cat.fullname,
                        supercategory: cat.supercateogry,
                        supercategoryId: cat.supercategoryId,
                        items:[],
                        isExpanded: false,
                        menuIsOpened: false,
                        isGreyedOut: false,
                        insertMode: false,
                        isReadOnly: !(cat.isReadOnly === false),
                    }
                    dictionary[cat.id] = item;
                    this.categories.push(item);
                }
            }
            for (const cat of categories) {
                let item = dictionary[cat.id];
                if(!item){
                    continue;
                }
                let parent = dictionary[cat.supercategoryId];

                if(!parent){
                    parent = root;
                }
                parent.items.push(item);
            }
            this.tree = root;
            this.categories.push(this.tree);
        },
        select(id){
            const item = this.categories.find(c=>c.id === id);
            if(item && this.$refs.editor){
                this.$refs.editor.set(item);
            }
        },
        toggleNodes(expand, category){
            this.toggleNodesRecursive(expand, category);
        },
        toggleNodesRecursive(expand, category){
            category.isExpanded = expand;
            if(category.items.length === 0){
                return;
            }
            for (const item of category.items) {
                this.toggleNodesRecursive(expand, item)
            }
        },
        openMenu(category,e){
            e.stopPropagation();
            this.closeMenu();
            category.menuIsOpened = true;
            this.menuCategory = category;
        },
        closeMenu(){
            if(this.menuCategory){
                this.menuCategory.menuIsOpened = false;
            }
        },
        addItem(category){
            const ids = this.categories.map(c=>c.id);
            const newxtId = Math.max(...ids)+1;
            if(category){
                let newItem = {
                    id: newxtId,
                    name: "новый класс "+newxtId,
                    color: category.color,
                    fullname: "",
                    supercategory: category.name,
                    supercategoryId: category.id,
                    items:[],
                    isExpanded: false,
                    menuIsOpened: false,
                    isGreyedOut: false,
                    isReadOnly: false,
                };
                category.items.push(newItem);
                this.categories.push(newItem);
                category.isExpanded = true;
            }
        },
        deleteItem(category){
            this.modalMessage = `Вы уверены, что хотите удалить класс '${category.name}'`
            this.showModal = true;
            this.categoryToDelete = category;
        },
        cutItem(category){
            this.itemToBeCut = category;
            this.itemToBeCut.isExpanded = false;
            this.itemToBeCut.isGreyedOut = true;
            this.toggleInsertMode(true);

        },
        cancelCut(){
            this.itemToBeCut.isGreyedOut = false;
            this.itemToBeCut = null;
            this.toggleInsertMode(false);
        },
        toggleInsertMode(newMode){
            for (const cat of this.categories) {
                cat.insertMode = newMode;
            }
        },
        insertItem(category){
            if(!this.itemToBeCut || !category){
                return;
            }
            let oldParent = this.categories.find(c=>c.id === this.itemToBeCut.supercategoryId);
            if(!oldParent){
                oldParent = this.tree;
            }
            oldParent.items = oldParent.items.filter(i=>i.id!== this.itemToBeCut.id);
            category.items.push(this.itemToBeCut);
            this.itemToBeCut.isGreyedOut = false;
            this.itemToBeCut = null;
            this.toggleInsertMode(false);
        },
        onModalClose(answer){
            if(answer === 'yes' && this.categoryToDelete){
                const parent = this.categories.find(c=>c.id === this.categoryToDelete.supercategoryId);
                if(parent){
                    parent.items = parent.items.filter(i=>i.id != this.categoryToDelete.id);
                }
            }
            this.categories = this.categories.filter(c=>c.id!= this.categoryToDelete.id);
            this.categoryToDelete = null;
            this.showModal = false;
        },
        applyGroupColor(color){
            for (const cat of this.categories) {
                cat.color = color;
            }
        },
        syncronize(group){
            console.log("SYNC GROUP", group);
            this.closeInfoModal();
            if(!group){
                return;
            }
            let mergeError = false;
            let mergeMessage = "Информация об обновлении, следующие классы не будут обновлены:\n";
            for (const category of group.markupCategories) {
                const existing = this.categories.find(c=>c.id === category.id);
                if(!existing){
                    this.categories.push({
                        name: category.name,
                        id: category.id,
                        supercategory: category.supercategory,
                        color: category.color,
                        objectCount: 0,
                        editable: true,
                        fullname: category.fullname,
                        supercategoryId: category.supercategoryId,
                        isReadOnly: false,
                    });
                }
                else{
                    if(this.shouldSyncSuperCategory){
                        existing.supercategory = category.supercategory;
                        existing.supercategoryId = category.supercategoryId;
                    }
                    if(this.shouldSyncFullname){
                        existing.fullname = category.fullname;
                    }
                    if(existing.name != category.name || existing.supercategory != category.supercategory){
                        mergeError = true;
                        mergeMessage += `Класс c id=${existing.id}\n\t- Текщий: ${existing.name} (${existing.supercategory});\n \t- Новый: ${category.name} (${category.supercategory})\n`
                    }
                    
                }
                console.log("Category",category);
                console.log("EXISTING Category",existing);
            }
            if(mergeError){
                this.modalMessage = mergeMessage;
                this.closeInfoModal();
            }
            const name = this.tree.name;
            const categoriesToUpdate = [...this.categories];
            this.init(name, categoriesToUpdate);
        },
        closeInfoModal(){
            this.showInfoModal = false;
        },
        openInfoModal(){
            this.showInfoModal = this.tree;
        },
        enlistCategories(){
            const list = [];
            for (const category of this.categories) {
                if(category.id == 0){
                    continue;
                }
                list.push({
                    name: category.name,
                    id: category.id,
                    supercategory: category.supercategory,
                    color: category.color,
                    fullname: category.fullname,
                    supercategoryId: category.supercategoryId,
                });
            }
            return list;
        }
    }
}
</script>

<style>
.editing{
    align-self: flex-start;
}
</style>