export default function highlight(sWord, searchStr) {
    const parts = [];
    let str = sWord;
    let index = -1;
    let i = 0;
    if (searchStr.length == 0) {
        parts.push({ text: str, isHighlighted: false });
        return parts;
    }
    while (
        (index = str.toLowerCase().indexOf(searchStr.toLowerCase())) > -1
    ) {
        const part1 = str.substr(0, index);
        const part2 = str.substr(index, searchStr.length);
        str = str.substr(index + searchStr.length);
        parts.push({ text: part1, isHighlighted: false });
        parts.push({ text: part2, isHighlighted: true });
        i++;
        if (i > 100) {
            return parts;
        }
    }
    parts.push({ text: str, isHighlighted: false });
    return parts;
}