import IdManager from "./IdManager.js"
export default class CategoryTree {

    name;
    id;
    fullname;
    color;
    supercategoryId;
    supercategory;
    isCutting;
    isExpanded;
    branch = [];
    idManager = {};
    constructor(markupGroup, markupCategory, parentIdManager) {

        if (markupGroup) {
            this.name = markupGroup.name;
            this.id = 0;
            this.idManager = new IdManager(this.id);
            this.maxId = 0;
        }
        else {
            this.name = markupCategory.name;
            this.idManager = parentIdManager;
            this.idManager.addId(markupCategory.id);
            this.id = markupCategory.id;
            this.fullname = markupCategory.fullname;
            this.supercategory = markupCategory.supercategory;
            this.supercategoryId = markupCategory.supercategoryId;
        }

        if (markupCategory?.color) {
            this.color = markupCategory.color;
        }
        else {
            this.color = "#ff0000";
        }

        this.branch = [];
        this.isExpanded = true;
        this.isVisible = true;
        this.isCutting = false;

    }

    create(markupCategories) {
        let childCategories = markupCategories.filter(C => C.supercategoryId == this.id);
        for (var childCategory of childCategories) {
            var child = new CategoryTree(null, childCategory, this.idManager)
            this.branch.push(child);
            child.create(markupCategories);
        }
        //console.log(this.name, this.id, childCategories.length);

    }
    toggleExpand() {
        this.isExpanded = !this.isExpanded;
    }
    addElementHere() {
        let childCategory = {
            name: "Новый элемент",
            id: this.idManager.getNewId(),
            fullname: "",
            supercategory: this.name,
            supercategoryId: this.id
        };
        console.log("addElementHere", childCategory);
        let child = new CategoryTree(null, childCategory, this.idManager);
        this.branch.push(child);
    }
    deleteElement(categoryItem) {
        this.branch = this.branch.filter(C => C != categoryItem);
    }
    editElement(editData) {
        this.name = editData.name;
        this.fullname = editData.fullname;
        this.id = editData.id;
        this.color = editData.color;
        console.log("editData", editData);
    }
    createCategoryList(tmpCategoriesList) {
        var categoriesList;
        if (!tmpCategoriesList) {
            categoriesList = [];
        }
        else {
            categoriesList = tmpCategoriesList;

            let category = {
                name: this.name,
                id: this.id,
                fullname: this.fullname,
                color: this.color,
                supercategoryId: this.supercategoryId,
                supercategory: this.supercategory,
                objectCount: 0,
            }
            categoriesList.push(category)
        }
        for (var childCategory of this.branch) {
            childCategory.createCategoryList(categoriesList)
        }
        if (!tmpCategoriesList) {
            //console.log("categoriesList from class", categoriesList);
            return categoriesList;
        }
    }
    readCategory() {

    }
    cutThisElement() {
        this.isCutting = true;
    }
    uncutThisElement() {
        this.isCutting = false;
    }
    insertElement(categoryItem) {
        categoryItem.supercategory = this.name;
        categoryItem.supercategoryId = this.id;
        this.branch.push(categoryItem);
    }
    deleteCutItem() {
        for (var categoryItem of this.branch) {
            if (categoryItem.isCutting == true) {
                this.branch = this.branch.filter(B => B.isCutting != true)
                categoryItem.uncutThisElement();
                return;
            }
            else {
                categoryItem.deleteCutItem();
            }
        }
    }
    setColorsForAll(unicolor) {
        console.log("unicolor in catJS", unicolor);
        if (this.color) {
            this.color = unicolor;
        }
        console.log("this.color", this.color);
        for (var categoryItem of this.branch) {
            console.log("categoryItem", categoryItem);
            categoryItem.setColorsForAll(unicolor);
        }
    }
}





