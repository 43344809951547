export default class IdManager {
    maxId;
    groupIdentifiers = [];
    constructor(id) {
        this.groupIdentifiers.push(id);
        this.updateMaxId();
    }
    updateMaxId() {
        this.maxId = Math.max(...this.groupIdentifiers);
    }
    addId(id) {
        this.groupIdentifiers.push(id);
        this.updateMaxId();
    }
    getNewId() {
        let newId = this.maxId + 1;
        this.groupIdentifiers.push(newId);
        this.updateMaxId();
        //console.log(this.groupIdentifiers)
        return newId;
    }
    //checkId проверка на повторение
}