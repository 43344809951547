import { createApp } from 'vue'
import App from './App.vue'
import MapManager from "./services/mapServices/MapManager";
import EventAggregator from './services/eventAggregator';
import IsLoadingItem from "./new_components/IsLoadingItem.vue";
import ConfirmationModal from "./components/ConfirmationModal.vue";
import Modal from "./components/Modal.vue";
import router from "./router"

//TODO: get rid of window.coordinateMultiplier
const eventAggregator = new EventAggregator();
const services = {
    mapManager: new MapManager(eventAggregator),
    eventAggregator: eventAggregator,
    state: {
        maxZoom: 0,
        datasetType: "",
        mapSystem: "simple",
        hasClusterization: true,
    },
    version: "3.0.beta"
}
window.L.ClickableTooltip = window.L.Tooltip.extend({
    onAdd: function (map) {
        window.L.Tooltip.prototype.onAdd.call(this, map);

        var el = this.getElement(),
            self = this;

        el.addEventListener('click', function(e) {
            e.preventDefault();
            self.fire("click");
        });
        el.style.pointerEvents = 'auto';
    }
});
const messageManager = {show : m => console.log(m), openPopup : text => console.log(text)};
window.appstate = {
    maxZoom : 16.0,
    circleRadius: 16,
    hasClusterization: true,
    showMarkup: true,
    showObjectInfo: true,
    showComments: true,
    drawingsOn: false,
    neuralLayerOn: false,
    messageManager: messageManager,
    favourites: [],
    serverUserRole:"",
};

const app = createApp(App);
app.use(router);
app.config.globalProperties.$messageManger = messageManager;
app.config.globalProperties.$services = services;
app.component('is-loading-item', IsLoadingItem);
app.component('confirmation-modal', ConfirmationModal);
app.component('modal', Modal);
app.mount('#app');
