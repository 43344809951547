<template>
    <div style="text-align:center" v-if="isLoading">
        <h2>Загрузка</h2>
        <br />
        <IsLoadingItem />
    </div>
    <div v-if="!isLoading">
        <div class="flex-grower">
            <router-link v-if="data.group" tag="span" :to="'/group/'+data.group.id" class="btn flex-grower">назад к группе миссий</router-link>
        </div>
        <div class="flex-header">
            <span class="flex-grower"></span>
            <h3 class="page-header">
                <span class="small-muted">Редактирование классов </span>
                <span>{{markupGroup.name}}</span>
            </h3>
            <span class="flex-grower"></span>
        </div>
        <category-tree ref="categoryTree" @on-load="treeLoaded"/>
        <TreeVue :markupGroup="markupGroup" v-if="false"
                 @update-categories="updatecategories"
                 :ref="'TreeVue'+markupGroup.id" />
        <div style="border-top: 1px solid lightgrey; margin: 1.2em;">
            <div class="flexbox">
                <span class="flex-grower" style="max-width:250px">Синхронизировать с группой классов из проекта: </span>
                <select class="flex-grower" v-model="selectedGroup">
                    <option v-for="group in projectGroups" v-bind:value="group" :key="group.id">{{group.name}}</option>
                </select>
                <button class="site-button main-button" type="button" @click="syncronize">Ок!</button>
            </div>
            <div class="flexbox">
                <label for="sync-supercategory-checkbox" style="margin: 0.4rem;">
                    <input id="sync-supercategory-checkbox" type="checkbox" v-model="shouldSyncSuperCategory">
                    Синхронизировать суперкатегории
                </label>
                <label for="sync-fullname-checkbox" style="margin: 0.4rem;">
                    <input id="sync-fullname-checkbox" type="checkbox" v-model="shouldSyncFullname">
                    Синхронизировать полное имя
                </label>
            </div>
            <div style="border-bottom: 1px solid lightgrey;">
                <p v-if="shouldSyncFullname || shouldSyncSuperCategory" style="color:orangered; font-size:90%; font-weight: bold;">
                    Внимание! При синхронизации полного имени и суперкатегорий могут возникнуть несоответствия!
                </p>
            </div>
            <div class="flexbox" style="border-bottom: 1px solid lightgrey">
                <span>Цвет для всех класссов группы</span>
                <input class="ml-1" type="color" v-model="unicolor" />
                <button class="site-button" type="button" @click="applyGroupColor">задать всем классам</button>
            </div>
            <div>
                <button type="button" class="site-button main-button" :disabled="saveSendInfo.loading" @click="save">Сохранить изменения в классах</button>
                <span class="ml-2 badge" v-bind:class="saveSendInfo.classList">{{saveSendInfo.status}}</span>
                <p v-if="hasError">{{errorMessage}}</p>
            </div>
        </div>         
    </div>

</template>
<script>
import BackEndService from "../services/BackEndService.js"
import TreeVue from "./TreeVue.vue"
import IsLoadingItem from "./IsLoadingItem.vue"
import SendInfo from "../helpers/SendInfo.js"
import CategoryTree from "./categories/CategoryTree.vue";
export default
{
    props: ['id', 'missionGroup'],
    components: {
        TreeVue,
        IsLoadingItem,
        CategoryTree
    },
    data() {
        return {
            tree: null,
            data: {},
            markupGroup: {markupCategories:[]},
            projectGroups: [],
            isLoading:false,
            selectedGroup: null,
            shouldSyncSuperCategory: false,
            shouldSyncFullname  : false,
            unicolor : "#ff0000",
            saveSendInfo: new SendInfo(),
            errorMessage: "",
            hasError: false
        };
    },
    methods: {
        async getCategories() {
            this.isLoading = true;
            var b = new BackEndService;
            var req = await b.editCategoriesVue(this.id)
            this.data = req.data;
            console.log("req", req);
            this.createMarkupGroup();
            this.createTree(this.data.group.name, this.data.markupCategories);
            this.isLoading = false;
        },
        updatecategories() {
        },
        createMarkupGroup() {
            this.markupGroup = {
                id: this.data.group.id,
                name: this.data.group.name,
                projectId: 0,
                unicolor: "#ff0000",
                markupCategories: this.data.markupCategories,
            }
            this.projectGroups = this.data.projectGroups;
            if(this.projectGroups && this.projectGroups.length > 0){
                this.selectedGroup = this.projectGroups[0];
            }
            console.log("MARKUP GROUP", this.markupGroup);
        },
        syncronize(){
            this.$refs.categoryTree.syncronize(this.selectedGroup);
        },
        applyGroupColor(){
            this.$refs.categoryTree.applyGroupColor(this.unicolor);
        },
        async save(){
            const categories = this.$refs.categoryTree.enlistCategories();
            const dataObj = {
                group: { id: this.markupGroup.id },
                markupCategories: categories
            };
            var b = new BackEndService;
            var req = await b.saveGroupCategories(dataObj)
            console.log("Save result", req);
        },
        createTree(name, categories){
            if(this.$refs.categoryTree){
                this.$refs.categoryTree.init(name, categories);
            }
        },
        treeLoaded(){
            if(this.$refs.categoryTree && this.data.group){
                this.$refs.categoryTree.init(this.data.group.name, this.data.markupCategories);
            }
        }
    },
    mounted() {
        this.getCategories()
    },
}
</script>
<style scoped>
.btn {
        padding: 0.25rem 0.5rem;
        font-size: .875rem;
        line-height: 1.5;
        background-color: #6c757d;
        border-color: #6c757d;
        border-radius: 0.2rem;
        color: white;
        text-decoration: none;
    }
    .btn:hover {
        text-decoration: underline;
    }
</style>
