import ShapeParam from "./ShapeParam";
export default class NoteShape extends ShapeParam {
    type = "note";
    value;
    textId;
    constructor(mapManager, options) {
        super(mapManager, options);
        this.points = [];
        this.size = 5;
        this.textId = "notetext_" + Date.now();
        this.value = "";
        this.shape = window.L.polygon(this.points, { shape: "box", id: 0, color: "white", opacity: options.srokeOpacity, fillOpacity: 0 });
        this.textShape = new window.L.ClickableTooltip({
            direction: 'center',
            permanent: true,
            noWrap: false,
            offset: [0, 2],
            className: "note-shape",
            labelText: this.value
        });
        let {w,h} = {w:100,h:100};
        let content = `<textarea id=${this.textId} style='width:${w}px;height:${h}px;padding:1em;'>${this.value}</textarea>`;
        this.textShape.setContent(content);
    }
    setCaretToLastPos() {
        const input = document.getElementById(this.textId);

        if(input!=null)
        {
        input.focus();
        input.selectionStart = input.value.length;
        }
    }
    getBoxSize(){
        const geo = this.getGeoPoints();
        let zoom = this.mapManager.getZoom();
        let scale = Math.pow(2, window.appstate.maxZoom - zoom);
        const x1 = this.mapManager.toFlat(geo[1]);
        const x2 = this.mapManager.toFlat(geo[2]);
        const x0 = this.mapManager.toFlat(geo[0]);
        let h = Math.abs(x1.y - x2.y);
        let w = Math.abs(x0.x - x1.x);
        h /= scale;
        w /= scale;
        if (h < 0) { h = 0; }
        if (w < 0) { w = 0; }
        return {w:w, h:h};
    }
    setTextLabel() {
        let {w,h} = this.getBoxSize();
        const textArea = document.getElementById(this.textId);
        if (textArea) {
            textArea.style.width = w+"px";
            textArea.style.height = h+"px";
        }
        const center = this.getCenter();
        this.textShape.setLatLng(center);
        this.setCaretToLastPos(textArea);
    }
    drawCorners() {
        this.undrawCorners();
        let points = this.getGeoPoints();
        let R = this.getCircleR();
        for (let i = 0; i < points.length; i++) {
            this.addCorner(points[i], R, "orange", i);
        }
        this.centerIndex = points.length;
        this.addCenterCorner(R, "white", this.centerIndex);
     
        this.setTextLabel();
        if(this.selectedCornerIndex >= 0){
            this.selectCorner(this.selectedCornerIndex);
        }
    }
    getNoteCenter() {
        const geo = this.getGeoPoints();
        const lat = Math.max(...geo.map(g => g.lat));
        let lng = (geo[0].lng + geo[1].lng) / 2.0;
        return { lat: lat, lng: lng };
    }
    addCenterCorner(r, color) {
        const topCenter = this.getNoteCenter();
        this.addCorner(topCenter, r, color, this.centerIndex);
    }
    moveCenterCorner(shouldAdd) {
        const topCenter = this.getNoteCenter();
        if (shouldAdd) {
            this.addCorner(this.centerIndex, topCenter.lat, topCenter.lng, this.getR(), "purple", "move-cursor");
        }
        else {
            this.corners[this.centerIndex].setLatLng(topCenter);
        }
    }

    draw(points) {
        let geo = this.toGeo(points);
        this.shape.setLatLngs(geo);
        this.setTextLabel();

    }
    remove() {
        this.shape.removeFrom(this.map);
        this.textShape.removeFrom(this.map);
        this.value= document.getElementById(this.textId).value;
    }

    add() {
        this.shape.addTo(this.map);
        this.textShape.addTo(this.map);
        document.getElementById(this.textId).value = this.value;
    }
    onZoom() {
        this.setTextLabel();
    }
    changeColor(color) {
        super.changeColor(color);
    }

    drawDirectionCorner(points) {
        super.drawDirectionCorner(points);
    }
    getCenter() {
        var geo = this.shape.getLatLngs();
        if (geo.length === 1) {
            geo = geo[0];
        }
        let avgLat = 0.0;
        let avgLng = 0.0;
        let counter = 0;
        for (let v of geo) {
            avgLat += v.lat;
            avgLng += v.lng;
            counter++;
        }
        if (counter === 0) {
            return null;
        }
        return { lat: avgLat / counter, lng: avgLng / counter }
    }
    move(latlng) {
        let lat = latlng.lat;
        let lon = latlng.lng;
        if (this.selectedCorner) {
            if (this.selectedCorner.options.index == this.centerIndex) {
                this.translate(this.getGeoPoints(this.shape), lat, lon);
                this.setTextLabel();
                return;
            }
            let p1 = { x: lon, y: lat };

            var geo = this.shape.getLatLngs();
            if (geo.length === 1) {
                var subGeo = geo[0];
                var ind = this.selectedCorner.options.index;
                var prevIndex = (ind - 1 + subGeo.length) % subGeo.length;
                var nexIndex = (ind + 1) % subGeo.length;

                const diameterIndex = (ind + 2) % subGeo.length;
                let dGeo = subGeo[diameterIndex];
                let p3 = { x: dGeo.lng, y: dGeo.lat };
                if (Math.abs(p1.x - p3.x) < this.minSize() || Math.abs(p1.y - p3.y) < this.minSize()) {
                    return;
                }

                subGeo[ind] = { lat: lat, lng: lon };
                if (ind % 2 == 0) {
                    subGeo[prevIndex] = { lat: subGeo[prevIndex].lat, lng: lon };
                    this.corners[prevIndex].setLatLng({ lat: subGeo[prevIndex].lat, lng: lon });

                    subGeo[nexIndex] = { lat: lat, lng: subGeo[nexIndex].lng };
                    this.corners[nexIndex].setLatLng({ lat: lat, lng: subGeo[nexIndex].lng });
                }
                else {
                    subGeo[prevIndex] = { lat: lat, lng: subGeo[prevIndex].lng };
                    this.corners[prevIndex].setLatLng({ lat: lat, lng: subGeo[prevIndex].lng });

                    subGeo[nexIndex] = { lat: subGeo[nexIndex].lat, lng: lon };
                    this.corners[nexIndex].setLatLng({ lat: subGeo[nexIndex].lat, lng: lon });
                }


            }
            this.shape.setLatLngs(subGeo);
            this.selectedCorner.setLatLng({ lat: lat, lng: lon });
            this.moveCenterCorner(false);
            this.setTextLabel();
        }
    }
}