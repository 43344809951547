<template>
  <div class="dataset-mission-box">
    <div class="flexbox">
      <span class="small-muted">миссия</span>
      <span class="ml-2">{{task.id}}</span>
      <span class="small-muted">{{cancelled ? 'исполнитель': 'выполняет'}}</span>
      <span class="ml-2">{{task.user.name}}</span>
    </div>
    <div class="flexbox">
      <a :href="'/Markup/Download/'+task.downloadLinkParams" target="_blank">
        скачать разметку
      </a>
      <a :href="'/Markup/DownloadObjImg/'+task.downloadLinkParams" target="_blank">
        скачать Obj.csv
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["task", "cancelled"],
};
</script>

<style scoped>
.dataset-mission-box{
    margin-left: 2rem;
    background-color: #eee;
    border-bottom: 1px solid gray;
}
.dataset-mission-box .flexbox{
    align-items: baseline;
}
span.small-muted + span {
    margin-left: 0.4rem;
}
span + span.small-muted {
    margin-left: 0.4rem;
}
.flexbox a {
    margin: 0.4rem 0.3rem;
}
</style>