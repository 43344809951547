<template>
    <span v-if="statusCode!=''"
         class="container"
         :class="{ 'ok-status':statusCode == '200' ,'error-status':statusCode != '200' & statusCode!='0'}">
        <div v-if="statusCode=='0'" class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        <span v-if="statusCode=='200'">Ok!</span>
        <span v-if="statusCode!='200' & statusCode!='0'">Error!</span>
    </span>
</template>
<script>

    export default
        {
            props: ['statusCode'],

            data() {
                return {

                };
            },
            methods: {},
            mounted() {
            },
        }</script>
<style scoped>
    .container {
        border: 1px solid black;
        border-radius: 0.3em;
        display:inline-block;
        width: 2.5em !important;
        height: 1.5em !important;
        text-align: center;
    }

    .ok-status {
        background-color: forestgreen;
        color: white;
        border-color: black;
    }

    .error-status {
        background-color: rgb(220, 53, 69);
        color: black;
        border-color: black;
    }


    .lds-spinner {
        color: official;
        display: inline-block;
        position: relative;
        /*width: 40px;*/
        width: 0.8em;
        height: 0.8em;
    }

        .lds-spinner div {
            transform-origin: 0.5em 0.5em;
            animation: lds-spinner 1.2s linear infinite;
        }

            .lds-spinner div:after {
                content: "";
                display: block;
                position: absolute;
                top: 0px;
                left: 0.7em;
                width: 0.15em;
                height: 0.3em;
                border-radius: 20%;
                background: grey;
            }

            .lds-spinner div:nth-child(1) {
                transform: rotate(0deg);
                animation-delay: -1.1s;
            }

            .lds-spinner div:nth-child(2) {
                transform: rotate(30deg);
                animation-delay: -1s;
            }

            .lds-spinner div:nth-child(3) {
                transform: rotate(60deg);
                animation-delay: -0.9s;
            }

            .lds-spinner div:nth-child(4) {
                transform: rotate(90deg);
                animation-delay: -0.8s;
            }

            .lds-spinner div:nth-child(5) {
                transform: rotate(120deg);
                animation-delay: -0.7s;
            }

            .lds-spinner div:nth-child(6) {
                transform: rotate(150deg);
                animation-delay: -0.6s;
            }

            .lds-spinner div:nth-child(7) {
                transform: rotate(180deg);
                animation-delay: -0.5s;
            }

            .lds-spinner div:nth-child(8) {
                transform: rotate(210deg);
                animation-delay: -0.4s;
            }

            .lds-spinner div:nth-child(9) {
                transform: rotate(240deg);
                animation-delay: -0.3s;
            }

            .lds-spinner div:nth-child(10) {
                transform: rotate(270deg);
                animation-delay: -0.2s;
            }

            .lds-spinner div:nth-child(11) {
                transform: rotate(300deg);
                animation-delay: -0.1s;
            }

            .lds-spinner div:nth-child(12) {
                transform: rotate(330deg);
                animation-delay: 0s;
            }

    @keyframes lds-spinner {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }
</style>
