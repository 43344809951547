import ShapeParam from "./ShapeParam";
import ShapesMath from "../../services/mathHelpers/shapesMath";
export default class BrushShape extends ShapeParam {
    type = "brush";
    constructor(mapManager, options) {
        super(mapManager, options);
        this.points = [];
        this.size = 5;
        if(options.drawing!=null){
            this.selectedShape = window.L.polyline([0, 0], { color: "white", opacity: 1, weight: options.drawing.weight*1.5, fillOpacity: 0.3 });
            this.shape = window.L.polyline([0, 0], { color: options.drawing.color, opacity: 1, weight: options.drawing.weight, fillOpacity: 0.3 });
        }
        else
        {
            console.log("NO DRAWING IN BRUSH!");
        }
    }
    draw(points) {
        let geo = this.toGeo(points);
        this.shape.setLatLngs(geo);
        this.drawCorners();
    }
    add() {
        this.shape.addTo(this.map);
    }
    remove() {
        this.shape.removeFrom(this.map);
        this.selectedShape.removeFrom(this.map);

    }

    drawCorners() {
        this.shape.removeFrom(this.map);
        let geo = this.toGeo(this.points);
        this.selectedShape.setLatLngs(geo);
        this.selectedShape.addTo(this.map);
        this.shape.addTo(this.map);
    }

    move(latlng) {
        let point = this.mapManager.toFlat(latlng);
        if (ShapesMath.Distance(this.points[this.points.length - 1], point) > 8) {
            this.points.push(point);
        }
        this.draw(this.points);
        this.remove();
        this.add();
    }
}