<template>
  <div>
    <div class="flexbox">
      <input
        class="tag-input"
        type="text"
        v-model="newTag"
        v-on:keyup.enter="addTag"
      />
      <button class="site-button" @click="addTag">Добавить</button>
    </div>

    <div>
      <span class="tag p-tag" v-for="tag in positiveTags" :key="tag">
        {{ tag }}
        <button class="tag-delete-button grow-button" @click="deleteTag(tag)">
          ⨉
        </button>
      </span>
    </div>
    <div class="mt-2">
      <span class="tag n-tag" v-for="tag in negativeTags" :key="tag">
        {{ tag }}
        <button class="tag-delete-button grow-button" @click="deleteTag(tag)">
          ⨉
        </button>
      </span>
    </div>

    <div class="mt-2">
      <button
      v-if="!isLoading"
      class="site-button"
      style="background-color: #17a2b8"
      @click="saveTags"
    >
      Сохранить тэги
    </button>
    <is-loading-item v-else />

    </div>
  </div>
</template>

<script>
import IsLoadingItem from "../IsLoadingItem.vue";
export default {
  components: {
    IsLoadingItem,
  },
  data() {
    return {
      newTag: "",
      positiveTags: [],
      negativeTags: [],
      tags: [],
      isLoading: false,
    };
  },
  emits: ["save-tags", "on-ready"],
  mounted() {
    this.$emit("on-ready");
  },
  methods: {
    addTag() {
      if (!this.newTag || this.newTag.length === 0) {
        return;
      }
      this.tags.push(this.newTag);
      this.filterTags();
      this.newTag = "";
    },
    deleteTag(tag) {
      this.tags = this.tags.filter((T) => T != tag);
      this.filterTags();
    },
    saveTags() {
      const list = [];
      for (var pTag of this.positiveTags) {
        list.push(pTag);
      }
      for (var nTag of this.negativeTags) {
        list.push(nTag);
      }
      this.isLoading = true;
      this.$emit("save-tags", list);
    },
    updateTags(list) {
      this.isLoading = false;
      this.tags = list;
      this.filterTags();
    },
    filterTags() {
      this.positiveTags = this.tags.filter(
        (t) => !t.startsWith("не ") && !t.startsWith("not ")
      );
      this.negativeTags = this.tags.filter(
        (t) => t.startsWith("не ") || t.startsWith("not ")
      );
    },
  },
};
</script>

<style scoped>
.tag-input {
  flex-grow: 1;
}
.tag-delete-button {
  border-width: 0;
  background-color: transparent;
  color: inherit;
}
.tag {
  border-radius: 10rem;
  text-align: center;
  font-weight: bold;
  display: inline-block;
  padding: 0 0.4em;
}
.tag + .tag {
  margin-left: 0.4rem;
}
.p-tag {
  color: #fff;
  background-color: #17a2b8;
}

.n-tag {
  color: #212529;
  background-color: #ffc107;
}
</style>