<template>
    <h4 v-if="!tasks">
        В группе пока нет миссий.
    </h4>
    <div v-if="tasks">
        <h2>
            Миссии
        </h2>
        <p v-if="!tasks || tasks.length === 0">
            Пока что миссии не созданы. Перейдите на вкладку "картинки" для создания миссий
        </p>
        <div v-for="task in tasks" :key="task.id" class="task">
            <div class="flexbox">
                <input type="checkbox" />
                <span style="font-weight:bold">Миссия {{task.id}}</span>
                <span>
                    <span class="small-muted ml-1">
                        пользователь
                    </span>
                    <select v-model="currentUsers[task.id]" @change="changeUser(task)">
                        <option v-for="user in taskUsers[task.id]" :key="user.id" :value="user">{{user.fullName}}</option>
                        <option selected
                                v-if="currentUsers[task.id]"
                                :value="currentUsers[task.id]">
                            {{currentUsers[task.id].fullName}}
                        </option>
                    </select>
                    <statusElement :statusCode="changeUserStatusCode[task.id]" />
                </span>
            </div>
            <span>
                прогресс
                <progress style="vertical-align: middle;"
                          :value="task.progress" max="100">
                </progress> {{task.progressText}}
            </span>
            <div>
                <button class="site-button first-left main-button" @click="createLink(task.id)">Перейти к выполнению</button><br />
            </div>
            <div>
                <button class="image-range-button" @click="getImageList(task)">
                    Диапазон изображений {{this.isShowImgList[task.id] ? '▲' : '▼'}}
                </button>
            </div>
            <div style="text-align: center" v-if="isLoadingImgListArray[task.id]">
                <IsLoadingItem />
            </div>
            <div v-if="isShowImgList[task.id] & !isLoadingImgListArray[task.id]" class="imageContainer">
                <span style="font-weight: bold; font-size:1.5em;">Список картинок</span>
                <div class="imageList">
                    <p v-for="image in imagelist[task.id]" :key="image" class="imageListItem">
                        {{image}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import statusElement from "../statusElement.vue"

    import IsLoadingItem from "../IsLoadingItem.vue"

    import BackEndService from "../../services/BackEndService.js"

    export default {
        components: {
            statusElement,
            IsLoadingItem,
        },
        props: ['tasks'],
        emits: ['update-user'],
        data() {
            return {
                currentUsers: [],
                users: [],
                taskUsers: [],
                changeUserStatusCode: [],
                isShowImgList: [],
                isLoadingImgListArray: [],
                imagelist: [],
            };
        },
        methods: {
            async startUp() {
                console.log("tasks", this.tasks);

                await this.getUsers();
                await this.getCurrentUsers();

            },
            getCurrentUsers() {
                for (var task of this.tasks) {
                    if (task.user.id > 0) {
                        let currentUser = this.users.find(u => u.id == task.user.id);

                        this.currentUsers[task.id] = currentUser;
                    }
                    let otherUsers = this.users.filter(u => u.id != task.user.id);
                    this.taskUsers[task.id] = otherUsers;
                    this.changeUserStatusCode[task.id] = "";
                }
            },
            async getUsers() {
                var b = new BackEndService;
                let req = await b.getUsersJson();

                this.users = req.data;
                /*await this.getCurrentUsers();*/
            },
            createLink(id) {
                let link = "/Tasks/Continue/" + id;
                location.href = link;
            },
            async getImageList(task) {
                if (!this.isShowImgList[task.id]) {
                    this.isLoadingImgListArray[task.id] = true;

                    var b = new BackEndService;

                    let req = await b.imageList({ TaskId: task.id });

                    this.imagelist[task.id] = req.data.result;
                    console.log("imagelist", this.imagelist);
                    this.isShowImgList[task.id] = true;
                    this.isLoadingImgListArray[task.id] = false;

                }
                else {
                    this.isShowImgList[task.id] = false;
                }
            },
            async changeUser(task) {
                this.changeUserStatusCode[task.id] = "0";
                let newUserChanges = {
                    GroupId: task.groupId.toString(),
                    Id: task.id,
                    UserId: this.currentUsers[task.id].id,
                };
                console.log("newUserChanges", newUserChanges);
                var b = new BackEndService;
                let req = await b.taskUser(newUserChanges);
                console.log("req", req);
                this.changeUserStatusCode[task.id] = req.statusCode;
                this.$emit("update-user", newUserChanges);
            },
        },
        mounted() {
            this.startUp();
        },
    }
</script>

<style>
</style>