<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="24px"
    heigth="24px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M503.467,72.533h-51.2c-4.71,0-8.533,3.823-8.533,8.533v17.067h-409.6c-4.71,0-8.533,3.823-8.533,8.533V371.2H8.533
			c-4.71,0-8.533,3.823-8.533,8.533v51.2c0,4.71,3.823,8.533,8.533,8.533h51.2c4.71,0,8.533-3.823,8.533-8.533v-17.067h409.6
			c4.71,0,8.533-3.823,8.533-8.533V396.8v-256h17.067c4.71,0,8.533-3.823,8.533-8.533v-51.2
			C512,76.356,508.177,72.533,503.467,72.533z M469.333,396.8H68.267v-17.067c0-4.71-3.823-8.533-8.533-8.533H42.667v-256h401.067
			v17.067c0,4.71,3.823,8.533,8.533,8.533h17.067V396.8z"
        />
      </g>
    </g>
  </svg>
</template>