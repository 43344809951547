export default class ShapeParam {
    corners = [];
    selectedCorner;
    selectedCornerIndex = -1;
    centerIndex;
    type;

    constructor(mapManager, options) {
        this.mapManager = mapManager;
        this.map = this.mapManager.map;
        this.color = options.color;
    }
    
    changeColor(color) {
        this.shape.removeFrom(this.map);
        this.shape.options.color = color;
        this.shape.addTo(this.map);
    }
    changeOpacity(fillOpacity, strokeOpacity){
        this.shape.options.opacity = strokeOpacity;
        this.shape.options.fillOpacity = fillOpacity;
    }
    getGeoPoints() {
        var geo = this.shape.getLatLngs();
        let points = geo;
        if (geo.length === 1) {
            points = geo[0];
        }
        return points;
    }
    getSavePoints(){
        return this.getGeoPoints();
    }
    getR() {
        let zoom = this.mapManager.getZoom();
        let scale = Math.pow(2, 4 - zoom);
        let size = 0.125*Math.pow(2, 4);
        let r = size * scale;
        let len = this.getGeoLength();
        if(len > 0 && len < 4*r){
            return len /4;
        }
        return r;
    }
    getCircleR(){
        return this.getR();
    }
    circleArea(r) {
        return Math.PI * Math.pow(r, 2);
    }
    getMinMaxLatLng(){
        const points = this.getGeoPoints()
        
        if(points.length <= 2){
            return [0,0,0,0];
        }
        let maxly = points[0].lat;
        let minly = points[0].lat;
        let maxlx = points[0].lng;
        let minlx = points[0].lng;
        for (let i = 1; i < points.length; i++) {
            maxly = Math.max(maxly, points[i].lat);
            minly = Math.min(minly, points[i].lat);
            maxlx = Math.max(maxlx, points[i].lng);
            minlx = Math.min(minlx, points[i].lng);
        }
        return [minlx, minly, maxlx, maxly];
    }
    /*This is area of bounds */
    getArea() {
        const [minlx, minly,maxlx,maxly] = this.getMinMaxLatLng();
        let area = Math.abs(Math.abs(maxlx) - Math.abs(minlx)) * Math.abs(Math.abs(maxly) - Math.abs(minly));
        return area;
    }
    getGeoLength(){
        if(!this.shape){
            return 0;
        }
        const [minlx, minly,maxlx,maxly] = this.getMinMaxLatLng();
        let dist = Math.min(maxlx-minlx, maxly-minly);
        return dist;
    }
    draw(points) {
        let geo = this.toGeo(points);
        this.shape.setLatLngs(geo);
    }
    drawCorners() {
        this.undrawCorners();
        let points = this.getGeoPoints();
        let R = this.getCircleR();
        for (let i = 0; i < points.length; i++) {
            this.addCorner(points[i], R, "orange", i);
        }
        this.centerIndex = points.length;
        this.addCenterCorner(R, "white", this.centerIndex);
        if(this.selectedCornerIndex >= 0){
            this.selectCorner(this.selectedCornerIndex);
        }
    }
    addCorner(point, r, color, i) {
        const circle = window.L.circle([point.lat, point.lng], { radius: r, color: color, index: i, pane: "corners" });
        circle.addTo(this.map);
        circle.on("mousedown", (e) => this.circleClick(e));
        this.corners.push(circle);
    }
    resizeCoreners(){
        for (const corner of this.corners) {
            const cR = this.getCircleR();
            if(corner.getRadius() == cR){
                return;
            }
            corner.setRadius(cR);
        }
    }
    addCenterCorner(r, color) {
        this.addCorner(this.getCenter(), r, color, this.centerIndex);
    }
    undrawCorners() {
        for (const corner of this.corners) {
            corner.removeFrom(this.map);
            corner.off("mousedown", this.scircleClick);
        }
        this.corners = [];
        this.selectedCorner = null;
    }
    circleClick(e) {
        this.selectedCorner = e.sourceTarget;
        e.originalEvent.preventDefault();
    }
    translate(points, lat, lon) {
        if (this.centerIndex < 0) {
            return;
        }
        const cp = this.corners[this.centerIndex].getLatLng();
        const dLat = lat - cp.lat;
        const dLon = lon - cp.lng;

        for (let i = 0; i < points.length; i++) {
            const p = this.movePointOnDelta(points[i], dLat, dLon);
            points[i] = p;
            this.corners[i].setLatLng(p);
        }
        this.moveCenterCorner(false);
        this.shape.setLatLngs(points);
        if (this.orientationIndex >= 0 && this.orientation) {
            const dirLN = this.corners[this.orientationIndex].getLatLng();
            const dirP = this.movePointOnDelta(dirLN, dLat, dLon);
            this.moveDirectionCorner(false, { latlng: { lat: dirP.lat, lng: dirP.lng } });
        }
    }
    selectCorner(i) { 
        this.selectedCorner = this.corners[i];
        this.selectedCornerIndex = this.selectedCorner.options.index;
    }
    minSize = () => {
        return 0.2 * this.getR();
    }
    movePointOnDelta(point, dLat, dLon) {
        const tLat = point.lat + dLat;
        const tLon = point.lng + dLon;
        return { lat: tLat, lng: tLon };
    }
    moveCenterCorner(shouldAdd) {
        const center = this.getCenter();
        const lat3 = center.lat;
        const lon3 = center.lng;
        if (shouldAdd) {
            this.addCorner(this.centerIndex, lat3, lon3, this.getR(), "purple", "move-cursor");
        }
        else {
            this.corners[this.centerIndex].setLatLng({ lat: lat3, lng: lon3 });

        }
        // this.orientation = this.getDirectionShape();
        if (this.orientation) {
            this.orientation.moveTail(lat3, lon3);
        }

    }
    
    toGeo(points) {
        let geoPoints = [];
        if (points.length === 0) {
            geoPoints = this.mapManager.toLatLng(points[0]);
        }
        else {
            for (let i = 0; i < points.length; i++) {
                let geoPoint = this.mapManager.toLatLng(points[i]);
                geoPoints.push(geoPoint);
            }
        }
        return geoPoints;
    }
    isVisible(){
        return this.map.hasLayer(this.shape);
    }
    getBounds() {
        return this.shape.getBounds();
    }
    onZoom(){

    }
}