import ShapeObjectBase from "./ShapeObjectBase";
import NeuralBoxShape from "../shapes/NeuralBoxShape";
export default class NeuralBoxObject extends ShapeObjectBase {
    shape;
    type = "neural";
    constructor(mapManager) {
        super(mapManager);
    }
    updateLabel() {
    }
    
    createNewShape(options, point){
        const p1 = {x:point.x, y:point.y};
        const p2 = {x:point.x+5, y:point.y};
        const p3 = {x:point.x+5, y:point.y+5};
        const p4 = {x:point.x, y:point.y+5};
        let newPoints = [p1,p2,p3,p4];
        this.shape = new NeuralBoxShape(this.mapManager, options);
        this.shape.draw(newPoints);
        this.select();
        this.shape.selectCorner(2);
    }
}