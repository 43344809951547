import ShapeParam from "./ShapeParam";
export default class BoxShape extends ShapeParam {
    type = "box";

    constructor(mapManager, options) {
        super(mapManager, options);
        this.points = [];
        this.size = 5;
        this.shape = window.L.polygon(this.points, { shape: "box", id: 0, color: this.color, opacity: options.srokeOpacity, fillOpacity: options.fillOpacity });
    }

    remove() {
        this.shape.removeFrom(this.map);
    }

    add() {
        this.shape.addTo(this.map);
    }

    changeColor(color) {
        super.changeColor(color);
    }

    drawDirectionCorner(points) {
        super.drawDirectionCorner(points);
    }
    getCenter() {
        var geo = this.shape.getLatLngs();
        if (geo.length === 1) {
            geo = geo[0];
        }
        let avgLat = 0.0;
        let avgLng = 0.0;
        let counter = 0;
        for (let v of geo) {
            avgLat += v.lat;
            avgLng += v.lng;
            counter++;
        }
        if (counter === 0) {
            return null;
        }
        return { lat: avgLat / counter, lng: avgLng / counter }
    }
    move(latlng) {
        let lat = latlng.lat;
        let lon = latlng.lng;
        if (this.selectedCorner) {
            if (this.selectedCorner.options.index == this.centerIndex) {
                this.translate(this.getGeoPoints(this.shape), lat, lon);
                return;
            }
            // if (this.orientationIndex === this.selectedCorner.options.index) {
            //     this.moveDirectionCorner(false, e);
            //     return;
            // }
            //let cornerGeo = this.selectedCorner.getLatLng();
            let p1 = { x: lon, y: lat };
            //let p2 = { x: cornerGeo.lng, y: cornerGeo.lat };

            var geo = this.shape.getLatLngs();
            if (geo.length === 1) {
                var subGeo = geo[0];
                var ind = this.selectedCorner.options.index;
                var prevIndex = (ind - 1 + subGeo.length) % subGeo.length;
                var nexIndex = (ind + 1) % subGeo.length;

                const diameterIndex = (ind + 2) % subGeo.length;
                let dGeo = subGeo[diameterIndex];
                let p3 = { x: dGeo.lng, y: dGeo.lat };
                if (Math.abs(p1.x - p3.x) < this.minSize() || Math.abs(p1.y - p3.y) < this.minSize()) {
                    return;
                }

                subGeo[ind] = { lat: lat, lng: lon };
                if (ind % 2 == 0) {
                    subGeo[prevIndex] = { lat: subGeo[prevIndex].lat, lng: lon };
                    this.corners[prevIndex].setLatLng({ lat: subGeo[prevIndex].lat, lng: lon });

                    subGeo[nexIndex] = { lat: lat, lng: subGeo[nexIndex].lng };
                    this.corners[nexIndex].setLatLng({ lat: lat, lng: subGeo[nexIndex].lng });
                }
                else {
                    subGeo[prevIndex] = { lat: lat, lng: subGeo[prevIndex].lng };
                    this.corners[prevIndex].setLatLng({ lat: lat, lng: subGeo[prevIndex].lng });

                    subGeo[nexIndex] = { lat: subGeo[nexIndex].lat, lng: lon };
                    this.corners[nexIndex].setLatLng({ lat: subGeo[nexIndex].lat, lng: lon });
                }


            }
            this.shape.setLatLngs(subGeo);
            this.selectedCorner.setLatLng({ lat: lat, lng: lon });
            this.moveCenterCorner(false);
            this.resizeCoreners();
        }
    }
}