<template>
    <!--v-if="taskGroup.tasks.length > 0"-->
    <div :class="{'blur-background':!result}">
        <div>
            <h2>
                Объединение слоев в итоговый слой
            </h2>
            <p v-if="!tasks || tasks.length === 0">
                Пока что миссии не созданы. Перейдите на вкладку "картинки" для создания миссий
            </p>
            <div v-else>
                <div class="mt-2">Название слоя</div>
                <input class="mt-2" style="width:100%" type="text" :value="finalLayerName" />
                <p class="mt-2">Алгоритм: Объединить разметку</p>
                <button class="site-button first-left main-button" @click="createFinalLayer">Создать черновик</button>
            </div>
        </div>

        <div v-for="task in tasks" :key="task.id" class="task">
            <div class="flexbox">
                <input type="checkbox" v-model="task.isChecked" />
                <span style="font-weight:bold">Миссия {{task.id}}</span>
            </div>
        </div>
    </div>
    <div v-if="!result" class="message" style="display:block">
        {{errorMessage}}
        <div class="flexbox">
            <input type="checkbox" v-model="force" />
            <span>всё равно сохранить </span>
        </div>
        <button class="site-button first-left main-button" @click="createFinalLayer">Создать черновик</button>
    </div>
</template>

<script>
    import BackEndService from "../../services/BackEndService.js"

    export default {
        props: ['taskGroup'],
        data() {
            return {
                finalLayerName: 'Cлой ' + this.taskGroup.name,
                checkedMissions: [],
                tasks: [],
                result: true,
                errorMessage: "",
                force: false,
            };
        },
        methods: {
            async createFinalLayer() {
                var obj = {
                    groupId: this.taskGroup.id,
                    name: this.finalLayerName,
                    force: this.force,
                    checkedMissions: [],
                }
                var allTasksIds = [];
                for (var task of this.tasks) {
                    var counter = 0;
                    if (task.isChecked) {
                        obj.checkedMissions.push(task.id);
                        counter++;
                    }
                    if (counter == 0) {
                        allTasksIds.push(task.id);
                    }

                }
                if (obj.checkedMissions.length == 0) {
                    obj.checkedMissions = allTasksIds;
                }

                //console.log("createFinalLayer", obj);
                //if (obj.checkedMissions.length == 0) {
                //    for (var task of this.tasks) {
                //        obj.checkedMissions.push(task.id);
                //    }
                //}

                var b = new BackEndService();
                let req = await b.createFinalLayer(obj);
                this.result = req.data.result;
                this.errorMessage = req.data.message;
                console.log("createFinalLayer", req);
            },
            createTasks() {
                for (var task of this.taskGroup.tasks) {
                    var t = {
                        id: task.id,
                        isChecked: false,
                    }
                    this.tasks.push(t);
                }
            }
        },
        mounted() {
            this.createTasks();
        },
    }
</script>

<style scoped>
    .message {
        position: absolute;
         top: 50%;
        left: 50%;
        /*display: block;*/
        background-color: white;
        transform: translate(-50%, -50%);
        z-index: 10;
        padding: 1em;
        border: groove;
    }

    .blur-background {
        filter: blur(4px);
    }
</style>