<template>
    <div class="font-settings">
        <div class="flex-header">
            <span class="flex-grower"></span>
            <h2 class="page-header flex-center">Проекты</h2>
            <div  class="flex-grower align-right">
                <router-link v-if="serverUserRole=='ServerAdmin'" to="/NewProj" class="btn flex-grower to-right">Новый проект</router-link>
            </div>
        </div>

        <div v-if="isLoading==true" style="text-align: center">
            <IsLoadingItem />
        </div>

        <div class="project-cards" v-if="isLoading==false">
            <div class="project-card" v-for="proj in projects" :key="proj.id">
                <!-- <div v-if="proj.status=='Active'">-->
                <div class="card-title" :style="{backgroundImage: 'url(/Image/Thumb/'+proj.id+')'}">

                    <h3 class="page-header" :class="{title: proj.userRole == 'Admin'}" @click="openCatTree(proj)">
                        {{proj.title}}
                    </h3>

                    <p>
                        <span class=""> Количество фотоальбомов: {{ proj.dataSetCount}}</span>
                    </p>
                </div>
                <div class="card-box" v-for="ds in proj.dataSets" :key="ds.id">
                    <span class="small-muted" v-if="ds.dataSetType == 'PhotoAlbum'">Фотоальбом:</span>
                    <span class="small-muted" v-if="ds.dataSetType == 'Video'">Видео:</span>
                    <router-link tag="p" :to="'/photoAlbum/'+ ds.id"> {{ds.name}}</router-link>
                    <p style="margin-bottom:1rem">
                        {{ds.imageCount}} изображений
                        <span class="site-button" v-if="ds.imageCount>0" @click="createLink(ds)">к первому</span>
                    </p>
                </div>
                <!--</div>-->
            </div>
        </div>
        <h2 v-if="isLoading==false && archivedProjects.length > 0">Архивные проекты</h2>
        <div class="project-cards" v-if="isLoading==false && archivedProjects.length > 0">
            
            <div class="project-card" v-for="proj in archivedProjects" :key="proj.id">
                <!-- <div v-if="proj.status=='Active'">-->
                <div class="card-title" :style="{backgroundImage: 'url(/Image/Thumb/'+proj.id+')'}">

                    <h3 class="page-header" :class="{title: proj.userRole == 'Admin'}" @click="openCatTree(proj)">
                        {{proj.title}}
                    </h3>

                    <p>
                        <span class=""> Количество фотоальбомов: {{ proj.dataSetCount}}</span>
                    </p>
                </div>
                <div class="card-box" v-for="ds in proj.dataSets" :key="ds.id">
                    <span class="small-muted">Фотоальбом:</span><router-link tag="p" :to="'/photoAlbum/'+ ds.id"> {{ds.name}}</router-link>
                    <p style="margin-bottom:1rem">
                        {{ds.imageCount}} изображений
                        <span class="site-button" v-if="ds.imageCount>0" @click="createLink(ds)">к первому</span>
                    </p>
                </div>
                <!--</div>-->
            </div>
        </div>
    </div>
</template>

<script>
    import IsLoadingItem from "./IsLoadingItem.vue"
    import BackEndService from "../services/BackEndService.js"

    //import axios from 'axios'


    export default {
        data() {
            return {
                projects: [],
                archivedProjects: [],
                isLoading: false,
                //link:""
            };
        },
        computed: {
            serverUserRole() {
                return window.appstate.serverUserRole;
            }
        },
        components: {
            IsLoadingItem,
        },
        methods: {
            async getProj() {
                this.isLoading = true;

                var b = new BackEndService;

                let req = await b.getHomeJson();
                //axios.post('/Projects/GetHomeJson');

                for (var proj of req.data) {
                    if (proj.status == "Active") {
                        this.projects.push(proj);
                    }
                    else{
                        this.archivedProjects.push(proj);
                    }
                }

                //this.projects = req.data;
                console.log("this.req", req);
                console.log("this.projects", this.projects);
                this.isLoading = false;
            },
            createLink(ds) {
                let link = "/Image/First/" + ds.id;
                location.href = link;
            },
            openCatTree(proj) {
                if(proj.userRole != 'Admin'){
                    return;
                }
                this.$router.push('/projSettings/' + proj.id);
            },
        },
        mounted() {
            this.getProj();
        },
    }

</script>

<style scoped>
    .project-cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap !important;
        justify-content: space-around !important;
    }

    .project-card {
        flex: 1 1 1;
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
        border-radius: 5px;
        min-width: 250px;
        margin: 1rem;
    }

    .card-title {
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 5px 5px 0 0;
        height: 150px;
        text-shadow: 1px 1px 5px black;
        font-weight: bold;
        color: white;
    }

    .title {
        cursor: pointer;
    }

        .title:hover {
            text-decoration: underline;
        }

    .card-box {
        margin: 1rem;
        border-bottom: ridge;
    }

    .btn {
        padding: 0.25rem 0.5rem;
        font-size: .875rem;
        line-height: 1.5;
        background-color: #6c757d;
        border-color: #6c757d;
        border-radius: 0.2rem;
        color: white;
        text-decoration: none;
    }

        .btn:hover {
            text-decoration: underline;
        }
</style>