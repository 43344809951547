<template>
  <nav class="main-nav">
    <div class="dropdown">
                    <div class="dropdown-label">
                      <div class="humburger">
                        <div class="humburger-item"></div>
                        <div class="humburger-item"></div>
                        <div class="humburger-item"></div>
                      </div>
                    </div>
                    <div class="dropdown-menu">
                        <ul class="submenu">
                            <li><router-link class="btn-l" to="/">На главную</router-link></li>
                            <li><router-link class="btn-l" to="/tasks">Миссии</router-link></li>
                            <li><router-link class="btn-l" :to="'/userPage/' + userId">Профиль</router-link></li>
                            <li><a class="btn-l" @click.prevent="logOut" href="#">Выход</a></li>
                        </ul>
                    </div>
    </div>
    <!-- <span style="margin-left: 1em" class="image-name">{{ imageName }}</span> -->
    <div class="tooltip">
        <span style="margin-left: 0.4em" class="image-name">{{ imageName }}</span>
        <span class="tooltiptext">{{nameTooltip}}</span>
      </div>

    <button class="tool-button"  v-if="!editorEnabled && isEditable" @click="enableEditor">Включить редактирование</button>

    <button class="tool-button" style="background-color:#dc3545" v-if="editorEnabled" @click="unableEditor">Выключить редактирование</button>

        <ToolButtons v-if="isEditable && navBarActive && !drawingsOn && !neuralOn" @editor-is-enabled="showSaveButton" :editorEnabled="editorEnabled" />
        <div v-show="editorEnabled">
            <category-chooser />
        </div>
        <button style="margin-left: auto; margin-right: 1em;" class="tool-button main-button" v-if="editorEnabled && navBarActive" @click="save">Сохранить</button>
        <div v-if="!navBarActive" style="margin-left:auto;margin-right:auto">Режим заметки</div>
        <div v-if="!(editorEnabled && navBarActive)" class="auto-left"></div>
        <router-link class="nav-link" :to="'/userPage/' + userId">{{ userName }}</router-link>
        <!--<a class="nav-link" :href="'/Users/Info/'+userId">{{ userName }}</a>-->
</nav>
</template>

<script>
import ToolButtons from "./ToolButtons.vue";
import CategoryChooser from "./CategoryChooser.vue";
import BackEndService from "../../services/BackEndService";
export default {
  data() {
    return {
      canSave : false,
    }
  },
  props: {
    navBarActive:{
      type:Boolean,
      required:true,
    },
    imageName: {
      type: String,
      required: true,
    },
    missionGroupName: {
      type: String,
      required: true,
    },
    userName: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
    editorEnabled: {
      type: Boolean,
      required: true,
    },
    neuralOn: {
      type: Boolean,
      required: true,
    },
    drawingsOn: {
      type: Boolean,
      required: true,
    },
    userId: {
      type: Number,
      required: true
    }
  },
  computed : {
    nameTooltip(){
      if(this.missionGroupName && this.missionGroupName.length > 0){
        return `'${this.missionGroupName}', изображение: '${ this.imageName }'`;
      }
      return this.imageName;
    }
  },
  components: {
    ToolButtons,
    CategoryChooser
  },
        emits: ["save-markup", "editor-is-enabled","unable-editor"],
  methods : {
    showSaveButton(){
      this.canSave = true;
      this.$emit("editor-is-enabled");
    },
    save(){
      this.$emit("save-markup");
    },
    enableEditor(){
      this.$services.eventAggregator.fire("enableeditor");
    },
    unableEditor(){
        this.$emit("unable-editor");
    },
    async logOut() {
        var b = new BackEndService();
        var req = await b.logout();
        console.log("req", req);
        //this.$router.push("/login");
        window.location.href = "/";
        this.$emit('exit');
    },
  }
};
</script>

<style scoped>
.main-nav {
  height: 2rem;
  display : flex;
  align-items: center;
  margin: 0;
} 
.auto-left {
  margin-left: auto;
}
.dropdown {
    display: inline-block;
    position: relative;
    z-index: 1001;
}
.dropdown-label {
    padding: 0 0.25em 0 0.25em;
    border: 1px solid #ccc;
    background-color: #eee;
}

.dropdown:hover .dropdown-label::after {
    top: 0.7em;
    border-color: transparent transparent black;
}

.dropdown-menu {
    display: none;
    position: absolute;
    left: 0;
    top: 2.1em;
    min-width: 16rem;
    background-color: #eee;
}
.dropdown:hover .dropdown-menu {
    display: block;
}
.submenu {
    padding-left: 0;
    margin: 0;
    list-style-type: none;
    border: 1px solid #999;
}
.submenu > li + li {
    border-top: 1px solid #999;
}
.submenu > li > a,
.submenu > li > button
 {
    display: block;
    padding: 0.5em 1.5em;
    background-color: #eee;
    color: #369;
    text-decoration: none;
}
.submenu > li > button {
  border-width: 0;
  font-size: inherit;
  width: 100%;
  margin: 0 0.2rem;
}
.submenu > li > a:hover,
.submenu > li > button:hover {
    background-color: #fff;
}
.object-info-flex {
  display: flex;
}
.humburger{
  width: 2rem;
  height: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.humburger-item{
  height: 4px;
  margin: 0 0.2rem;
  background-color: black;
  border-radius: 0.2rem;
}
</style>