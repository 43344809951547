<template>
    <h1 style="text-align: center" v-if="!id">Создать новый профиль пользователя</h1>
    <h1 style="text-align: center" v-if="id">Редактировать профиль пользователя</h1>
    <div class="center-container">
        <p>Имя</p>
        <input type="text" aria-describedby="Имя" placeholder="Имя" v-model="firstName">
        <p>Фамилия</p>
        <input type="text" aria-describedby="Фамилия" placeholder="Фамилия" v-model="lastName">
        <p>Логин</p>
        <input type="text" aria-describedby="Логин" placeholder="Логин" v-model="username">
        <p>Пароль</p>
        <input type="text" aria-describedby="Пароль" placeholder="Пароль" v-model="password">
        <p>Повтор пароля</p>
        <input type="text" aria-describedby="Повтор пароля" placeholder="Повтор пароля" v-model="passwordConfirm">
        <p>Роль</p>
        <select v-model="role">
            <option value="ServerUser">Пользователь сервера</option>
            <option value="ServerAdmin">Админ сервера</option>
        </select>
        <div>
            <button class="site-button main-button ml-0" @click="save">Сохранить</button>
        </div>
        <!-- <span class="btn" style="background-color:crimson" @click="deleteUser">удалить пользователя</span>-->
        <span v-if="passwordsOverlap" style="background-color: crimson; text-align: center">Сохранение не возможно, пароли не совпадают</span>
    </div>

</template>
<script>
    //import axios from 'axios'
    import BackEndService from "../services/BackEndService.js"

    export default {
        props: ['id'],
        data() {
            return {
                firstName: "",
                lastName: "",
                username: "",
                password: "",
                passwordConfirm: "",
                role: "",
                passwordsOverlap: false,
                isEdit: false,
            };
        },
        methods: {
            async save() {
                let newUser = {}
                if (this.password == this.passwordConfirm) {
                    if (this.id) {
                        newUser = {
                            id: this.id,
                            firstName: this.firstName,
                            lastName: this.lastName,
                            username: this.username,
                            password: this.password,
                            PasswordConfirm: this.passwordConfirm,
                            role: this.role,
                        };
                    }
                    else {
                        newUser = {
                            firstName: this.firstName,
                            lastName: this.lastName,
                            username: this.username,
                            password: this.password,
                            PasswordConfirm: this.passwordConfirm,
                            role: this.role,
                        };
                    }
                    //console.log("newUser", newUser);
                    var b = new BackEndService;
                    var req = await b.saveUser(newUser);
                    //await axios.post('/Users/SaveUser', newUser);
                    console.log("req", req);
                    let link = "/users";
                    this.$router.push(link);
                }
                else {
                    this.passwordsOverlap = true;
                }
            },
            async start() {
                if (window.appstate.serverUserRole == 'ServerAdmin') {
                    if (this.id) {
                        //console.log("ID==", this.id);
                        var b = new BackEndService;
                        var req = await b.getUserInfo(this.id);

                        let info = req;
                        console.log("info", info);
                        this.firstName = info.data.firstName;
                        this.lastName = info.data.lastName;
                        this.username = info.data.username;
                        this.role = info.data.role;
                        this.isEdit = true;
                    }
                }
                else {
                    this.$router.push('/');
                }
            }
        },
        mounted() {
            this.start();
        },
    }</script>
<style scoped>
    p {
        flex-shrink: 1;
        flex-grow: 1;
        flex-basis: 1em;
    }

    input {
        margin: 0.5rem 0;
        padding: 0.4rem 0.8rem;
    }

    select {
        margin: 0.5rem 0;
        padding: 0.4rem 0.8rem;
    }   
    
    .btn:hover {
        text-decoration: underline;
    }
</style>