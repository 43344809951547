export default class MapImageManager {
    map = null;
    mapLayer = null;
    basePath = "";
    oldOverlays = [];
    overlay = null;

    constructor(mapManager) {
        this.mapManager = mapManager;
    }
    init(data) {
        this.oldOverlays.push(this.overlay);

        const bounds = this.getBounds(data.width, data.height);
        const viewBounds = this.getViewBounds(data.width, data.height);

        let imageUrl = "/Image/TestImage";
        this.overlay = null;
        const imageOptions = {
            maxNativeZoom: window.appstate.maxZoom,
            minNativeZoom: 0,
            maxZoom: 20,
            minZoom: -10,
            bounds: bounds
        };
        if(data.isWholeImage){
          imageUrl = `${this.basePath}/tiles/image/${data.project}/${data.dataSet}/${data.imageName}/0/`;
          this.overlay = window.L.imageOverlay(imageUrl, bounds, imageOptions);
          this.overlay.on('load', this.deleteOldImage);
          this.overlay.on('error', this.deleteOldImage);
        }
        else {
          imageUrl = `${this.basePath}/tiles/layers/${data.project}/${data.dataSet}/${data.imageName}/`+'{z}/{y}_{x}/';
          this.overlay = window.L.tileLayer(imageUrl, imageOptions);
          this.deleteOldImage();
        }
        if(!this.overlay){
            console.error("Image layer is corrupted");
            return;
        }
        
        this.overlay.addTo(this.mapManager.map);
        this.mapManager.map.fitBounds(viewBounds);
    }
    getViewBounds(width, height){
        let x = width;
        let y = height;
        if(x > 1024){
            x = 1024;
        }
        if(y > 1024){
            y = 1024;
        }
        const bounds = this.getBounds(x,y);
        return bounds;
    }
    getBounds(width, height){
        const coordinate = {x:width, y: height};
        if(width <= 10 && height<= 10){
            coordinate.x = 1000;
            coordinate.y = 1000;
        }
        const p = this.mapManager.toLatLng(coordinate);
        const bounds = [[0, 0], [p.lat, p.lng]];
        return bounds;
    }
    initGoogleMap() {
        this.mapLayer = window.L.tileLayer('https://mt1.google.com/vt/lyrs=y&z={z}&x={x}&y={y}').addTo(this.map);
    }
    addSlices(mapSlices) {
        for (let i = 0; i < mapSlices.length; i++) {
            const slice = mapSlices[i];
            var sliceUrl = "https://localhost:5001/Image/TestSlice?name=" + slice.id;
            var sliceBounds = [slice.hLcoords, slice.bRcoords];
            slice.imageLayer = window.L.imageOverlay(sliceUrl, sliceBounds);
            slice.imageLayer.addTo(this.map);
            slice.imageLayer.setZIndex(slice.zIndex);
        }
    }
    deleteOldImage = () => {
        if (this.oldOverlays && this.oldOverlays.length > 0) {
            for (const imageLayer of this.oldOverlays) {
                imageLayer?.remove();
            }
            this.oldOverlays = [];
        }
        // window.vuebusy = false;
        this.overlay.off('load', this.deleteOldImage);
        this.overlay.off('error', this.deleteOldImage);
        this.toggleImageFade(false);
    }
    toggleImageFade(fadeOut){
        const imgTag = document.querySelector('.leaflet-image-layer')
        if (imgTag) {
          if(fadeOut){
            imgTag.classList.add('img-fade-out');
          }
          else{
            imgTag.classList.remove('img-fade-out')
          }
        }
    }
}   