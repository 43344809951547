import LabelMarker from "../tooling/LabelMarker";
export default class ShapeObjectBase {

    id;
    category;
    trackerId;
    locked;
    comment;
    orientation;
    itsLayer;
    delta = 50;
    selected = false;
    labelOpacity = 1.0;
    fillOpacity = 0.2;

    constructor(mapManager) {
        this.mapManager = mapManager;
        this.map = this.mapManager.map;
        this.size = this.mapManager.size;
        this.zoom = this.mapManager.getZoom();
    }
    //
    createExisting(markup, options) {
        this.id = markup.id;
        this.trackerId = markup.trackerId;
        this.locked = markup.lock;
        this.color = options.color;
        this.category = markup.class;
        this.categoryName = options.categoryName;
        this.comment = markup.comment;
        this.orientation = { angle: markup.orientation };
        this.itsLayer = options.itsLayer;
        this.tagList = options.tagList;

        this.label = new LabelMarker(this.map);
        if(!options.strokeOpacity){
            options.strokeOpacity = 1.0;
        }
        if(!options.fillOpacity){
            options.fillOpacity = 0.2;
        }
        this.createShape(markup, options);
        //this.add();
    }
    createNew(options, point) {
        this.color = options.color;
        this.category = options.category;
        this.categoryName = options.categoryName;
        this.label = new LabelMarker(this.map);
        this.itsLayer = options.itsLayer;
        this.id = options.id;
        this.trackerId = options.trackerId;
        this.comment = "";
        if(!options.strokeOpacity){
            options.strokeOpacity = 1.0;
        }
        if(!options.fillOpacity){
            options.fillOpacity = 0.2;
        }
        let zoom = this.map.getZoom();
        let scale = Math.pow(2, window.appstate.maxZoom - zoom);
        this.delta = 5*scale;
        this.createNewShape(options, point);
        this.add();
    }
    getPoints() {
        const geoPoints = this.shape.getGeoPoints();
        const points = [];
        for (let i = 0; i < geoPoints.length; i++) {
            let p = this.mapManager.toFlat(geoPoints[i]);
            points.push(p);
        }
        return points;
    }
    getMarkupPoints(){
        return this.getPoints();
    }
    //TODO: eliminate the geo points. Achange also server api for saving
    getGeoPoints(){
        return this.shape.getGeoPoints();
    }
    getSavePoints(){
        return this.shape.getSavePoints();
    }
    getShapeCenter(){
        return this.shape.getCenter();
    }
    intersects(rectBounds) {
        const geoPoints = this.shape.getGeoPoints();
        var objBounds = window.L.latLngBounds(geoPoints);
        return objBounds.intersects(rectBounds);
    }
    select() {
        this.selected = true;
        this.shape.drawCorners();
    }
    deselect() {
        this.selected = false;
        this.shape.undrawCorners();
        this.selectedCornerIndex = -1;
    }

    onZoom(zoom) {
        this.zoom = zoom;
        this.shape.onZoom();
        if(this.selected){
            this.shape.drawCorners();
        }
    }
    changeColor(color) {
        this.color = color;
        this.shape.changeColor(color);
    }
    updateLabel() {
        if (window.appstate.showObjectInfo) {
            let labelP = this.getLabelPoint(this.shape);
            const options = {
                color: this.color,
                hasTags: this.hasTags(),
                opacity: this.labelOpacity
            }
            this.label.setLabel(this.categoryName, this.comment, labelP, options);
        }
        if(this.shape){
            this.shape.changeColor(this.color);
        }
    }
    hasTags(){
        return this.tagList && this.tagList.length > 0;
    }
    //TODO: add move label
    move(point) {
        this.shape.move(point);
        this.updateLabel();
    }
    mouseUp(){
        this.shape.selectedCorner = null;
        this.shape.selectedCornerIndex = -1;
    }
    remove() {
        this.shape.remove();
        this.label.remove();
        this.shape.undrawCorners();
        this.selectedCornerIndex = -1;
    }

    add() {
        this.shape.add();
        this.updateLabel();
        if(this.selected){
            this.shape.drawCorners();
        }
    }

    getBounds() {
        return this.shape.getBounds();
    }
    getLabelPoint(shape) {
        let points = shape.getGeoPoints();
        let labelPnt=points[0];

        for (var i = 0; i < points.length; i++) {
            if (points[i].lat > labelPnt.lat) {
                labelPnt = points[i];
            }
        }
        return { lat: labelPnt.lat, lng: labelPnt.lng };
    }
    changeOpacity(fillOpacity, strokeOpacity){
        this.labelOpacity = strokeOpacity;
        this.fillOpacity = fillOpacity;
        this.shape.changeOpacity(fillOpacity, strokeOpacity);
    }
    isVisible(){
        return this.shape.isVisible();
    }
    processPoint(){
    }
}