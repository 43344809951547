<template>

    <div class="center-container">
        <h1 style="text-align: center">Новый проект</h1>
        <p>Название</p>
        <input type="text" aria-describedby="Имя" placeholder="придумайте название" v-model="proj.name">
        <p>Описание</p>
        <textarea v-model="proj.description" placeholder="введите описание"></textarea>
        <div>
            <button class="site-button main-button ml-0 mt-2" @click="save">Сохранить</button>
            <statusElement :statusCode="saveStatusCode" />
        </div>
    </div>
</template>
<script>
    //import axios from 'axios'
    import BackEndService from "../services/BackEndService.js"
    import statusElement from "./statusElement.vue"


    export default {
        components: {
            statusElement,
        },
        data() {
            return {
                proj: {
                    name: "",
                    description: "",
                },
                saveStatusCode:"",
            };
        },
        methods: {
            async save() {
                console.log("async save()", this.proj);
                this.saveStatusCode = "0";
                var b = new BackEndService;
                var req = await b.saveNewProj(this.proj);
                //var req = await axios.post('/Projects/SaveNewProj', this.proj);
                this.saveStatusCode = req.statusCode;
                console.log("save req",req);
                let link = "/";
                this.$router.push(link);
            },
        },
        mounted() {

        },
    }
</script>
<style scoped>
    input[type='checkbox'],
    input[type='radio'] {
        display: inline-block;
        width: auto;
        margin-right: 1rem;
    }

        input[type='checkbox'] + label,
        input[type='radio'] + label {
            font-weight: normal;
        }

    input,
    select,
    textarea {
        display: block;
        width: 100%;
        font: inherit;
        margin-top: 0.5rem;
        margin-left: 0;
    }

    select {
        width: auto;
    }

    p {
        flex-shrink: 1;
        flex-grow: 1;
        flex-basis: 1em;
    }

    input {
    }

    .btn {
        padding: 0.25rem 0.5rem;
        font-size: .875rem;
        line-height: 1.5;
        background-color: #6c757d;
        border-color: #6c757d;
        border-radius: 0.2rem;
        color: white;
        text-decoration: none;
    }

        .btn:hover {
            text-decoration: underline;
        }
</style>