import MapObjectManager from "./MapObjectManager";
import MapImageManager from "./MapImageManager";
import MapMarkupManager from "./MapMarkupManager";
import InputHelper from "../inputHelper";
import { createNewLayer, createCategory, getMarkupOptions } from "../../models/layerFactory";
import MapDrawingsManager from "./MapDrawingsManager";

export default class MapManager {
  listeners = {
    zoom: [],
    moved: [],
    mousemoved: [],
    contextmenu: [],
    mousedown: [],
    selected: [],
    activelayer: [],
    layers: [],
    togglelayer: [],
    mapready: [],
    centermap: [],
    fitimage: [],
    togglecategory: [],
    changeCategoryColor: [],
    savenotes: [],
    updatecategorycount: []
  };
  constructor(eventAggregator) {
    this.eventAggregator = eventAggregator;
    this.inputHelper = new InputHelper();

    this.mapObjectManager = new MapObjectManager(this, this.inputHelper, this.eventAggregator);
    this.mapImageManager = new MapImageManager(this);
    this.mapMarkupManager = new MapMarkupManager(this, this.eventAggregator, this.inputHelper);
    this.mapDrawingsManager = new MapDrawingsManager(this, this.inputHelper);
    this.mapMarkupManager.mapDrawingsManager = this.mapDrawingsManager;
    this.datasetType = "";
    this.map = null;
    this.imageWidth = 0.0;
    this.imageHeight = 0.0;
    this.neuralHelperType = "";
    this.imgId=-1;
  }
  init(datasetType) {
    window.coordinateMultiplier = Math.pow(2, 4)
    this.map = this.mapObjectManager.init(datasetType);
    this.initMapEvents();
    this.datasetType=datasetType;
    window.addEventListener('beforeunload', e=>this.askSaveBeforeLeave(e));
  }
  initMapEvents() {
    const self = this;
    //map.on is leaflet method 
    this.map.on('zoom', () => self.onZoom());
    this.map.on('drag', () => self.map.fire('moveend'));
    this.map.on('move', () => self.onMove());
    this.map.on('mousemove', e => self.onMouseMove(e));
    this.map.on('contextmenu', (e) => self.onContextMenu(e), false);
    this.map.on('mousedown', (e) => self.onMapDown(e));
    this.map.on('mouseup', (e) => self.onMapUp(e));
  }
  onZoom() {
    this.fire('zoom', this.map.getZoom());
  }
  onMove() {
    this.map.fire('moveend');
    this.mapMarkupManager.drawInView();
  }
  onMouseMove(e) {
    this.fire('mousemoved', e.latlng);
    this.mapMarkupManager.onMouseMove(e);
  }
  onContextMenu(e) {
    e.originalEvent.preventDefault();
    if (this.inputHelper.mouseMoved(e.originalEvent)) {
      return;
    }
    if (e.originalEvent.ctrlKey) {
      this.fire("contextmenu", { open: true, e: e });
    }
  }
  onMapDown(e) {
    this.inputHelper.mouseDownPosition = { x: e.originalEvent.clientX, y: e.originalEvent.clientY };
    this.fire("contextmenu", { open: false, e: null });
    this.mapMarkupManager.onMouseDown(e);
  }
  onMapUp(e) {
    this.mapMarkupManager.onMouseUp(e);
  }
  initMap(datasetType) {
    this.datasetType = datasetType;
    window.coordinateMultiplier = Math.pow(2, 4)
    window.editorEnabled = true
    window.map = null
    window.showMarkup = true
    window.services.markupLayers = []
    window.services.mapManager.initMap(datasetType);
    //this.mapObjectManager.initMap(datasetType);
    window.addEventListener('beforeunload', this.askSaveBeforeLeave);
  }
  initImage(data) {
        //console.log("MapManager_initImage", data)
    this.imgId = data.id;
    window.appstate.maxZoom = data.maxZoom;
    window.coordinateMultiplier = Math.pow(2, data.maxZoom);
    this.mapMarkupManager.coordinateMultiplier = Math.pow(2, data.maxZoom);
    //window.appstate.c = Math.pow(2, data.maxZoom);
    this.mapImageManager.init(data);
    this.imageHeight = data.height;
    this.imageWidth = data.width;
    this.fire("mapready", this.map);
  }
  fadeImage(){
    this.mapImageManager.toggleImageFade(true);
  }
  returnImage(){
    this.mapImageManager.toggleImageFade(false);
  }
  onSaveSuccess(){
    this.mapMarkupManager.edited = [];
  }
  initMarkup(data) {
    this.mapMarkupManager.create(data.layers, this.map);
    this.mapMarkupManager.edited = [];
  }
  askSaveBeforeLeave(e) {
    if (!this.hasChanges()) {
      return
    }
    var confirmationMessage = 'Разметка не сохранена. Вы действительно хотите покинуть страницу?';
    (e || window.event).returnValue = confirmationMessage // Gecko + IE
    return confirmationMessage // Webkit, Safari, Chrome etc.
  }
  hasChanges(){
    const edited = this.mapMarkupManager.edited;
    if (edited.length === 0) {
      return false;
    }    
    return true;
  }

  changeImage(imagePath, data) {

    window.maxImageZoom = this.datasetType === "MapSlices" ? 20 : data.maxZoom;
    window.mapSystem = this.datasetType === "MapSlices" ? 'gps' : 'simple';
    window.coordinateMultiplier = Math.pow(2, window.maxImageZoom)
    window.isWholeImage = data.isWholeImage
    window.imagePath = imagePath
    window.imgHeight = data.height
    window.imgWidth = data.width
    //window.imagePath = imagePath
    //window.imgHeight = data.height
    //window.imgWidth = data.width
    window.services.mapManager.updateMapImage();
  }

  changeLayers(layers) {
    this.updateLayers(layers);
    window.updateMapLayers()
    if (layers.length > 0) {
      window.services.mapManager.layerChanged(layers[0], layers[0].isOn)
      window.vueContext.actions.initObjectCategories(layers[0].categories)
    }
  }

  updateLayers(layers) {
    window.services.markupLayers = []
    for (const layer of layers) {
      const newLayer = createNewLayer(layer);
      for (const category of layer.items) {
        newLayer.categories[category.id] = createCategory(category);
      }
      for (const markup of layer.markups) {
        const options = getMarkupOptions(layer, markup);
        options.itsLayer = newLayer;
        let objToDraw = window.services.markupManager.createMarkupObject(markup);
        objToDraw.itsLayer = newLayer;
        objToDraw.createExisting(markup, options, options.objStrokeOpacity, options.objFillOpacity);
        newLayer.data.push(objToDraw);
      }
      window.services.markupLayers.push(newLayer);
    }
  }

  sendChangeImage() {
    const imgTag = document.querySelector('.leaflet-image-layer')
    if (imgTag) {
      imgTag.classList.add('img-fade-out')
    }
  }

  revertChangeImage() {
    const imgTag = document.querySelector('.leaflet-image-layer')
    if (imgTag) {
      imgTag.classList.remove('img-fade-out')
    }
  }

  toStrFlat(geo) {
    let x = geo.lng * this.mapMarkupManager.coordinateMultiplier;
    let y = -geo.lat * this.mapMarkupManager.coordinateMultiplier;
    x = parseFloat(x).toFixed(1);
    y = parseFloat(y).toFixed(1);
    return { x: x, y: y };
  }
  toFlat(geo) {
    let x = geo.lng * this.mapMarkupManager.coordinateMultiplier;
    let y = -geo.lat * this.mapMarkupManager.coordinateMultiplier;
    return { x: x, y: y };
  }
  toLatLngArray(coordinate){
    const latlng = this.toLatLng(coordinate);
    return [latlng.lat, latlng.lng];
  }
  toLatLng(coordinate) {
    let lat = -coordinate.y / this.mapMarkupManager.coordinateMultiplier;
    let lng = coordinate.x / this.mapMarkupManager.coordinateMultiplier;
    return { lat: lat, lng: lng };
  }
  toGeoDistance(len){
    return len / this.mapMarkupManager.coordinateMultiplier;
  }
  getZoom() {
    return this.map.getZoom();
  }
  getMulitplier(){
    return this.mapMarkupManager.coordinateMultiplier;
  }
  getMapState(){
    let bounds = this.map.getBounds();
    let zoom = this.map.getZoom();
    let lat = (bounds._northEast.lat + bounds._southWest.lat) / 2;
    let lng = (bounds._northEast.lng + bounds._southWest.lng) / 2;
    const data = {
      centerLat:lat,
      centerLng:lng,
      zoom:zoom
    }
    return data;
  }
  getMapBounds(){
    const mapBounds = this.map.getBounds();
    const x = mapBounds.getWest()*this.mapMarkupManager.coordinateMultiplier;
    const y =  -mapBounds.getNorth()*this.mapMarkupManager.coordinateMultiplier;
    const w =  mapBounds.getEast()*this.mapMarkupManager.coordinateMultiplier - x;
    const h =  -mapBounds.getSouth()*this.mapMarkupManager.coordinateMultiplier - y;
    const bounds = {
      x: x,
      y: y,
      w: w,
      h: h,
    };
    return bounds;
  }
  getNeuralBoxBounds(){
    return this.mapMarkupManager.getNeuralBoxBounds();
  }
  getItemsInsideNeuralBox(){
    return this.mapMarkupManager.getItemsInsideNeuralBox();
  }
  getDrawings(){
    return this.mapDrawingsManager.getNotesToSave();
  }
  setNotes(data){
    this.mapDrawingsManager.setNotes(data);
  }
  moveMarkup(markups, offset){
    const list = [...markups];
    for (const markup of list) {
      markup.box[0] = markup.box[0] + offset.x;
      markup.box[1] = markup.box[1] + offset.y;

      let i = 0;
      const newPoints = [];
      if(markup.points && markup.points.length > 0){
        for (const number of markup.points) {
          if(i % 2 === 0){
            newPoints.push(number + offset.x);
          }
          else{
            newPoints.push(number + offset.y);
          }
          i++;
        }
        markup.points = newPoints;
      }
    }
    return list;
  }
  setNeuralMarkup(data, shouldClean, maxOverlay){
    this.mapMarkupManager.addTempLayer(data.markups, shouldClean, maxOverlay);
  }
  applyNeuralMarkup(){
    this.mapMarkupManager.applyNeuralMarkup();
  }
  discardNeuralMarkup(){
    this.mapMarkupManager.discardNeuralMarkup();
  }
  getLayersToSave(){
    return this.mapMarkupManager.getLayersToSave();
  }
  canSaveMarkup(){
    const allow =  this.mapMarkupManager.canSaveMarkup();
    return allow;
  }
  setNeuralHelperType(type){
    this.neuralHelperType = type;
    this.mapMarkupManager.clearTools();
  }
  on(label, action) {
    if (!(label in this.listeners)) {
      return;
    }
    this.listeners[label].push(action);
  }
  off(label, action) {
    if (!(label in this.listeners)) {
      return;
    }
    const actions = this.listeners[label];
    const index = actions.indexOf(action);
    if (index > -1) {
      action.splice(index, 1);
    }
  }
  fire(label, e) {
    if (!(label in this.listeners)) {
      console.warn("Map manager fired unknown action", label);
      return;
    }
    for (const action of this.listeners[label]) {
      action(e);
    }
  }
}
