<template>
  <div class="imageThumb" :class="{opened: isShown}" :style="offsetStyle">
    <div v-show="!isLoading">
      <img :src="imageSrc" alt="" :width="imgWidth" :height="imgHeight" @load="imageLoaded"/>
    </div>
    <div v-if="isLoading" class="center-text">
      <is-loading-item />
    </div>
  </div>
</template>

<script>
export default {
    props : ["id", "isShown","position"],
    data(){
        return {
            isLoading: true,
            imgWidth: 250,
            imgHeight: 250,
        };
    },
    computed: {
        imageSrc(){
            if(!this.isShown || this.id === 0){
                return "";
            }
            return "/Image/Preview?id="+this.id;
        },
        offsetStyle(){
            const style = {
                left: "initial",
                bottom: "initial",
                right: "initial",
                top: "initial"
            }
            if(!this.position){
                return style;
            }
            if(this.position.x < this.imgWidth*1.2){
                style.left = 0;
            }
            else if(this.position.x > window.innerWidth - this.imgWidth*1.2){
                style.right = 0;
            }
            else {
                style.left = 0;
            }

            if(this.position.y >  this.imgWidth*1.2){
                style.bottom = "1.5em";
            }
            else {
                style.top = "1.5em";
            }
            return style;
        }
    },
    methods: {
        imageLoaded(){
            this.isLoading = false;
        }
    }
};
</script>

<style>
.imageThumb {
    display: none;
    position: absolute;
    /* left: -50%;
    bottom: 1.5em; */
    min-width: 250px;
    background-color: #eee;
}
.opened {
    display: block;
}
</style>