<template>
    <section class="neural-net-box">
    <div style="display: flex">
      <select
          style="width: 20rem; margin-left: 1em"
          v-model="selectedHelper"
          name="helperSelect"
          id="helperSelect"
        >
          <option v-for="helper in helpers" :value="helper" :key="helper.id">{{ helper.name }}</option>
      </select>
      <!-- <input
        style="min-width: 30em; margin-left: 1em"
        type="text"
        v-model="nnurl"
      /> -->
      <button
        style="margin-left: auto; margin-right: 1em"
        class="site-button main-button"
        @click="test"
      >
        Тест
      </button>
      <button
        style="margin-left: auto; margin-right: 1em"
        class="site-button main-button"
        @click="openLog"
      >
        Лог
      </button>
      <div @click="closeAssist" class="grow-btn">
        ⨉
      </div>
    </div>
    <div class="managing-line" v-if="!isLoading">
      <div>
        <input type="checkbox" id="screen-checkbox" v-model="clipImage" />
        <label for="screen-checkbox">ограничить изображение экраном</label>
      </div>
      <button
          class="site-button main-button"
          @click="sendWrongTaskResult"
          title="Несуществующая задача">
          НЗ
      </button>
      <button
          v-if="selectedHelper.type === 'helper2' || selectedHelper.type === 'helper3'"
          class="tool-button main-button"
          @click="sendBoxRequest">
          Отправить
      </button>
    </div>
    <div class="managing-line" v-if="!isLoading">
      <div>
        <input type="checkbox" id="clean-checkbox" v-model="shouldClean" />
        <label for="clean-checkbox">очистить наложения</label>
      </div>
    </div>
    <div v-if="!isLoading && hasMarkup" class="markup-buttons">
      <button type="button" class="tool-button main-button" @click="transferMarkup">Применить</button>
      <button type="button" class="tool-button" @click="discardMarkup">Отмена</button>
    </div>
    <div v-if="isLoading" class="loading-line">
      <span style="flex-grow: 1">{{loadingText}}</span>
      <button class="tool-button" v-if="canCancelLoading" type="button" @click="onCancel">отмена</button>
    </div>
  </section>
  <section class="neural-net-log" v-if="showLog">
      <button @click="closeLog">
          Закрыть
      </button>
      <p class="doselect" style="white-space: pre-wrap; font-size: 80%">
          {{logText}}
      </p>
  </section>
  <conformation-modal @close-win="processConfirmation" :isShown="comformationIsShown" :messasge="confirmationText" />
  <modal @close-win="closeModal" :isShown="modalIsShown" :messasge="modalText" />
</template>

<script>
import DataService from "../../services/DataService";
import NeuralDataService from "../../services/neuralServices/NeuralDataService";
import StorageService from "../../services/StorageService";
import BrowserManager from "../../services/browserManager";
import ConformationModal from "../ConfirmationModal.vue";
import Modal from "../Modal.vue";
export default {
    components : {
      ConformationModal,
      Modal
    },
    data(){
        return {
            //nnurl: 'http://10.55.229.114:3080/predictions/fastrcnn/',
            //nnurl: 'http://10.55.229.114:3080/predictions/deeplab/',
            nnurl: '',
            //nnurl: 'https://localhost:5001/Neural/GetDummyNeuralResponse/',
            nnListUrl : '',
            showLog: false,
            logText : "",
            storageService: null,
            selectedHelper: {},
            shouldClean: true,
            maxOverlay: 0.85,
            helpers: [],
            helperAcions: [
              ()=>{},
              this.sendPointRequest,
              this.sendNeuralRequest
            ],
            waitIntervel: 500,
            looper: null,
            isLoading: false,
            canCancelLoading: false,
            loadingText: "Загрузка...",
            hasMarkup : false,
            comformationIsShown : false,
            modalIsShown: false,
            modalText: "",
            confirmationText: "Выйти и перенести разметку на основной слой?",
            clipImage: true,
            imageBounds: null,
            taskImageBounds: {},
            maxImgBounds: {w:3000,h:3000},
            //TODO: real values
            projectId : 1,
            taskId: 22,
            imageId: 5547,
            currentReqId: 0,
        }
    },
    watch: {
      selectedHelper(newHelper) {
        this.$services.mapManager.setNeuralHelperType(newHelper.type);
        this.storageService.setNeuralHelperId(newHelper.id);
      }
    },
    methods : {
        closeAssist(){
          if(this.hasMarkup){
            this.comformationIsShown = true;
            return;
          }
          this.$services.eventAggregator.fire("toggleneuralassist", false);
        },
        closeModal(){
          this.modalIsShown = false;
        },
        processConfirmation(type){
          if(type === "yes")
          {
            this.transferMarkup();
          }
          else if(type === "no"){
            this.discardMarkup();
            this.closeAssist();
          }
          this.comformationIsShown = false;
        },
        getHelpersList(){
          this.loadingText = "Загрузка помощников...";
          this.canCancelLoading = false;
          this.isLoading = true;
          var dataService = new NeuralDataService();
          const data = { projectId: this.projectId }
          const callbacks = {
            success: this.onHelpersListSuccess,
            error: this.onError
          }
          dataService.listHelpers(data, callbacks);
        },
        onHelpersListSuccess(data){
          this.isLoading = false;
          this.helpers = data.helpers;
          if(data.max_image_size.w > 0 && data.max_image_size.w > 0){
            this.maxImgBounds = data.max_image_size;
          }
          if(data.max_overlay > 0){
            this.maxOverlay = data.max_overlay;
          }
          if(this.helpers && this.helpers.length > 0){
            const savedId = this.storageService.getNeuralHelperId();
            let helperToSelect = this.helpers.find(h=>h.id == savedId);
            if(!helperToSelect){
              helperToSelect = this.helpers[0];
            }
            this.selectedHelper = helperToSelect;
          }
        },
        onError(data){
          this.isLoading = false;
          console.log("Ошибка запроса", data);
          clearInterval(this.looper);
        },
        onCancel(){
          this.canCancelLoading = false;
          this.isLoading = false;
          clearInterval(this.looper);
        },
        closeLog(){
            this.showLog = false;
        },
        openLog(){
            this.showLog = true;
        },
        send(){
          const action = this.helperAcions[this.selectedHelper.type];
          if(action){
            action();
          }
        },
        getSafeBounds(bounds){
          const imageHeight = this.$services.mapManager.imageHeight;
          const imageWidth = this.$services.mapManager.imageWidth;
          if(bounds.x < 0){
            bounds.x = 0;
          }
          if(bounds.y < 0){
            bounds.y = 0;
          }
          if(bounds.w > imageWidth){
            bounds.w = imageWidth;
          }
          if(bounds.h > imageHeight){
            bounds.h = imageHeight;
          }
          return bounds;
        },
        isSizeTooBig(w,h){
          if(w > this.maxImgBounds.w || h > this.maxImgBounds.h){
            return true;
          }
          return false;
        },
        isImageTooBig(){
          const imageHeight = this.$services.mapManager.imageHeight;
          const imageWidth = this.$services.mapManager.imageWidth;
          return this.isSizeTooBig(imageWidth, imageHeight);
        },
        isImageSizeOk(data){
          this.imageBounds = null;
          if(this.clipImage){
            const bounds = this.$services.mapManager.getMapBounds();
            const safeBounds = this.getSafeBounds(bounds);
            const isTooBig = this.isSizeTooBig(safeBounds.w, safeBounds.h);
            if(isTooBig){
              this.modalText = "Видимая область экрана слишком большая для нейросетевой обработки! Попробуйте приблизить.";
              this.modalIsShown = true;
              return false;
            }
            this.imageBounds = safeBounds;
            data.imageinfo = safeBounds;
          }
          else{
            const isImgTooBig = this.isImageTooBig();
            if(isImgTooBig){
              this.modalText = "Изображение слишком большое для нейросетевой обработки! Попробуйте ограничить размеры видимой областью.";
              this.modalIsShown = true;
              return false;
            }
          }
          return true;
        },
        getBaseData(){
          const params = this.browserManager.getUrlParams();
          this.imageId = params.image;
          const data = {
            taskId: this.taskId,
            imageId: this.imageId,
            helper: this.selectedHelper.id
          };
          return data;
        },
        sendBoxRequest(){
          if(this.isLoading){
            return;
          }
          const data = this.getBaseData();
          const boxBounds = this.$services.mapManager.getNeuralBoxBounds();
          if(!this.isImageSizeOk(data))
          {
            return;
          }
          const callbacks = {
            success: this.onTaskSent,
            error: this.onError
          }
          var dataService = new NeuralDataService();
          if(this.selectedHelper.type === 'helper2')
          {
            data.box = boxBounds;
            dataService.addBoxTask(data, callbacks);
          }
          else if(this.selectedHelper.type === 'helper3'){
            dataService.addEmptyTask(data, callbacks);
          }
          
        },
        sendWrongTaskResult(){
          var dataService = new NeuralDataService();
          const data = { taskId: "ab28c312-5aab-4f0c-9a38-2050a113acca" }
          const callbacks = {
            success: this.onMarkup,
            error: this.onError
          }
          dataService.getResult(data, callbacks);
        },
        onNeuralMouseRequest(e){
          if(this.isLoading){
            return;
          }
          const data = this.getBaseData();
          if(!this.isImageSizeOk(data))
          {
            return;
          }
          const callbacks = {
            success: this.onTaskSent,
            error: this.onError
          }
          var dataService = new NeuralDataService();
          if(this.selectedHelper.type === "helper1")
          {
            data.point = e;
            dataService.addTask(data, callbacks);
          }
        },
        sendPointRequest(){
          if(this.isLoading){
            return;
          }
          var dataService = new NeuralDataService();
          const data = {
            ponit: {x:100,y:100},
            taskId: this.taskId,
            imageId: this.imageId,
          };
          const callbacks = {
            success: this.onTaskSent,
            error: this.onError
          }
          dataService.addTask(data, callbacks);
        },
        onTaskSent(data){
          this.$messageManger.show("Запрос отправлен нейросети!", 'info');
          this.loadingText = "Ожидание ответа нейросети...";
          this.canCancelLoading = true;
          this.isLoading = true;
          if(this.looper){
            clearInterval(this.looper);
          }
          if(this.imageBounds){
            this.taskImageBounds[data] = this.imageBounds;
          }
          this.looper = setInterval(() => this.getResultsInLoop(data), this.waitIntervel);
        },
        
        onMarkup(data){
          if(data != ""){
            this.logMessage(data, this);
            clearInterval(this.looper);
            const imageBounds = this.taskImageBounds[data.taskId] = this.imageBounds;
            if(imageBounds){
              const markups = this.$services.mapManager.moveMarkup(data.markups, {x:imageBounds.x, y: imageBounds.y});
              data.markups = markups;
            }
            this.$services.mapManager.setNeuralMarkup(data, this.shouldClean, this.maxOverlay);
            this.canCancelLoading = false;
            this.isLoading = false;
            this.hasMarkup = true;
          }
        },
        getResultsInLoop(id){
          this.currentReqId = id;
          console.log("--LOOP--");
          var dataService = new NeuralDataService();
          const data = { taskId: id }
          const callbacks = {
            success: this.onMarkup,
            error: this.onError
          }
          dataService.getResult(data, callbacks);
        },
        test(){
          var dataService = new NeuralDataService();
          const data = { taskId: this.currentReqId }
          const callbacks = {
            success: ans=> console.log("TestAns",ans),
            error: this.onError
          }
          dataService.sendTest(data, callbacks);
        },
        sendNeuralRequest(){
          if(!this.nnurl || this.nnurl.length === 0){
            this.$messageManger.show("Не указан адрес нейросети!", 'error');
            return;
          }
          this.storageService.setNeuralNetAddress(this.nnurl);
          let bounds = this.$services.mapManager.getNeuralBoxBounds();
          if(!bounds){
            bounds = this.$services.mapManager.getMapBounds();
          }
          const self= this;
          const payload = this.getPayload(bounds);
          this.dataService.getNeuralMarkup(
            payload,
            (data, success, errorMessage) => this.onResponse(data,success, errorMessage, self)
          );
        },
        getPayload(bounds){
          const markupObjects = this.$services.mapManager.getItemsInsideNeuralBox();
          const p = this.browserManager.getUrlParams();
          let payload = {
            imageId: parseInt(p.image),
            bounds: bounds,
            url: this.nnurl,
            markups: markupObjects
          }
          return payload;
        },
        onResponse(data, success, errorMessage, self){
          if (success) {
            self.$messageManger.show("Получен ответ нейросети!", 'success', data);
            self.$services.eventAggregator.fire("externalobjects", data.body);
            this.logMessage(data, self);
             
          } else {
            self.$messageManger.show("Ошибка получения ответа нейросети!", 'error');
            console.log(errorMessage);
            this.logMessage(errorMessage, self);
          }
        },
        transferMarkup(){
          this.$services.mapManager.applyNeuralMarkup();
          this.hasMarkup = false;
        },
        discardMarkup(){
          this.$services.mapManager.discardNeuralMarkup();
          this.hasMarkup = false;
        },
        logMessage(data, self){
          var today = new Date();
          let message = "\n"+today.toISOString()+"\n";
          message += "############################\n";
          message += JSON.stringify(data, null, 2);
          //self.$messageManger.openPopup(JSON.stringify(data));
          self.logText += message;
        },
        neural(){
            const bounds = this.getMapBounds();
            const self= this;
            let imgData = null;
            const mapDiv = document.querySelector("#mapid");
            if(mapDiv){
                window.domtoimage.toJpeg(mapDiv, {quality: 0.95})
                .then(function (dataUrl) {
                    imgData = dataUrl;
                    imgData = dataUrl.split(',')[1];
                    imgData = Buffer.from(imgData, "base64");
                    var dataS = new DataService();
                    dataS.getNeuralMarkup(
                      0,
                      bounds,
                      imgData,
                      (data, success, errorMessage) => {
                        if (success) {
                           //window.drawNnBox(data);
                           //window.drawNnBox(data);
                           self.nnJpeg(data);
                           console.log("Popup", JSON.stringify(data));
                           var today = new Date();
                           let message = "\n"+today.toISOString()+"\n";
                           message += "############################\n";
                           message += JSON.stringify(data, null, 2);
                          //self.$messageManger.openPopup(JSON.stringify(data));
                          self.logText += message;
                          self.$messageManger.show("Получен ответ нейросети!", 'success', data);

                        } else {
                          self.$messageManger.show("Ошибка получения ответа нейросети!", 'error');
                          console.log(errorMessage);
                        }
                      },
                      self.nnurl
                    );
                    // console.log("imgData len", imgData.length);
                    // var link = document.createElement('a');
                    // link.download = 'my-image-name.jpg';
                    // link.href = dataUrl;
                    // link.click();
                })
                .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                });
            }
        }
    },
    mounted(){
      this.dataService = new DataService();
      this.browserManager = new BrowserManager();
      this.storageService = new StorageService();
      this.nnurl = this.storageService.getNeuralNetAddress();
      this.$services.eventAggregator.on("mouseneuralclick", e => this.onNeuralMouseRequest(e));
      this.getHelpersList();
    }
};
</script>

<style>

.neural-net-box {
    padding: 0.6em 1em;
    border: 2px solid black;
    background-color: #6eceb1a2;
    position: absolute;
    border-radius: 0.6em;
    top: 0;
    left: 50%;
    margin-top: 0.4em;
    transform: translate(-50%, 0);
    cursor: auto;
    z-index: 1001;
}
.neural-net-log {
    padding: 0.3em 0.1em;
    border: 2px solid black;
    background-color: #6eceb1;
    position: absolute;
    border-radius: 0.6em;
    top: 0;
    left: 0;
    margin-top: 0.4em;
    cursor: initial;
    max-height: calc(100vh - 5rem);
    width: 100%;
    overflow: auto;
    z-index: 1002;
    font-size: 150%;
}
.neural-net-box input {
  opacity: 0.7;
}
.neural-net-box input:focus {
  opacity: 1;
}
.markup-buttons {
  justify-content: flex-end;
  display: flex; 
  margin-top: 0.6rem; 
}
.managing-line {
  display: flex;
  margin-left: 1em;
  margin-top: 0.2em;
}
.loading-line {
  width: 20rem; 
  margin-left: 1em;
  margin-top: 0.2em;
  display: flex;
  align-items: center;
}
</style>