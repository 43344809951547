export default class SendInfo {
    constructor() {
        this.classList = {
            'badge-info': true,
            'badge-danger': false,
            'badge-success': false,
            'd-none': true
        };
        this.status = "";
        this.busy = false;
        this.loading = false;
    }
    wait() {
        this.loading = true;
        this.classList['d-none'] = false;
        this.classList['badge-info'] = true;
        this.classList['badge-danger'] = false;
        this.classList['badge-success'] = false;
        this.status = "выполняю...";
    }
    done(success) {
        this.status = success ? "ок" : "ошибка!";
        this.classList['badge-info'] = false;
        this.classList['badge-danger'] = !success;
        this.classList['badge-success'] = success;
        this.loading = false;
    }
}