<template>
    <div class="main-div">
        <NavBar v-if="showTopAndBottom & login " :currentUser="currentUser" @exit="exit" />
        <div v-if="login" class="router-div">
            <router-view />
        </div>
        <Login v-if="!login" @get-current-user="getCurrentUser()" />
        <Footer v-if="showTopAndBottom" />
    </div>
</template>
<script>
    import Login from "./new_components/Login"
    import NavBar from "./new_components/NavBar.vue"
    import Footer from "./new_components/Footer.vue";
    import BackEndService from "./services/BackEndService";
    export default {
        data() {
            return {
                backEndService: {},
                currentPath: window.location.hash,
                currentUser: {},
                login: false,
            };
        },
        computed: {
            showTopAndBottom() {
                if (this.$route.path.startsWith("/view")) {
                    return false;
                }
                if (this.$route.path.startsWith("/mission")) {
                    return false;
                }
                return true;
            }
        },
        methods: {
            async getCurrentUser() {
                let req = await this.backEndService.getCurrentUser();
                if (req.data.id === 0) {
                    return;
                }
                this.currentUser = req.data;
                window.appstate.serverUserRole = this.currentUser.role;
                this.login = true;
            },
            exit() {
                this.currentUser = {}
                this.login = false;
            }
        },
        components: {
            NavBar,
            Footer,
            Login,
        },
        mounted() {
            this.backEndService = new BackEndService();
            window.addEventListener('hashchange', () => {
                this.currentPath = window.location.hash
            });
            this.getCurrentUser();
        }
    }
</script>
<style lang="css">
    @import "./assets/categoryAndTree.css";
    @import "./assets/styles/site.css";

    .flex-non-shrink {
        flex-shrink: 0;
    }

    .flex-grow {
        flex-grow: 1;
    }

    .font-settings {
        line-height: 1.5;
    }

    .flex-header {
        display: flex;
        margin: 0;
        align-items: center;
        margin: 0 0.4rem;
    }

    .mx-1 {
        margin-left: 1em;
        margin-right: 1em;
    }

    .body {
        background-color: blueviolet;
        overflow-y: hidden;
        overflow-x: hidden;
    }

    .tool-buttons {
        list-style: none;
        padding-left: 0;
        display: inline-flex;
        margin: 0;
    }

        .tool-buttons li {
            list-style: none;
        }

    .tool-button {
        cursor: pointer;
        color: #fff;
        padding: 0.25em 0.5em;
        border-radius: 0.2rem;
        display: inline-block;
        background-color: #6c757d;
        border: 1px solid transparent;
        margin-left: 0.5em;
        text-align: center;
        vertical-align: middle;
        white-space: nowrap;
    }

    .main-button {
        background-color: #1b6ec2;
        border-color: #1861ac;
    }

    .tool-button:disabled {
        background-color: #ccc;
    }

    .tool-active {
        background-color: #28a745;
    }

    .flex-grower {
        flex: 1 1 0;
    }

    .flex-center {
        flex: 0 1 0;
    }

    .main-div {
        display: flex;
        flex-flow: column;
        height: 100vh;
    }

    .router-div {
        overflow: auto;
        flex-grow: 1;
    }

    .d-flex-container {
        display: flex !important;
    }

    .to-right {
        margin-left: auto;
    }
</style>
