import Task from "./Task.js"

export default class TaskGrop {
    baseLayers;
    classCounter;
    classSource;
    classes;
    dataSet;
    dataSetId;
    dataSets;
    description;
    divisionStep;
    divisionType;
    id;
    imageCount;
    imagePath;
    isAdmin;
    isCancelled;
    layer;
    layerId;
    name;
    project;
    projectGroups;
    projectId;
    refLayerId;
    tasks;
    time;
    timeText;
    userId;
    users;
    constructor(taskGroup) {
        this.baseLayers = taskGroup.baseLayers;
        this.classCounter = taskGroup.classCounter;
        this.classSource = taskGroup.classSource;
        this.classes = taskGroup.classes;
        this.dataSet = taskGroup.dataSet;
        this.dataSetId = taskGroup.dataSetId;
        this.dataSets = taskGroup.dataSets;
        this.description = taskGroup.description;
        this.divisionStep = taskGroup.divisionStep;
        this.divisionType = taskGroup.divisionType;
        this.id = taskGroup.id;
        this.imageCount = taskGroup.imageCount;
        this.imagePath = taskGroup.imagePath;
        this.isAdmin = taskGroup.isAdmin;
        this.isCancelled = taskGroup.isCancelled;
        this.layer = taskGroup.layer;
        this.layerId = taskGroup.layerId;
        this.name = taskGroup.name;
        this.project = taskGroup.project;
        this.projectGroups = taskGroup.projectGroups;
        this.projectId = taskGroup.projectId;
        this.refLayerId = taskGroup.refLayerId;
        this.tasks = this.createTasks(taskGroup.tasks);
        this.time = taskGroup.time;
        this.timeText = taskGroup.timeText;
        this.userId = taskGroup.userId;
        this.users = taskGroup.users;
    }
    createTasks(tasks) {
        var taskList = [];
        for (var tItem of tasks) {
            var task = new Task(tItem);
            taskList.push(task);
        }
        return taskList;
    }
    deleteTask(task) {
        console.log("deleteTask", task.id);
        this.tasks = this.tasks.filter(t => t.id != task.id);
    }
    addTask(task) {
        var newTask = new Task(task);
        newTask.done = false;
        newTask.progress = 0;
        newTask.progressText = "0,0%";
        newTask.user =  {
            id: 0,
            login: null,
            name: null,
            role: null,
        };
        console.log("newTask", newTask);
        this.tasks.push(newTask);
    }
    updateUser(newUserChanges) {
        var task = this.tasks.find(t => t.id == newUserChanges.Id);
        task.user.id = newUserChanges.UserId;
        console.log(this.tasks);
    }
}
