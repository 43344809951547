import ShapeParam from "./ShapeParam";
import ShapesMath from "../../services/mathHelpers/shapesMath";
import {geoDistance} from "../../services/mathHelpers/geometryHelper";
export default class OrientedBoxShapes extends ShapeParam {
    type = "obox";

    angleCornerIndex = 5;
    centerCornerIndex = 4;
    centralLine = [];

    constructor(mapManager, options) {
        super(mapManager, options);
        this.points = [];
        this.size = 5;
        this.shape = window.L.polygon(this.points, { shape: "obox", id: 0, color: this.color, opacity: options.srokeOpacity, fillOpacity: options.fillOpacity });
        this.beak = window.L.polyline([[0, 0]], { color: this.color, opacity: options.srokeOpacity, fillOpacity: options.fillOpacity });
    }
    draw(points) {
        let geo = this.toGeo(points);
        // this.shape.setLatLngs(geo);
        this.updatePoints(geo);
    }
    add() {
        this.shape.addTo(this.map);
        this.beak.addTo(this.map);
        
        let points = this.getGeoPoints();
        this.updateBeakPoints(points);
    }
    remove() {
        this.shape.removeFrom(this.map);
        this.beak.removeFrom(this.map);
    }
    changeColor(color) {
        super.changeColor(color);
        this.changeBeakColor(color);
    }
    changeBeakColor(color) {
        this.beak.removeFrom(this.map);
        this.beak.options.color = color;
        this.beak.addTo(this.map);
    }
    changeOpacity(fillOpacity, strokeOpacity){
        this.shape.removeFrom(this.map);
        this.beak.removeFrom(this.map);
        this.shape.options.opacity = strokeOpacity;
        this.shape.options.fillOpacity = fillOpacity;
        this.beak.options.opacity = strokeOpacity;
        this.beak.options.fillOpacity = fillOpacity;
        this.shape.addTo(this.map);
        this.beak.addTo(this.map);
    }
    getCenter() {
        var geo = this.shape.getLatLngs();
        if (geo.length === 1) {
            geo = geo[0];
        }
        let avgLat = 0.0;
        let avgLng = 0.0;
        let counter = 0;
        for (let v of geo) {
            avgLat += v.lat;
            avgLng += v.lng;
            counter++;
        }
        if (counter === 0) {
            return null;
        }
        return { lat: avgLat / counter, lng: avgLng / counter }
    }
    drawCorners() {
        super.drawCorners();
        //const r = this.getR();
        const points = this.getGeoPoints();
        if(points.length >= 4){
            this.moveRotationCorner(points, true);
            this.updateBeakPoints(points);
        }
    }
    move(latlng) {
        let lat = latlng.lat;
        let lon = latlng.lng;
        if (this.selectedCorner) {
            let points = this.getGeoPoints();
            if (points.length === 4) {
                if (this.selectedCorner.options.index === this.angleCornerIndex) {
                    this.rotate(points, lat, lon);
                } else
                    if (this.selectedCorner.options.index === this.centerCornerIndex) {
                        this.translate(points, lat, lon);
                        
                    }
                    else if (this.selectedCorner.options.index === this.angleCornerIndex) {
                        this.moveDirectionCorner(false, latlng);
                    }
                    else {
                        this.moveCorner(this.selectedCorner.options.index, points, lat, lon);
                        // this.moveCorner(this.selectedCorner.options.index, points, lat, lon);
                    }
            }
            else {
                this.selectedCorner.setLatLng({ lat: lat, lng: lon });
                points[this.selectedCorner.options.index] = { lat: lat, lng: lon };

                //this.shape.setLatLngs(points);
                this.updatePoints(points);
                this.inCreation = true;
            }
        }
    }
    getAngle(x, y) {
        let phi = 0.0;
        if (x > 0) {
            phi = Math.atan(y / x);
        }
        else if (x < 0 && y >= 0) {
            phi = Math.atan(y / x) + Math.PI;
        }
        else if (x < 0 && y < 0) {
            phi = Math.atan(y / x) - Math.PI;
        }
        else if (x == 0 && y > 0) {
            phi = Math.PI / 2;
        }
        else if (x == 0 && y < 0) {
            phi = -Math.PI / 2;
        }

        return phi;
    }
    getRotatedAngle(p1, p2) {
        const x = p1.lat - p2.lat;
        const y = p1.lng - p2.lng;
        const angle = this.getAngle(x, y);
        return angle;
    }
    rotate(points, lat, lon) {
        const center = this.getCenter();
        const rotationCircleGeo = this.corners[this.angleCornerIndex].getLatLng();

        const currentAngle = this.getRotatedAngle({ lat: lat, lng: lon }, center);
        const initAngle = this.getRotatedAngle(rotationCircleGeo, center);

        const angleDelta = currentAngle - initAngle;

        let newPoints = this.getGeoPoints().map(p => ShapesMath.RotatePoint({ x: p.lat, y: p.lng }, { x: center.lat, y: center.lng }, angleDelta));

        let geoPoints = newPoints.map(p => {
            return { lat: p.x, lng: p.y }
        });

        //this.shape.setLatLngs([geoPoints]);
        this.updatePoints(geoPoints);
        let ind = 0;
        for (let v of geoPoints) {
            this.corners[ind].setLatLng({ lat: v.lat, lng: v.lng });
            ind++;
        }
        //if (geoPoints.length === this.angleCornerIndex) 
        {
            let lat3, lon3;
            [lat3, lon3] = this.getRotaionCirclePosition(geoPoints);
            this.corners[this.angleCornerIndex].setLatLng({ lat: lat3, lng: lon3 });
        }
    }
    getRotaionCirclePosition(points) {
        const delta = 2*this.getR();
        
        let p1 = {x: points[2].lat, y: points[2].lng};
        let p2 = {x: 0.5*(points[1].lat + points[2].lat), y: 0.5*(points[1].lng + points[2].lng)};
        let pp1 = ShapesMath.GetRotatedPointOnSegment(p2, p1, delta, 90);
        let pp1Indicator = ShapesMath.GetPointOnLine(p2, pp1, 0.1*delta);
        var isInPoly = ShapesMath.IsInPoly2(points.map(p=>{
            return {x:p.lat, y:p.lng}
        }), pp1Indicator);

        if(isInPoly){
            p1 = {x: points[1].lat, y: points[1].lng};
            p2 = {x: 0.5*(points[1].lat + points[2].lat), y: 0.5*(points[1].lng + points[2].lng)};
            pp1 = ShapesMath.GetRotatedPointOnSegment(p2, p1, delta, 90);
        }

        return [pp1.x, pp1.y];
    }
    moveCorner(index, points, lat, lon) {
        const len = points.length;
        const i0 = (index - 2 + len) % len;
        const i1 = (index - 1 + len) % len;
        const i3 = (index + 1 + len) % len;
        const geoPoint = {lat:lat, lng:lon};
        if(geoDistance(points[i1], geoPoint) < 2*this.minSize()){
            return;
        }

        let tLat, tLon;

        if (this.inCreation) {
            [tLat, tLon] = this.getPerpendicularPoint(this.centralLine[0], this.centralLine[1], lat, lon);
            const mousePoint = { x: lat, y: lon };
            const lineP1 = { x: this.centralLine[0].lat, y: this.centralLine[0].lng };
            const lineP2 = { x: this.centralLine[1].lat, y: this.centralLine[1].lng };
            const perpendPoint = ShapesMath.DistancePointLine(mousePoint, lineP1, lineP2);
            lat = lat - perpendPoint.intersection.x + this.centralLine[1].lat
            lon = lon - perpendPoint.intersection.y + this.centralLine[1].lng
            // let lat2 = lat - this.centralLine[0].lat + this.centralLine[1].lat;
            // let lon2 = lon - this.centralLine[1].lng + this.centralLine[1].lng;

            points[2] = { lat: lat, lng: lon };
            this.corners[2].setLatLng({ lat: lat, lng: lon });

            const oppositePoint = ShapesMath.RotatePointDegrees({ x: lat, y: lon }, lineP2, 180);
            points[1] = { lat: oppositePoint.x, lng: oppositePoint.y };
            this.corners[1].setLatLng({ lat: oppositePoint.x, lng: oppositePoint.y });

            tLat = oppositePoint.x - lineP2.x + lineP1.x;
            tLon = oppositePoint.y - lineP2.y + lineP1.y;

            points[0] = { lat: tLat, lng: tLon };
            this.corners[0].setLatLng({ lat: tLat, lng: tLon });

            tLat = lat - lineP2.x + lineP1.x;
            tLon = lon - lineP2.y + lineP1.y;

            points[3] = { lat: tLat, lng: tLon };
            this.corners[3].setLatLng({ lat: tLat, lng: tLon });

            this.moveRotationCorner(points, false);
            this.moveCenterCorner(false);
            this.moveDirectionCorner(false);



            // this.shape.setLatLngs(points);
            this.updatePoints(points);
            return;
        }


        [tLat, tLon] = this.getPerpendicularPoint(points[i0], points[i1], lat, lon);
        points[i1] = { lat: tLat, lng: tLon };
        this.corners[i1].setLatLng({ lat: tLat, lng: tLon });

        [tLat, tLon] = this.getPerpendicularPoint(points[i0], points[i3], lat, lon);
        points[i3] = { lat: tLat, lng: tLon };
        this.corners[i3].setLatLng({ lat: tLat, lng: tLon });

        this.corners[index].setLatLng({ lat: lat, lng: lon });
        points[index] = { lat: lat, lng: lon };

        this.moveRotationCorner(points, false);
        this.moveCenterCorner(false);
        this.moveDirectionCorner(false);
        // this.shape.setLatLngs(points);
        this.updatePoints(points);
    }
    getPerpendicularPoint(geo1, geo2, lat, lon) {
        const mousePoint = { x: lat, y: lon };
        const lineP1 = { x: geo1.lat, y: geo1.lng };
        const lineP2 = { x: geo2.lat, y: geo2.lng };
        const perpendPoint = ShapesMath.DistancePointLine(mousePoint, lineP1, lineP2);
        return [perpendPoint.intersection.x, perpendPoint.intersection.y]
    }
    translate(points, lat, lon) {
        super.translate(points, lat, lon);
        this.moveRotationCorner(points, false);
        this.updateBeakPoints(points);
    }
    moveRotationCorner(points, shouldAdd) {
        let lat3, lng3;
        [lat3, lng3] = this.getRotaionCirclePosition(points);
        let R = this.getCircleR();
        if (shouldAdd) {
            this.addCorner({ lat: lat3, lng: lng3 }, R, "purple", this.angleCornerIndex);
        }
        else {
            this.corners[this.angleCornerIndex].setLatLng({ lat: lat3, lng: lng3 });
        }
    }
    moveDirectionCorner(shouldAdd, latlng) {

        if (this.orientationIndex < 0) {
            return;
        }
        this.orientation = this.getDirectionShape();
        if (!this.orientation) {
            return;
        }
        if (!latlng) {
            const points = this.orientation.getPoints();
            latlng = { lat: points[1].lat, lng: points[1].lng };
        }
        let lat3 = latlng.lat;
        let lng3 = latlng.lng;
        if (shouldAdd) {
            this.addCorner(this.orientationIndex, lat3, lng3, this.getR(), "purple", "move-cursor");
        }
        else {

            const headPoint = this.orientation.moveHead(lat3, lng3);
            this.corners[this.orientationIndex].setLatLng(headPoint);

        }
        //this.orientation.moveHead(lat3, lon3);
    }
    getDirectionShape() {
        const orient = this.shape.options.orientation;
        if (!orient || orient.angle === null) {
            return null;
        }
        return null;
    }
    addPoint = () => {
        const delta = this.getCircleR();
        let result = false;
        let points = this.getGeoPoints();
        if(points.length == 4){
            this.inCreation = false;
            return true;
        }
        let p1 = {x: points[0].lat, y: points[0].lng};
        let p2 = {x: points[1].lat, y: points[1].lng};
        
        let pp1 = ShapesMath.GetRotatedPointOnSegment(p1, p2, delta, -90);
        let pp2 = ShapesMath.GetRotatedPointOnSegment(p2, p1, delta, 90);

        points.push({lat:pp2.x, lng: pp2.y});
        points.push({lat:pp1.x, lng: pp1.y});

        // this.shape.setLatLngs(points);
        this.updatePoints(points);

        this.previousPoints = points.map(p=> {
            return { lat: p.lat, lng: p.lng}
        });

        
        // let lat3 = points.map(p=>p.lat).reduce((acc,current)=>acc+current)/4.0;
        // let lon3 = points.map(p=>p.lng).reduce((acc,current)=>acc+current)/4.0;
        this.centralLine.push({lat:points[0].lat, lng: points[0].lng});
        this.centralLine.push({lat:points[1].lat, lng: points[1].lng});
        //this.moveRotationCorner(points, false);
        //this.moveCenterCorner(false);
        //this.moveDirectionCorner(false);
        // this.shape.setLatLngs(points);
        this.updatePoints(points);
        this.drawCorners();
        this.selectCorner(2);
        return result;
    }
    updatePoints(points){
        this.shape.setLatLngs(points);
        this.updateBeakPoints(points);
    }
    updateBeakPoints(points){
        if(points.length < 3){
            return;
        }
        let lat3, lng3;
        [lat3, lng3] = this.getRotaionCirclePosition(points);
        const beakPoints = [
            {lat:points[2].lat, lng: points[2].lng},
            {lat:lat3, lng: lng3},
            {lat:points[1].lat, lng: points[1].lng},
        ];
        for (const p of beakPoints) {
            if(isNaN(p.lat) || isNaN(p.lng)){
                console.log("Beak point error", points);
                return;
            }
        }
        this.beak.setLatLngs(beakPoints);
        //console.log("Beak points", beakPoints);
    }
    getArea() {
        var geo = this.shape.getLatLngs();
        if (geo.length === 1) {
            geo = geo[0];
        }
        if (geo.length <= 2) {
            return 0.0;
        }
        let area = 0.0;
        let i1 = 1;
        for (let i = 0; i < geo.length; i++) {
            i1 = (i + 1) % geo.length;
            area += geo[i].lat * geo[i1].lng - geo[i1].lat * geo[i].lng;
        }
        area *= 0.5;
        return area;
    }
    onZoom(){
        let points = this.getGeoPoints();
        this.updateBeakPoints(points);
    }
}