<template>
  <h3>Список релизных слоёв</h3>
  <table>
    <tbody>
      <tr
        class="tr-layer"
        v-for="taggedLayer of imageList.taggedLayers"
        :key="taggedLayer.id"
      >
        <dataset-layer :taggedLayer="taggedLayer" :imageList="imageList" @get-image-list="getImageList"/>
      </tr>
    </tbody>
  </table>
</template>

<script>
import DatasetLayer from "./DatasetLayer.vue";
export default {
    components : {
        DatasetLayer
    },
    props : ["imageList"],
    emits: ["get-image-list"],
    methods : {
      getImageList(){
          this.$emit('get-image-list');
        }
    }
    
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  /* background-color:lightgrey; */
}
/* td {
        border-top: solid grey 1px;
        padding:1em;
    } */
tr {
  background: rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid #ddd;
  vertical-align: middle;
}

tr:nth-child(even) {
  background: white;
  border-bottom: 1px solid #ddd;
}
</style>