<template>
  <div class="project-datasets">
    <div v-for="dataSet in active" :key="dataSet" class="dataset-card  each-card flexbox">
      <div class="image-count">
          <img src="../../assets/images/images.svg" />
          <p class="small">{{ dataSet.imageCount }}</p>
        </div>
        <div class="name-and-date">
          <div class="card-title">
            <router-link :to="'/PhotoAlbum/' + dataSet.id">
              {{ dataSet.title }}
            </router-link>
          </div>
          <div class="dataset-date">
            <span class="muted">создан: </span>
            <span class="ml-2">
              {{ dataSet.createdText }}
            </span>
          </div>
        </div>
    </div>
  </div>
  <h4>
    Архивные фотоальбомы
    <button @click="showArchived = !showArchived">
      {{ showArchived ? "-" : "+" }}
    </button>
  </h4>
  <div v-if="showArchived">
    <p v-if="archived.length === 0">Нет архивных фотоальбомов</p>
    <div v-for="dataSet in archived" :key="dataSet">
      <div v-if="dataSet.status == 'Archived'">
        <router-link tag="span" :to="'/PhotoAlbum/' + dataSet.id">{{
          dataSet.title
        }}</router-link>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showArchived: false,
    };
  },
  computed: {
    archived() {
      return this.datasets.filter((d) => d.status == "Archived");
    },
    active() {
      return this.datasets.filter((d) => d.status != "Archived");
    },
  },
  props: ["datasets"],
};
</script>

<style scoped>
.each-card {
 min-width: 250px;
 padding: 1.3rem;
}
.image-count {
  margin-right: 0.4em;
  text-align: center;
}
.project-datasets {
  display: flex;
  flex-wrap: wrap;
}
.name-and-date{
  flex-grow: 1;
}
.dataset-date {
  text-align: right;
  margin-left: auto;
  text-shadow: initial;
  font-weight: bold;
  color: black;
  font-size: 75%;
}
.dataset-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: 1rem;
  transition: 0.3s;
}
.dataset-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.card-title {
  background-color: transparent;
  border-radius: 5px;
  text-shadow: 1px 1px 5px black;
  font-weight: bold;
  font-size: 140%;
  color: white;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dataset-card a {
  text-decoration: none;
  color: white;
}
.dataset-card a:hover {
  text-decoration: underline;
}
</style>