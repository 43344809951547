import BrowserManager from "../../services/browserManager";
export default class MapObjectManager {
    constructor(mapManger, inputHelper, eventAggregator) {
        this.mapManager = mapManger;
        this.inputHelper = inputHelper;
        this.eventAggregator = eventAggregator;
        this.mapManager.on("fitimage", () => this.fitImage());
        this.mapManager.on("mapready", () => this.onMapReady());
        this.mapManager.on("flyto", () => this.flyTo());
        this.browserManager = new BrowserManager();
    }
    map;
    initCenter = [51.620768, 39.148457];
    initZoom = 14;
    init(datasetType) {
        const zoomSnap = 1;
        const mapOptions = {
            zoomSnap: zoomSnap,
            minZoom: -10,
            maxZoom: 500
        };
        if (datasetType !== "MapSlices") {
            mapOptions.crs = window.L.CRS.Simple;
        }
        const map = window.L.map('mapid', mapOptions);
        const cornerPane = map.createPane("corners");
        cornerPane.style.zIndex = '705';
        this.map = map;
        if (datasetType === "MapSlices") {
            let googleLayer = window.L.tileLayer('https://mt1.google.com/vt/lyrs=y&z={z}&x={x}&y={y}');
            googleLayer.addTo(map);
            map.setView(this.initCenter, this.initZoom);
        }
        else {
            const p = {x: 1920, y: 1080};
            const arr = this.mapManager.toLatLngArray(p);
            let bounds = [[0, 0], arr];
            map.fitBounds(bounds);
        }

        return map;
    }
    initMap(datasetType) {
        console.log("Ftttttds", datasetType);
        //var zoomSnap = isWholeImage ? 0.125 : 1;
        var zoomSnap = 1;
        const mapOptions = {
            zoomSnap: zoomSnap,
            minZoom: -10,
            maxZoom: 500
        }
        if (datasetType !== "MapSlices") {
            mapOptions.crs = window.L.CRS.Simple;
        }
        window.services.map = window.L.map('mapid', mapOptions);
        this.map = window.services.map;
        window.services.mapManager.map = this.map;
        //window.map = this.map;
        if (datasetType === "MapSlices") {
            window.L.tileLayer('https://mt1.google.com/vt/lyrs=y&z={z}&x={x}&y={y}').addTo(this.map);
        }
        if (datasetType !== "MapSlices") {
            const p = {x: 1920, y: 1080};
            const arr = this.mapManager.toLatLngArray(p);
            var bounds = [[0, 0], [arr]];
            this.map.fitBounds(bounds);
            console.log("Fitting bounds");
        }
        this.map.setView(this.initCenter, this.initZoom);

        var cornerPane = this.map.createPane("corners");
        cornerPane.style.zIndex = '705';
        const labelState = window.services.storageManager.getLabelState();
        if (labelState) {
            window.services.appState.showObjectInfo = labelState.categoryOn;
            window.services.appState.showComments = labelState.commentOn;
        }
        this.initEvents();
        document.addEventListener("keydown", window.services.userInputManager.onKeyDown);
        document.addEventListener("keypress", window.services.userInputManager.onKeyPressed);
        window.services.markupLayers = [];
        window.updateMarkupManager(window.services.map, []);
        // window.services.markupManager.map = this.map;
        // window.services.markupManager.layers = window.services.markupLayers;
        window.services.userInputManager.finishEditing = window.services.markupManager.finishEditing;
    }
    initEvents() {
        const self = this;
        this.map.on('zoom', () => self.onZoom());
        this.map.on('drag', () => self.map.fire('moveend'));
        this.map.on('move', e => self.onMove(e));
        this.map.on('mousemove', (e) => self.onMouseMove(e));
        this.map.on('contextmenu', (e) => self.onContextMenu(e), false);
        this.map.on('mousedown', (e) => self.onMapDown(e));
        this.map.on('mouseup', (e) => self.onMapUp(e));
    }
    onZoom(e) {
        this.mapManager.fire("zoom", e);
        //window.onZoom();
        console.log("MapObjectManager onZoom is not implemented!");
    }
    onMouseMove(e) {
        // var lat = e.latlng.lat;
        // var lon = e.latlng.lng;
        this.mapManager.fire("moved", e.latlng);
        //window.onMouseMove(e);
    }
    onMapDown(e) {
        this.inputHelper.mouseDownPosition = { x: e.originalEvent.clientX, y: e.originalEvent.clientY };
        this.mapManager.fire("contextmenu", { open: false, e: null });
        this.mapManager.fire("mousedown", e);
        console.log("MapObjectManager.onMapDown not implemented args:", e);
        //window.onMapDown(e);
    }
    onMapUp(e) {
        console.log("MapObjectManager.onMapUp not implemented args:", e);
        //window.onMapUp(e);
    }
    onMove() {
        // let bounds = this.map.getBounds();
        // let zoom = this.map.getZoom();
        // let lat = (bounds._northEast.lat + bounds._southWest.lat) / 2;
        // let lng = (bounds._northEast.lng + bounds._southWest.lng) / 2;
        // window.services.storageManager.saveMapState(lat, lng, zoom);
        this.map.fire('moveend');
        // window.services.mapManager.moveShapes();
    }
    onContextMenu(e) {
        e.originalEvent.preventDefault();
        if (this.inputHelper.mouseMoved(e.originalEvent)) {
            return;
        }
        if (e.originalEvent.ctrlKey) {
            this.mapManager.fire("contextmenu", { open: true, e: e });
            //window.vueContext.actions.onToggleContextMenu(true, e);
        }
    }
    fitImage() {
        const topleft = this.mapManager.toLatLng({ x: 0, y: 0 });
        const bottomright = this.mapManager.toLatLng({ x: 1920, y: 1080 });
        const bounds = [
            [topleft.lat, topleft.lng],
            [bottomright.lat, bottomright.lng]
        ];
        console.log("Setting fit image", bounds);

        this.mapManager.map.fitBounds(bounds, { padding: [5, 5] });
    }
    onMapReady(){
        var params = this.browserManager.getUrlParams();
        if(this.flyToObject(params)){
            return;
          }
          this.flyToGeoPoint(params.lat, params.lng, params.zoom);
    }
    flyToObject(params){
        if(!isNaN(params.x) && !isNaN(params.y)){
          this.flyMapTo({x:params.x,y:params.y}, params.zoom, true);
          return true;
        }      
        return false;
    }
    flyToGeoPoint(lat, lng, zoom){
        if(!isNaN(lat) && !isNaN(lng)){
          if(isNaN(zoom)){
            zoom = null;
          }
          this.flyMapToGeo(lat, lng, zoom);
          return true;
        }
        return false;
    }
    
    flyMapTo(point, zoom, zoomIn){
        console.log("fly to ", point, "Zoom", zoom);
        let geo = this.mapManager.toLatLng(point);
        const geoArr = [geo.lat,geo.lng];
        if(!isNaN(zoom)){
            this.mapManager.map.flyTo(geoArr, zoom);
        }
        else if(zoomIn){
            this.mapManager.map.flyTo(geoArr, window.appstate.maxZoom);
        }
        else {
            this.mapManager.map.flyTo(geoArr);
        }
        this.eventAggregator.fire("showmarker");
    }
    flyMapToGeo(lat,lng,zoom){
        if(zoom){
            this.mapManager.map.flyTo([lat,lng], zoom);
        }
        else {
            this.mapManager.map.flyTo([lat,lng]);
        }   
    }
}