import BrushObject from "../../models/objects/BrushObject";
import NoteObject from "../../models/objects/NoteObject";
import BrowserManager from "../../services/browserManager";
export default class MapDrawingsManager {
    drawings = [];
    brushInfo = { color: "#00ff00", weight: 5 };
    factories = {
        "brush": mapManager => new BrushObject(mapManager),
        "eraser": () => { },
        "note": mapManager => new NoteObject(mapManager)
    }
    selected = null;
    constructor(mapManger, inputHelper) {
        this.mapManager = mapManger;
        this.inputHelper = inputHelper;
        this.browserManager = new BrowserManager();
        this.mapManager.on("zoom", z => this.onZoom(z));
    }
    onZoom(zoom) {
        for (var dr of this.drawings) {
            dr.onZoom(zoom);
        }
    }
    getNotesToSave() {
        const center = this.mapManager.getMapState(); 
        const flatCenter = this.mapManager.toFlat({lat: center.centerLat, lng: center.centerLng}); 
        var drawData = {
            notes: [],
            drawings: [],
            zoom: this.mapManager.map.getZoom(),
            mapPointNotes: {
                x: flatCenter.x,
                y: flatCenter.y
            }
        };
        for (var dr of this.drawings) {
            if (dr.type == "note") {
                const savingObject = {
                    points: dr.getPoints(),
                    text: dr.shape.value,
                }
                drawData.notes.push(savingObject);
            }
            else {
                const savingObject = {
                    points: dr.shape.points,
                    color: dr.shape.color,
                    weight: parseInt(dr.shape.shape.options.weight),
                }
                drawData.drawings.push(savingObject);
            }
        }
        return drawData;
    }
    onMouseDown(e, tool) {
        const options = {
            color: this.brushInfo.color,
            drawing: this.brushInfo,
        }
        const newObject = this.getNewObject(tool);
        const point = this.mapManager.toFlat(e.latlng);
        newObject.createNew(options, point);
        this.mapManager.fire("selected", { obj: newObject, category: "cat", layer: "layer" });
        this.drawings.push(newObject);
        this.selected = newObject;
        return true;
    }
    getNewObject(type) {
        let factory = this.factories[type];
        if (!factory) {
            console.log("Unknown factory", type);
            factory = this.factories["poly"];
        }
        return factory(this.mapManager);
    }
    setNotes(data) {
    if (data.status == "Ok") {
        for (var drawing of data.notes.drawings) {
            const newDR = this.getNewObject("brush");
            newDR.createShape(drawing);
            this.drawings.push(newDR);
        }
        for (var note of data.notes.notes) {
            const newNote = this.getNewObject("note");
            newNote.createShape(note)
            this.drawings.push(newNote);
        }
        let arr = this.mapManager.toLatLngArray(data.notes.mapPointNotes);
        this.mapManager.map.flyTo(arr, data.notes.zoom);
    }
}
move(e) {
    this.selected.move(e);
}
toggleDrawings(drawingModeOn) {
    if (drawingModeOn) {
        for (const dr of this.drawings) {
            dr.add();
            dr.onZoom(this.mapManager.map.getZoom());
        }
    }
    else {
        for (const dr of this.drawings) {
            dr.deselect();
            dr.remove();
            this.selected = null;
        }
    }
}
}