export default class ImageForTask {
    id;
    name;
    taskId;
    isHighlighted;
    constructor(imgData) {
        this.id = imgData.id;
        this.name = imgData.name;
        this.taskId =0;
        this.isHighlighted = false;
    }
    togleHighlighted() {
        this.isHighlighted = !this.isHighlighted;
    }
    setTaskId(id) {
        this.taskId = id;
    }



}
