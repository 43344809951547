<template>
    <div style="text-align:center" v-if="isLoading">
        <h2>Загрузка</h2>
        <br />
        <IsLoadingItem />
    </div>
    <div v-if="!isLoading">
        <h2 style="text-align:center" v-if="!isEditDescription">
            <span>
                Группа миссий
            </span>
            <span>
                '{{taskGroup.name}}'
            </span>
            <button class="svg-btn" @click="isEditDescription=!isEditDescription">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z" />
                </svg>
            </button>
        </h2>
        <p v-if="!isEditDescription">
            Описание: {{taskGroup.description}}
        </p>
        <statusElement :statusCode="saveDescriptionStatusCode" />

        <div class="center-text mt-4" v-if="isEditDescription">
            <span>Группа миссий: <input type="text" style="font-size: x-large" v-model="taskGroup.name" /></span>
        </div>
        <div v-if="isEditDescription">

            Описание:
            <textarea class="description-edit" placeholder="введите описание" v-model="taskGroup.description"></textarea>
            <button class="site-button main-button" @click="isEditDescription=!isEditDescription; saveTaskGroup();">Сохранить изменения</button>
            <button class="site-button" @click="isEditDescription=!isEditDescription;">отмена</button>
        </div>
        <div>
            <span class="muted">Прогресс группы</span>
            <progress class="ml-1" style="vertical-align: middle;"
                      :value="groupProgress" max="100">
            </progress> {{groupProgress}}%
        </div>
        <div class="section-tabs">
            <div class="section-tab" :class="{active:toggleTasksImg}" @click="open('tasksImg')"><p>Картинки</p></div>
            <div class="section-tab" :class="{active:toggleTasksUser}" @click="open('tasksUser')"><p>Миссии</p></div>
            <div class="section-tab" :class="{active:toggleClassSource}" @click="open('classSource')"><p>Классы</p></div>
            <div class="section-tab" :class="{active:toggleReferenceLayer}" @click="open('referenceLayer')"><p>Разметка</p></div>
            <div class="section-tab" :class="{active:toggleFinalLayer}" @click="open('finalLayer')"><p>Слои</p></div>
        </div>
        <div class="tab-content">
            <TasksImages v-if="toggleTasksImg" :taskGroup="taskGroup" @reload="reloadTaskGroup"/>  <!--:tasks="taskGroup.tasks" :datasetId="taskGroup.dataSetId" :taskGroupId="taskGroup.id"-->
            <TasksUsers v-if="toggleTasksUser" :tasks="taskGroup.tasks" @update-user="updateUser"/>
            <ClassSource v-if="toggleClassSource" :id="taskGroup.id" />
            <ReferenceLayer v-if="toggleReferenceLayer"  :id="taskGroup.id" :datasetId="taskGroup.dataSetId"/>
            <FinalLayer v-if="toggleFinalLayer" :taskGroup="taskGroup"/>
        </div>

    </div>
</template>

<script>
    import IsLoadingItem from "./IsLoadingItem.vue"
    import BackEndService from "../services/BackEndService.js"
    //import Task from "../helpers/Task.js"
    import TaskGroup from "../helpers/TaskGroup.js"

    import TasksImages from "./tasksGroup-page/TasksImages.vue"
    import TasksUsers from "./tasksGroup-page/TasksUsers.vue"
    import ClassSource from "./tasksGroup-page/ClassSource.vue"
    import ReferenceLayer from "./tasksGroup-page/ReferenceLayer.vue"
    import FinalLayer from "./tasksGroup-page/FinalLayer.vue"

    import statusElement from "./statusElement.vue"

    export default {
        components: {
            IsLoadingItem,
            statusElement,
            TasksImages,
            TasksUsers,
            ClassSource,
            ReferenceLayer,
            FinalLayer
        },
        props: ['id'],
        data() {
            return {
                isLoading: false,
                taskGroup: {},
                groupProgress: 0,
                isEditDescription: false,
                saveDescriptionStatusCode: "",
                //tasksList:[],

                toggleTasksImg: true,
                toggleTasksUser: false,
                toggleClassSource: false,
                toggleReferenceLayer: false,
                toggleFinalLayer: false,
            };
        },

        methods: {
            open(tab) {
                this.toggleTasksImg = tab === 'tasksImg';
                this.toggleTasksUser = tab === 'tasksUser';
                this.toggleClassSource = tab === 'classSource';
                this.toggleReferenceLayer = tab === 'referenceLayer';
                this.toggleFinalLayer = tab === 'finalLayer';
            },
            async saveDescription() {
                this.saveDescriptionStatusCode = "0"
                var rep = await this.saveTaskGroup();
                this.saveDescriptionStatusCode = rep.statusCode;
            },
            async saveTaskGroup() {

                var b = new BackEndService;
                var req = await b.createGroupVue(this.taskGroup);

                console.log("saveGroup", req);
                //this.$router.push('/PhotoAlbum/' + this.datasetId)
                return req;

            },
            async getTaskGroup() {
                this.isLoading = true;
                //this.loading = true;

                var b = new BackEndService;
                let req = await b.getTaskGroup({ id: this.id });

                var taskGroupItem = req.data;
                this.taskGroup = new TaskGroup(taskGroupItem);
                console.log("TaskGroup", this.taskGroup);

                this.getGroupProgress();
                //this.createTask() 
                //await this.getUsers();
                this.isLoading = false;
            },
            //createTask() {
            //    for (var taskItem of this.taskGroup.tasks) {
            //        var task = new Task(taskItem);
            //        this.tasksList.push(task);
            //    }
            //    console.log("this.tasksList", this.tasksList);
            //},
            getGroupProgress() {
                if (this.taskGroup.tasks.length > 0) {
                    var grPr = 0;
                    for (var task of this.taskGroup.tasks) {
                        grPr = grPr + task.progress;
                        //console.log("grPr", grPr);
                    }
                    this.groupProgress = (grPr / this.taskGroup.tasks.length).toFixed(1);
                }
                else {
                    this.groupProgress = 0;
                }
                //console.log("this.groupProgress", this.groupProgress);
            },
            async reloadTaskGroup() {
                console.log("reloadTaskGroup");
                await this.getTaskGroup();
            },
            updateUser(newUserChanges) {
                console.log("newUserChanges", newUserChanges);
                this.taskGroup.updateUser(newUserChanges);
            },
        },
        mounted() {
            this.getTaskGroup();
        },

    }
</script>

<style>
    textarea {
        font-size: large;
        width: calc(100% - 3rem);
        margin: 0 0.4rem;
        min-height: 3em;
        height: max-content;
        resize: none;
    }

    .section-tabs {
        display: flex;
        justify-content: space-around;
    }

        .section-tabs .section-tab.active {
            background-color: white;
        }

        .section-tabs .section-tab {
            background-color: lightgrey;
        }

    .section-tab {
        color: gray;
        flex: 1 0 0;
        padding: 0.2em 1.8em;
        text-align: center;
        border: 1px solid gray;
        border-width: 1px 1px 0 1px;
        border-radius: 0.6rem 0.6rem 0 0;
        cursor: pointer;
        align-items: center;
    }

        .section-tab + .section-tab {
            margin-left: 0.4rem;
        }

        .section-tab.active {
            color: black;
            border-color: black;
            margin-bottom: -1.5px;
        }

    .tab-content {
        border: 1px solid black;
        border-width: 1px 0 0 0;
        padding: 1em;
    }
</style>