export default class HelperLines {
    constructor(map) {
        this.active = false;
        // this.color = "white";
        let newPoints = [[0, 0], [0, 0]];
        this.vline = window.L.polyline(newPoints, { color: 'yellow', weight: 1, dashArray: "10 10", interactive: false });
        this.hline = window.L.polyline(newPoints, { color: 'yellow', weight: 1, dashArray: "10 10", interactive: false });
        this.vbackLine = window.L.polyline(newPoints, { color: 'black', weight: 1, dashArray: "10 10", dashOffset: "10", interactive: false });
        this.hbackLine = window.L.polyline(newPoints, { color: 'black', weight: 1, dashArray: "10 10", dashOffset: "10", interactive: false });
        this.map = map;
    }
    draw(e) {
        if(!this.active){
            this.add();
        }
        const lon = e.latlng.lng;
        const lat = e.latlng.lat;
        const bounds = this.map.getBounds();

        var bottomLat = bounds._southWest.lat;
        var bottomLng = bounds._southWest.lng;
        var topLat = bounds._northEast.lat;
        var topLng = bounds._northEast.lng;

        var vPoints = [];
        vPoints.push({ lat: lat, lon: topLng });
        vPoints.push({ lat: lat, lon: bottomLng });
        this.vline.setLatLngs(vPoints);
        this.vbackLine.setLatLngs(vPoints);

        var hPoints = [];
        hPoints.push({ lat: topLat, lon: lon });
        hPoints.push({ lat: bottomLat, lon: lon });
        this.hline.setLatLngs(hPoints);
        this.hbackLine.setLatLngs(hPoints);
    }
    remove() {
        this.vline.removeFrom(this.map);
        this.hline.removeFrom(this.map);
        this.vbackLine.removeFrom(this.map);
        this.hbackLine.removeFrom(this.map);
        this.active = false;
    }
    add() {
        this.vbackLine.addTo(this.map);
        this.hbackLine.addTo(this.map);
        this.vline.addTo(this.map);
        this.hline.addTo(this.map);
        this.active = true;
    }

}

