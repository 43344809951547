<template>
  <table v-if="closeQuestion" style="width:100%;">
    <tr>
      <td colspan="3">
        <button class="close-button" name="close-button" @click="askQuestion">
          X
        </button>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <button
          name="brush-button"
          class="tool-button note-button"
          v-bind:class="{ 'tool-active': brushActive }"
          @click="selectBrush"
        >
          Кисть
        </button>
      </td>
    </tr>
    <tr>
      <td>
        <span class="note-label">Цвет:</span>
      </td>
      <td colspan="2">
        <input
          type="color"
          class="icp-input"
          v-model="color"
          @click="selectColor"
        />
      </td>
    </tr>
    <tr>
      <td>
        <span class="note-label">Толщ:</span>
      </td>
      <td colspan="2">
        <input
          class="icp-input"
          type="number"
          min="1"
          max="25"
          v-model="weight"
        />
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <button
          name="note-button"
          class="tool-button note-button"
          v-bind:class="{ 'tool-active': notesActive }"
          @click="selectNote"
        >
          Заметки
        </button>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <button
          class="tool-button note-button"
          @click="selectEditor"
          v-bind:class="{ 'tool-active': editorActive }"
        >
          Редактировать
        </button>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <button class="tool-button note-button" @click="saveAndClose">
          Сохранить и выйти
        </button>
      </td>
    </tr>
  </table>
  <table v-if="!closeQuestion" class="confirm-exit">
    <tr>
      <td colspan="2" style="font-weight: bold; width:10em; text-align:center;">
        Закрыть и удалить созданные заметки?
      </td>
    </tr>
    <tr>
      <td>
        <button
          class="tool-button note-button"
          @click="closeNotes"
          style="background-color: red"
        >
          Да
        </button>
      </td>
      <td>
        <button class="tool-button note-button" @click="questionFalse">
          Нет
        </button>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  data() {
    return {
      notesActive: false,
      brushActive: false,
      editorActive: false,
      brushInfo: {color:"#00ffff",weight:5},
      closeQuestion: true,
    };
  },
  computed: {
    color: {
      get() {
        return this.brushInfo.color;
      },
      set(color) {
        if (color === this.color) {
          return;
        }
        this.brushInfo.color = color;
        this.mapDrawingsManager.color = color;
        this.mapDrawingsManager.weight = this.weight;
      },
    },
    weight: {
      get() {
        return this.brushInfo.weight;
      },
      set(weight) {
        if (weight === this.weight) {
          return;
        }
        this.brushInfo.weight = weight;
        this.mapDrawingsManager.color = this.color;
        this.mapDrawingsManager.weight = weight;
      },
    },
  },
  emits: ["close-notes", "save-notes"],
  methods: {
    selectEditor() {
      this.$services.eventAggregator.fire("toolchanged", "edit");
      this.brushActive = false;
      this.notesActive = false;
      this.editorActive = true;
    },
    selectBrush() {
      this.$services.eventAggregator.fire("toolchanged", "brush");
      this.brushActive = true;
      this.notesActive = false;
      this.editorActive = false;
    },
    selectNote() {
      this.$services.eventAggregator.fire("toolchanged", "note");
      this.brushActive = false;
      this.notesActive = true;
      this.editorActive = false;
    },
    selectColor() {},
    saveAndClose() {
      this.closeNotes();
      this.$services.mapManager.fire("savenotes");
    },
    askQuestion() {
      this.closeQuestion = false;
    },
    questionFalse() {
      this.closeQuestion = true;
    },
    closeNotes() {
      this.$services.eventAggregator.fire("toggledrawings", false);
      this.$services.eventAggregator.fire("toolchanged", "edit");
    },
  },
  mounted(){
    this.mapDrawingsManager = this.$services.mapManager.mapDrawingsManager;
    this.brushInfo = this.mapDrawingsManager.brushInfo;
    //window.vueContext.actions.selectEditorAfterNewNote = this.selectEditor;
    this.selectBrush();
  }
};
</script>

<style scoped>

.note-button {
  width: 96%;
}
.note-label {
  margin-left: 0.5em;
}
.notes {
  border-radius: 0.4em;
  white-space: nowrap;
  background-color: white;
  padding: 0.2em 0.4em;
  margin: 0.1em 0.2em;
  display: inline-block;
  font-size: 80%;
}
.icp-input {
  border-radius: 0.2rem;
  border: 1px solid transparent;
  margin-left: 0;
  margin-top: 0;
  background-color: rgb(100, 100, 100);
  width: 100%;
  color: white;
  font-weight: bold;
  text-align: center;
}

table  .tool-button {
  margin-top: 0.4em;
}
.close-button {
  cursor: pointer;
  color: rgb(255, 255, 255);
  padding: 0.25em 0.5em;
  border-radius: 0.2rem;
  display: block;
  background-color: hsl(0, 100%, 61%);
  border: 1px solid transparent;
  margin-left: auto;
  text-align: center;
  white-space: nowrap;
}
.confirm-exit {
  margin-top: 3em; 
  margin-left: auto;
  margin-right: auto;
}
</style>