<template>
  <div
    class="modal-div"
    :class="{ 'modal-hidden': !isShown, 'modal-visible': isShown }"
  >
    <div class="center-screen">
      <p>{{messasge}}</p>
      <div style="text-align: center">
        <button class="dissmis-button" @click="hide">Ок</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isShown: {
      type: Boolean,
      required: true,
    },
    messasge: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isShown_old: false,
    };
  },
  emits: ["close-win"],
  methods: {
    hide() {
      this.$emit("close-win");
      //this.isShown = false;
    },
  },
};
</script>

<style>
.modal-hidden {
  display: none;
}
.model-visible {
  display: block;
}
.modal-div {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(000, 000, 000, 0.7);
  z-index: 1002;
}

.center-screen {
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  padding: 0 2em;
}
.dissmis-button {
  cursor: pointer;
  color: #fff;
  padding: 0.25em 0.5em;
  border-radius: 0.2rem;
  display: inline-block;
  background-color: blue;
  border: 1px solid transparent;
  margin-left: 0.5em;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 1em;
}
</style>