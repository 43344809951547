import ShapeObjectBase from "./ShapeObjectBase";
import CenterShape from "../shapes/CenterShape";
export default class CenterObject extends ShapeObjectBase {
    shape;
    type = "center";
    centerPoint = {x: 0, y: 0};
    constructor(mapManager) {
        super(mapManager);
    }
    
    createShape(markup, options) {
        let points = [];
        points.push({ x: markup.box[0], y: markup.box[1] });
        this.centerPoint = points[0];
        this.shape = new CenterShape(this.mapManager, options);
        this.shape.draw(points);
    }
    createNewShape(options, point){
        let newPoints = [point];
        this.centerPoint = point;
        this.shape = new CenterShape(this.mapManager, options);
        this.shape.draw(newPoints);
        this.select();
        this.shape.selectCorner(0);
    }
    getMarkupPoints(){
        return [this.centerPoint];
    }
}