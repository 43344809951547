export default class Task {
    done;
    groupId;
    id;
    imageCount;
    imageIds;
    layerId;
    number;
    progress;
    progressText;
    user;
    isEditable;
    constructor(taskItem) {
        this.done = taskItem.done;
        this.groupId = taskItem.groupId;
        this.id = taskItem.id;
        this.imageCount = taskItem.imageCount;
        this.imageIds = taskItem.imageIds;
        this.layerId = taskItem.layerId;
        this.number = taskItem.number;
        this.progress = taskItem.progress;
        this.progressText = taskItem.progressText;
        this.user = taskItem.user;
        if(taskItem.number == 0){
            this.number = taskItem.id;
        }
        this.isEditable = !this.user || this.user.id == 0;
        console.log(`task ${this.id} for user `,this.user);
    }
    
}