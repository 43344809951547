export default class BrowserManager {
    fullscreen = false;
    getUrlParams() {
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);
        let result = {
            note: parseInt(params.get("note")),
            image: params.get("image"),
            layer: params.get("layer"),
            x: parseFloat(params.get("objx")),
            y: parseFloat(params.get("objy")),
            zoom: parseFloat(params.get("zoom")),
            lat: parseFloat(params.get("maplat")),
            lng: parseFloat(params.get("maplng")),
        }
        if (!result.zoom) {
            result.zoom = parseFloat(params.get("mapzoom"));
        }
        return result;
    }
    getQueryLink(data) {
        const baseAddress = '';
        const apiAddress = window.location.origin + window.location.pathname;
        let params = this.getUrlParams();
        let paramsData = {
            image: params.image,
            layer: params.layer
        }
        Object.assign(paramsData, data);
        const searchParams = new URLSearchParams(paramsData);
        const linkText = baseAddress + apiAddress + "?" + searchParams;
        return linkText;
    }
    toggleFullScreen() {
        this.fullscreen = !this.fullscreen;
        if (this.fullscreen) {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        }
        else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }
    setUrl(imageId, layerId, addToHistory) {
        const urlData = {
            image: imageId,
        };
        if (layerId) {
            urlData["layer"] = layerId;
        }
        const searchParams = new URLSearchParams(urlData);
        const newUrl = window.location.pathname + "?" + searchParams
        if (addToHistory) {
            window.history.pushState({}, "", newUrl);
        }
        else {
            window.history.replaceState({}, "", newUrl);
        }
    }
}