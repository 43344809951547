export default class ClipBoardManager {
    constructor(messageManager){
        this.messageManager = messageManager;
    }
    copyTextToClipboard(text) {
        const mm = this.messageManager;
        if(!navigator.clipboard){
            mm.show("Ошибка копирования координат! Буффер обмена недоступен", 'error');
            return;
        }
        navigator.clipboard.writeText(text).then(
            function () {
                mm.show("Координаты скопированы в буфер!", 'success');
            },
            function (err) {
                console.error("Could not copy text: ", err);
                mm.show("Ошибка копирования координат!", 'error');
            }
        );
    }
}