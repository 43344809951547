<template>
  <div v-if="showWarning">
    <p class="warning-massage" @click="closeWarning">
      {{ message }} <span v-if="warningIsClosable">×</span>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: "",
      showWarning: false,
      warningIsClosable: false,
    };
  },
  methods: {
    showMarkupWarning(text, closable) {
      if (text && text.length > 0) {
        this.message = text;
        this.showWarning = true;
        this.warningIsClosable = closable;
        return;
      }
      this.showWarning = false;
      this.warningIsClosable = false;
    },
    closeWarning() {
      if (this.warningIsClosable) {
        this.showWarning = false;
      }
    },
  }
};
</script>

<style scoped>
.warning-massage {
  background-color: rgba(255, 165, 0, 0.7);
  border-radius: 0.2em;
  padding: 0.3em 1em;
  font-weight: bold;
  font-size: 200%;
  position: absolute;
  top: 0;
  margin-right: auto;
  margin-left: auto;
  left: 50%;
  transform: translate(-50%, 0);
  cursor: pointer;
  border: 3px solid black;
  z-index: 1000;
}
</style>