import ShapeObjectBase from "./ShapeObjectBase";
import ShadowShape from "../shapes/ShadowShape";
export default class ShadowObject extends ShapeObjectBase {
    shape;
    // corner = [];
    type = "shadow";
    constructor(mapManager) {
        super(mapManager);
    }
    createShape(markup, options) {

        let points = [];
        for (let i = 0; i < markup.points.length - 1; i = i + 2) {
            points[i / 2] = { x: markup.points[i], y: markup.points[i + 1] };
        }
        this.shape = new ShadowShape(this.mapManager, options);
        this.shape.draw(points);
    }
    createNewShape(options, point){
        const p1 = {x:point.x, y:point.y};
        const p2 = {x:point.x+2, y:point.y+2};
        let newPoints = [p1,p2];
        this.shape = new ShadowShape(this.mapManager, options);
        this.shape.draw(newPoints);
        this.select();
        this.shape.selectCorner(1);
    }
    addPoint(point){
        return this.shape.addPoint(point);
    }
    finish(){
        return this.shape.finish();
    }
}