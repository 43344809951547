<template>
    <footer class="footer-bar" style="margin-top: auto; margin-bottom:0em;">
        <p>© {{year}} - Программа разметки {{appVersion}} - МФТИ</p>

    </footer>

</template>
<script>
    export default {
        data() {
            return {

            };
        },
        computed : {
            appVersion(){
                return this.$services.version;
            },
            year(){
              var date = new Date(Date.now());
              return date.getFullYear();
            }
        },
        methods: {},
        mounted() {
        },
    }</script>
<style scoped>
    .footer-bar {
        min-height: 2rem;
        display: flex;
        align-items: center;
        flex-grow:0;
    }

        .footer-bar p {
            margin: 0;
        }
</style>