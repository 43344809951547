<template>
  <div class="dropdown" :class="{'in-front':isOpened}">
            <img
              src="../../assets/images/menu.svg"
              @click="openMenu"
            />
            <div class="dropdown-menu" :class="{opened:isOpened}">
                <ul class="submenu">
                    <li v-if="!insertMode"><button type="button" @click="addNew">Добавить элемент</button></li>
                    <li v-if="!limited && !insertMode"><button type="button" @click="cutItem">Вырезать</button></li>
                    <li v-if="insertMode"><button type="button" @click="insertItem">Вставить здесь</button></li>
                    <li v-if="!limited && !insertMode"><button type="button" @click="deleteItem">Удалить</button></li>
                </ul>
            </div>
        </div>
</template>

<script>
export default {
    props:["isOpened", "treeServices","category","limited", "insertMode"],
    emits:["open-menu"],
    methods: {
        openMenu(e){
            this.$emit("open-menu",e);
        },
        addNew(){
            console.log("addNew", this.treeServices, this.category);
            this.treeServices.addItem(this.category);
        },
        deleteItem(){
            this.treeServices.deleteItem(this.category);
        },
        cutItem(){
            console.log("Menu cut");
            this.treeServices.cutItem(this.category);
        },
        insertItem(){
            this.treeServices.insertItem(this.category);
        }
    }
}
</script>

<style>
.dropdown {
    display: inline-block;
    position: relative;
}
.dropdown-menu {
    display: none;
    position: absolute;
    right: 0;
    top: 2.1em;
    background-color: #eee;
}
.dropdown-menu.opened{
    display: block;
}
.submenu {
    padding-left: 0;
    margin: 0;
    list-style-type: none;
    border: 1px solid #999;
}
.submenu > li + li {
    border-top: 1px solid #999;
}
.submenu > li > button {
    display: block;
    padding: 0.5em 1.5em;
    background-color: #eee;
    color: #369;
    text-decoration: none;
    border-width: 0;
    width: 100%;
}
.submenu > li > button:hover {
    background-color: #fff;
}
.in-front{
    z-index: 10;
}
</style>