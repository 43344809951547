import ShapeObjectBase from "./ShapeObjectBase";
import EditShape from "../shapes/EditShape";
export default class EditObject extends ShapeObjectBase {
    shape;
    type = "edit";
    constructor(mapManager) {
        super(mapManager);
    }
    updateLabel() {
    }
    
    createNewShape(options, point){
        const p1 = {x:point.x, y:point.y};
        const p2 = {x:point.x+5, y:point.y};
        const p3 = {x:point.x+5, y:point.y+5};
        const p4 = {x:point.x, y:point.y+5};
        let newPoints = [p1,p2,p3,p4];
        this.shape = new EditShape(this.mapManager, options);
        this.shape.draw(newPoints);
        this.select();
        this.shape.selectCorner(2);
    }
}