import ShapeObjectBase from "./ShapeObjectBase";
import RulerShape from "../shapes/RulerShape";
export default class RulerObject extends ShapeObjectBase {
    shape;
    type = "ruler";
    constructor(mapManager) {
        super(mapManager);
    }
    updateLabel() {
    }
    
    createNewShape(options, point){
        const p1 = {x:point.x, y:point.y};
        const p2 = {x:point.x+2, y:point.y+2};
        let newPoints = [p1,p2];
        this.shape = new RulerShape(this.mapManager, options);
        this.shape.draw(newPoints);
        this.select();
        this.shape.selectCorner(1);
    }
}