<template>
    <div v-show="showInfo">
        <p>Выбранный объект</p>
        <hr />

        <div class="object-info-flex">
            <strong v-bind:style="{
          margin: '0 1em 0 0',
          borderBottom: '6px solid ' + selectedCategory.color,
        }"
                    id="selectedObjectClassLabel">
                Класс
            </strong>
            <button v-if="editable"
                    type="button"
                    class="tool-button main-button"
                    @click="openTree">
                дерево
            </button>
            <input type="hidden" id="selectedCategoryColor" />
        </div>
        <div v-if="!editable" class="margined">
            <span>
                {{ currentCategory.name }}
            </span>
        </div>

        <CategorySelector v-show="editable"
                          :categories="categories"
                          ref="categorySelectorItem"
                          @category-selected="onCategoryChanged" />
        <div style="margin-top: 1em">
            <strong style="margin: auto">Тэги</strong>
            <div>
                <div>
                    <span v-for="tag in positiveTags"
                          :key="tag"
                          class="tag-base positive-tag">
                        {{ tag }}
                        <button type="button"
                                class="grow-btn"
                                style="
                border-width: 0;
                background-color: transparent;
                color: white;
                outline: none;
              "
                                @click="() => deleteTag(tag)">
                            ⨉
                        </button>
                    </span>
                </div>
                <div>
                    <span v-for="tag in negativeTags"
                          :key="tag"
                          class="tag-base negative-tag">
                        {{ tag }}
                        <button type="button"
                                class="grow-btn"
                                style="
                border-width: 0;
                background-color: transparent;
                color: white;
                outline: none;
              "
                                @click="() => deleteTag(tag)">
                            ⨉
                        </button>
                    </span>
                </div>
            </div>
            <div class="object-info-flex" v-if="editable && selectedObject">
                <span>добавить: </span>
                <select style="width: 100%; margin-left: 1em"
                        v-model="selectedTag"
                        name="tagSelect"
                        id="tagSelect"
                        @change="tagChanged">
                    <option v-for="tag in tags" :value="tag" :key="tag">{{ tag }}</option>
                </select>
                <!-- <input style="width:100%" list="tag-list" type="text" name="currentTag" id="currentTag">
                <datalist id="tag-list">
                  <option v-for="tag in tags" :value="tag" :key="tag" />
                </datalist> -->
            </div>
        </div>
        <div style="margin-top: 1em">
            <strong style="margin: auto">Комментарий</strong>
            <div v-if="editable && selectedObject">
                <textarea v-bind:style="{
            width: 'calc(100%-1em)',
          }"
                          class="my-1 d-block form-control"
                          placeholder="---"
                          id="selectedObjectComment"
                          v-model="currentComment"></textarea>
            </div>
            <div v-else class="mx-1">
                <p class="my-1 d-block form-control">{{ currentComment }}</p>
            </div>
            <!-- <button
              class="tool-button main-button"
              v-bind:style="{ marginLeft: 0 }"
              @click="onAddRemoveDirection"
            >
              +/- направление
            </button> -->
        </div>

        <div id="image-list"></div>
        <category-tree v-if="treeIsOpened"
                       ref="categoryTreeItem"
                       @close-tree="closeTree"
                       @put-in-line="onTreeSelected"
                       @on-mount="onTreeMounted" />

        <!-- <Teleport to="#category-tree-div" :disabled="!treeIsOpened">
          <category-tree v-if="treeIsOpened" />
        </Teleport> -->
        
        <div v-if="editable && selectedObject && datasetType=='Video'">
            <div style="display:flex; flex-direction:row; flex-wrap:wrap; justify-content:space-around">
                <div>ID объекта {{this.selectedObject.trackerId}}</div>
                <input id="newTrackerId" type="number" v-model="newTrackerId"/> 
                <button @click="changeTrackerId">заменить</button>
            </div>
            <button v-if="!selectedObject.locked" @click="lockItem" class="state-button" style=" background-color: dodgerblue; margin-top: 1em">
                Зафиксировать
            </button>
            <button v-if="this.selectedObject.locked" @click="lockItem" class="state-button" style=" background-color: darkorange; margin-top: 1em">
                Разблокировать
            </button>
            <div>
                <button @click="deleteAcross" class="state-button" style="background-color: red; margin-top: 1em">
                    Сквозное удаление
                </button>
                <button @click="deleteAcrossAfter" class="state-button" style="background-color: red; margin-top: 1em">
                    ➤
                </button>
            </div>
        </div>
    </div>
    <div v-if="!showInfo">
        <p class="empty-selection-info">Нет выбранных объектов</p>
    </div>

</template>

<script>
    import CategorySelector from "./CategorySelector.vue";
    import CategoryTree from "./CategoryTree.vue";

    export default {
        data() {
            return {
                // editable: true,
                selectedCategory: { id: 0, color: "red", name: "Name" },
                selectedObject: null,
                comment: "",
                categories: [],
                selectedTag: "",
                appliedTags: [],
                treeIsOpened: false,
                layer: null,
                showInfo: false,

                newTrackerId: null,
                //locked: false,
            };
        },
        components: {
            CategorySelector,
            CategoryTree,
        },
        props: {
            layers: {
                type: Array,
                required: true,
            },
            editable: {
                type: Boolean,
                required: true,
            },
            tags: {
                type: Array,
                required: true,
            },
            datasetType: {
                type: String,
                required: true,
            }
        },
        computed: {
            currentCategory: {
                get() {
                    return this.selectedCategory;
                },
                set(value) {
                    this.selectedCategory.count--;
                    this.selectedCategory = value;
                    this.selectedCategory.count++;
                    this.$refs?.categorySelector?.blur();
                },
            },
            currentComment: {
                get() {
                    return this.comment;
                },
                set(value) {
                    this.comment = value;
                    this.selectedObject.comment = this.comment;
                    this.$services.eventAggregator.fire("objectinfochanged", this.selectedObject);
                },
            },
            positiveTags: {
                get() {
                    return this.appliedTags.filter(
                        (t) => !t.startsWith("не ") && !t.startsWith("not ")
                    );
                },
            },
            negativeTags: {
                get() {
                    return this.appliedTags.filter(
                        (t) => t.startsWith("не ") || t.startsWith("not ")
                    );
                },
            },
        },
        emits: ["add-remove-direction","lock-markup-item","delete-across"],
        methods: {
            changeTrackerId() {
                //console.log("changeTrackerId",this.$services.mapManager.mapMarkupManager.mapMarkupLayersManager.markupLayers[0].data);
                let markups=this.$services.mapManager.mapMarkupManager.mapMarkupLayersManager.markupLayers[0].data;
                if (this.newTrackerId != null && this.newTrackerId > 0) {
                    let ids = markups.map(obj => obj.trackerId);
                    //console.log("changeTrackerId", ids);
                    if (!ids.includes(this.newTrackerId)) {
                        this.selectedObject.trackerId = this.newTrackerId;
                        console.log("T_ID was changet to ", this.newTrackerId)
                        this.$services.eventAggregator.fire("objectinfochanged", this.selectedObject);
                        this.$messageManger.show("Id элемента разметки обновлён", 'success');
                        this.newTrackerId = null;
                    } else {
                        this.$messageManger.show("Такой Id объекта уже существует на этом кадре", 'error');
                    }
                }
                //TO DO: save after changes
            },
            lockItem() {
                this.selectedObject.locked = !this.selectedObject.locked;
                this.$emit("lock-markup-item", this.selectedObject.id, this.selectedObject.locked);
                this.$services.eventAggregator.fire("objectinfochanged", this.selectedObject);
            },
            deleteAcross() {
                var data = {
                    markupId: this.selectedObject.id,
                    after: false,
                }
                this.$services.mapManager.mapMarkupManager.deleteObject();
                this.$services.mapManager.mapMarkupManager.drawInView(true);
                this.$emit("delete-across", data);
            },
            deleteAcrossAfter() {
                var data = {
                    markupId: this.selectedObject.id,
                    after: true,
                }
                this.$services.mapManager.mapMarkupManager.deleteObject();
                this.$services.mapManager.mapMarkupManager.drawInView(true);
                this.$emit("delete-across", data);
            },
            setInfo(data) {
                //console.log("ObjInfo.vue___setInfo",data);
                const selected = data.obj;
                if (data.list && data.list.length > 0) {
                    this.setManyInfo(data);
                    return;
                }
                else if (!selected) {
                    this.showInfo = false;
                    this.clearObjectInfo();
                    return;
                }
                this.showInfo = true;
                this.selectedObject = selected;
                this.comment = selected.comment;
                    
                this.trackerId = selected.trackerId;
                this.locked = selected.locked;

                if (selected.tagList) {
                    this.appliedTags = selected.tagList;
                } else {
                    this.appliedTags = [];
                }
                const l = this.layer;
                const cat = l.categoryList.find((i) => i.id == selected.category);
                this.categoryList = l.categoryList;
                this.categories.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
                if (cat) {
                    this.selectedCategory = cat;
                    this.$services.eventAggregator.fire("categoryselected", cat);
                }
                // console.log("ItsLayer", selected.itsLayer);
                this.updateCategorySelector();
            },
            setManyInfo() {
                this.clearObjectInfo();
                this.showInfo = true;
            },
            setLayer(layer) {
                if (!layer) {
                    return;
                }
                this.layer = layer;
                this.initObjectCategories(this.layer.categoryList);
            },
            setObjectInfo(selected) {
                console.log("SET INFO", selected);
                if (!selected) {
                    return;
                }
                this.selectedObject = selected;
                if (selected.comment) {
                    this.comment = selected.comment;
                }
                if (selected.tagList) {
                    this.appliedTags = selected.tagList;
                } else {
                    this.appliedTags = [];
                }
                const objCategory = selected.category;
                if (!selected.itsLayer) {
                    return;
                }
                var l = this.layers.find((ll) => ll.id === selected.itsLayer.id);
                if (!l) {
                    return;
                }
                const cat = l.items.find((i) => i.id == objCategory);
                this.categories = l.items;
                this.categories.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
                if (cat) {
                    this.selectedCategory = cat;
                }
                this.updateCategorySelector();
            },
            clearObjectInfo() {
                this.selectedObject = null;
                this.appliedTags = [];
                this.comment = "";
            },
            initObjectCategories(initCategories) {
                this.categories = initCategories;
                this.categories.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
                const currentSelected = this.categories.find(
                    (c) => c.id == this.selectedCategory.id
                );
                if (currentSelected) {
                    this.currentCategory = currentSelected;
                } else {
                    this.currentCategory = this.categories[0];
                }
                this.updateCategorySelector();
            },
            updateCategorySelector() {
                const item = this.$refs["categorySelectorItem"];
                if (item) {
                    item.initShowList(this.categories);
                    item.setItem(this.selectedCategory);
                }
                const treeItem = this.$refs["categoryTreeItem"];
                if (treeItem) {
                    treeItem.init(this.categories);
                }
            },
            onAddRemoveDirection() {
                this.$emit("add-remove-direction");
            },
            tagChanged(e) {
                const tag = e.target.value;
                if (!this.appliedTags.includes(tag) && tag && this.selectedObject) {
                    this.appliedTags.push(tag);
                }
                this.selectedTag = "";
                if (!this.selectedObject) {
                    return;
                }
                this.selectedObject.tagList = this.appliedTags;
                console.log("SelectedObject", this.selectedObject);
                // window.services.markupManager.selected.tagList = this.appliedTags;
                // window.services.markupManager.selected.updateLabel();
                // window.services.markupManager.addEdited(
                //   window.services.markupManager.selected.shape
                // );
                this.$services.eventAggregator.fire("objectinfochanged", this.selectedObject);
            },
            deleteTag(tag) {
                const index = this.appliedTags.indexOf(tag);
                if (index > -1) {
                    this.appliedTags.splice(index, 1);
                }
                this.selectedObject.tagList = this.appliedTags;
                this.$services.eventAggregator.fire("objectinfochanged", this.selectedObject);
            },
            onCategoryChanged(category) {
                const categoryToSelect = this.categories.find(
                    (c) => c.id === category.id
                );
                if (categoryToSelect) {
                    this.currentCategory = categoryToSelect;
                    this.$services.eventAggregator.fire(
                        "categoryselected",
                        categoryToSelect
                    );
                }
            },
            openTree() {
                this.treeIsOpened = true;
            },
            closeTree() {
                this.treeIsOpened = false;
            },
            onFavouriteSelected(data) {
                console.log(" ON FAV SELECTED OBJINFO", data);
                if (data.changeSelected) {
                    this.onTreeSelected(data.category);
                }
            },
            onTreeSelected(node) {
                const categoryToSelect = this.categories.find((c) => c.id === node.id);
                if (categoryToSelect) {
                    this.currentCategory = categoryToSelect;
                    this.$refs["categorySelectorItem"]?.setItem(categoryToSelect);
                    this.$services.eventAggregator.fire(
                        "categoryselected",
                        categoryToSelect
                    );
                }
                this.treeIsOpened = false;
            },
            onTreeMounted() {
                const treeItem = this.$refs["categoryTreeItem"];
                if (treeItem) {
                    treeItem.init(this.categories);
                    treeItem.setValue(this.currentCategory.name);
                }
            },
        },
        mounted() {
            this.$services.mapManager.on("selected", this.setInfo);
            this.$services.mapManager.on("activelayer", this.setLayer);
            this.$services.eventAggregator.on("favouritecategoryselected", c => this.onFavouriteSelected(c));
            // window.vueContext.actions.clearObjectInfo = this.clearObjectInfo;
            // window.vueContext.actions.initObjectCategories = this.initObjectCategories;
            // window.vueContext.actions.onEditorEnabled = this.onEditorEnabled;
        },
    };
</script>

<style scoped>
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .state-button {
        cursor: pointer;
        color: #fff;
        padding: 0.25em 0.5em;
        border-radius: 0.2rem;
        display: inline-block;
        background-color: #6c757d;
        border: 1px solid transparent;
        margin: 0 0.5em;
        text-align: center;
        vertical-align: middle;
        white-space: nowrap;
        font-size: smaller;
    }
        .state-button:hover {
            background-color: #6c757d;
        }

    .empty-selection-info {
        height: 100%;
        text-align: center;
    }

    .object-info-form-control {
        width: 100%;
    }

    .object-info-flex {
        display: flex;
    }

    .margined {
        margin: 0.6rem 0.2rem;
    }

    .tag-base {
        border-radius: 0.4em;
        white-space: nowrap;
        color: white;
        padding: 0.2em 0.4em;
        margin: 0.1em 0.2em;
        display: inline-block;
        font-size: 80%;
    }

    .positive-tag {
        background-color: LightSeaGreen;
    }

    .negative-tag {
        background-color: DarkOrange;
    }
</style>