<template>
    <div v-if="errors=='noErrors'">
        <IsLoadingItem v-if="isLoading" />
        <!--<button @click="createCategoryList">TestButton</button>-->
        <TreeVueItem v-if="!isLoading"
                     :isCutOut="isCutOut"
                     :categoryItem="treeOfCat"
                     @element-cut-out="elementCutOut"
                     @insert-element="insertElement" />
    </div>
    <div v-if="errors!='noErrors'">
        <span>{{errors}}</span>
    </div>
</template>
<script>
    import TreeVueItem from "./TreeVueItem.vue"
    import IsLoadingItem from "./IsLoadingItem.vue"
    import CategoryTree from "../helpers/CategoryTree.js"

    export default {
        components: {
            TreeVueItem,
            IsLoadingItem,
        },
        props: ["markupGroup"],
        data() {
            return {
                treeOfCat: {},
                isLoading: false,
                isCutOut: false,
                errors: "noErrors",
                cuttingElement: {},
            }
        },
        computed: {
            creatingList: {
                get() {
                    return "ok";
                },
                set() {

                }
            }
        },
        emits: ["update-categories"],
        methods: {
            setColorsForAll(unicolor) {
                this.isLoading = true;
                this.treeOfCat.setColorsForAll(unicolor);
                //await this.createCategoryList();
                //await this.createTree();  
                this.isLoading = false;
            },
            createTree() {
                this.treeOfCat = new CategoryTree(this.markupGroup, this.markupGroup.markupCategories);
                let errorCategories = this.markupGroup.markupCategories.filter(C => C.id == 0);
                if (errorCategories.length > 0) {
                    this.errors = "Есть категории с id=0: ";
                    for (var errorCategory of errorCategories) {
                        this.errors = this.errors + errorCategory.name + ", ";
                    }
                }
                else {
                    this.treeOfCat.create(this.markupGroup.markupCategories);
                }
                this.isLoading = false;
            },
            createCategoryList() {
                var сategoryList = this.treeOfCat.createCategoryList();
                this.$emit("update-categories", сategoryList, this.markupGroup.id);
            },
            elementCutOut(isCutOut, cuttingElement) {
                this.isCutOut = isCutOut;
                this.cuttingElement = cuttingElement;
            },
            insertElement(categoryItem) {
                this.treeOfCat.deleteCutItem();
                categoryItem.insertElement(this.cuttingElement);
                this.isCutOut = false;
            }
        },
        mounted() {
            this.isLoading = true;
            this.createTree();

        },
    };
</script>
<style>
    .element {
        border-left: 1px dashed grey;
        border-bottom: 1px dashed grey;
        margin: 0.5em;
        padding: 0.3em;
        margin-right: 0;
        padding-right: 0;
        margin-bottom: 0;
    }

        .element input[type="text"] {
            border-width: 0;
            border-bottom-width: 1px;
        }

    .element-cutting {
        background-color: lightblue;
    }

        .element-cutting button {
            display: none;
        }

        .element-cutting input {
            pointer-events: none;
        }

    .sub-node {
        margin-left: 2em;
    }

    .class-tree-buttons {
        border-radius: 15px 15px 0% 0%;
        width: max-content;
        background-color: #6c757d;
        border: black;
        padding-left: 0.5em;
        padding-right: 0.5em;
        padding-top: 0.5em;
    }

    .buttons-for-textarea {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
    }

    .button-textarea {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: auto;
        margin: 0.3em;
    }



    .first-str {
        display: flex;
        align-items: center;
        max-width: 600px;

    }

    .button-open {
        border-radius: 4px;
        font-size: 12px;
        font: bold;
        font-family: "Fira Code", monospace;
        background: #1b6ec2;
        color: white;
        cursor: pointer;
        flex: 0 0 0;
        margin: 5px;
    }

    .root-name {
        flex: 7 0 0;
        width: 0;
        margin: 5px;
    }

    .root-id {
        flex: 1 0 0;
        width: 0;
        margin: 5px;
    }



    .delete-button {
        border-radius: 4px;
        font-size: 12px;
        font: bold;
        font-family: "Fira Code", monospace;
        background: #dc3545;
        color: white;
        cursor: pointer;
        flex: 0 0 2;
        margin: 5px;
    }

    .text {
        font-size: 16px;
        font: bold;
        font-family: "Fira Code", monospace;
        flex: 0 0 2;
        margin: 5px;
    }


    .second-str {
        display: flex;
        align-items: center;
        /* border-bottom: 1px dashed gray; */
        max-width: 600px;
    }

    .root-full-name {
        flex: 10 0 0;
        margin: 5px;
    }

    .root-color {
        flex: 1 0 0;
        margin: 5px;
    }

    .cut-button {
        border-radius: 4px;
        font-size: 12px;
        font: bold;
        font-family: "Fira Code", monospace;
        background: Teal;
        color: white;
        cursor: pointer;
        flex: 0 0 2;
        margin: 5px;
    }

    .uncut-button {
        border-radius: 4px;
        border-color: black;
        font-size: 12px;
        font: bold;
        font-family: "Fira Code", monospace;
        background: #dc3545;
        color: white;
        cursor: pointer;
        flex: 1 1 2;
        margin: 5px;
    }



    .add-element-button {
        border-radius: 4px;
        font-size: 12px;
        font: bold;
        font-family: "Fira Code", monospace;
        background: #1b6ec2;
        color: white;
        cursor: pointer;
        flex: 5 0 0;
        margin: 5px;
        flex-grow: 0;
    }

    .button-empty {
        border-radius: 4px;
        border-color: transparent;
        background: transparent;
        display: inline-block;
        /*padding-bottom: 0.5em;*/
        color: #1b6ec2;
        flex-grow: 1;
    }
</style>

