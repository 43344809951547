<template>
    <div class="toolbar-backgrnd">
        <div class="slider-row">
            <a class="left-number">{{curImageNumber}}</a>
            <!--<input class="slider" type="range" min="0" max="100" step="1" v-model="rangeValue">-->
            <input class="slider" type="range" min="1" :max="maxRangeValue" step="1" v-model="rangeValue">
            <a class="right-number">{{maxImageNumber}}</a>
        </div>
        <div class="btn-backgrnd">
            <div class="btn-env" @click="previousImage">
                <BackwardIcon class="icon" />
            </div>
            <div class="btn-env" v-if="!isPlaying" @click="play">
                <PlayIcon class="icon" />
            </div>
            <div class="btn-env" v-if="isPlaying" @click="pause">
                <PauseIcon class="icon" />
            </div>
            <div class="forvard-el">
                <ForwardIcon class="icon" @click="playWithBreack" />
                <input style="margin-right:0.7em" class="frame-count" type="number" step="1" min="1" max="25" id="amounFrame" name="amounFrame" v-model="frameQuantity" />
            </div>
            <!--<label for="amounFrame">кол-во кадров</label>-->
            <div class="t-check">
                <label for="trackingOn" style="color:white">
                    Трекинг<input type="checkbox" id="trackingOn" v-model="trackingOn">
                </label>
                <!--<a style="color:white">Трекинг</a>-->
            </div>
        </div>
    </div>
</template>
<script>

    import PlayIcon from "../videoToolsIcons/play.vue";
    import PauseIcon from "../videoToolsIcons/pause.vue";
    import ForwardIcon from "../videoToolsIcons/forward.vue";
    import BackwardIcon from "../videoToolsIcons/backward.vue";
    export default {
        components: {
            PlayIcon,
            PauseIcon,
            ForwardIcon,
            BackwardIcon,
        },
        data() {
            return {
                frameQuantity: 1,
                maximumImageNumber: 0,
                maxRangeValue: 100,
            };
        },
        emits: ["fbf-play", "fbf-pause", "fbf-play-with-breack", "go-to-image","is-tracking"],
        props: ['isPlaying', 'curImageNumber', 'maxImageNumber', 'isTrackingOn'],
        computed: {
            rangeValue: {
                get() {
                    return this.curImageNumber;
                },
                set(data) {
                    this.goToImage(data);
                }
            },
            trackingOn: {
                get() {
                    return this.isTrackingOn
                },
                set() {
                    this.$emit("is-tracking");
                },
            }
            //rangeValue: {
            //    get() {
            //        var tmp = ((this.curImageNumber - 1) * 100 / (this.maxImageNumber - 1));
            //        //console.log("----------getrangeValue_______", tmp)
            //        return tmp;
            //    },
            //    set(data) {
            //        var newNum = Math.round(data * (this.maxImageNumber - 1) / 100) + 1;
            //        if (newNum != this.curImageNumber) {
            //            //console.log("----------setrangeValue_______", newNum)
            //            this.goToImage(newNum);
            //        }
            //    }
            //},
        },
        methods: {
            play() {
                this.$emit("fbf-play");
            },
            pause() {
                this.$emit("fbf-pause");
            },
            previousImage() {
                this.$services.eventAggregator.fire("changeimage", { step: -1 });
            },
            playWithBreack() {
                this.$emit("fbf-play-with-breack", this.frameQuantity);
            },
            goToImage(data) {
                this.$emit("go-to-image", data);
            },
        },
        mounted() {
            this.maxRangeValue = this.maxImageNumber;
        }
    }

</script>

<style scoped>
    .toolbar-backgrnd {
        width: 50%;
        position: absolute;
        flex-direction: column;
        background-color: black;
        z-index: 1001;
        bottom: 2.5em;
        height: 6em;
        left: 20%;
        border-radius: 1em;
    }

    .slider {
        -webkit-appearance: none;
        top: 5%;
        width: 90%;
        /*left: 2.5%;*/
        height: 15px;
        border-radius: 5px;
        background: #d3d3d3;
        outline: none;
        opacity: 0.7;
        -webkit-transition: .2s;
        transition: opacity .2s;
        position: relative;
    }

        .slider::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: #ff0000;
            cursor: pointer;
        }

        .slider::-moz-range-thumb {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: #4CAF50;
            cursor: pointer;
        }

    .slider-row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .right-number {
        color: white;
    }

    .left-number {
        color: white;
    }

    .btn-backgrnd {
        width: 21em;
        height: 4.5em;
        background-color: #000000;
        z-index: 1001;
        position: relative;
        bottom: -0.3em;
        left: 38%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    .forvard-el {
        display: flex;
        flex-direction: row;
        height: 4.5em;
        border-radius: 1em;
        background: transparent;
        cursor: pointer;
        z-index: 1001;
    }

        .forvard-el:hover {
            background-color: #303030;
        }

    .btn-env {
        height: 4.5em;
        border-radius: 1em;
        background: transparent;
        cursor: pointer;
        z-index: 1001;
    }

        .btn-env:hover {
            background-color: #303030;
        }

    .frame-count {
        height: 2em;
        width: 3em;
        position: relative;
        top: 27%;
    }

    .icon {
    }

        .icon:hover {
            stroke: #fafafa;
            stroke-width: 1px;
        }

    .t-check {
        /*display: flex;*/
        /*align-content: center;*/
    }
</style>
