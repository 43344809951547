import { getMarkupOptions } from "../../models/layerFactory";
import { deleteItem } from "../tools";
import CenterObject from "../../models/objects/CenterObject";
import BoxObject from "../../models/objects/BoxObject";
import PolyObjects from "../../models/objects/PolyObject";
import OrientedBoxObjects from "../../models/objects/OrientedBoxObject";
import ClusterManager from "../../models/tooling/ClusterManager";
import ShadowObject from "../../models/objects/ShadowObject";
import RulerObject from "../../models/objects/RulerObject";
import EditObject from "../../models/objects/EditObject";
import HelperLines from "../../models/shapes/HelperLines";
import ShapesMath from "../mathHelpers/shapesMath";
import CommandHistory from "../CommandHistory";
import NeuralBoxObject from "../../models/objects/NeuralBoxObject";
import MapMarkupLayers from "./MapMarkupLayers";
import BackEndService from "../BackEndService";
export default class MapMarkupManager {
    clusterShapes = [];
    clusterManagers = {};
    coordinateMultiplier = Math.pow(2, 4);
    showMarkup = true;
    moving = false;
    selectedList = [];
    drawingModeOn = false;
    drawings = [];
    editing = false;
    tool = "edit";
    activeCategory = null;
    edited = [];
    step = 1;
    helperLines = null;
    mouseUpTime = Date.now();
    markupNet = [];
    neuralAssistOn = false;
    temporaryLayer = null;
    activeLayer = null;
    //helpertools
    editTool;
    neuralBoxTool;
    ruler;
    isEditingNeural = false;
  
    factories = {
        "center": mapManager => new CenterObject(mapManager),
        "box": mapManager => new BoxObject(mapManager),
        "poly": mapManager => new PolyObjects(mapManager),
        "obox": mapManager => new OrientedBoxObjects(mapManager),
        "shadow": mapManager => new ShadowObject(mapManager),
        "ruler": mapManager => new RulerObject(mapManager),
        "edit": mapManager => new EditObject(mapManager),
        "neural": mapManager => new NeuralBoxObject(mapManager),
    }
    keyActions = {
        "Q": () => this.toggleMarkup(),
        "W": () => window.appstate.showObjectInfo = !window.appstate.showObjectInfo,
        "E": () => window.appstate.showComments = !window.appstate.showComments,
        "R": () => this.toggleClusters(),
        "G": (e) => this.jumpToPreviousObject(e),
        "H": (e) => this.jumpToNextObject(e),
        "Escape": () => this.cancelObject(),
        "Enter": () => this.finishEditing(),
        "Delete": () => this.deleteObject(),

    }

    constructor(mapManager, eventAggregator, inputHelper) {
        this.commandHistory = new CommandHistory(() => console.log("No more redos"), () => console.log("No more undos"));
        this.mapManager = mapManager;
        this.inputHelper = inputHelper;
        this.eventAggregator = eventAggregator;
        this.mapMarkupLayersManager = new MapMarkupLayers(m => this.createMarkupObject(m));
        this.mapManager.on("zoom", z => this.onZoom(z));
        this.mapManager.on("togglelayer", l => this.toggleLayer(l, !l.isOn));
        this.mapManager.on("togglecategory", d => this.toggleCategory(d));
        this.mapManager.on("changeCategoryColor", d => this.changeCategoryColor(d));
        this.eventAggregator.on("categoryselected", c => this.onCategorySelected(c));
        this.eventAggregator.on("drawcategoryselected", c => this.onDrawCategorySelected(c));
        this.eventAggregator.on("toolchanged", t => this.onToolSelected(t));
        this.eventAggregator.on("key", k => this.onKey(k));
        this.eventAggregator.on("keystep", k => this.onSetStep(k));
        this.eventAggregator.on("editactions", a => this.onEditAction(a));
        this.eventAggregator.on("toggledrawings", s => this.onToggleDrawings(s));
        this.eventAggregator.on("togglenet", () => this.onToggleNet());
        this.eventAggregator.on("externalobjects", data => this.drawExternalObjects(data));
        this.eventAggregator.on("toggleneuralassist", show => this.onToggleNeurAlassist(show));
        this.eventAggregator.on("objectinfochanged", (selected) => this.onInfoChanged(selected));
    }
    drawExternalObjects(data) {
        //console.log(data);
        let activeLayer = this.mapMarkupLayersManager.getActiveLayer();
        if (!activeLayer) {
            return false;
        }
        for (const markup of data.markups) {
            const options = getMarkupOptions(activeLayer, markup);
            options.itsLayer = activeLayer;
            let objToDraw = this.createMarkupObject(markup);
            objToDraw.itsLayer = activeLayer;
            objToDraw.createExisting(markup, options, options.objStrokeOpacity, options.objFillOpacity);
            activeLayer.data.push(objToDraw);
        }
        this.drawInView(true);
    }
    onToggleNeurAlassist(show) {
        this.neuralAssistOn = show;
        if (!show && this.neuralBoxTool) {
            this.neuralBoxTool.remove();
            this.neuralBoxTool = null;
        }
        this.mapMarkupLayersManager.discardNeuralLayer();
    }
    onToggleNet() {
        const step = 256;
        const color = "rgba(127,127,127,0.6)";
        const imgHeight = this.mapManager.imageHeight;
        const imgWidth = this.mapManager.imageWidth;
        if (this.markupNet.length > 0) {
            for (const line of this.markupNet) {
                line.removeFrom(this.map);
            }
            this.markupNet = [];
            return;
        }
        for (let i = 0; i <= imgWidth + step; i += step) {
            const p1 = this.mapManager.toLatLng({ x: i, y: -step });
            const p2 = this.mapManager.toLatLng({ x: i, y: imgHeight + step });
            const newPoints = [[p1.lat, p1.lng], [p2.lat, p2.lng]];
            const vline = window.L.polyline(newPoints, { color: color, weight: 1, interactive: false });
            vline.addTo(this.map);
            this.markupNet.push(vline);
        }
        for (let i = 0; i <= imgHeight + step; i += step) {
            const p1 = this.mapManager.toLatLng({ x: -step, y: i });
            const p2 = this.mapManager.toLatLng({ x: imgWidth + step, y: i });
            const newPoints = [[p1.lat, p1.lng], [p2.lat, p2.lng]];
            const vline = window.L.polyline(newPoints, { color: color, weight: 1, interactive: false });
            vline.addTo(this.map);
            this.markupNet.push(vline);
        }
    }
    onToggleDrawings(show) {
        this.drawingModeOn = show;
        this.mapDrawingsManager.toggleDrawings(show);
    }
    toggleMarkup() {
        this.showMarkup = !this.showMarkup;
        this.fireWarning();
    }
    toggleClusters() {
        window.appstate.hasClusterization = !window.appstate.hasClusterization
        this.fireWarning();
    }
    fireWarning() {
        this.eventAggregator.fire("markupwarning", { markup: this.showMarkup, clusterization: window.appstate.hasClusterization });
    }
    onSetStep(number) {
        this.step = number;
    }
    create(layers, map) {
        this.map = map;
        //TO DO: fix HelperLines
        this.helperLines = new HelperLines(this.map);
        this.mapMarkupLayersManager.clearView();
        this.mapMarkupLayersManager.init(layers);
        const markupLayers = this.mapMarkupLayersManager.getAll();
        this.mapManager.fire("layers", markupLayers);
        this.mapManager.fire("activelayer", markupLayers[0]);
        this.drawInView(true);
    }
    onCategorySelected(category) {
        for (const selected of this.selectedList) {
            if (selected.category !== category.id) {
                this.addEdited(selected);
            }
            this.mapManager.fire("updatecategorycount", { id: selected.category, step: -1 });
            selected.category = category.id;
            selected.categoryName = category.name;
            this.mapManager.fire("updatecategorycount", { id: selected.category, step: 1 });
        }
        this.drawInView(true);
    }
    onDrawCategorySelected(category) {
        this.activeCategory = category;
    }
    stopDrawingPoly() {
        if (this.tool == "poly" && this.selectedList.length == 1) {
            this.selectedList[0].shape.shape._latlngs[0].pop();
            var geoPoints = this.selectedList[0].shape.shape._latlngs[0];
            this.selectedList[0].shape.shape.setLatLngs(geoPoints);
        }
    }
    onToolSelected(tool) {
        this.stopDrawingPoly();
        if (tool !== "point" && tool!=="obox") {
            this.clearSelection();
        }
        this.tool = tool;
        if (!this.hasHelperLines() && this.helperLines) {
            this.helperLines.remove();
        }
    }
    onKey(data) {
        if (!data.key) {
            return;
        }
        const action = this.keyActions[data.key];
        if (!action) {
            return;
        }
        action(data);
        this.drawInView(true);

    }
    addTempLayer(markups, shouldClean, maxOverlay) {
        this.isEditingNeural = true;
        let markupsToAdd = markups;
        if (shouldClean) {
            markupsToAdd = this.cleanTempLayer(markups, maxOverlay);
        }
        this.mapMarkupLayersManager.addTempLayer(markupsToAdd);
        this.drawInView(true);
    }
    cleanTempLayer(markups, maxOverlay) {
        if (!this.isEditableLayer) {
            return;
        }
        const redundant = [];
        const layerList = this.mapMarkupLayersManager.getAll();
        for (const layer of layerList) {
            for (const obj of layer.data) {
                for (const m of markups) {
                    const p1 = obj.getPoints();
                    const b1 = this.getMarkupBounds(m);
                    const intersectionFraction = this.countIntersectionFraction(p1, b1);
                    if (intersectionFraction > maxOverlay) {
                        redundant.push(m);
                    }
                }
            }
        }
        const cleanedMarkups = markups.filter(m => !redundant.includes(m));
        return cleanedMarkups;
    }
    applyNeuralMarkup() {
        this.isEditingNeural = false;
        this.mapMarkupLayersManager.applyNeuralMarkup();
        this.drawInView(true);
    }
    discardNeuralMarkup() {
        this.isEditingNeural = false;
        this.mapMarkupLayersManager.discardNeuralLayer();
        this.drawInView(true);
    }
    canSaveMarkup() {
        return !this.isEditingNeural;
    }
    createMarkupObject(markup) {
        const type = this.getMarkupType(markup);
        let objToDraw = this.getNewObject(type);

        return objToDraw;
    }
    getMarkupType(markup) {
        if (markup.type) {
            return markup.type;
        }
        if (markup.box[2] === 0 && markup.box[3] === 0) {
            return "center";
        }
        if (!markup.points) {
            return "box";
        }
        return "poly";
    }
    getNewObject(type) {
        let factory = this.factories[type];
        if (!factory) {
            console.warn("Unknown factory", type);
            factory = this.factories["poly"];
        }
        return factory(this.mapManager);
    }

    drawInView = (force) => {
        if (!this.map) {
            return;
        }
        const zoom = this.map.getZoom();
        var mapBounds = this.map.getBounds();
        const orderedLayers = this.mapMarkupLayersManager.getSorted();
        this.clusterShapes.forEach(shape => {
            shape.draw.remove();
        });

        this.clusterShapes = [];
        let id = 1000;
        for (var layer of orderedLayers) {
            if (!layer.isOn || !this.showMarkup) {
                continue;
            }
            if (!this.clusterManagers[layer.id]) {
                const cm = new ClusterManager(this.mapManager);
                this.clusterManagers[layer.id] = cm;
            }
            const clusterManager = this.clusterManagers[layer.id];

            clusterManager.calculate(zoom, layer, force);
            const clusterList = clusterManager.clusters[zoom].tree.getObjects(mapBounds);
            for (const cluster of clusterList) {
                this.addClusterToMap(cluster, mapBounds, id, layer);
                id++;
            }
        }
        this.clusterShapes.sort((l1, l2) => l2.order - l1.order);
        for (const shape of this.clusterShapes) {
            shape.draw.add();
        }
    }
    addClusterToMap(cluster, mapBounds, _, layer) {
        if (cluster.type !== "cluster") {
            this.addObjectToMap(cluster, layer, mapBounds);
            return;
        }
        const cObj = cluster.obj;
        this.clusterShapes.push({ draw: cObj, order: 0 });
    }
    moveShapes() {
        const zoom = this.map.getZoom();
        var mapBounds = this.map.getBounds();
        const orderedLayers = this.mapMarkupLayersManager.getSorted();
        const prevVisible = [];
        this.clusterShapes.forEach(shape => {
            prevVisible.push(shape.draw);
        });
        this.clusterShapes = [];
        let id = 1000;
        for (var layer of orderedLayers) {
            if (!layer.isOn || !this.showMarkup) {
                continue;
            }
            if (!this.clusterManagers[layer.id]) {
                const cm = new ClusterManager();
                this.clusterManagers[layer.id] = cm;
            }
            const clusterManager = this.clusterManagers[layer.id];

            clusterManager.calculate(zoom, layer, false);
            const clusterList = clusterManager.clusters[zoom].tree.getObjects(mapBounds);
            for (const cluster of clusterList) {
                this.addClusterToMap(cluster, mapBounds, id, layer);
                id++;
            }
        }
        this.clusterShapes.sort((l1, l2) => l2.order - l1.order);
        const newShapes = [];
        for (const shape of this.clusterShapes) {
            var index = prevVisible.indexOf(shape.draw);
            if (index !== -1) {
                prevVisible.splice(index, 1);
            }
            else {
                newShapes.push(shape);
            }
        }
        for (const shape of prevVisible) {
            shape.remove();
        }
        for (const shape of newShapes) {
            shape.draw.add();
        }
    }
    addObjectToMap(obj, layer, mapBounds) {
        if (layer.isOn && window.appstate.showMarkup) {
            const otherCategory = layer.categories[99999];
            var objBounds = obj.getBounds();

            const category = layer.categories[obj.category];
            const addWithCategory = (!category && otherCategory && otherCategory.isOn) || (category && category.isOn);
            if (objBounds.intersects(mapBounds) && addWithCategory) {
                obj.itsLayer = layer;
                if ((obj.type !== "orientation") && window.appstate.showObjectInfo) {
                    //labelMarker.setLabel(obj.options.comment, this.showComments);
                }
                //this.addOrientation(obj);
                this.clusterShapes.push({ draw: obj, order: 1 });
            }
        }
    }
    onMouseDown(e) {
        let origE = e.originalEvent;
        this.moving = true;
        if (origE.button !== 0) {
            return;
        }
        if (this.neuralAssistOn && !e.originalEvent.ctrlKey) {
            const helperType = this.mapManager.neuralHelperType;
            console.log("Helper type", helperType);
            if (helperType === "helper1") {
                const point = this.mapManager.toFlat(e.latlng);
                this.eventAggregator.fire("mouseneuralclick", point);
            }
            else if (helperType === "helper2") {
                this.clearTools(e);
                this.mapManager.map.dragging.disable();
                this.drawNeuralTool(e);
            }
            return;
        }
        this.clearTools(e);
        if (this.addToSelection(e)) {
            return;
        }
        if (this.removeFromSelection(e)) {
            return;
        }
        if (origE.button === 0) {
            this.mapManager.map.dragging.disable();
        }
        if (!window.appstate.isEditable && this.tool !== "edit") {
            return;
        }
        if (origE.button === 0 && (this.tool == "poly" || this.tool == "shadow" || this.tool == "obox") && this.drawMarkupPoint(e)) {
            return;
        }
        if (this.tool === "point" && this.processPoint(e)) {
            return;
        }
        if (origE.defaultPrevented && origE.button === 0) {
            this.mapManager.map.dragging.disable();
            return;
        }

        let processed = false;
        switch (this.tool) {
            case "edit":
                processed = this.selectOne(e);
                if (!processed) {
                    processed = this.drawEditTool(e);
                }
                break;
            case "box":
            case "poly":
            case "center":
            case "obox":
            case "shadow":
                processed = this.createNew(e);
                break;
            case "brush":
            case "eraser":
            case "note":
                processed = this.mapDrawingsManager.onMouseDown(e, this.tool);
                break;
            case "ruler":
                processed = this.drawRulerTool(e);
                break;
        }
        if (processed) {
            return;
        }
    }
    clearTools(e) {
        if (e) {
            let origE = e.originalEvent;
            if (this.ruler && !origE.defaultPrevented) {
                this.ruler.remove();
            }
        }
        else {
            if (this.ruler) {
                this.ruler.remove();
            }
        }
        if (this.editTool) {
            this.editTool.remove();
        }
        if (this.neuralBoxTool) {
            this.neuralBoxTool.remove();
            this.neuralBoxTool = null;
        }
    }
    addToSelection(e) {
        const orig = e.originalEvent;
        if (orig.button === 0 && orig.shiftKey) {
            const nearest = this.findNear(e);
            if (nearest) {
                if (!this.selectedList.includes(nearest)) {
                    this.selectedList.push(nearest);
                    nearest.deselect();
                }
                nearest.select();
                return true;
            }
        }
        return false;
    }
    findNear = e => {
        let mousePoint = this.mapManager.toFlat(e.latlng);
        let inClick = [];
        if (this.drawingModeOn) {
            inClick = this.findInDrawings(mousePoint);
        }
        else {
            inClick = this.findInMarkups(mousePoint);
        }
        let nearest = null;
        let dist;
        for (const entry of inClick) {
            const d = ShapesMath.DistanceToPolyCenter(entry.points, mousePoint);
            if (!dist || d < dist) {
                dist = d;
                nearest = entry.obj;
            }
        }
        return nearest;
    }
    selectMany = rect => {
        this.clearSelection();
        this.selectedList = this.findInRect(rect);
        for (const shape of this.selectedList) {
            shape.select();
        }
        if (this.selectedList.length > 0) {
            this.mapManager.fire("selected", { obj: null, list: this.selectedList });
        }
    }
    findInRect = rect => {
        const bounds = rect.getBounds();
        const list = [];
        if (this.drawingModeOn) {
            for (const obj of this.drawings) {
                if (obj.intersects(bounds)) {
                    list.push(obj);
                }
            }
        }
        else {
            for (var layer of this.mapMarkupLayersManager.getVisible()) {
                for (const obj of layer.data) {
                    if (!obj.isVisible()) {
                        continue;
                    }
                    if (obj.intersects(bounds)) {
                        list.push(obj);
                    }
                }
            }
        }
        return list;
    }
    removeFromSelection = e => {
        const orig = e.originalEvent;
        if (orig.button !== 0 || !orig.altKey) {
            return false;
        }
        const shapeInClick = this.findSelectedNear(e);
        if (shapeInClick) {
            shapeInClick.deselect();
            var index = this.selectedList.indexOf(shapeInClick);
            if (index !== -1) {
                this.selectedList.splice(index, 1);
                return true;
            }
        }
        return false;
    }
    findSelectedNear = e => {
        let mp = this.mapManager.toFlat(e.latlng);
        const inClick = [];
        for (const obj of this.selectedList) {
            this.addToInClick(inClick, obj, mp);
        }
        let nearest = null;
        let dist;
        for (const entry of inClick) {
            const d = ShapesMath.DistanceToPolyCenter(entry.points, mp);
            if (!dist || d < dist) {
                dist = d;
                nearest = entry.obj;
            }
        }
        return nearest;
    }

    findInDrawings(mp) {
        const inClick = [];
        for (const obj of this.mapDrawingsManager.drawings) {
            this.addToInClick(inClick, obj, mp);
        }
        return inClick;
    }
    findInMarkups(mousePoint) {
        const inClick = [];
        for (var layer of this.mapMarkupLayersManager.getVisible()) {
            for (var obj of layer.data) {
                if (!obj.isVisible()) {
                    continue;
                }
                this.addToInClick(inClick, obj, mousePoint);
            }
        }
        return inClick;
    }
    addToInClick(inClick, obj, mp) {
        const points = obj.getPoints();
        var isInPoly = (obj.type === "poly" || obj.type === "obox") ? ShapesMath.IsInPoly2(points, mp) : ShapesMath.IsInPoly(points, mp)
        if (isInPoly) {
            inClick.push({ obj: obj, points: points });
        }
    }
    drawMarkupPoint(e) {
        if (!this.editing) {
            return false;
        }
        if (this.selectedList.length !== 1) {
            return false;
        }
        const selected = this.selectedList[0];
        let finished = selected.addPoint(e.latlng, e.originalEvent.ctrlKey);
        if (finished) {
            this.editing = false;
        }
        return true;
    }
    processPoint(e) {
        if (this.selectedList.length !== 1) {
            return false;
        }
        const selected = this.selectedList[0];
        selected.processPoint(e);
        return true;
    }
    selectOne(e) {
        const nearest = this.findNear(e);
        if (!nearest) {
            this.clearSelection();
            return false;
        }
        if (this.selectedList.length === 1 && this.selectedList[0] === nearest) {
            return true;
        }
        this.clearSelection();
        this.selectedList.push(nearest);
        nearest.select();
        this.mapManager.fire("selected", { obj: nearest, category: "cat", layer: "layer" });
        return true;
    }
    drawRulerTool(e) {
        this.clearSelection();
        this.ruler = this.getNewObject(this.tool);
        const options = {
        }
        const point = this.mapManager.toFlat(e.latlng);
        this.ruler.createNew(options, point);
        this.ruler.select();
        return true;
    }
    drawEditTool(e) {
        this.clearSelection();
        this.editTool = this.getNewObject(this.tool);
        const options = {}
        const point = this.mapManager.toFlat(e.latlng);
        this.editTool.createNew(options, point);
        this.editTool.select();
        return true;
    }
    drawNeuralTool(e) {
        this.clearSelection();
        this.neuralBoxTool = this.getNewObject("neural");
        const options = {};
        const point = this.mapManager.toFlat(e.latlng);
        this.neuralBoxTool.createNew(options, point);
        this.neuralBoxTool.select();
        return true;
    }
    async getTrackerId(options,imgId) {

        let b = new BackEndService();
        var data = { imgId:imgId };
        let resp = await  b.getTrackerId(data);
        options.trackerId = resp.data.newTrackerId;
        console.log("getTrackerId_____createNew", options);
    }
    async createNew(e) {
        let activeLayer = this.mapMarkupLayersManager.getActiveLayer();
        if (!activeLayer) {
            return false;
        }
        this.clearSelection();
        if (!this.activeCategory) {
            this.activeCategory = activeLayer.categoryList[0];
        }
        let id = this.getMaxLayerId(activeLayer);
        const newObject = this.getNewObject(this.tool);
        this.editing = this.continiousTool();
       
        const options = {
            id: id,
            category: this.activeCategory.id,
            color: this.activeCategory.color,
            categoryName: this.activeCategory.name,
            itsLayer: activeLayer,
        }
        if (this.mapManager.datasetType === "Video") {
            await this.getTrackerId(options, this.mapManager.imgId);
        }
        const point = this.mapManager.toFlat(e.latlng);
        newObject.createNew(options, point);
        newObject.select();
        activeLayer.data.push(newObject);
        this.selectedList.push(newObject);
        this.mapManager.fire("selected", { obj: newObject, category: this.activeCategory, layer: activeLayer });
        this.addEdited(newObject);
        return true;
    }
    continiousTool() {
        return this.tool === "poly" || this.tool === 'obox' || this.tool === 'shadow';
    }
    clearSelection() {
        for (const selectedItem of this.selectedList) {
            selectedItem.deselect();
        }
        this.selectedList = [];
        this.mapManager.fire("selected", { obj: null });
    }
    isPassive(layer) {
        return !layer.isOn || layer.isReadOnly;
    }
    getMaxLayerId(activeLayer) {
        let id = 1;
        if (activeLayer.data.length > 0) {
            const ids = activeLayer.data.map(o => o.id);
            id = Math.max(...ids) + 1;
        }
        return id;
    }
    onMouseUp(e) {
        this.mapManager.map.dragging.enable();
        if (this.selectedList.length === 1 && !this.editing) {
            const selected = this.selectedList[0];
            selected.mouseUp(e);
        }

        this.moving = this.selectedList.length === 1 && this.continiousTool();
        if (this.moving) {
            return;
        }
        if (this.editTool) {
            this.selectMany(this.editTool.shape);
            this.editTool.remove();
            this.editTool = null;
        }
        this.fitImageOnMap(e);
        this.mouseUpTime = Date.now();
    }
    fitImageOnMap(e) {
        let origE = e.originalEvent;
        if (this.inputHelper.mouseMoved(origE)) {
            return;
        }
        const delta = Date.now() - this.mouseUpTime;
        if (delta > 500) {
            return;
        }
        if (origE.button === 2) {
            origE.preventDefault();
            this.mapManager.fire("fitimage");
            console.log("Fire fit image");
            return true;
        }
        return false;
    }
    onMouseMove(e) {
        if (e.originalEvent.buttons === 2) {
            this.moveShapes();
            return;
        }
        if (!window.appstate.isEditable) {
            return;
        }
        if (this.hasHelperLines()) {
            this.helperLines.draw(e);
        }
        if (this.moving) {
            if (this.drawingModeOn && this.tool !== "edit") {
                this.mapDrawingsManager.move(e.latlng);
            }
            else if (this.ruler && this.tool === "ruler") {
                this.ruler.move(e.latlng);
            }
            else if (this.editTool && this.tool === "edit") {
                this.editTool.move(e.latlng);
            }
            else if (this.neuralBoxTool && this.neuralAssistOn) {
                this.neuralBoxTool.move(e.latlng);
            }
            else if (this.selectedList.length === 1) {
                const selected = this.selectedList[0];
                selected.move(e.latlng);
                this.addEdited(selected);
            }
        }
    }
    hasHelperLines() {
        return this.tool === "box" || this.tool === "center" || this.tool === "ruler";
    }
    onZoom(zoom) {
        for (var layer of this.mapMarkupLayersManager.getVisible()) {
            for (var obj of layer.data) {
                if (!obj.isVisible()) {
                    continue;
                }
                obj.onZoom(zoom);
            }
        }
        if (this.ruler) {
            this.ruler.onZoom(zoom);
        }
        this.mapDrawingsManager.onZoom(zoom);
        this.drawInView(true);
    }
    toggleLayer(e, value) {
        const layer = this.mapMarkupLayersManager.getAll().find(l => l.id === e.id);
        layer.isOn = value;
        this.drawInView(true);
    }
    changeCategoryColor(data) {
        const layers = this.mapMarkupLayersManager.getAll();
        var layer = layers.find(l => l.id == data.layerId)
            for (var markupObj of layer.data) {

                if (markupObj.category == data.category.id) {
                    markupObj.changeColor(data.category.color);
                }
        }
        
    }
    toggleCategory(data) {
        const layer = this.mapMarkupLayersManager.getAll().find(l => l.id === data.layer);
        if (!layer) {
            return;
        }
        if (data.altOn) {
            for (const cat of data.list) {
                const category = layer.categories[cat.id];
                if (!category) {
                    continue;
                }
                category.isOn = cat.isOn;
            }
        }
        else {
            const category = layer.categories[data.category.id];
            if (!category) {
                return;
            }
            category.isOn = !data.category.isOn;
        }
        this.drawInView(true);
    }
    isEditableLayer(layer) {
        return layer.isOn && !layer.isReadOnly;
    }
    addEdited(obj) {
        if (!this.edited.includes(obj)) {
            this.edited.push(obj);
        }
    }
    finishEditing() {
        if (this.editing && this.selectedList.length === 1) {
            const selected = this.selectedList[0];
            const result = selected.finish();
            if (!result) {
                return;
            }
            this.editing = false;
        }
    }
    cancelObject() {
        this.clearTools();
        if (!this.editing) {
            return;
        }
        this.deleteObject();
    }
    deleteObject() {
        if (!window.appstate.isEditable) {
            return;
        }
        if (this.selectedList.length === 1) {
            const shape = this.selectedList[0];
            this.commandHistory.execute(() => this.deleteObjectAction(shape), () => this.revertObjectDeletion(shape));
            this.addEdited(shape);
        }
        else if (this.selectedList.length > 0) {
            const list = [...this.selectedList];
            this.commandHistory.execute(() => this.deleteManyObjects([...list]), () => this.revertManyObjectDeletion([...list]));
            this.addEdited(list[0]);
        }
        this.drawInView();
    }
    revertObjectDeletion(shape) {
        const selectedLayer = this.mapMarkupLayersManager.getActiveLayer();
        selectedLayer.data.push(shape);
        this.addEdited(shape);
        if (selectedLayer.disposeList) {
            deleteItem(selectedLayer.disposeList, shape);
        }
        if (this.drawingModeOn) {
            this.mapDrawingsManager.drawings.push(shape);
        }
        shape.add();
        shape.select();
        this.selectedList.push(shape);
        //vueContext.onUpdateCategoryCount(shape.category, 1);
        //window.services.mapManager.findCommentsInView();
        this.drawInView();
    }

    revertManyObjectDeletion(objects) {
        const selectedLayer = this.mapMarkupLayersManager.getActiveLayer();
        for (const obj of objects) {
            const shape = obj;
            if (obj.type == "note" || obj.type == "brush") {
                this.mapDrawingsManager.drawings.push(obj);
                obj.add();
            }
            else {
                selectedLayer.data.push(shape);
                this.addEdited(shape);
                if (selectedLayer.disposeList) {
                    deleteItem(selectedLayer.disposeList, shape);
                }
                shape.add();
                //vueContext.onUpdateCategoryCount(shape.category, 1);
            }
            this.drawInView();
        }
    }

    deleteObjectAction(shape) {
        // window.services.appState.editing = false;
        const selectedLayer = this.mapMarkupLayersManager.getActiveLayer();
        this.clearSelection();
        deleteItem(selectedLayer.data, shape);
        if (!selectedLayer.disposeList) {
            selectedLayer.disposeList = [];
        }
        if (!selectedLayer.disposeList.includes(shape)) {
            selectedLayer.disposeList.push(shape);

        }
        deleteItem(this.edited, shape);
        deleteItem(this.mapDrawingsManager.drawings, shape);
        //vueContext.onUpdateCategoryCount(shape.category, -1);

        shape.remove();
        this.drawInView();
    }

    deleteManyObjects(objects) {
        this.editing = false;
        const selectedLayer = this.mapMarkupLayersManager.getActiveLayer();
        this.clearSelection();
        for (const obj of objects) {
            obj.remove();
            deleteItem(selectedLayer.data, obj);
            if (!selectedLayer.disposeList) {
                selectedLayer.disposeList = [];
            }
            if (!selectedLayer.disposeList.includes(obj)) {
                selectedLayer.disposeList.push(obj);
            }
            deleteItem(this.edited, obj);
            deleteItem(this.mapDrawingsManager.drawings, obj);
            deleteItem(this.selectedList, obj);
            // vueContext.onUpdateCategoryCount(obj.category, -1);
        }
        this.clearSelection();
        this.drawInView();
    }
    onSelectShape(shape) {
        this.clearSelection();
        shape.select();
        this.selectedList.push(shape);
        this.mapManager.fire("selected", { obj: shape, category: "cat", layer: "layer" });
        this.drawInView();
    }
    jumpToNextObject(e) {
        let nextSelected = this.jumpToObject(+1 * this.step, e.shift);
        this.setSelectObject(nextSelected);
    }
    jumpToPreviousObject(e) {
        let nextSelected = this.jumpToObject(-1 * this.step, e.shift);
        this.setSelectObject(nextSelected);
    }
    setSelectObject(shape) {
        if (!shape) {
            return;
        }
        const geoCenter = shape.getShapeCenter();
        if (geoCenter) {
            //this.mapManager.fire("centermap", geoCenter);
            this.mapManager.map.setView([geoCenter.lat, geoCenter.lng], this.mapManager.map.getZoom());
        }
        this.drawInView();
        this.onSelectShape(shape);
    }
    jumpToObject(step, chooseSameCategory) {
        const selected = this.selectedList[0];
        for (var layer of this.mapMarkupLayersManager.getVisible()) {
            const otherCategory = layer.categories[99999];
            let index = -1;
            let start = 0;
            if (selected) {
                index = layer.data.indexOf(selected);
                start++;
            }
            for (let i = start; i < layer.data.length; i++) {
                let newIndex = (index + i * step + layer.data.length) % layer.data.length;
                const element = layer.data[newIndex];
                const categoryId = element.category;
                let category = layer.categories[categoryId];
                if (!category) {
                    category = otherCategory;
                }
                if (chooseSameCategory && category && category.id === selected.category) {
                    return element;
                }
                if (!chooseSameCategory && category && category.isOn && element.type !== "orientation") {
                    return element;
                }
            }
        }
        return null;
    }
    onEditAction(action) {
        if (action === "undo") {
            this.commandHistory.undo();
        }
        else if (action === "redo") {
            this.commandHistory.redo();
        }
    }
    getItemsInsideNeuralBox() {
        if (!this.neuralBoxTool || !this.neuralBoxTool.shape) {
            console.log("No neural box");
            return null;
        }
        let rect = this.neuralBoxTool.shape;
        if (!rect) {
            rect = this.map;
        }
        var list = this.findInRect(rect);
        console.log("*** List: ***");
        const markupList = list.map(item => {
            return {
                id: item.id,
                type: item.type,
                points: item.getMarkupPoints(),
                category: item.category,
                comment: item.comment
            };
        });
        console.log(markupList);
        return markupList;
    }
    getNeuralBoxBounds() {
        if (!this.neuralBoxTool || !this.neuralBoxTool.shape) {
            console.log("No neural box");
            return null;
        }
        const shapeGeoBounds = this.neuralBoxTool.shape.getBounds();
        const x = shapeGeoBounds.getWest() * window.coordinateMultiplier;
        const y = -shapeGeoBounds.getNorth() * window.coordinateMultiplier;
        const w = shapeGeoBounds.getEast() * window.coordinateMultiplier - x;
        const h = -shapeGeoBounds.getSouth() * window.coordinateMultiplier - y;
        const bounds = {
            x: x,
            y: y,
            w: w,
            h: h,
        }
        return bounds;
    }
    getLayersToSave() {
        const list = [];
        const activeLayer = this.mapMarkupLayersManager.getActiveLayer();
        list.push(activeLayer);
        return list;
    }
    countIntersectionFraction(points1, bounds2) {
        const bounds1 = ShapesMath.GetBounds(points1);
        const intersection = ShapesMath.GetBoundsIntersection(bounds1, bounds2);
        const a = ShapesMath.GetBoundsArea(intersection);
        const a1 = ShapesMath.GetBoundsArea(bounds1);
        const a2 = ShapesMath.GetBoundsArea(bounds2);
        return a / Math.max(a1, a2);
    }

    getMarkupBounds(m) {
        const points = [
            { x: m.box[0], y: m.box[1] },
            { x: m.box[0] + m.box[2], y: m.box[1] + m.box[3] },
        ];
        return ShapesMath.GetBounds(points);
    }
    onInfoChanged(selected) {
        this.addEdited(selected);
        this.drawInView(true);
    }
}