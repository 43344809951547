import axios from 'axios';
export default class NeuralDataService {
    constructor() {
        this.baseAddress = ''
        this.apiAddress = window.location.pathname
        if (process.env.NODE_ENV == 'development') {
            this.baseAddress = 'https://localhost:5001'
        }
    }
    
    listHelpers(payload, callbacks) {
        this.get('/Neural/GetHelpersList?', payload, callbacks);
    }
    addTask(payload, callbacks) {
        this.post('/Neural/AddTask', payload, callbacks);
    }
    addBoxTask(payload, callbacks){
        this.post('/Neural/AddBoxTask', payload, callbacks);
    }
    addEmptyTask(payload, callbacks) {
        this.post('/Neural/AddEmptyTask', payload, callbacks);
    }
    getResult(payload, callbacks) {
        this.post('/Neural/GetTaskResult', payload, callbacks);
    }
    sendTest(payload, callbacks){
        this.post('/Neural/AddTestResult', payload, callbacks);
    }

    //private
    get(link, data, callbacks) {
        const searchParams = new URLSearchParams(data);
        axios.get(this.baseAddress + link + searchParams)
            .then(response => {
                callbacks.success(response.data);
            })
            .catch(err => {
                if (callbacks.error) {
                    callbacks.error(err)
                }
            });
    }
    post(link, data, callbacks) {
        axios.post(this.baseAddress + link, data)
            .then(response => {
                callbacks.success(response.data);
            })
            .catch(err => {
                if (callbacks.error) {
                    callbacks.error(err)
                }
            })
    }
}