<template>
  <section class="message-box">
      <div v-for="message in shownMessages" :key="message.key" class="message-item" :class="message.type">
          <span @click="hide(message)">{{message.text}}</span>
          <button @click="hide(message)">✕</button>
      </div>
  </section>
</template>

<script>
export default {
    data(){
        return {
            messages: [],
            counter: 0,
            hideTimeout : null
        }
    },
    computed : {
        shownMessages : {
            get(){
                return  this.messages.filter(m=>!m.type.hidden);
            }
        }
    },
    methods : {
        show(text, type){
            let classList = {hidden:false};
            let keep = false;
            if(type === 'success'){
                classList = {hidden:false, 'message-success':true};
            }
            else if(type === 'error'){
                keep = true;
                classList = {hidden:false, 'message-error':true};
            }
            else if(type === 'warning'){
                classList = {hidden:false, 'message-warning':true};
            }
            this.counter++;
            const m = {text:text, type: classList, key: this.counter};
            this.messages.push(m);
            if(!keep){
                setTimeout(()=>this.hide(m), 5000);
                console.log("mCount!", this.messages.length);
            }
        },
        hide(message){
            const index = this.messages.indexOf(message);
            if (index > -1) {
                this.messages[index].type.hidden = true;
                
                if(this.hideTimeout){
                     clearTimeout(this.hideTimeout);
                }
                this.hideTimeout = setTimeout(()=>{ 
                     if(this.messages.length > 1000){
                         this.messages = [];
                     }
                }, 300);
            }
        }
    },
    mounted() {
        this.$messageManger.show = this.show;
    }
}
</script>

<style>
.message-box{
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
}
.message-item{
    border-radius: 0.4em;
    border-color: black;
    color: black;
    padding: 0.3em;
    background-color: lightgray;
    border-style: solid;
    border-width: 2px;
    margin: 0.4em 0.2em;
    transform-origin: 100% center;
    animation: flyin 0.3s linear 1;
    animation-fill-mode: forwards;
}
.message-success {
    border-color: teal;
    color: teal;
    background-color: lightgreen;
}
.message-error {
    border-color: red;
    color: red;
    background-color: #ffcccc;
}
.message-warning {
    border-color: rgb(255, 166, 0);
    color: orange;
    background-color: #ffeecc;
}
.message-item > button {
    background-color: transparent;
    color: inherit;
    font-weight: bold;
    border: initial;
    opacity: 0.7;
}
.message-item > button:hover {
  color: black;
  transform: scale(1.3);
  opacity: 1;
}
.message-item.hidden {
    animation: flyout 0.3s linear 1;
    animation-fill-mode: forwards;
}
@keyframes flyin {
    0% {
        transform: scaleX(0);
    }
    100% {
        transform: scaleX(1);
    }
}
@keyframes flyout {
    0% {
        transform: scaleX(1);
    }
    100% {
        transform: scaleX(0);
    }
}
</style>