<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="24px"
    height="24px"
    viewBox="0 0 100 100"
    style="enable-background: new 0 0 100 100"
    xml:space="preserve"
  >
    <g id="turf-point-on-line">
      <path
        d="M10.658,91.166c-0.512,0-1.024-0.195-1.414-0.586c-0.781-0.781-0.781-2.047,0-2.828l7.071-7.071
		c0.78-0.781,2.048-0.781,2.828,0c0.781,0.781,0.781,2.047,0,2.828l-7.071,7.071C11.682,90.971,11.169,91.166,10.658,91.166z"
      />
      <path
        d="M53.084,48.74c-0.512,0-1.023-0.195-1.414-0.586c-0.781-0.781-0.781-2.047,0-2.828l7.071-7.071
		c0.781-0.781,2.047-0.781,2.828,0c0.781,0.781,0.781,2.047,0,2.828l-7.071,7.071C54.107,48.544,53.596,48.74,53.084,48.74z"
      />
      <path
        d="M67.227,34.598c-0.512,0-1.023-0.195-1.414-0.586c-0.781-0.781-0.781-2.047,0-2.828l7.071-7.071
		c0.781-0.781,2.047-0.781,2.828,0c0.781,0.781,0.781,2.047,0,2.828l-7.071,7.071C68.25,34.402,67.738,34.598,67.227,34.598z"
      />
      <path
        d="M81.368,20.456c-0.512,0-1.023-0.195-1.414-0.586c-0.781-0.781-0.781-2.047,0-2.828l7.071-7.071
		c0.781-0.781,2.047-0.781,2.828,0c0.781,0.781,0.781,2.047,0,2.828l-7.071,7.071C82.392,20.26,81.88,20.456,81.368,20.456z"
      />
      <g>
        <path
          d="M27.655,69.34l-4.27,4.269c-0.781,0.78-0.781,2.047,0,2.828c0.39,0.391,0.902,0.586,1.414,0.586s1.023-0.195,1.414-0.586
			l4.27-4.269C29.344,71.446,28.378,70.48,27.655,69.34z"
        />
        <path
          d="M47.427,52.396c-0.78-0.781-2.048-0.781-2.828,0l-4.421,4.421c1.14,0.722,2.106,1.689,2.828,2.828l4.421-4.421
			C48.208,54.443,48.208,53.178,47.427,52.396z"
        />
      </g>
      <circle cx="35.331" cy="64.493" r="6.845" />
      <path
        d="M17.331,55.338c-4.877,0-8.845-3.968-8.845-8.845c0-4.877,3.968-8.845,8.845-8.845c4.877,0,8.845,3.968,8.845,8.845
		C26.176,51.37,22.208,55.338,17.331,55.338z M17.331,41.648c-2.672,0-4.845,2.173-4.845,4.845c0,2.671,2.173,4.845,4.845,4.845
		c2.671,0,4.845-2.173,4.845-4.845C22.176,43.821,20.002,41.648,17.331,41.648z"
      />
    </g>
  </svg>
</template>