import highlight from "./Highlighter";
export default class TreeFS {
    name;
    id;
    fullname;
    branch;
    isExpanded;
    isVisible;
    parts;
    fullnameParts;
    constructor(tree, Expanded) {
        this.name = tree.Name;

        this.id = tree.ID;
        this.fullname = tree.FullName;

        this.branch = [];
        this.isExpanded = Expanded;
        this.isVisible = true;
        this.fullnameParts=[];
        this.fullnameParts.push({text: tree.FullName, isHighlighted: false});
        this.parts = [];
        this.parts.push({ text: tree.Name, isHighlighted: false });
    }
    create(tree, Expanded) {
        for (var subtree of tree.Branch) {
            var b = new TreeFS(subtree, Expanded);
            this.branch.push(b);
            b.create(subtree, Expanded);
        }
    }
    search(tree, searchStr) {

        tree.isVisible = false;

        tree.isExpanded = false;
        for (var subtree of tree.branch) {

          

            subtree.isExpanded = false;
            subtree.isVisible = false;
            if (subtree.branch.length > 0) {
                this.search(subtree, searchStr);
            }
            if (!subtree.name) {
                continue;
            }
            if (subtree.name.toLowerCase().includes(searchStr.toLowerCase())) { 
                subtree.isExpanded = true;
                subtree.isVisible = true;
                subtree.parts = highlight(subtree.name, searchStr);
            }
            else {
                subtree.parts = highlight(subtree.name, "");
            }

            if (subtree.fullname && subtree.fullname.toLowerCase().includes(searchStr.toLowerCase())) {
                subtree.isExpanded = true;
                subtree.isVisible = true;
                subtree.fullnameParts = highlight(subtree.fullname, searchStr);
            }
            else{
                subtree.fullnameParts = highlight(subtree.fullname, "");
            }
            if (subtree.isExpanded) {
                tree.isExpanded = true;
                tree.isVisible = true;
            }
        }
    }
    searchClear(tree) {
        for (var subtree of tree.branch) {
            subtree.parts = highlight(subtree.name, "");
            subtree.fullnameParts = highlight(subtree.fullname, "");
            subtree.isVisible = true;
            subtree.isExpanded = false;
            if (subtree.branch.length > 0) {
                this.searchClear(subtree);
            }
        }
    }
}