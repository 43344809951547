import ShapeParam from "./ShapeParam";
export default class NeuralBoxShape extends ShapeParam {
    type = "neural";
    constructor(mapManager, options) {
        super(mapManager, options);
        this.points = [];
        this.size = 5;
        const shapeOptions = {shape: "neural", fill:true, dashArray: "1 6", color: "fuchsia"};
        this.shape = window.L.polygon(this.points, shapeOptions);
    }
    changeColor() {
    }
    draw(points) {
        let geo = this.toGeo(points);
        this.shape.setLatLngs(geo);
        this.drawCorners();
    }
    drawCorners() {
    }
    add() {
        this.shape.addTo(this.map);
    }
    remove() {
        this.shape.removeFrom(this.map);
    }
    selectCorner() { 
        
    }
    move(latlng) {
        let lat = latlng.lat;
        let lon = latlng.lng;
        var geo = this.getGeoPoints();
        const min = this.minSize();
        if(Math.abs(lat-geo[0].lat)< min || Math.abs(lon-geo[0].lng) < min){
            return;
        }
        const p1 = {lat: geo[0].lat, lng:geo[0].lng};
        const p2 = {lat: geo[1].lat, lng:lon};
        const p3 = {lat: lat, lng:lon};
        const p4 = {lat: lat, lng:geo[3].lng};
        this.shape.setLatLngs([p1,p2,p3,p4]);
    }
}