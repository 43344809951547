<template>
  <div class="right-top favourite-categories">
    <div v-if="isVisible" class="category-list">
      <p class="favourite-header">избранные классы</p>
      <p class="empty-list-info" v-if="list.length === 0">
        Пока здесь ничего нет. Откройте дерево класов и отметьте звездочкой нужные классы
      </p>
      <p class="category-item" v-for="cat in list" :key="cat.id">
        <span @click="e => select(e,cat)" class="catregory-name">{{cat.name}}</span>
        <span class="delete-button">
          <button @click="() => deleteFavourite(cat)" class="close-button">⨉</button></span>
      </p>
    </div>
    <div>
        <div v-if="!isVisible" class="tooltip">
        <img
          @click="toggle"
          src="../../assets/images/full-star.svg"
        />
        <span class="tooltiptext">Избранные категории</span>
      </div>
        
        <button v-if="isVisible" class="hide-button" @click="toggle">
        <span>&gt;</span>
        </button>
    </div>
  </div>
</template>

<script>
import { deleteItem } from "../../services/tools";
import StorageService from "../../services/StorageService";

export default {
  data() {
    return {
      list: [],
      isVisible:false
    };
  },
  mounted() {
    this.storagService = new StorageService();
    this.$services.eventAggregator.on("togglefavouritecategory", d=>this.toggleFavourite(d));
    this.$services.eventAggregator.on("favouritecategories", (l) =>
      this.checkFavourite(l)
    );
    window.appstate.favourites = this.storagService.getFavoriteCategories();
    this.$services.eventAggregator.fire("favouritecategories",window.appstate.favourites);
  },
  methods: {
    checkFavourite(newList) {
      this.list = [...newList];
      this.storagService.setFavoriteCategories(newList);
    },
    deleteFavourite(category) {
      let existing = window.appstate.favourites.find(
        (f) => f.id == category.id && f.name == category.name
      );
      if (existing) {
        deleteItem(window.appstate.favourites, existing);
        this.$services.eventAggregator.fire(
          "favouritecategories",
          window.appstate.favourites
        );
      }
      console.log("Fav_list", window.appstate.favourites);
    },
    select(e, category) {
      this.$services.eventAggregator.fire("favouritecategoryselected", {category:category, changeSelected: e.ctrlKey});
    },
    toggle(){
        this.isVisible = !this.isVisible;
    },
    toggleFavourite(data){
      if(!data.category){
        return;
      }
      let existing = window.appstate.favourites.find(f=>f.id == data.category.id && f.name == data.category.name);
      console.log("Fav", existing, data);
      if(!existing && data.isFavourite){
        existing = {id:data.category.id, name: data.category.name};
        window.appstate.favourites.push(existing);
        this.$services.eventAggregator.fire("favouritecategories",window.appstate.favourites)
      }
      else if(existing && !data.isFavourite){
        deleteItem(window.appstate.favourites, existing);
        this.$services.eventAggregator.fire("favouritecategories",window.appstate.favourites)
      }
      console.log("Fav_list", window.appstate.favourites);

    }
  },
};
</script>

<style scoped>
.hide-button{
    height: 100%;
    padding: 0.1em;
    border-width: 0;
    border-left: 2px solid black;
}
.right-top {
  position: absolute;
  top: 0;
  right: 0;
  margin-left: auto;
}
.favourite-categories{
 display: flex;
 background-color: rgba(255, 255, 255, 0.7);
  border: 2px solid black;
  margin: 0;
  margin-top: 0.2em;
  padding: 0.2em;
  border-radius: 5px;
  z-index: 1000;   
}
.category-list {
    max-height: 10rem;
    overflow-y: auto;
}
.category-list > p {
  margin: 0.1em;
}
.category-item {
  display: flex;
  border-bottom: 1px solid grey;
  padding-bottom: 0.2em;
  align-items: center;
}
.delete-button {
  margin-left: auto;
}
.catregory-name {
  margin-right: 0.3em;
}
.favourite-header {
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid grey;
  padding-bottom: 0.2em;
}
.empty-list-info{
    text-align: center;
    max-width: 20rem;
}
</style>