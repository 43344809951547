<template>
  <div>
    <div>
      <p class="word-item-wrapper" v-if="treeroot.isVisible">
        <button
          class="button-open"
          v-if="treeroot.isExpanded && treeroot.branch.length > 0"
          @click="close"
        >
          -
        </button>
        <button
          class="button-open"
          v-if="!treeroot.isExpanded && treeroot.branch.length > 0"
          @click="open"
        >
          +
        </button>
        <button class="button-empty" v-if="treeroot.branch.length < 1">■</button>

        <span
          @click="selectCategory()"
          v-for="part in treeroot.parts"
          :key="part.key"
          :class="{
            'word-highlight': part.isHighlighted,
            'word-item': true,
            pointed: true,
          }"
          >{{ part.text }}</span
        >
        <span class="eye">|</span>
        <span>ID:{{ treeroot.id }}</span>
        <span class="eye">|</span>

        <img
          v-if="treeroot.branch.length > 0"
          src="../../assets/images/expand-all.svg"
          @click="openAll(treeroot)"
        />
        <img
          v-if="treeroot.branch.length > 0"
          src="../../assets/images/collapse-all.svg"
          @click="closeAll"
        />
        <img
          title="Убрать из избранного"
          v-if="isFavourite"
          src="../../assets/images/full-star.svg"
          @click="()=>toggleFavourite(false)"
        />
        <img
          title="Добавить в избранное"
          v-if="!isFavourite"
          src="../../assets/images/empty-star.svg"
          @click="()=>toggleFavourite(true)"
        />
        <!--<img
          src="../assets/images/eye.svg"
          class="eye"
          v-if="treeroot.branch.length > 0"
          @click="showHidden"
        />
        <img src="../assets/images/info.svg" class="eye" @click="showInfo" />-->
      </p>

      <p class="word-item-wrapper, fullname" v-if="treeroot.isVisible">
        <small 
          @click="selectCategory()"
          v-for="part in treeroot.fullnameParts"
          :key="part.key"
          :class="{
            'word-highlight': part.isHighlighted,
            'word-item': true,
            pointed: true,
          }"
        >
          {{ part.text }}</small
        >
      </p>

      <div class="sub-node" v-if="treeroot.branch.length > 0 && treeroot.isExpanded">
        <div v-for="child in treeroot.branch" :key="child.Name">
          <category-tree-item
            :treeroot="child"
            @toggle-expand="toggleExpand"
            @toggle-view="toggleView"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryTreeItem from "./CategoryTreeItem.vue";
export default {
  data(){
    return{
      isFavourite: false
    };
  },
  props: ["treeroot"],
  components: {
    CategoryTreeItem,
  },
  inject: ["onNodeSelected"],
  emits: ["toggle-expand", "toggle-view"],
  mounted(){
    this.$services.eventAggregator.on("favouritecategories", l=>this.checkFavourite(l));
    this.checkFavourite(window.appstate.favourites);
  },
  methods: {

    toggleView(parent) {
      for (var rt of parent.branch) {
        rt.isVisible = true;
      }
    },
    showHidden() {
      this.$emit("toggle-view", this.treeroot);
    },
    openAll() {
      this.$emit("toggle-expand", true, this.treeroot, true);
    },
    closeAll() {
      this.$emit("toggle-expand", false, this.treeroot, true);
    },
    open() {
      this.$emit("toggle-expand", true, this.treeroot);
    },
    close() {
      this.$emit("toggle-expand", false, this.treeroot);
    },
    toggleExpand(shouldExpand, branch, recursive) {
      var child = this.treeroot.branch.find((i) => i == branch);
      if (child) {
        child.isExpanded = shouldExpand;
      }
      if (recursive && child) {
        this.toggleExpandRecursive(shouldExpand, child);
      }
    },

    toggleExpandRecursive(shouldExpand, parent) {
      for (var rt of parent.branch) {
        rt.isExpanded = shouldExpand;
        this.toggleExpandRecursive(shouldExpand, rt);
      }
    },
    selectCategory() {
      this.onNodeSelected(this.treeroot);
    },
    checkFavourite(list){
      this.isFavourite = false;
     for (const fav of list) {
      if(fav.name === this.treeroot.name && fav.id === this.treeroot.id){
        this.isFavourite = true;
        return;
      }
    }   
    },
    toggleFavourite(isFavourite){
      const data = {
        isFavourite : isFavourite,
        category : this.treeroot
      };
      this.$services.eventAggregator.fire("togglefavouritecategory", data);
    }
  },
};
</script>

<style scoped>
.eye {
  position: relative;
  padding-right: 0.225em;
  padding-left: 0.225em;
  font-weight: bold;
  z-index: -1;
}
.button-open {
  border-radius: 4px;
  font-size: 12px;
  font: bold;
  font-family: "Fira Code", monospace;
  background: #1b6ec2;
  color: white;
  cursor: pointer;
  margin-right: 0.5em;
}
.button-empty {
  border-radius: 4px;
  border-color: transparent;
  background: transparent;
  display: inline-block;
  padding-bottom: 0.5em;
  color: #1b6ec2;
  flex-grow :0;
}
.word-item-wrapper {
  display: flex;
  align-items: center;
}
.sub-node {
  margin-top: 0.2em;
}
.pointed {
  cursor: pointer;
}
.shifted {
  margin-left: 0.5em;
}
.abcde-wrap {
  /* обертка пункта */
  text-decoration: none;
  display: block; /* растянем до правого края блока */
  margin: 0 0 0 1.3em;
  background-color: transparent;
  border: dotted silver; /* цвет линий */
  border-width: 0 0 1px 1px; /*границы: низ и лево*/
}
.abcde {
  /* текст элемента дерева */
  display: block;
  position: relative;
  top: 0.95em; /* смещаем узел на середину линии */
  background-color: #fff; /* закраска в цвет фона обязательна иначе будет видно линию */
  margin: 0 0 0.2em 0.7em; /* делаем промежуток между узлами, отодвигаем левее  */
  padding: 0 0.3em; /* небольшой отступ от линии */
}
.fullname {
  margin-left: 0.2em;
  margin-top: 0.2em;
}
.word-highlight {
  background-color: yellow;
}
.word-select {
  background-color: lightskyblue;
}
</style>