import ShapeObjectBase from "./ShapeObjectBase";
import LabelMarker from "../tooling/LabelMarker";
import ClusterShape from "../shapes/ClusterShape";
export default class ClusterObject extends ShapeObjectBase {
    shape;
    type = "cluster";
    labelPnt;

    constructor(mapManager) {
        super(mapManager);
    }

    createNew(groupBounds, labels) {
        const p1 = this.mapManager.toFlat(groupBounds.getNorthWest());
        const p2 = this.mapManager.toFlat(groupBounds.getSouthEast());
        let points = [
            {x: p1.x, y: p1.y},
            {x: p2.x, y: p1.y},
            {x: p2.x, y: p2.y},
            {x: p1.x, y: p2.y},
        ];
        const options = {
            color: "yellow",
            categoryName: ""
        };
        this.label = new LabelMarker(this.map, options);
        this.label.setMultiLabel(labels);
        this.shape = new ClusterShape(this.mapManager, options);
        this.shape.draw(points);
    }

    
}
