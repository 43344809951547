<template>
  <div>
    <div class="object-info-flex">
      <strong
        v-bind:style="{
          margin: '0 0.4em 0 0',
          borderBottom: '6px solid ' + selectedCategory.color,
        }"
        id="selectedObjectClassLabel"
      >
        Класс
      </strong>
      <CategorySelector
        :categories="categories"
        ref="categorySelectorItem"
        @category-selected="onCategoryChanged"
      />
      <div class="tooltip">
        <img
          src="../../assets/images/tree.svg"
          @click="openTree"
        />
        <span class="tooltiptext">дерево классов</span>
      </div>
        
      <!-- <input type="hidden" id="selectedCategoryColor" /> -->
    </div>
    <!-- <div v-if="!editable" class="margined">
    <span >
      {{ currentCategory.name }}
    </span>
  </div> -->

    
  </div>
  <category-tree v-if="treeIsOpened" ref="categoryTreeItem" @close-tree="closeTree" @put-in-line="onTreeSelected" @on-mount="onTreeMounted" />

</template>

<script>
import CategorySelector from "./CategorySelector.vue";
import CategoryTree from "./CategoryTree.vue";


export default {
  data() {
    return {
      selectedCategory: { id: 0, color: "red", name: "Name" },
      categories: [],
      treeIsOpened: false,
      layer: null
    };
  },
  components: {
    CategorySelector,
    CategoryTree,
  },
  mounted(){
    this.$services.mapManager.on("activelayer", this.setLayer);
    this.$services.eventAggregator.on("favouritecategoryselected", c => this.favouriteCategorySelected(c));

  },
  methods : {
    favouriteCategorySelected(data){
        this.onTreeSelected(data.category);
    },
    updateCategorySelector(){
      const item = this.$refs["categorySelectorItem"];
      if(item){
        item.initShowList(this.categories);
        item.setItem(this.selectedCategory);
      }
      const treeItem = this.$refs["categoryTreeItem"];
      if(treeItem){
        treeItem.init(this.categories);
      }
    },
    onCategoryChanged(category){
      const categoryToSelect = this.categories.find(c=>c.id === category.id);
      if(categoryToSelect){
        this.selectedCategory = categoryToSelect;
        this.$services.eventAggregator.fire("drawcategoryselected", categoryToSelect);
      }
    },
    setLayer(layer){
      if(!layer){
        return;
      }
      this.initObjectCategories(layer.categoryList);
      this.categories = layer.categoryList;
    },
    initObjectCategories(initCategories) {
      this.categories = initCategories;
      this.categories.sort((a,b) => {
        if(a.name < b.name) {return -1;}
        if(a.name > b.name) {return 1;}
        return 0;
      });
      const currentSelected = this.categories.find(
        (c) => c.id == this.selectedCategory.id
      );
      if (currentSelected) {
        this.selectedCategory = currentSelected;
      } else {
        this.selectedCategory = this.categories[0];
      }
      this.updateCategorySelector();
      this.$services.eventAggregator.fire("drawcategoryselected", this.selectedCategory);
    },
    openTree(){
      this.treeIsOpened = true;
    },
    closeTree(){
      this.treeIsOpened = false;
    },
    onTreeSelected(node){
      const categoryToSelect = this.categories.find(c=>c.id === node.id);
      if(categoryToSelect){
        this.selectedCategory = categoryToSelect;
        this.$refs["categorySelectorItem"]?.setItem(categoryToSelect);
        this.$services.eventAggregator.fire("drawcategoryselected", categoryToSelect);
      }
      this.treeIsOpened = false;
    },
    onTreeMounted(){
      const treeItem = this.$refs["categoryTreeItem"];
      if(treeItem){
        treeItem.init(this.categories);
        treeItem.setValue(this.selectedCategory.name);
      }
    }
  }
};
</script>

<style scoped>
.object-info-flex {
  display: flex;
  align-items: center;
  margin-left: 0.2em;
}
</style>