import ShapeObjectBase from "./ShapeObjectBase";
import BrushShape from "../shapes/BrushShape";
import LabelMarker from "../tooling/LabelMarker";
export default class BrushObject extends ShapeObjectBase {
    shape;
    type = "brush";
    points = [];
    finished = false;

    constructor(mapManager) {
        super(mapManager);
    }

    createShape(drawing) {
        this.color = drawing.color;
        var options = new Object();
        options.drawing=drawing;
        options.color = drawing.color;
        this.shape = new BrushShape(this.mapManager, options);
        for(var p of drawing.points){
            this.shape.points.push(p);
        }
        this.shape.draw(this.shape.points);
        this.shape.add();
        this.label = new LabelMarker(this.map);
    }

    createNewShape(options, point) {
        this.shape = new BrushShape(this.mapManager, options);
        this.shape.points.push(point);
        this.shape.draw(this.shape.points);
        this.shape.add();
        this.select();
    }

    select() {
        this.selected = true;
        this.shape.drawCorners();
    }
    deselect() {
        this.shape.remove();
        this.selected = false;
        this.shape.selected = false;
        this.shape.add();
    }
    move(point) {
        if (this.finished) {
            return;
        }
        this.shape.move(point);
    }
    mouseUp() {
        this.finished = true;
    }
    updateLabel() {
        
    }
    onZoom() {

    }
}