<template>
  <div>
    <ul class="layer-list">
      <li v-for="layer in layers" :key=layer.id class="layer-list-item">
            <div>
                <div class="layer-item">
                    <div class="form-check flex-grow-0">
                        <input type="checkbox" :id="layer.idName" class="form-check-input growable" v-model="layer.isOn" @input="toggleLayer(layer)">
                    </div>
                    <label v-bind:for="layer.idName"  v-bind:style="{ paddingLeft: '1em;', flexGrow: 1}">
                        <div><span class="layer-name noselect" bottom-lined>{{layer.name}}</span></div>

                    </label>
                </div>
                <div class="layer-item">
                    <div v-if="extended" class="layer-name__container">
                        <span class="layer-name layer-type noselect"  v-bind:style="{ color: 'light-gray', fontSize: '0.8rem'}" bottom-lined>{{layer.type}}</span>
                    </div>
                    <div class="right-pos">
                        <span class="elements-counter my-auto">{{layer.count}}</span>
                        <button v-if="!layer.isExpanded" type="button" class="btn btn-outline-primary btn-sm list-expander" @click="expandLayer(layer);">&#43;</button>
                        <button v-else type="button" class="btn btn-outline-primary btn-sm list-expander"  @click="expandLayer(layer);">&#45;</button>
                    </div>
                </div>

                <!-- <img v-if="!layer.isLocked" src="~/img/lock.svg" class="m-auto" width="18" height="18" /> -->

            </div>
            <ul v-if="layer.isExpanded"  class="layer-list category-list">
                <li v-for="item in layer.categoryList" :key=item.id class="layer-list-item">
                    <label style="margin-bottom: 0; text-wrap: none; display: flex; justify-content: space-between; width: 100%">
                        <input type="checkbox" v-model="item.isOn" @click="toggleCategory(item, layer, $event)" />
                        <span class="mx-1 font-weight-bold" v-bind:style="{ margin: 'auto'}">{{item.name}}</span>
                        <!--<span class="mx-1 font-weight-bold" v-bind:style="{ margin: 'auto', borderBottom: '6px solid ' + item.color}">{{item.name}}</span>-->
                        <input type="color" class="form-tem" v-model="item.color" @change="changeLayerColor(item, layer)"/>
                        <span class="elements-counter right-pos">{{item.count}}</span>
                    </label>
                </li>
            </ul>
        </li>
  </ul>
  <div v-if="hasExtraLayers" class="text-centered expander">
      <button v-if="extended && !extraLayersLoaded" class="tool-button more-button" @click="loadMoreLayers">Ещё</button>
      <hr v-if="extraLayersLoaded">
      <button v-if="extraLayersLoaded" class="hide-layers-button" @click="hideExtraLayers" >▲</button>
  </div>
    <ul class="layer-list">
        <li v-for="layer in extraLayers" :key=layer.id class="layer-list-item">
            <div>
                <div class="layer-item">
                    <div class="form-check flex-grow-0">
                        <input type="checkbox" :id="layer.idName" class="form-check-input growable" v-model="layer.isOn" @input="toggleLayer(layer)">
                    </div>
                    <label v-bind:for="layer.idName"  v-bind:style="{ paddingLeft: '1em;', flexGrow: 1}">
                        <div><span class="layer-name noselect" bottom-lined>{{layer.name}}</span></div>

                    </label>
                </div>
                <div class="layer-item">
                    <div v-if="extended" class="layer-name__container">
                        <span class="layer-name layer-type noselect"  v-bind:style="{ color: 'light-gray', fontSize: '0.8rem'}" bottom-lined>{{layer.type}}</span>
                    </div>
                    <div class="right-pos">
                        <span class="elements-counter my-auto">{{layer.count}}</span>
                        <button v-if="!layer.isExpanded" type="button" class="btn btn-outline-primary btn-sm list-expander" @click="expandLayer(layer);">&#43;</button>
                        <button v-else type="button" class="btn btn-outline-primary btn-sm list-expander"  @click="expandLayer(layer);">&#45;</button>
                    </div>
                </div>

                <!-- <img v-if="!layer.isLocked" src="~/img/lock.svg" class="m-auto" width="18" height="18" /> -->

            </div>
            <ul v-if="layer.isExpanded"  class="layer-list category-list">
                <li v-for="item in layer.categoryList" :key=item.id class="layer-list-item">
                    <label style="margin-bottom: 0; text-wrap: none; display: flex; justify-content: space-between; width: 100%">
                        <input type="checkbox" v-model="item.isOn" @click="toggleCategory(item, layer, $event)" />
                        <span class="mx-1 font-weight-bold" v-bind:style="{ margin: 'auto', borderBottom: '6px solid ' + item.color}">{{item.name}}</span>
                        <span class="elements-counter right-pos">{{item.count}}</span>
                    </label>
                </li>
            </ul>
        </li>
    </ul>
  </div>
</template>

<script>

    import BackEndService from "../../services/BackEndService.js"


export default {
  data () {
    return {
      currentLayer: null,
      layers: []
      // extraLayers : [],
    }
  },
        props: ['extended', 'extraLayers', 'extraLayersLoaded', 'hasExtraLayers','missionGroupId'],
  emits: ['load-extra-layers', 'hide-extra-layers'],
  methods: {

    changeLayerColor(category, layer) {
          this.$services.mapManager.fire("changeCategoryColor", { layerId: layer.id, category: category })
          if (window.appstate.serverUserRole == 'ServerAdmin' && layer == this.layers[0]) {
              console.log("changeLayerColor"+ this.missionGroupId);

              var tmpCat = {};
              var categories = [];
              layer.categoryList.forEach(c => {
                  if (c.id != 99999) {
                      tmpCat = {
                          color: c.color,
                          fullname: c.fullname,
                          id: c.id,
                          name: c.name,
                          supercategory: c.supercategory,
                          supercategoryId: c.supercategoryId,
                      }
                      categories.push(tmpCat)
                  }
              }
              )
              const dataObj = {
                  group: { id: this.missionGroupId },
                  markupCategories: categories
              };
              console.log("tmpCat", dataObj);

          var b = new BackEndService;
                  b.saveGroupCategories(dataObj)
          }
      },
    setLayers(layers){
      this.layers = layers;
    },
    expandLayer (layer) {
      layer.isExpanded = !layer.isExpanded
    },
    toggleLayer (l) {
      this.$services.mapManager.fire("togglelayer", l);
    },
    toggleCategory (toggledCategory, layer, e) {
      if(e.altKey){
        e.preventDefault();
        for (const catItem of layer.categoryList) {
          if(catItem != toggledCategory){
            catItem.isOn = !toggledCategory.isOn ;
          }
        }
        
      }
      this.$services.mapManager.fire("togglecategory", {layer:layer.id, category: toggledCategory, altOn: e.altKey, list:layer.categoryList});
    },
    loadMoreLayers () {
      this.$emit('load-extra-layers')
    },
    hideExtraLayers () {
      this.$emit('hide-extra-layers')
      // this.extraLayers = [];
      // this.extraLayersLoaded = false;
    },
    updateCount (data) {
      const id = data.id;
      const step = data.step;
      if (!this.currentLayer) {
        this.currentLayer = this.layers[0]
      }
      const category = this.currentLayer.categoryList.find(i => i.id === id)
      if (category) {
        category.count += step
        this.currentLayer.count += step
      }
    }
  },
  mounted () {
    // window.vueContext.onUpdateCategoryCount = (id, step) => {
    //   this.updateCount(id, step)
    // }
    this.$services.mapManager.on("updatecategorycount", this.updateCount);
    this.$services.mapManager.on("layers", this.setLayers);
  }
}
</script>

<style>
.hide-layers-button {
    position: absolute;
    border: transparent;
    background-color: rgba(172, 172, 172, 0.6);
    border-radius: 0.4em;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}
.expander {
    position: relative;
}
.layer-list{
    list-style: none;
    padding: 0;
    list-style-type: none;
    width: 100%;
    margin: 0.3rem 0;
}
.list-expander {
    margin-left: 0.2em;
}
.category-list{
    padding-left: 1em;
}
.layer-list-item{
    margin: 0.1em 0;
    padding: 0 0 0.6em 0;
}
.bottom-lined{
    border-bottom: 1px solid grey;
}
.right-pos{
    margin-left: auto;
}
.layer-item{
    display: flex;
    align-items: center;
    width: 100%;
}
.layer-name {
    margin: 0 0.2em;
    white-space: normal;
}
.layer-name__container {
    text-align: right;
}
.layer-type {
    color: lightgrey;
    text-align: right;
    font-size: 80%;
    font-weight: bold;
}
.elements-counter{
  padding: 0.1rem 0.2rem;
  font-weight: bold;
  font-size: 70%;
  margin-left: 0.2rem;
  background-color: grey;
  color: white;
  border-radius: 0.3rem;
  align-self: center;
}
</style>
