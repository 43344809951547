<template>
  <div v-if="category" class="flexbox">
    <div :style="{minWidth: '1rem', backgroundColor: category.color, alignSelf: 'strech'}">&nbsp;</div>
    <div>
        <div class="flexbox">
            <p class="small-muted">id:</p>
            <input type="text" v-model="category.id" />
            <p class="small-muted">имя:</p>
            <input type="text" v-model="category.name" />
        </div>
        <div class="flexbox">
            <p class="small-muted">полное имя:</p>
            <input type="text" v-model="category.fullname" />
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            category: null
        }
    },
    methods: {
        set(category){
            this.category = category;
        }
    }
}
</script>

<style>

</style>